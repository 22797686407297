import { useDispatch } from "react-redux";
import {
  apiCreateRecipeTag,
  apiDeleteRecipeTag,
  apiFindAllRecipeTag,
  apiFindOneRecipeTag,
  apiUpdateRecipeTag,
} from "../api/recipe-tag";
import {
  setLoadingRecipeTag,
  setErrorRecipeTag,
  findAllRecipeTag,
  findOneRecipeTag,
  createRecipeTag,
  updateRecipeTag,
  deleteRecipeTag,
} from "../redux/action/recipe-tag.action";
import { useHistory } from "react-router-dom";
import { setUpladImageStatus } from "../redux/action/config-app.action";
import { generateQueryString } from "../helpers/functions/generateQueryString";

export default function useRecipeTag() {
  const dispatch = useDispatch();
  const history = useHistory();

  const fetchAllRecipeTag = async (params: any) => {
    try {
      dispatch(setLoadingRecipeTag(true));
      let query = generateQueryString(params);

      const result = await apiFindAllRecipeTag(query);

      dispatch(findAllRecipeTag(result.data));

      dispatch(setLoadingRecipeTag(false));
    } catch (error: any) {
      dispatch(setLoadingRecipeTag(false));
      dispatch(setErrorRecipeTag(error.response));
    }
  };

  const fetchOneRecipeTag = async (recipeTagId: string) => {
    try {
      dispatch(setLoadingRecipeTag(true));

      const result = await apiFindOneRecipeTag(recipeTagId);

      dispatch(findOneRecipeTag(result.data.payload));

      dispatch(setLoadingRecipeTag(false));
    } catch (error: any) {
      dispatch(setLoadingRecipeTag(false));
      dispatch(setErrorRecipeTag(error.response));
    }
  };

  const addRecipeTag = async (data: any) => {
    try {
      dispatch(setLoadingRecipeTag(true));
      const result = await apiCreateRecipeTag(data);

      if (result.data.payload) {
        dispatch(createRecipeTag(result.data.payload));
        history.go(-1);
      }

      dispatch(setLoadingRecipeTag(false));
      dispatch(setUpladImageStatus(true));
    } catch (error: any) {
      dispatch(setLoadingRecipeTag(false));
      dispatch(setErrorRecipeTag(error.response));
    }
  };

  const updateOneRecipeTag = async (data: any) => {
    try {
      dispatch(setLoadingRecipeTag(true));
      const result = await apiUpdateRecipeTag(data);
      console.log(result)

      if (result.data.payload) {
        dispatch(updateRecipeTag(result.data.payload));
        history.go(-1);
      }

      dispatch(setLoadingRecipeTag(false));
      dispatch(setUpladImageStatus(true));
    } catch (error: any) {
      dispatch(setLoadingRecipeTag(false));
      dispatch(setErrorRecipeTag(error.response));
    }
  };

  const removeRecipeTag = async (recipeTagId: string) => {
    try {
      dispatch(setLoadingRecipeTag(true));

      await apiDeleteRecipeTag(recipeTagId);

      dispatch(deleteRecipeTag(recipeTagId));

      dispatch(setLoadingRecipeTag(false));
    } catch (error: any) {
      dispatch(setLoadingRecipeTag(false));
      dispatch(setErrorRecipeTag(error.response));
    }
  };

  return {
    fetchAllRecipeTag,
    fetchOneRecipeTag,
    addRecipeTag,
    updateOneRecipeTag,
    removeRecipeTag,
  };
}

import React, { useState, useEffect, useContext, createContext } from "react";
import { apiGetAdminInfo } from '../api/admin';
import { apiForgotPassword, apiResetPassword, apiSignIn, apiSignOut } from '../api/auth';
import { APP_AUTH_TOKEN, getToken, removeToken, saveToken } from '../helpers/auth';
import { EPermission, ERoles, IRole, LIST_ROLE } from '../helpers/role';
import { AdminSingle } from '../types/admin.type';
import { useHistory } from 'react-router-dom';

interface ILoginData {
  email: string;
  password: string;
}
interface IResetPassword {
  sessionId: string;
  newPassword: string;
}

export interface IUser extends AdminSingle {
  permissions: Array<string>,
}

type InitialState = {
  user: IUser;
  loading: boolean;
  loadingLogout: boolean;
  signin: (data: ILoginData, cb?: () => void) => void;
  signup: (data: ILoginData, cb?: () => void) => void;
  signout: (cb?: any) => void;
  sendPasswordResetEmail: (email: string, cb?: () => void) => void;
  confirmPasswordReset: (data: IResetPassword, cb?: () => void) => void;
  userSignIn: boolean;
  fetchMyProfile: () => void;
  checkUserSignIn: (cb?: (value: boolean) => void) => void
};

const initialState: InitialState = {
  user: {
    name: '',
    email: '',
    role: '',
    phoneNumber: '',
    status: 'active',
    permissions: [],
  },
  loading: false,
  loadingLogout: false,
  signin: (data: ILoginData) => console.info("oo"),
  signup: (data: ILoginData) => console.info("oo"),
  signout: () => console.info("oo"),
  sendPasswordResetEmail: (email: string) => console.info("oo"),
  confirmPasswordReset: (data: IResetPassword) => console.info("oo"),
  userSignIn: false,
  fetchMyProfile: () => console.info("oo"),
  checkUserSignIn: () => console.info("oo")
};

const authContext = createContext(initialState);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: any) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const location = useHistory()
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingLogout, setLoadingLogout] = useState<boolean>(false);
  const [user, setUser] = useState<IUser>(initialState['user']);
  const [userSignIn, setUserSignIn] = useState<boolean>(false);

  const fetchMyProfile = async () => {
    const user = await apiGetAdminInfo()
    const myPermission: IRole | undefined = LIST_ROLE.find((item: IRole) => item.id === user.role)
    const currentUser = {
      adminId: user.adminId,
      name: user.name,
      email: user.email,
      role: user.role,
      status: user.status,
      phoneNumber: user.phoneNumber,
      imageUrl: user.imageUrl,
      permissions: myPermission ? myPermission.permissions : [],
    }

    setUser(currentUser)
  }

  const signin = async (data: ILoginData, cb?: any) => {
    try {
      setLoading(true)
      const result = await apiSignIn(data)

      if (result && result.data && result.data.payload) {
        await saveToken(result.data.payload.token)

        await fetchMyProfile()

        setUserSignIn(true)

        setLoading(false)

        if (cb && result.data.code && result.data.code === 'success') {
          cb();
        }

      }
    } catch (error) {
      setLoading(false)
    }
  };

  const signup = (data: ILoginData, cb?: any) => {
    console.info("email", data.email, data.password);
    if (cb) cb();
  };

  const signout = async (cb?: any) => {
    try {
      setLoadingLogout(true)
      await apiSignOut()
      setLoadingLogout(false)
      removeToken()
      if (cb) cb();
    } catch (error) {
      setLoadingLogout(false)
      console.info('ERROR_LOGOUT:::', error)
    }
  };

  const sendPasswordResetEmail = async (email: string, cb?: any) => {
    try {
      setLoading(true)
      const result = await apiForgotPassword(email)
      if (result && result.data && result.data.payload && result.data.payload.isSuccess) {
        if (cb) cb();
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  };

  const confirmPasswordReset = async (data: IResetPassword, cb?: any) => {
    try {
      setLoading(true)
      const result = await apiResetPassword({
        sessionId: data.sessionId,
        newPassword: data.newPassword,
      })
      if (result && result.data && result.data.payload && result.data.payload.isSuccess) {
        if (cb) cb();
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  };

  const checkUserSignIn = async (cb?: any) => {
    setLoading(true)
    let token = await getToken()
    if (token) {
      if (!user || !user.name) {
        await fetchMyProfile()
      }
      setUserSignIn(true)
      setLoading(false)
    } else {
      setLoading(false)
      setUserSignIn(false)
      setUser(initialState['user']);
      if (window.location.pathname !== "/") {
        window.location.href = "/";
      }
    }
  }

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    // checkUserSignIn()
    // let token = window.localStorage.getItem(APP_AUTH_TOKEN);
    // if (token) {
    //   if (!user || !user.name) {
    //     fetchMyProfile()
    //   }
    //   setUserSignIn(true);
    // } else {
    //   setUserSignIn(false);
    //   setUser(initialState['user']);
    //   if (window.location.pathname !== "/") {
    //     window.location.href = "/";
    //   }
    // }
    // Cleanup subscription on unmount
    // return () => unsubscribe();
  }, []);



  // Return the user object and auth methods
  return {
    user,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    userSignIn,
    fetchMyProfile,
    loading,
    loadingLogout,
    checkUserSignIn
  };
}

export default useAuth;

import { FormControl, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import { Link } from "react-router-dom";
import useGlobalStyles from "../../styles/globalStyles";
import AppButton from "../../components/Form/AppButton";
import HeaderPage from "../../components/Layout/HeaderPage";
import Search from "../../components/Form/Search";
import useTag from '../../hooks/useTag';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from '../../hooks/useDebounce';
import { ApplicationState } from '../../redux/store';
import { generateQueryString } from '../../helpers/functions/generateQueryString';
import { useLocation } from 'react-router-dom';
import { setLatestParams, enableUpdateParams } from '../../redux/action/config-app.action';
import useRecipeTag from "../../hooks/useRecipeTag";
import { deleteRecipeTag, findOneRecipeTag } from "../../redux/action/recipe-tag.action";

const headers: ITableHeader[] = [
  {
    title: "recipeTagId",
    column: "recipeTagId",
    type: "id",
  },
  {
    title: "Recipe Tag ID",
    column: "recipeTagId",
    width: '20%'
  },
  {
    title: "Name",
    sortable: true,
    column: "recipeTagName",
  },
  {
    title: "Created At",
    column: "createdAt",
    type: 'date',
    width: "20%",
  },
];

export default function RecipeTag() {
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();

  const recipeTagState = useSelector((state: ApplicationState) => state.recipeTag);

  const [page, setPage] = useState(1);
  const [filterStatus, setFilterStatus] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState<string | null>(null);
  const [query, setQuery] = useState({ limit: 25, offset: 0, search: '', published: 1 });

  const { fetchAllRecipeTag } = useRecipeTag();
  const debouncedTitle = useDebounce(search, 300);

  const location = useLocation();

  const { updateParams, latestParams } = useSelector((state: ApplicationState) => state.configApp)




  useEffect(() => {
    if (updateParams) {
      console.info('update true')
      dispatch(setLatestParams({
        pathname: location.pathname,
        params: generateQueryString(query),
        query: {
          limit: query['limit'],
          offset: query['offset'],
          published: query['published'],
          page
        }
      }))
      fetchAllRecipeTag(query)
    } else {
      console.info('update false')
      setPage(latestParams.query.page || page || 1)
      fetchAllRecipeTag(latestParams.query)
      dispatch(enableUpdateParams(true))
    }
  }, [query])

  useEffect(() => {
    searchTagTitle(search);
  }, [debouncedTitle]);

  const searchTagTitle = async (value: any) => {
    if (value !== null && value.length > 0) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          offset: 0,
          limit: 25,
          search: value,
        };
      });
      setPage(1)
    } else if (value !== null) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          search: '',
        };
      });
    }
  };

  useEffect(() => {
    setTotalData(recipeTagState.data.payload.count);
    if (recipeTagState.data.payload.count > 0) {
      setTotalPage(Math.ceil(recipeTagState.data.payload.count / rowsPerPage));
    } else {
      setTotalPage(1)
    }
  }, [recipeTagState.data]);

  function changePagination(value: number) {
    setPage(value);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
        limit: rowsPerPage
      };
    });
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value);
    setPage(1)
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: 0,
        limit: value
      };
    });
  }

  function setFilter(value: number) {
    setFilterStatus(value);
    setQuery((val: any) => {
      return {
        ...val,
        published: value,
      };
    });
  }

  return (
    <Layout title="Recipe Tag" loading={recipeTagState.isLoadingRecipeTag}>
      <HeaderPage title="Recipe Tag" subtitle="List of all recipe tag" />

      <Grid container spacing={3} justify="space-between">
        <Grid item xs={12} md={5} lg={7}>
          <Link
            to={{ pathname: "create-recipe-tag" }}
            className={globalClasses.link}
          >
            <AppButton
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
            >
              Add Recipe Tag
            </AppButton>
          </Link>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Search
            placeholder="Search by tag name"
            onChange={(value: string) => setSearch(value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <CustomizedTable
            data={recipeTagState.data.payload.results}
            headers={headers}
            totalData={totalData}
            showAction={["detail", "edit"]}
            page={page}
            totalPage={totalPage}
            rowsPerPage={rowsPerPage}
            onPressDetail={(data: any) => {
              dispatch(findOneRecipeTag(data));
            }}
            onPressEdit={(data: any) => {
              dispatch(findOneRecipeTag(data));
            }}
            onChange={(newPage) => changePagination(newPage)}
            onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
          />
        </Grid>
      </Grid>
    </Layout>
  );
}

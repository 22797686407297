
export enum RecipeTagTypes {
  FIND_ALL_RECIPE_TAG = 'FIND_ALL_RECIPE_TAG',
  FIND_ONE_RECIPE_TAG = 'FIND_ONE_RECIPE_TAG',
  CREATE_RECIPE_TAG = 'CREATE_RECIPE_TAG',
  UPDATE_RECIPE_TAG = 'UPDATE_RECIPE_TAG',
  REMOVE_RECIPE_TAG = 'REMOVE_RECIPE_TAG',
  SET_LOADING_RECIPE_TAG = 'SET_LOADING_RECIPE_TAG',
  SET_ERROR_RECIPE_TAG = 'SET_ERROR_RECIPE_TAG',
  SET_LAST_PATH_RECIPE_TAG = 'SET_LAST_PATH_RECIPE_TAG',
}

export type RecipeTagResponse = {
  recipeTagName: string;
  recipeTagId: string;
  updatedAt?: Date | string;
  createdAt?: Date | string;
  deletedAt?: Date | string;
}

export type RecipeTagRequest = {
  recipeTagId?: string;
  recipeTagName: string;
  pdatedAt?: Date | string;
  createdAt?: Date | string;
  deletedAt?: Date | string;
}

export type RecipeTagResponsePayload = {
  code: string | null;
  message: string | null;
  payload: {
    next: string;
    prev: string;
    count: number;
    results: RecipeTagResponse[]
  }
}

export type RecipeTagState = {
  isLoadingRecipeTag: boolean;
  data: RecipeTagResponsePayload;
  recipeTag: RecipeTagResponse;
  error: any;
  isSuccess: boolean;
}
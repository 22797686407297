/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Typography,
  Paper,
  createStyles,
  makeStyles,
  Theme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  RadioGroup,
  Radio,
  FormControlLabel,
  CircularProgress,
  Divider,
  TextField,
} from "@material-ui/core";
import React, { ChangeEvent, useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import AppButton from "../../components/Form/AppButton";
import COLORS from "../../styles/colors";
import { useLocation, useParams } from "react-router-dom";
import UploadImage from "../../components/Form/UploadImage";
import ProductPreview from "../../components/Product/ProductPreview";
import ContentPreview from "../../components/Content/ContentPreview";
import FormInput from "../../components/Form/FormInput";
import HeaderPage from "../../components/Layout/HeaderPage";
import { EActionType } from "../../types/banner.type";
import { ENewsStatus, NewsResponse } from "../../types/news.type";
import useBanner from "../../hooks/useBanner";
import useNews from "../../hooks/useNews";
import useProduct from "../../hooks/useProduct";
import usePromotion from "../../hooks/usePromotion";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { ProductResponse } from "../../types/product.type";
import { Autocomplete } from "@material-ui/lab";
import useDebounce from "../../hooks/useDebounce";
import { useHistory } from "react-router-dom";
import { LoadingScreen } from "../../components/LoadingScreen";
import { findOneBanner } from "../../redux/action/content/banner.action";
import { initBanner } from "../../redux/reducer/content/banner.reducer";
import { initNews } from "../../redux/reducer/content/news.reducer";
import { initProduct } from "../../redux/reducer/product.reducer";
import { enableUpdateParams } from "../../redux/action/config-app.action";
import { toast } from "react-toastify";

const listActionType = [
  {
    key: EActionType.NEW_CONTENT,
    label: "New Content",
  },
  {
    key: EActionType.EXISTING_PRODUCT,
    label: "Use Existing Product",
  },
  {
    key: EActionType.EXISTING_PROMOTION,
    label: "Use Existing Promotion",
  },
  {
    key: EActionType.EXISTING_NEWS,
    label: "Use Existing News & Event",
  },
  {
    key: EActionType.NO_ACTION,
    label: "No Action",
  },
];

export default function BannerEdit() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();

  const classes = useStyles();

  const [mode, setMode] = useState<"detail" | "edit" | "create">("detail");

  const [titleBanner, setTitleBanner] = useState<any>("");
  const [status, setStatus] = useState<ENewsStatus>(ENewsStatus.UNPUBLISHED);
  const [actionType, setActionType] = useState<EActionType | string>(
    EActionType.NO_ACTION
  );
  const [content, setContent] = useState<any>("");
  const [file, setFile] = useState<any>(null);
  const [search, setSearch] = useState("");

  const [relatedProduct, setRelatedProduct] = useState<ProductResponse>();
  const [relatedNews, setRelatedNews] = useState<NewsResponse>();
  const [relatedPromotion, setRelatedPromotion] = useState<NewsResponse>();
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState({ limit: 25, offset: 0, search: "" });

  const bannerState = useSelector((state: ApplicationState) => state.banner);
  const productState = useSelector((state: ApplicationState) => state.product);
  const newsState = useSelector((state: ApplicationState) => state.news);
  const promotionState = useSelector(
    (state: ApplicationState) => state.promotion
  );

  const debouncedTitle = useDebounce(search, 300);

  const { createNewBanner, updateCurrentBanner, fetchOneBanner } = useBanner();
  const { fetchAllProduct, fetchOneProduct } = useProduct();
  const { fetchAllNews, fetchOneNews } = useNews();
  const { fetchAllPromotion, fetchOnePromotion } = usePromotion();

  useEffect(() => {
    searchBank(search);
  }, [debouncedTitle]);

  const searchBank = async (value: any) => {
    if (value.length > 0) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          search: value,
        };
      });
    } else {
      setQuery((oldVal: any) => {
        return { limit: 25, offset: 0, search: "" };
      });
    }
  };

  useEffect(() => {
    if (id && location.pathname.includes("edit")) {
      // edit
      fetchOneBanner(id);
      setMode("edit");
    } else if (id) {
      // detail
      fetchOneBanner(id);
      setMode("detail");
    } else {
      // create
      setMode("create");
      dispatch(findOneBanner(initBanner));
      setRelatedNews(initNews);
      setRelatedProduct(initProduct);
    }
  }, []);

  useEffect(() => {
    if (bannerState.banner) {
      setStatus(bannerState.banner.status);
      setActionType(bannerState.banner.actionType);
      if (bannerState.banner.actionType === EActionType.NEW_CONTENT) {
        setTitleBanner(bannerState.banner.title);
        setContent(bannerState.banner.content);
      }
      if (bannerState.banner.actionType === EActionType.EXISTING_PRODUCT) {
        fetchOneProduct(bannerState.banner.contentId);
      }
      if (bannerState.banner.actionType === EActionType.EXISTING_NEWS) {
        fetchOneNews(bannerState.banner.contentId);
      }
      if (bannerState.banner.actionType === EActionType.EXISTING_PROMOTION) {
        fetchOnePromotion(bannerState.banner.contentId);
      }
    }
  }, [bannerState.banner]);

  useEffect(() => {
    if (actionType === EActionType.EXISTING_PRODUCT) {
      fetchAllProduct(query);
    } else if (actionType === EActionType.EXISTING_NEWS) {
      fetchAllNews(query);
    } else if (actionType === EActionType.EXISTING_PROMOTION) {
      fetchAllPromotion(query);
    }
  }, [query, open]);

  useEffect(() => {
    if (productState.product) {
      setRelatedProduct(productState.product);
    }
  }, [productState.product]);

  useEffect(() => {
    if (newsState.news) {
      setRelatedNews(newsState.news);
    }
  }, [newsState.news]);

  useEffect(() => {
    if (promotionState.promotion) {
      setRelatedPromotion(promotionState.promotion);
    }
  }, [promotionState.promotion]);

  const handleChangeActionType = (event: any, newValue: string) => {
    if (newValue === EActionType.EXISTING_NEWS) {
      setActionType(EActionType.EXISTING_NEWS);
    } else if (newValue === EActionType.EXISTING_PRODUCT) {
      setActionType(EActionType.EXISTING_PRODUCT);
    } else if (newValue === EActionType.EXISTING_PROMOTION) {
      setActionType(EActionType.EXISTING_PROMOTION);
    } else if (newValue === EActionType.NEW_CONTENT) {
      setActionType(EActionType.NEW_CONTENT);
    } else if (newValue === EActionType.NO_ACTION) {
      setActionType(EActionType.NO_ACTION);
    }
  };

  const submit = async () => {
    const formData = new FormData();

    if (file) {
      formData.append("image", file);
    }

    formData.append("actionType", actionType);
    formData.append("status", status);

    if (actionType === EActionType.NEW_CONTENT) {
      if (!titleBanner) {
        toast.error("Title must be filled", {
          position: toast.POSITION.TOP_CENTER,
          pauseOnHover: false,
        });

        return true;
      }

      if (!content) {
        toast.error("Content must be filled", {
          position: toast.POSITION.TOP_CENTER,
          pauseOnHover: false,
        });

        return true;
      }

      formData.append("title", titleBanner);
      formData.append("content", content);
    }

    if (actionType === EActionType.EXISTING_PRODUCT) {
      if (!relatedProduct?.productId) {
        toast.error("Product must be selected", {
          position: toast.POSITION.TOP_CENTER,
          pauseOnHover: false,
        });

        return true;
      }

      formData.append("contentId", relatedProduct?.productId as string);
      formData.append("tableName", "products");
    }

    if (actionType === EActionType.EXISTING_NEWS) {
      if (!relatedNews?.newsId) {
        toast.error("News must be selected", {
          position: toast.POSITION.TOP_CENTER,
          pauseOnHover: false,
        });

        return true;
      }

      formData.append("contentId", relatedNews?.newsId as string);
      formData.append("tableName", "news");
    }

    if (actionType === EActionType.EXISTING_PROMOTION) {
      if (!relatedPromotion?.newsId) {
        toast.error("Promotion must be selected", {
          position: toast.POSITION.TOP_CENTER,
          pauseOnHover: false,
        });

        return true;
      }

      formData.append("contentId", relatedPromotion?.newsId as string);
      formData.append("tableName", "news");
    }
    if (id) {
      formData.append("bannerId", id);
      await updateCurrentBanner(formData);
      cleanData();
    } else {
      await createNewBanner(formData);
      cleanData();
    }
  };

  const showActionType = () => {
    const find = listActionType.find((item) => item.key === actionType);
    return find ? find.label : "-";
  };

  const cleanData = () => {
    setTitleBanner("");
    setStatus(ENewsStatus.UNPUBLISHED);
    setActionType(EActionType.NO_ACTION);
    setContent("");
    setFile(null);
    history.go(-1);
  };

  let title = "";
  let subtitle = "";

  if (mode === "create") {
    title = "Create Banner";
    subtitle = "Create new banner";
  } else if (mode === "edit") {
    title = "Edit Banner";
    subtitle = "Edit banner";
  } else if (mode === "detail") {
    title = "Detail Banner";
    subtitle = "Detail banner";
  }

  return (
    <Layout title={title}>
      {bannerState.isLoadingBanner ? (
        <LoadingScreen />
      ) : (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <HeaderPage
              title={title}
              subtitle={subtitle}
              showBackButton
              onBack={() => {
                history.go(-1);
                setFile(null);
              }}
            />

            {(mode === "edit" || mode === "create") && (
              <Box display="flex" justifyContent="flex-end">
                <AppButton
                  color="secondary"
                  onClick={() => {
                    dispatch(enableUpdateParams(false));
                    history.go(-1);
                    setFile(null);
                  }}
                >
                  Cancel
                </AppButton>
                <AppButton
                  loading={bannerState.isLoadingBanner}
                  disabled={
                    mode === "create" && (!file || !status || !actionType)
                  }
                  color="primary"
                  onClick={submit}
                >
                  Save
                </AppButton>
              </Box>
            )}
            {mode === "detail" && (
              <Box display="flex" justifyContent="flex-end">
                <AppButton onClick={() => setMode("edit")} color="primary">
                  Edit
                </AppButton>
              </Box>
            )}
          </Box>

          <Paper elevation={3} className={classes.paper}>
            <Box display="flex" className={classes.containerForm}>
              <Box width="50%" className={classes.input}>
                <UploadImage
                  image={bannerState.banner.imageLink || ""}
                  title="Banner Image"
                  imageResolution="1200x840"
                  onFileSelected={(e: any) => setFile(e)}
                  editable={mode === "edit" || mode === "create"}
                />

                <div style={{ marginTop: 20 }}>
                  <Typography>Choose banner action type</Typography>
                  <RadioGroup
                    row
                    aria-label="action_type"
                    name="action_type"
                    onChange={(
                      event: ChangeEvent<HTMLInputElement>,
                      newValue: string
                    ) => handleChangeActionType(event, newValue)}
                    value={actionType}
                  >
                    {listActionType.map((item, index) => (
                      <FormControlLabel
                        disabled={mode === "detail"}
                        key={index}
                        style={{ width: "100%" }}
                        value={item.key}
                        control={<Radio color="primary" />}
                        label={item.label}
                      />
                    ))}
                  </RadioGroup>
                </div>

                <FormControl className={classes.formStatus}>
                  <InputLabel id="select-status" required shrink={true}>
                    Status
                  </InputLabel>
                  <Select
                    readOnly={mode === "detail"}
                    required
                    label="Status"
                    placeholder="Status"
                    labelId="select-status"
                    id="demo-customized-select"
                    value={status}
                    onChange={(event: any) => {
                      setStatus(event.target.value);
                    }}
                  >
                    <MenuItem value={ENewsStatus.PUBLISHED}>Published</MenuItem>
                    <MenuItem value={ENewsStatus.UNPUBLISHED}>
                      Unpublished
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box width="50%" className={classes.input}>
                {actionType && actionType !== EActionType.NO_ACTION && (
                  <div style={{ marginBottom: 8 }}>
                    <Typography
                      style={{
                        marginTop: 20,
                        color: COLORS.grey,
                        fontSize: 11,
                      }}
                    >
                      Action Type
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        marginBottom: 20,
                        fontSize: 14,
                        lineHeight: 1.4,
                      }}
                    >
                      {showActionType()}
                    </Typography>
                  </div>
                )}

                {(actionType === EActionType.EXISTING_PROMOTION ||
                  actionType === EActionType.EXISTING_NEWS) && (
                  <>
                    {mode === "detail" ? (
                      <>
                        <Typography
                          variant="caption"
                          display="block"
                          style={{ color: COLORS.grey, marginBottom: 8 }}
                        >
                          Selected{" "}
                          {actionType === EActionType.EXISTING_PROMOTION
                            ? "Promotion"
                            : actionType === EActionType.EXISTING_NEWS
                            ? "News & Event"
                            : "Content"}
                        </Typography>
                      </>
                    ) : (
                      <div className={classes.formStatus}>
                        <InputLabel
                          style={{ fontSize: 10 }}
                          id="select-content"
                          required
                        >
                          Choose{" "}
                          {actionType === EActionType.EXISTING_PROMOTION
                            ? "Promotion"
                            : actionType === EActionType.EXISTING_NEWS
                            ? "News & Event"
                            : "Content"}
                        </InputLabel>
                        <Autocomplete
                          id="asynchronous-demo"
                          open={open}
                          onOpen={() => {
                            setOpen(true);
                          }}
                          onClose={() => {
                            setOpen(false);
                          }}
                          value={
                            actionType === EActionType.EXISTING_NEWS
                              ? relatedNews
                              : actionType === EActionType.EXISTING_PROMOTION
                              ? relatedPromotion
                              : relatedNews
                          }
                          onChange={(event: any, newValue: any) => {
                            if (actionType === EActionType.EXISTING_NEWS) {
                              setRelatedNews(newValue);
                            }
                            if (actionType === EActionType.EXISTING_PROMOTION) {
                              setRelatedPromotion(newValue);
                            }
                          }}
                          getOptionSelected={(
                            option: NewsResponse,
                            value: any
                          ) => option.title === value.title}
                          getOptionLabel={(option: NewsResponse) =>
                            option.title as string
                          }
                          options={
                            actionType === EActionType.EXISTING_NEWS
                              ? newsState.data.payload.results
                              : actionType === EActionType.EXISTING_PROMOTION
                              ? promotionState.data.payload.results
                              : newsState.data.payload.results
                          }
                          loading={newsState.isLoadingNews}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              variant="standard"
                              placeholder={
                                "Pilih " + actionType ===
                                EActionType.EXISTING_NEWS
                                  ? "News & Event..."
                                  : "Promotion..."
                              }
                              onChange={(e: any) => setSearch(e.target.value)}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {newsState.isLoadingNews ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </div>
                    )}

                    <Typography
                      style={{
                        marginTop: 20,
                        color: COLORS.grey,
                        fontSize: 11,
                        marginBottom: 4,
                      }}
                    >
                      This slider will related to this{" "}
                      {actionType === EActionType.EXISTING_PROMOTION
                        ? "Promotion"
                        : actionType === EActionType.EXISTING_NEWS
                        ? "News & Event"
                        : "Content"}
                    </Typography>

                    {actionType === EActionType.EXISTING_PROMOTION &&
                      !relatedPromotion && (
                        <Box
                          display="flex"
                          width="100%"
                          height={150}
                          alignItems="center"
                          justifyContent="center"
                          color={COLORS.grey}
                          style={{
                            borderColor: COLORS.grey,
                            borderWidth: 1,
                            borderStyle: "solid",
                            textAlign: "center",
                            borderRadius: 10,
                          }}
                        >
                          <Typography variant="body1">
                            No promotion selected
                          </Typography>
                        </Box>
                      )}

                    {actionType === EActionType.EXISTING_NEWS &&
                      !relatedNews && (
                        <Box
                          display="flex"
                          width="100%"
                          height={150}
                          alignItems="center"
                          justifyContent="center"
                          color={COLORS.grey}
                          style={{
                            borderColor: COLORS.grey,
                            borderWidth: 1,
                            borderStyle: "solid",
                            textAlign: "center",
                            borderRadius: 10,
                          }}
                        >
                          <Typography variant="body1">
                            No news & event selected
                          </Typography>
                        </Box>
                      )}

                    {actionType === EActionType.EXISTING_NEWS &&
                      relatedNews &&
                      relatedNews.newsId && (
                        <ContentPreview news={relatedNews} />
                      )}
                    {actionType === EActionType.EXISTING_PROMOTION &&
                      relatedPromotion &&
                      relatedPromotion.newsId && (
                        <ContentPreview news={relatedPromotion} />
                      )}
                  </>
                )}

                {actionType === EActionType.EXISTING_PRODUCT && (
                  <div>
                    <div className={classes.formStatus}>
                      <InputLabel
                        id="select-product"
                        required
                        style={{ fontSize: 10 }}
                      >
                        Choose Product
                      </InputLabel>
                      <Autocomplete
                        disabled={mode === "detail"}
                        id="asynchronous-demo"
                        open={open}
                        onOpen={() => {
                          setOpen(true);
                        }}
                        onClose={() => {
                          setOpen(false);
                        }}
                        value={relatedProduct}
                        onChange={(event: any, newValue: any) => {
                          setRelatedProduct(newValue);
                          console.info("prod", newValue);
                        }}
                        getOptionSelected={(
                          option: ProductResponse,
                          value: any
                        ) => option.productName === value.productName}
                        getOptionLabel={(option: ProductResponse) =>
                          option.productName
                        }
                        options={productState.data.payload.results}
                        loading={productState.isLoadingProduct}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder="Pilih Produk..."
                            onChange={(e: any) => setSearch(e.target.value)}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {productState.isLoadingProduct ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />

                      <Typography style={{ marginTop: 10, marginBottom: 5 }}>
                        This slider will related to this Product
                      </Typography>
                    </div>
                    {!relatedProduct && (
                      <Box
                        display="flex"
                        width="100%"
                        height={150}
                        alignItems="center"
                        justifyContent="center"
                        color={COLORS.grey}
                        style={{
                          borderColor: COLORS.grey,
                          borderWidth: 1,
                          borderStyle: "solid",
                          textAlign: "center",
                          borderRadius: 10,
                        }}
                      >
                        <Typography variant="body1">
                          No product selected
                        </Typography>
                      </Box>
                    )}
                    {relatedProduct && relatedProduct.productId && (
                      <ProductPreview product={relatedProduct} />
                    )}
                  </div>
                )}

                {actionType === EActionType.NEW_CONTENT && (
                  <Box>
                    <FormInput
                      value={titleBanner}
                      onChange={(value: any) => setTitleBanner(value)}
                      fullWidth
                      required
                      placeholder="Title"
                      label="Title"
                    />
                    <FormInput
                      value={content}
                      onChange={(html: string) => setContent(html)}
                      type="wyswyg"
                      fullWidth
                      required
                      placeholder="Description"
                      label="Description"
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Paper>
        </>
      )}
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 0, 0, 0),
      padding: theme.spacing(2),
    },
    containerForm: {},
    input: {
      padding: theme.spacing(0, 2, 1, 2),
    },
    paperTitle: {
      padding: theme.spacing(2),
    },
    containerButton: {
      flexDirection: "row",
      padding: theme.spacing(2),
    },
    formStatus: {
      width: "100%",
      margin: theme.spacing(1, 0, 2, 0),
    },
    cardRoot: {
      width: "100%",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      backgroundColor: COLORS.greyLighten,
      marginTop: 10,
    },
    cardMedia: {
      height: 200,
      width: 200,
      alignSelf: "center",
      backgroundColor: COLORS.accent,
      borderColor: COLORS.accent,
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 10,
    },
    cardActionArea: {
      display: "flex",
      padding: 10,
    },
  })
);

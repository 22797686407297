import React from 'react'
import DetailGeneralInformation from '../DetailGeneralInformation'

interface Props {
  orderId: string
  soId: string | null
  orderStatus: string
  distributor: string
  orderDate: string | null
  approveDate: string | null
  orderNote: string
}

const GeneralInformationSO: React.FC<Props> = (props) => {
  return (
    <>
      <DetailGeneralInformation
        data={[
          {
            label: "Order ID",
            value: props.orderId,
          },
          {
            label: "SO ID",
            value: props.soId,
          },
          {
            label: "Order Status",
            value: props.orderStatus,
          },
          {
            label: "Distributor",
            value: props.distributor,
          },
          {
            label: "Order Date",
            value: props.orderDate,
          },
          {
            label: 'Approve Date',
            value: props.approveDate,
          },
        ]}
      />
    </>
  )
}

export default GeneralInformationSO
import { Reducer } from "redux";
import { PickupPointState, PickupPointTypes } from "../../types/pickup-point";
import { EStatus } from "../../types/pickup-point";

export const initPickupPoint = {
  pickupPointId: "",
  aroId: "",
  namePickupPoint: "",
  phoneNumber: "",
  metaAddress: {
    province: "",
    city: "",
    subdistrict: "",
    address: "",
    location: {
      lat: "",
      lng: "",
    },
  },
  status: EStatus.ACTIVE,
  createdAt: new Date(),
  updatedAt: new Date(),
};

const initialState: PickupPointState = {
  isLoadingPickupPoint: false,
  data: {
    code: null,
    message: null,
    payload: {
      next: "",
      prev: "",
      count: 0,
      results: [],
    },
  },
  error: {},
  pickupPoint: { ...initPickupPoint },
  isSuccess: false,
};

export const pickupPoint: Reducer<PickupPointState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case PickupPointTypes.FIND_ALL_PICKUP_POINT:
      return {
        ...state,
        data: {
          ...action.payload,
          payload: {
            ...action.payload.payload,
          },
        },
      };
    case PickupPointTypes.FIND_ALL_PICKUP_POINT_BY_ARO:
      return {
        ...state,
        data: {
          ...action.payload,
          payload: {
            ...action.payload.payload,
          },
        },
      };
    case PickupPointTypes.FIND_ONE_PICKUP_POINT:
      return {
        ...state,
        pickupPoint: action.payload,
      };
    case PickupPointTypes.CREATE_PICKUP_POINT:
      return {
        ...state,
        pickupPoint: action.payload,
        isSuccess: true,
      };
    case PickupPointTypes.UPDATE_PICKUP_POINT:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case PickupPointTypes.REMOVE_PICKUP_POINT:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case PickupPointTypes.SET_LOADING_PICKUP_POINT:
      return {
        ...state,
        isLoadingPickupPoint: action.payload,
      };
    case PickupPointTypes.SET_ERROR_PICKUP_POINT:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

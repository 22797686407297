import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";
import COLORS from "../../styles/colors";
import { ArrowDown, ArrowUp } from "react-feather";
import { convertNumToCurrency } from '../../helpers/functions/currency';
import { spawn } from 'child_process';

type Props = {
  title: string;
  count_current: number;
  count_before: number;
  topRightIconComponent: any;
  inversePositivity?: boolean;
  bottomComponent?: any;
  valueOnly?: boolean;
  totalCurrentMonth?: any;
};

export default function SimpleCard(props: Props) {
  const classes = useStyles();

  let isPositif;
  if (props.count_before < props.count_current) {
    isPositif = true;
  } else if (props.count_before > props.count_current) {
    isPositif = false;
  }

  if (props.inversePositivity) {
    isPositif = !isPositif;
  }

  return (
    <Paper elevation={3} className={classes.paper}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box style={{ width: '100%' }}>
          <Typography variant="body1">Total {props.title}</Typography>
          <Box display="flex" width="100%" alignItems="center" style={{ justifyContent: 'space-between', marginTop: props.title === 'Transaction' ? 15 : 0 }}>
            <Typography
              variant="body1"
              style={{
                fontSize: props.title === 'Transaction' ? 22 : 36,
                fontWeight: props.title === 'Transaction' ? 'bold' : 'normal'
              }}
            >
              {props.count_current}
            </Typography>
            {props.title === 'Transaction' &&
              <Typography
                variant="body1"
                style={{
                  fontSize: props.title === 'Transaction' ? 22 : 36,
                  transform: 'translateX(40px)',
                  textAlign: 'right',
                  fontWeight: props.title === 'Transaction' ? 'bold' : 'normal'
                }}
              >
                {convertNumToCurrency(props.totalCurrentMonth)}
              </Typography>
            }
          </Box>
        </Box>
        <Box width={50} display="flex" flexDirection="row">
          <Box
            className={classes.containerTopRightIcon}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {props.topRightIconComponent}
          </Box>
        </Box>
      </Box>

      <Box style={{ width: '100%' }}>
        {!props.valueOnly && (props.count_before === props.count_current) && (
          <Typography variant="body1" color="textSecondary">
            No additions from last month
          </Typography>
        )}

        {props.count_before < props.count_current && (
          <Box style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', marginTop: 15, marginBottom: 5 }}>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <span
                className={
                  isPositif
                    ? classes.containerIconSuccess
                    : classes.containerIconError
                }
              >
                <ArrowUp style={{ width: 12, height: 12 }} />
              </span>
              <Typography
                component="span"
                variant="body1"
                style={{
                  color: isPositif ? COLORS.success : COLORS.error,
                  fontWeight: "bold",
                  marginRight: 15,
                }}
              >
                {props.count_current - props.count_before} {props.title}
              </Typography>
            </Box>
            <Typography component="span" variant="subtitle1" color="textSecondary">
              Since last month
            </Typography>
          </Box>
        )}

        {props.count_before > props.count_current && (
          <Box style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', marginTop: 15, marginBottom: 5 }}>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <span
                className={
                  isPositif
                    ? classes.containerIconSuccess
                    : classes.containerIconError
                }
              >
                <ArrowDown style={{ width: 12, height: 12 }} />
              </span>
              <Typography
                component="span"
                variant="body1"
                style={{
                  color: isPositif ? COLORS.success : COLORS.error,
                  fontWeight: "bold",
                  marginRight: 15,
                }}
              >
                {props.count_before - props.count_current} {props.title}
              </Typography>
            </Box>
            <Typography component="span" variant="subtitle1" color="textSecondary">
              Since last month
            </Typography>
          </Box>
        )}
      </Box>

      {props.bottomComponent ? (
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          {props.bottomComponent}
        </Box>
      ) : (
          false
        )}
    </Paper>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 0, 0, 0),
      padding: theme.spacing(2),
    },
    containerIconSuccess: {
      backgroundColor: COLORS.successLight,
      color: COLORS.success,
      width: 20,
      height: 20,
      marginRight: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 100,
    },
    containerIconError: {
      backgroundColor: COLORS.errorLight,
      color: COLORS.error,
      width: 20,
      height: 20,
      marginRight: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 100,
    },
    containerTopRightIcon: {
      width: 40,
      height: 40,
      backgroundColor: COLORS.greyLighten,
      borderRadius: 50,
    },
  })
);

import axios from 'axios';
import { getHeaders, getHeadersMultiPart } from '../../helpers/auth';
import { NewsRequestImageOnly, NewsResponse } from '../../types/news.type';

export async function apiFindAllNews(params: string) {
  const query = params || null
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/news' + query,
    method: 'GET',
    headers
  });
}

export async function apiFindOneNews(newsId: string) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/news/' + newsId,
    method: 'GET',
    headers
  });
}

export async function apiCreateNews(data: NewsResponse) {
  const headers = await getHeadersMultiPart()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/news',
    method: 'POST',
    headers,
    data
  });
}

export async function apiUpdateNews(data: any) {
  const headers = await getHeadersMultiPart()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/news/' + data.get('newsId'),
    method: 'PATCH',
    headers,
    data
  });
}

export async function apiUpdateImagePromotion(data: NewsRequestImageOnly) {
  const headers = await getHeadersMultiPart()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/news/upload-image/' + data.newsId,
    method: 'PUT',
    headers,
    data
  });
}

export async function apiDeleteNews(newsId: string) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/news?newsId=' + newsId,
    method: 'DELETE',
    headers,
  });
}
import {initializeApp} from 'firebase/app';
import { getDatabase } from "firebase/database";
  
const firebaseConfig = {
  apiKey: "AIzaSyCgNogdFBs-BCc4i7zXXnJMaeRQN6Rl3Ho",
  authDomain: "oxone-staging.firebaseapp.com",
  databaseURL: "https://oxone-staging-default-rtdb.firebaseio.com",
  projectId: "oxone-staging",
  storageBucket: "oxone-staging.appspot.com",
  messagingSenderId: "87900109774",
  appId: "1:87900109774:web:db3ab6014c4f508a90099f",
  measurementId: "G-GP1X0GBEKG"
};
    
const app = initializeApp(firebaseConfig);
const database = getDatabase(app)
  
export default database;
import { makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

export default function CustomLink(props: any) {
  const classes = useStyles();
  return <Link {...props} className={classes.link} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.common.white,
    textDecoration: "none",
  },
}));

import { ITableHeader } from '../../components/Table/CustomizedTable';

export const headersWaitingApproval: ITableHeader[] = [
  {
    title: 'Name',
    align: 'left',
    column: 'name'
    // column: JSON.stringify(['customerMetadata', 'customerName']),
    // type: 'nested',
  },
  {
    title: 'Phone',
    align: 'left',
    column: 'phone'
    // column: JSON.stringify(['customerMetadata', 'customerPhoneNumber']),
    // type: 'nested',
  },
  {
    title: 'Bank Number',
    align: 'left',
    column: 'bankAccount',
  },
  {
    title: 'Created Date',
    align: 'left',
    sortable: true,
    column: 'createdAt',
    type: 'date'
  },
  {
    title: 'Actions',
    align: 'center',
    column: 'actions',
  },
];

export const headersApproved: ITableHeader[] = [
  {
    title: 'Name',
    align: 'left',
    column: 'name'
    // column: JSON.stringify(['customerMetadata', 'customerName']),
    // type: 'nested',
  },
  {
    title: 'Phone',
    align: 'left',
    column: 'phone'
    // column: JSON.stringify(['customerMetadata', 'customerPhoneNumber']),
    // type: 'nested',
  },
  {
    title: 'Bank Number',
    align: 'left',
    column: 'bankAccount',
  },
  {
    title: 'Approval Date',
    align: 'left',
    sortable: true,
    column: 'updatedAt',
    type: 'date'
  },
  {
    title: 'Approved By',
    align: 'left',
    column: JSON.stringify(['adminMetadata', 'adminName']),
    type: 'nested',
  },
  {
    title: 'Role',
    align: 'left',
    column: JSON.stringify(['adminMetadata', 'role']),
    type: 'nested',
  },
];

export const headersRejected: ITableHeader[] = [
  {
    title: 'Name',
    align: 'left',
    column: 'name',
  },
  {
    title: 'Phone',
    align: 'left',
    column: 'phone',
  },
  {
    title: 'Bank Number',
    align: 'left',
    column: 'bankAccount',
  },
  {
    title: 'Reject Date',
    align: 'left',
    sortable: true,
    column: 'updatedAt',
    type: 'date'
  },
  {
    title: 'Rejected Note',
    align: 'left',
    column: 'note',
  },
  {
    title: 'Rejected By',
    align: 'left',
    column: JSON.stringify(['adminMetadata', 'adminName']),
    type: 'nested',
  },
  {
    title: 'Role',
    align: 'left',
    column: JSON.stringify(['adminMetadata', 'role']),
    type: 'nested',
  },
];

import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import { generateQueryString } from "../../helpers/functions/generateQueryString";
import useProduct from "../../hooks/useProduct";
import {
  enableUpdateParams,
  setLatestParams,
} from "../../redux/action/config-app.action";
import { ApplicationState } from "../../redux/store";

const ProductPriceLog = () => {
  let { id } = useParams<{ id: string }>();
  const [history, setHistory] = useState<any>([]);
  const [historyData, setHistoryData] = useState<any>();
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);

  const { updateParams, latestParams } = useSelector(
    (state: ApplicationState) => state.configApp
  );
  const dispatch = useDispatch();
  const [query, setQuery] = useState({
    limit: 25,
    offset: 0,
    search: "",
    productId: id,
  });
  const { fetchHistoryProductPrices } = useProduct();
  const location = useLocation();
  const historyState = useSelector((state: ApplicationState) => state.product);

  let headersHistory: ITableHeader[] = [
    {
      title: "",
      align: "left",
      column: "createdAt",
      type: "date",
    },
    {
      title: "Silver",
      align: "left",

      column: JSON.stringify(["discountNote", "silver"]),
      type: "nested",
    },
    {
      title: "Gold",
      align: "left",
      column: JSON.stringify(["discountNote", "gold"]),
      type: "nested",
    },
    {
      title: "Platinum",
      align: "left",
      column: JSON.stringify(["discountNote", "platinum"]),
      type: "nested",
    },
    {
      title: "Silver",
      align: "left",
      column: JSON.stringify(["discountAmount", "silver"]),
      type: "nested-price",
    },
    {
      title: "Gold",
      align: "left",
      column: JSON.stringify(["discountAmount", "gold"]),
      type: "nested-price",
    },
    {
      title: "Platinum",
      align: "left",
      column: JSON.stringify(["discountAmount", "platinum"]),
      type: "nested-price",
    },
    {
      title: "Silver",
      align: "left",

      column: JSON.stringify(["retailPrice", "silver"]),
      type: "nested-price",
    },
    {
      title: "Gold",
      align: "left",
      column: JSON.stringify(["retailPrice", "gold"]),
      type: "nested-price",
    },
    {
      title: "Platinum",
      align: "left",
      column: JSON.stringify(["retailPrice", "platinum"]),
      type: "nested-price",
    },
    {
      title: "",
      align: "left",
      column: "updatedBy",
    },
    {
      title: "",
      align: "left",
      column: "note",
      width: 10,
    },
  ];

  useEffect(() => {
    if (updateParams) {
      dispatch(
        setLatestParams({
          pathname: location.pathname,
          params: generateQueryString(query),
          query: {
            productId: query["productId"],
            limit: query["limit"],
            offset: query["offset"],
            page,
          },
        })
      );
      fetchHistoryProductPrices(query);
    } else {
      setPage(latestParams.query.page || page || 1);
      fetchHistoryProductPrices(latestParams.query);
      dispatch(enableUpdateParams(true));
    }
  }, [query]);

  useEffect(() => {
    setTotalData(historyState?.dataHistory?.payload?.count);
    if (historyState?.dataHistory?.payload?.count > 0) {
      setTotalPage(
        Math.ceil(historyState?.dataHistory?.payload?.count / rowsPerPage)
      );
    } else {
      setTotalPage(1);
    }
  }, [historyState.dataHistory]);

  function changePagination(value: number) {
    setPage(value);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
        limit: rowsPerPage,
      };
    });
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value);
    setPage(1);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: 0,
        limit: value,
      };
    });
  }

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          fontWeight: "bold",
          justifyContent: "space-between",
        }}
      >
        <p style={{ marginLeft: "1%" }}>Date</p>
        <p>Discount (%)</p>
        <p>Discount (Rp)</p>
        <p>Retail Price</p>
        <p>Updated By</p>
        <p style={{ marginRight: "3%" }}>Note</p>
      </div>
      <CustomizedTable
        data={historyState.dataHistory.payload.results}
        headers={headersHistory}
        totalData={totalData}
        page={page}
        totalPage={totalPage}
        rowsPerPage={rowsPerPage}
        onChange={(newPage) => changePagination(newPage)}
        onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
      />
    </div>
  );
};

export default ProductPriceLog;

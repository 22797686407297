export enum AdminTypes {
  FIND_ALL_ADMIN = "FIND_ALL_ADMIN",
  FIND_ONE_ADMIN = "FIND_ONE_ADMIN",
  SET_LOADING_ADMIN = "SET_LOADING_ADMIN",
  SET_ERROR_ADMIN = "SET_ERROR_ADMIN",
  SET_LAST_PATH_ADMIN = "SET_LAST_PATH_ADMIN",
}

export type EAdminRole =
  | "admin"
  | "admin_trip"
  | "driver"
  | "transaction"
  | "service_center"
  | "packaging"
  | "finance"
  | "spg_voucher_all"
  | "spg_voucher_generate"
  | "spg_voucher_validate";

export type EAdminStatus = "active" | "inactive";

export type AdminSingle = {
  adminId?: string;
  name: string;
  email: string;
  phoneNumber: string;
  role: EAdminRole | string;
  status: EAdminStatus;
  updatedAt?: Date | string;
  createdAt?: Date | string;
  imageUrl?: string;

  updatedImage?: any;
};

export type AdminPayloadResponse = {
  code: string;
  message: string;
  payload: {
    next: string;
    prev: string;
    count: number;
    results: AdminSingle[];
  };
};

export type AdminState = {
  isLoadingAdmin: boolean;
  data: AdminPayloadResponse;
  admin: AdminSingle;
};

export const initialUserB2B: AdminSingle = {
  adminId: "",
  name: "",
  email: "",
  phoneNumber: "",
  role: "",
  status: "active",
};

import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import { Link, useHistory } from "react-router-dom";
import useGlobalStyles from "../../styles/globalStyles";
import AppButton from "../../components/Form/AppButton";
import HeaderPage from "../../components/Layout/HeaderPage";
import Search from "../../components/Form/Search";
import useCategory from "../../hooks/useCategory";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../hooks/useDebounce";
import { ApplicationState } from "../../redux/store";
import { findOneCategory } from "../../redux/action/category.action";
import { generateQueryString } from "../../helpers/functions/generateQueryString";
import { useLocation } from "react-router-dom";
import {
  setLatestParams,
  enableUpdateParams,
} from "../../redux/action/config-app.action";
import { convertDateTimeToText } from "../../helpers/functions/datetime";
import usePickupPoint from "../../hooks/usePickupPoint";
import FormInput from "../../components/Form/FormInput";
import { EStatus, PickupPointType } from "../../types/pickup-point";
import { httpRequest } from "../../helpers/api/api";
import { Coords } from "google-map-react";
import AppMap, { Marker } from "../../components/AppMap";
import IconMarker from "../../assets/distributor-marker.png";
import {
  apiCreatePickupPoint,
  apiUpdatePickupPoint,
} from "../../api/pickup-point";
import { toast } from "react-toastify";

const headers: ITableHeader[] = [
  {
    title: "pickupPointId",
    column: "pickupPointId",
    type: "id",
  },
  {
    title: "Pickup Point",
    column: "namePickupPoint",
    sortable: true,
  },
  {
    title: "Phone Number",
    column: "phoneNumber",
  },
  {
    title: "Status",
    column: "status",
  },
  {
    title: "Created At",
    column: "createdAt",
  },
];

type PickupPointProps = {
  aroId?: string;
  type?: "component";
};

export default function PickupPoint(props: PickupPointProps) {
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const aroId = props.aroId ? props.aroId : "";
  const type = props.type ? props.type : "";

  const pickupPointState = useSelector(
    (state: ApplicationState) => state.pickupPoint
  );

  const [provinces, setProvinces] = useState<any[]>([]);
  const [citys, setCitys] = useState<any[]>([]);
  const [subdistricts, setSubdistricts] = useState<any[]>([]);

  const [editProvince, setEditProvince] = useState<boolean>(false);
  const [editCity, setEditCity] = useState<boolean>(false);
  const [editsubdistrict, setEditsubdistrict] = useState<boolean>(false);

  const [selectedProvince, setSelectedProvince] = useState({
    provinceId: "",
    provinceName: "",
  });

  const [selectedCity, setSelectedCity] = useState({
    cityId: "",
    cityName: "",
  });
  const [tmpCitys, setTmpCitys] = useState<any[]>([]);
  const [tmpRegion, setTmpRegion] = useState<boolean>(false);

  const [selectedSubdistrict, setSelectedSubdistrict] = useState({
    subdistrictId: "",
    subdistrictName: "",
  });
  const [tmpSubdistricts, setTmpSubdistricts] = useState<any[]>([]);

  const [page, setPage] = useState(1);
  const [filterStatus, setFilterStatus] = useState("");
  const [handleChangeStatus, setHandleChangeStatus] = useState("");
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [query, setQuery] = useState({
    limit: 25,
    offset: 0,
    search: "",
    status: "",
  });

  const [centerMap, setCentermap] = useState<Coords>({
    lat: -6.1734251377558955,
    lng: 106.82676927467757,
  });
  const [markers, setMarkers] = useState<Array<Marker>>([]);

  const [mode, setMode] = useState<"edit" | "create">("create");
  const [pickupPointId, setPickupPointId] = useState<string>("");

  const {
    fetchAllPickupPoint,
    fetchOnePickupPoint,
    fetchAllPickupPointByAro,
    addPickupPoint,
    updateOnePickupPoint,
    removePickupPoint,
  } = usePickupPoint();

  const location = useLocation();

  const { updateParams, latestParams } = useSelector(
    (state: ApplicationState) => state.configApp
  );

  const [pickupPointData, setPickupPointData] = useState<PickupPointType>({
    pickupPointId: "",
    aroId: aroId ? aroId : null,
    namePickupPoint: "",
    phoneNumber: "",
    metaAddress: {
      province: "",
      city: "",
      subdistrict: "",
      address: "",
      location: {
        lat: "",
        lng: "",
      },
    },
    status: EStatus.ACTIVE,
    createdAt: new Date(),
  });

  useEffect(() => {
    if (updateParams) {
      console.info("update true");
      console.log(query);
      dispatch(
        setLatestParams({
          pathname: location.pathname,
          params: generateQueryString(query),
          query: {
            limit: query["limit"],
            offset: query["offset"],
            status: query["status"],
          },
        })
      );
      if (type) {
        fetchAllPickupPointByAro(aroId, query);
      } else {
        fetchAllPickupPoint(query);
      }
      console.log(pickupPointState.data.payload.results);
    } else {
      console.info("update false");
      setPage(latestParams.query.page || page || 1);
      if (aroId) {
        fetchAllPickupPointByAro(aroId, query);
      } else {
        fetchAllPickupPoint(query);
      }
      dispatch(enableUpdateParams(true));
    }
  }, [query]);

  useEffect(() => {
    setTotalData(pickupPointState.data.payload.count);
    if (pickupPointState.data.payload.count > 0) {
      setTotalPage(
        Math.ceil(pickupPointState.data.payload.count / rowsPerPage)
      );
    } else {
      setTotalPage(1);
    }
  }, [pickupPointState.data]);

  function changePagination(value: number) {
    setPage(value);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
        limit: rowsPerPage,
      };
    });
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value);
    setPage(1);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: 0,
        limit: value,
      };
    });
  }

  function setFilter(value: string) {
    setFilterStatus(value);
    setQuery((val: any) => {
      return {
        ...val,
        status: value,
      };
    });
  }

  const handleAddPickupPoint = () => {
    setIsShowDialog(true);
  };

  const handleCloseDialog = () => {
    setIsShowDialog(false);
    setPickupPointData({
      pickupPointId: "",
      aroId: aroId ? aroId : null,
      namePickupPoint: "",
      phoneNumber: "",
      metaAddress: {
        province: "",
        city: "",
        subdistrict: "",
        address: "",
        location: {
          lat: "",
          lng: "",
        },
      },
      status: EStatus.ACTIVE,
    });
    setEditProvince(false);
    setSelectedProvince({
      provinceId: "",
      provinceName: "",
    });
    setSelectedCity({
      cityId: "",
      cityName: "",
    });
    setSelectedSubdistrict({
      subdistrictId: "",
      subdistrictName: "",
    });
    setPickupPointId("");
    setCentermap({
      lat: -6.1734251377558955,
      lng: 106.82676927467757,
    });

    setMarkers([
      {
        lat: 0,
        lng: 0,
        content: <></>,
      },
    ]);
  };

  useEffect(() => {
    httpRequest
      .get("/province")
      .then(({ data }) => {
        setProvinces(data.payload.results);
      })
      .catch((err) => {
        console.log(err);
      });

    httpRequest
      .get("/city")
      .then(({ data }) => {
        setCitys(data.payload.results);
      })
      .catch((err) => {
        console.log(err);
      });

    httpRequest
      .get("/subdistrict")
      .then(({ data }) => {
        setSubdistricts(data.payload.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (pickupPointId) {
      setCentermap({
        lat: pickupPointData?.metaAddress?.location?.lat,
        lng: pickupPointData?.metaAddress?.location?.lng,
      });

      setMarkers([
        {
          lat: pickupPointData?.metaAddress?.location?.lat,
          lng: pickupPointData?.metaAddress?.location?.lng,
          content: (
            <img
              style={{
                transform: "translateZ(0) translate(-50%, -100%)",
                backfaceVisibility: "hidden",
                height: 50,
              }}
              src={IconMarker}
            />
          ),
        },
      ]);
    }
  }, [pickupPointData]);

  const handleShowCitys = (provinceId: any) => {
    const newData = citys.filter((item: any) => item.provinceId === provinceId);
    setTmpCitys(newData);
  };

  const handleShowSubdistrics = (provinceId: any) => {
    const newData = subdistricts.filter(
      (item: any) => item.cityId === provinceId
    );
    setTmpSubdistricts(newData);
  };

  const cancel = () => {
    dispatch(enableUpdateParams(false));
    history.go(-1);
  };

  const handleChangeMarker = (value: { lat: number; lng: number }) => {
    setMarkers([
      {
        lat: value.lat,
        lng: value.lng,
        content: (
          <img
            style={{
              transform: "translateZ(0) translate(-50%, -100%)",
              backfaceVisibility: "hidden",
              height: 50,
            }}
            src={IconMarker}
          />
        ),
      },
    ]);

    setPickupPointData({
      ...pickupPointData,
      metaAddress: {
        ...pickupPointData.metaAddress,
        location: {
          lat: value.lat,
          lng: value.lng,
        },
      },
    });
  };

  useEffect(() => {
    if (pickupPointId !== "") {
      fetchOnePickupPoint(pickupPointId);
      setPickupPointData(pickupPointState.pickupPoint);
    }
  }, [pickupPointId]);

  const handleEditData = (data: any) => {
    // console.log(data.pickupPointId);
    setTimeout(() => {
      setPickupPointId(data.pickupPointId);
      setPickupPointData({
        pickupPointId: data.pickupPointId,
        aroId: aroId ? aroId : null,
        namePickupPoint: data.namePickupPoint.props.children,
        phoneNumber: data.phoneNumber.props.children,
        metaAddress: {
          ...data.metaAddress,
        },
        status: data.status.props.label,
      });
    }, 200);
    setIsShowDialog(true);
  };

  const submitData = async () => {
    console.log("create data", pickupPointData);
    // addPickupPoint(pickupPointData)
    try {
      setLoadingSave(true);

      console.log(`body: ${JSON.stringify(pickupPointData)}`);

      const result = await apiCreatePickupPoint(pickupPointData);

      if (result.data.payload) {
        console.log(`Success create pickup point`);
        toast.success("Success create pickup point", {
          position: toast.POSITION.TOP_CENTER,
          pauseOnHover: false,
        });

        if (type) {
          fetchAllPickupPointByAro(aroId, generateQueryString(query));
        } else {
          fetchAllPickupPoint(generateQueryString(query));
        }

        handleCloseDialog();
      }
      setLoadingSave(false);
    } catch (error) {
      console.log(error);
      toast.error("Failed create pickup point", {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
      setLoadingSave(false);
    }
  };

  const updateData = async () => {
    console.log("update data", pickupPointData);
    // updateOnePickupPoint(pickupPointData.namePickupPoint, pickupPointData)
    try {
      setLoadingSave(true);

      console.log(`body: ${JSON.stringify(pickupPointData)}`);

      const result = await apiUpdatePickupPoint(pickupPointId, pickupPointData);

      if (result.data.payload) {
        console.log(`Success update pickup point`);
        toast.success("Success update pickup point", {
          position: toast.POSITION.TOP_CENTER,
          pauseOnHover: false,
        });

        if (type) {
          fetchAllPickupPointByAro(aroId, generateQueryString(query));
        } else {
          fetchAllPickupPoint(generateQueryString(query));
        }

        handleCloseDialog();
      }
      setLoadingSave(false);
    } catch (error) {
      console.log(error);
      toast.error("Failed update pickup point", {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
      setLoadingSave(false);
    }
  };

  let title = "Oxone Pickup Point";
  let subtitle = "List of all Oxone Pickup Point";

  const data = pickupPointState.data.payload.results
    .map((item: any) => {
      return {
        ...item,
        namePickupPoint: (
          <Typography variant="body1">{item.namePickupPoint}</Typography>
        ),
        phoneNumber: (
          <Typography variant="body1">{item.phoneNumber}</Typography>
        ),
        address: <Typography variant="body1">{item.address}</Typography>,
        status: (
          <Chip
            size="small"
            color="primary"
            label={item.status}
            style={{ textTransform: "uppercase" }}
          />
        ),
        createdAt: (
          <Typography variant="body1">
            {convertDateTimeToText(item.createdAt, "dd mmm yyyy")}
          </Typography>
        ),
      };
    })
    .filter((item: any) => {
      if (type) {
        return true;
      } else {
        return item.aroId === "" || item.aroId === null;
      }
    });

  return (
    <>
      {type === "component" ? (
        <>
          <Grid container spacing={3} justify="space-between">
            <Grid item xs={12} md={12} lg={8}>
              <AppButton
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAddPickupPoint}
              >
                Add Pickup Point
              </AppButton>
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="select-status">Status</InputLabel>
                <Select
                  label="Status"
                  labelId="select-status"
                  id="demo-customized-select"
                  value={filterStatus}
                  onChange={(event: any) => {
                    setFilter(event.target.value);
                  }}
                >
                  <MenuItem value="">ALL</MenuItem>
                  <MenuItem value="active">ACTIVE</MenuItem>
                  <MenuItem value="inactive">INACTIVE</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <CustomizedTable
                data={data}
                headers={headers}
                totalData={totalData}
                page={page}
                totalPage={totalPage}
                rowsPerPage={rowsPerPage}
                showAction={["edit-dialog", "delete"]}
                onPressEditDialog={(data) => handleEditData(data)}
                onDeleteItem={(data: any) => {
                  removePickupPoint(data.aroId, data.pickupPointId);
                }}
                onChange={(newPage) => changePagination(newPage)}
                onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
              />
            </Grid>
            <Dialog
              fullWidth
              maxWidth={"sm"}
              open={isShowDialog}
              onClose={handleCloseDialog}
              scroll={"body"}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
            >
              <DialogTitle id="scroll-dialog-title">
                {pickupPointId !== ""
                  ? "Edit Point Pickup"
                  : "Add Point Pickup"}
              </DialogTitle>
              <DialogContent>
                <Grid
                  container
                  spacing={3}
                  justify="space-between"
                  style={{ marginTop: 0 }}
                >
                  <Grid item xs={12} md={12} lg={12} className={classes.input}>
                    <FormInput
                      onChange={(value: any) =>
                        setPickupPointData({
                          ...pickupPointData,
                          namePickupPoint: value,
                        })
                      }
                      fullWidth
                      required
                      value={pickupPointData.namePickupPoint}
                      placeholder="Masukan nama pickup point"
                      label="Nama Pickup Point"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  justify="space-between"
                  style={{ marginTop: 0 }}
                >
                  <Grid item xs={12} md={4} lg={4} className={classes.input}>
                    {pickupPointId && !editProvince ? (
                      <Box
                        onClick={() => {
                          setEditProvince(true);
                          setTmpRegion(true);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <InputLabel
                          id="Province"
                          style={{ fontSize: 11, marginBottom: 3 }}
                        >
                          Province *
                        </InputLabel>
                        <Typography
                          variant="body1"
                          style={{
                            borderBottom: "1px solid black",
                            paddingBottom: 1.3,
                          }}
                        >
                          {pickupPointData?.metaAddress?.province}
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        <InputLabel
                          id="Province"
                          style={{ fontSize: 11, marginBottom: 3 }}
                        >
                          Province *
                        </InputLabel>
                        <Select
                          label="Province"
                          labelId="province"
                          // value={mode === 'edit' ? data?.metaAddress?.province : ''}
                          onChange={(event: any) => {
                            setSelectedProvince({
                              provinceId: event.target.value.provinceId,
                              provinceName: event.target.value.provinceName,
                            });
                            setPickupPointData({
                              ...pickupPointData,
                              metaAddress: {
                                ...pickupPointData.metaAddress,
                                province: event.target.value.provinceName,
                                city: "",
                                subdistrict: "",
                              },
                            });
                            handleShowCitys(event.target.value.provinceId);
                            handleShowSubdistrics("");
                            setSelectedCity({
                              cityId: "",
                              cityName: "",
                            });
                          }}
                          displayEmpty={true}
                          fullWidth={true}
                        >
                          {provinces?.map((value: any, index: any) => (
                            <MenuItem value={value} key={index}>
                              {value.provinceName}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} md={4} lg={4} className={classes.input}>
                    {selectedProvince.provinceId === "" &&
                    pickupPointId &&
                    !editProvince ? (
                      <Box>
                        <InputLabel
                          id="City"
                          style={{ fontSize: 11, marginBottom: 3 }}
                        >
                          City *
                        </InputLabel>
                        <Typography
                          variant="body1"
                          style={{
                            borderBottom: "1px dotted black",
                            paddingBottom: 1.3,
                          }}
                        >
                          {pickupPointData.metaAddress.city}
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        <InputLabel
                          id="city"
                          style={{ fontSize: 11, marginBottom: 3 }}
                        >
                          City *
                        </InputLabel>
                        <Select
                          fullWidth={true}
                          label="City"
                          labelId="city"
                          // value={item.status}
                          onChange={(event: any) => {
                            setSelectedCity({
                              cityId: event.target.value.cityId,
                              cityName: event.target.value.cityName,
                            });
                            setPickupPointData({
                              ...pickupPointData,
                              metaAddress: {
                                ...pickupPointData.metaAddress,
                                city: event.target.value.cityName,
                                subdistrict: "",
                              },
                            });
                            handleShowSubdistrics(event.target.value.cityId);
                          }}
                          displayEmpty={true}
                          disabled={
                            selectedProvince.provinceId === "" ? true : false
                          }
                        >
                          {tmpCitys?.map((value: any, index: any) => (
                            <MenuItem value={value} key={index}>
                              {value.cityName}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} md={4} lg={4} className={classes.input}>
                    {selectedCity.cityId === "" &&
                    pickupPointId &&
                    !editProvince ? (
                      <Box>
                        <InputLabel
                          id="Subdistrict"
                          style={{ fontSize: 11, marginBottom: 3 }}
                        >
                          Subdistrict *
                        </InputLabel>
                        <Typography
                          variant="body1"
                          style={{
                            borderBottom: "1px dotted black",
                            paddingBottom: 1.3,
                          }}
                        >
                          {pickupPointData.metaAddress.subdistrict}
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        <InputLabel
                          id="subdistrict"
                          style={{ fontSize: 11, marginBottom: 3 }}
                        >
                          Subdistrict *
                        </InputLabel>
                        <Select
                          label="Subdistrict"
                          labelId="subdistrict"
                          // value={item.status}
                          onChange={(event: any) => {
                            setSelectedSubdistrict({
                              subdistrictId: event.target.value.subdistricId,
                              subdistrictName:
                                event.target.value.subdistricName,
                            });
                            setPickupPointData({
                              ...pickupPointData,
                              metaAddress: {
                                ...pickupPointData.metaAddress,
                                subdistrict: event.target.value.subdistricName,
                              },
                            });
                            setTmpRegion(false);
                          }}
                          displayEmpty={true}
                          fullWidth={true}
                          disabled={selectedCity.cityId === "" ? true : false}
                        >
                          {tmpSubdistricts?.map((value: any, index: any) => (
                            <MenuItem value={value} key={index}>
                              {value.subdistricName}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  justify="space-between"
                  style={{ marginTop: 20 }}
                >
                  <Grid item xs={12} md={6} lg={12} className={classes.input}>
                    <FormInput
                      onChange={(value: any) =>
                        setPickupPointData({
                          ...pickupPointData,
                          metaAddress: {
                            ...pickupPointData.metaAddress,
                            address: value,
                          },
                        })
                      }
                      fullWidth
                      required
                      placeholder="Address"
                      value={pickupPointData?.metaAddress?.address}
                      label="Address"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  justify="space-between"
                  style={{ marginTop: 0 }}
                >
                  <Grid item xs={12} md={12} lg={12} className={classes.input}>
                    <FormInput
                      type="number"
                      onChange={(value: any) =>
                        setPickupPointData({
                          ...pickupPointData,
                          phoneNumber: value,
                        })
                      }
                      fullWidth
                      required
                      placeholder="Masukan nomor telepon"
                      value={pickupPointData.phoneNumber}
                      label="Nomor Telepon"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  justify="space-between"
                  style={{ marginTop: 0 }}
                >
                  <Grid item xs={12} md={12} lg={12} className={classes.input}>
                    <AppMap
                      handleOnClick={(value: any) => handleChangeMarker(value)}
                      markers={markers}
                      height={270}
                      centerMap={centerMap}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  justify="space-between"
                  style={{ marginTop: 0 }}
                >
                  <Grid item xs={12} md={6} lg={6} className={classes.input}>
                    <FormInput
                      onChange={(value: any) =>
                        setPickupPointData({
                          ...pickupPointData,
                          metaAddress: {
                            ...pickupPointData.metaAddress,
                            location: {
                              ...pickupPointData.metaAddress.location,
                              lat: value,
                            },
                          },
                        })
                      }
                      fullWidth
                      placeholder="Latitude"
                      label="Latitude"
                      value={pickupPointData?.metaAddress?.location?.lat}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} className={classes.input}>
                    <FormInput
                      onChange={(value: any) =>
                        setPickupPointData({
                          ...pickupPointData,
                          metaAddress: {
                            ...pickupPointData.metaAddress,
                            location: {
                              ...pickupPointData.metaAddress.location,
                              lng: value,
                            },
                          },
                        })
                      }
                      fullWidth
                      placeholder="Longitude"
                      label="Longitude"
                      value={pickupPointData?.metaAddress?.location?.lng}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justify="space-between"
                  style={{ marginTop: 0 }}
                >
                  <Grid item xs={12}>
                    <InputLabel id="select-status" shrink={true}>
                      Status
                    </InputLabel>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flex: 1,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: 20,
                        }}
                      >
                        <Radio
                          style={{ paddingLeft: 0 }}
                          color="primary"
                          onClick={() => {
                            setPickupPointData({
                              ...pickupPointData,
                              status: EStatus.ACTIVE,
                            });
                            setHandleChangeStatus(EStatus.ACTIVE);
                          }}
                          checked={
                            pickupPointData?.status === EStatus.ACTIVE
                              ? true
                              : false
                          }
                        />
                        <div>Active</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Radio
                          style={{ paddingLeft: 0 }}
                          color="primary"
                          onClick={() => {
                            setPickupPointData({
                              ...pickupPointData,
                              status: EStatus.INACTIVE,
                            });
                            setHandleChangeStatus(EStatus.INACTIVE);
                          }}
                          checked={
                            pickupPointData?.status === EStatus.INACTIVE
                              ? true
                              : false
                          }
                        />
                        <div>Inactive</div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <AppButton
                  disabled={loadingSave}
                  color="secondary"
                  onClick={handleCloseDialog}
                >
                  Cancel
                </AppButton>
                <AppButton
                  disabled={
                    /[0-9!@#$%^&*()_+|~=`{}\[\]:";'<>?,./\\]/.test(
                      pickupPointData.namePickupPoint
                    ) ||
                    pickupPointData.namePickupPoint === "" ||
                    pickupPointData.metaAddress.province === "" ||
                    pickupPointData.metaAddress.city === "" ||
                    pickupPointData.metaAddress.subdistrict === "" ||
                    pickupPointData.metaAddress.address === "" ||
                    pickupPointData.phoneNumber === "" ||
                    pickupPointData.phoneNumber.length <= 9 ||
                    pickupPointData.phoneNumber.length >= 14 ||
                    tmpRegion
                      ? true
                      : false
                  }
                  loading={loadingSave}
                  onClick={pickupPointId !== "" ? updateData : submitData}
                >
                  Save
                </AppButton>
              </DialogActions>
            </Dialog>
          </Grid>
        </>
      ) : (
        <Layout title={title} loading={pickupPointState.isLoadingPickupPoint}>
          <HeaderPage title={title} subtitle={subtitle} />
          <Grid container spacing={3} justify="space-between">
            <Grid item xs={12} md={12} lg={8}>
              <AppButton
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAddPickupPoint}
              >
                Add Pickup Point
              </AppButton>
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="select-status">Status</InputLabel>
                <Select
                  label="Status"
                  labelId="select-status"
                  id="demo-customized-select"
                  value={filterStatus}
                  onChange={(event: any) => {
                    setFilter(event.target.value);
                  }}
                >
                  <MenuItem value="">ALL</MenuItem>
                  <MenuItem value="active">ACTIVE</MenuItem>
                  <MenuItem value="inactive">INACTIVE</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <CustomizedTable
                data={data}
                headers={headers}
                totalData={totalData}
                page={page}
                totalPage={totalPage}
                rowsPerPage={rowsPerPage}
                showAction={["edit-dialog", "delete"]}
                onPressEditDialog={(data) => handleEditData(data)}
                onDeleteItem={(data: any) => {
                  removePickupPoint(null, data.pickupPointId);
                }}
                onChange={(newPage) => changePagination(newPage)}
                onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
              />
            </Grid>
            <Dialog
              fullWidth
              maxWidth={"sm"}
              open={isShowDialog}
              onClose={handleCloseDialog}
              scroll={"body"}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
            >
              <DialogTitle id="scroll-dialog-title">
                {pickupPointId !== ""
                  ? "Edit Point Pickup"
                  : "Add Point Pickup"}
              </DialogTitle>
              <DialogContent>
                <Grid
                  container
                  spacing={3}
                  justify="space-between"
                  style={{ marginTop: 0 }}
                >
                  <Grid item xs={12} md={12} lg={12} className={classes.input}>
                    <FormInput
                      onChange={(value: any) =>
                        setPickupPointData({
                          ...pickupPointData,
                          namePickupPoint: value,
                        })
                      }
                      fullWidth
                      required
                      value={pickupPointData.namePickupPoint}
                      placeholder="Masukan nama pickup point"
                      label="Nama Pickup Point"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  justify="space-between"
                  style={{ marginTop: 0 }}
                >
                  <Grid item xs={12} md={4} lg={4} className={classes.input}>
                    {pickupPointId && !editProvince ? (
                      <Box
                        onClick={() => {
                          setEditProvince(true);
                          setTmpRegion(true);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <InputLabel
                          id="Province"
                          style={{ fontSize: 11, marginBottom: 3 }}
                        >
                          Province *
                        </InputLabel>
                        <Typography
                          variant="body1"
                          style={{
                            borderBottom: "1px solid black",
                            paddingBottom: 1.3,
                          }}
                        >
                          {pickupPointData?.metaAddress?.province}
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        <InputLabel
                          id="Province"
                          style={{ fontSize: 11, marginBottom: 3 }}
                        >
                          Province *
                        </InputLabel>
                        <Select
                          label="Province"
                          labelId="province"
                          // value={mode === 'edit' ? data?.metaAddress?.province : ''}
                          onChange={(event: any) => {
                            setSelectedProvince({
                              provinceId: event.target.value.provinceId,
                              provinceName: event.target.value.provinceName,
                            });
                            setPickupPointData({
                              ...pickupPointData,
                              metaAddress: {
                                ...pickupPointData.metaAddress,
                                province: event.target.value.provinceName,
                                city: "",
                                subdistrict: "",
                              },
                            });
                            handleShowCitys(event.target.value.provinceId);
                            handleShowSubdistrics("");
                            setSelectedCity({
                              cityId: "",
                              cityName: "",
                            });
                          }}
                          displayEmpty={true}
                          fullWidth={true}
                        >
                          {provinces?.map((value: any, index: any) => (
                            <MenuItem value={value} key={index}>
                              {value.provinceName}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} md={4} lg={4} className={classes.input}>
                    {selectedProvince.provinceId === "" &&
                    pickupPointId &&
                    !editProvince ? (
                      <Box>
                        <InputLabel
                          id="City"
                          style={{ fontSize: 11, marginBottom: 3 }}
                        >
                          City *
                        </InputLabel>
                        <Typography
                          variant="body1"
                          style={{
                            borderBottom: "1px dotted black",
                            paddingBottom: 1.3,
                          }}
                        >
                          {pickupPointData.metaAddress.city}
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        <InputLabel
                          id="city"
                          style={{ fontSize: 11, marginBottom: 3 }}
                        >
                          City *
                        </InputLabel>
                        <Select
                          fullWidth={true}
                          label="City"
                          labelId="city"
                          // value={item.status}
                          onChange={(event: any) => {
                            setSelectedCity({
                              cityId: event.target.value.cityId,
                              cityName: event.target.value.cityName,
                            });
                            setPickupPointData({
                              ...pickupPointData,
                              metaAddress: {
                                ...pickupPointData.metaAddress,
                                city: event.target.value.cityName,
                                subdistrict: "",
                              },
                            });
                            handleShowSubdistrics(event.target.value.cityId);
                          }}
                          displayEmpty={true}
                          disabled={
                            selectedProvince.provinceId === "" ? true : false
                          }
                        >
                          {tmpCitys?.map((value: any, index: any) => (
                            <MenuItem value={value} key={index}>
                              {value.cityName}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} md={4} lg={4} className={classes.input}>
                    {selectedCity.cityId === "" &&
                    pickupPointId &&
                    !editProvince ? (
                      <Box>
                        <InputLabel
                          id="Subdistrict"
                          style={{ fontSize: 11, marginBottom: 3 }}
                        >
                          Subdistrict *
                        </InputLabel>
                        <Typography
                          variant="body1"
                          style={{
                            borderBottom: "1px dotted black",
                            paddingBottom: 1.3,
                          }}
                        >
                          {pickupPointData.metaAddress.subdistrict}
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        <InputLabel
                          id="subdistrict"
                          style={{ fontSize: 11, marginBottom: 3 }}
                        >
                          Subdistrict *
                        </InputLabel>
                        <Select
                          label="Subdistrict"
                          labelId="subdistrict"
                          // value={item.status}
                          onChange={(event: any) => {
                            setSelectedSubdistrict({
                              subdistrictId: event.target.value.subdistricId,
                              subdistrictName:
                                event.target.value.subdistricName,
                            });
                            setPickupPointData({
                              ...pickupPointData,
                              metaAddress: {
                                ...pickupPointData.metaAddress,
                                subdistrict: event.target.value.subdistricName,
                              },
                            });
                            setTmpRegion(false);
                          }}
                          displayEmpty={true}
                          fullWidth={true}
                          disabled={selectedCity.cityId === "" ? true : false}
                        >
                          {tmpSubdistricts?.map((value: any, index: any) => (
                            <MenuItem value={value} key={index}>
                              {value.subdistricName}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  justify="space-between"
                  style={{ marginTop: 20 }}
                >
                  <Grid item xs={12} md={6} lg={12} className={classes.input}>
                    <FormInput
                      onChange={(value: any) =>
                        setPickupPointData({
                          ...pickupPointData,
                          metaAddress: {
                            ...pickupPointData.metaAddress,
                            address: value,
                          },
                        })
                      }
                      fullWidth
                      required
                      placeholder="Address"
                      value={pickupPointData?.metaAddress?.address}
                      label="Address"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  justify="space-between"
                  style={{ marginTop: 0 }}
                >
                  <Grid item xs={12} md={12} lg={12} className={classes.input}>
                    <FormInput
                      type="number"
                      onChange={(value: any) =>
                        setPickupPointData({
                          ...pickupPointData,
                          phoneNumber: value,
                        })
                      }
                      fullWidth
                      required
                      placeholder="Masukan nomor telepon"
                      value={pickupPointData.phoneNumber}
                      label="Nomor Telepon"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  justify="space-between"
                  style={{ marginTop: 0 }}
                >
                  <Grid item xs={12} md={12} lg={12} className={classes.input}>
                    <AppMap
                      handleOnClick={(value: any) => handleChangeMarker(value)}
                      markers={markers}
                      height={270}
                      centerMap={centerMap}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  justify="space-between"
                  style={{ marginTop: 0 }}
                >
                  <Grid item xs={12} md={6} lg={6} className={classes.input}>
                    <FormInput
                      onChange={(value: any) =>
                        setPickupPointData({
                          ...pickupPointData,
                          metaAddress: {
                            ...pickupPointData.metaAddress,
                            location: {
                              ...pickupPointData.metaAddress.location,
                              lat: value,
                            },
                          },
                        })
                      }
                      fullWidth
                      placeholder="Latitude"
                      label="Latitude"
                      value={pickupPointData?.metaAddress?.location?.lat}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} className={classes.input}>
                    <FormInput
                      onChange={(value: any) =>
                        setPickupPointData({
                          ...pickupPointData,
                          metaAddress: {
                            ...pickupPointData.metaAddress,
                            location: {
                              ...pickupPointData.metaAddress.location,
                              lng: value,
                            },
                          },
                        })
                      }
                      fullWidth
                      placeholder="Longitude"
                      label="Longitude"
                      value={pickupPointData?.metaAddress?.location?.lng}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justify="space-between"
                  style={{ marginTop: 0 }}
                >
                  <Grid item xs={12}>
                    <InputLabel id="select-status" shrink={true}>
                      Status
                    </InputLabel>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flex: 1,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: 20,
                        }}
                      >
                        <Radio
                          style={{ paddingLeft: 0 }}
                          color="primary"
                          onClick={() => {
                            setPickupPointData({
                              ...pickupPointData,
                              status: EStatus.ACTIVE,
                            });
                            setHandleChangeStatus(EStatus.ACTIVE);
                          }}
                          checked={
                            pickupPointData?.status === EStatus.ACTIVE
                              ? true
                              : false
                          }
                        />
                        <div>Active</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Radio
                          style={{ paddingLeft: 0 }}
                          color="primary"
                          onClick={() => {
                            setPickupPointData({
                              ...pickupPointData,
                              status: EStatus.INACTIVE,
                            });
                            setHandleChangeStatus(EStatus.INACTIVE);
                          }}
                          checked={
                            pickupPointData?.status === EStatus.INACTIVE
                              ? true
                              : false
                          }
                        />
                        <div>Inactive</div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <AppButton
                  disabled={loadingSave}
                  color="secondary"
                  onClick={handleCloseDialog}
                >
                  Cancel
                </AppButton>
                <AppButton
                  disabled={
                    /[0-9!@#$%^&*()_+|~=`{}\[\]:";'<>?,./\\]/.test(
                      pickupPointData.namePickupPoint
                    ) ||
                    pickupPointData.namePickupPoint === "" ||
                    pickupPointData.metaAddress.province === "" ||
                    pickupPointData.metaAddress.city === "" ||
                    pickupPointData.metaAddress.subdistrict === "" ||
                    pickupPointData.metaAddress.address === "" ||
                    pickupPointData.phoneNumber === "" ||
                    pickupPointData.phoneNumber.length <= 9 ||
                    pickupPointData.phoneNumber.length >= 14 ||
                    tmpRegion
                      ? true
                      : false
                  }
                  loading={loadingSave}
                  onClick={pickupPointId !== "" ? updateData : submitData}
                >
                  Save
                </AppButton>
              </DialogActions>
            </Dialog>
          </Grid>
        </Layout>
      )}
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      padding: theme.spacing(0, 0, 1, 0),
    },
  })
);

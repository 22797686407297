import axios from "axios";
import { getHeaders } from "../helpers/auth";
import { CustomerResponse, SummaryRequest } from "../types/customer.type";

export async function apiFindAllCustomer(params: string) {
  const headers = await getHeaders();
  const query = params || null;
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/customer" + query,
    method: "GET",
    headers,
  });
}

export async function apiFindAllCustomerByServiceCenter(
  serviceCenterId: string,
  params: string
) {
  const headers = await getHeaders();
  const query = params || null;
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      "/service-center-technician/" +
      serviceCenterId +
      query,
    method: "GET",
    headers,
  });
}

export async function apiCreateCustomerByServiceCenter(data: any) {
  const headers = await getHeaders();
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/service-center-technician",
    method: "POST",
    headers,
    data,
  });
}

export async function apiRemoveCustomerByServiceCenter(customerId: any) {
  const headers = await getHeaders();
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      "/service-center-technician/" +
      customerId,
    method: "DELETE",
    headers,
  });
}

export async function apiUpdateCustomerByServiceCenter(
  customerId: string,
  data: any
) {
  const headers = await getHeaders();
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      "/service-center-technician/" +
      customerId,
    method: "PATCH",
    headers,
    data,
  });
}

export async function apiFindOneCustomer(customerId: string) {
  const headers = await getHeaders();
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/customer/find/" + customerId,
    method: "GET",
    headers,
  });
}

export async function apiUpdateCustomer(data: CustomerResponse) {
  const headers = await getHeaders();
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/customer/update/" + data.customerId,
    method: "PUT",
    headers,
    data,
  });
}

export async function apiExportCustomer() {
  const headers = await getHeaders();
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/customer/export",
    method: "GET",
    headers,
    responseType: "blob",
  });
}

export async function apiSummaryCustomer(params: string) {
  const headers = await getHeaders();
  const query = params || null;
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/customer/customer-summary" + query,
    method: "GET",
    headers,
  });
}

import { Reducer } from "redux";
import {
  ExtraWarrantyTypes,
  LoyaltyTypes,
  ReferralComissionState,
  ReferralComissionTypes,
  ReferralCommissionResponse,
  ReferralInformationTypes,
  ShipmentDiscountTypes,
} from "../../types/master-data-referral.type";
import { convertNumToCurrency } from "../../helpers/functions/currency";

const initialState: ReferralComissionState = {
  isLoadingReferralComission: false,
  isLoadingRetailComission: false,
  isLoadingSPGComission: false,
  isLoadingMemberPremium: false,
  isLoadingReferralInformation: false,
  isLoadingShipmentDiscount: false,
  isLoadingExtraWarranty: false,

  logReferralComission: {
    code: "",
    message: "",
    payload: {
      prev: "",
      next: "",
      count: 0,
      results: [],
    },
  },
  logShipmentDiscount: {
    code: "",
    message: "",
    payload: {
      prev: "",
      next: "",
      count: 0,
      results: [],
    },
  },
  logExtraWarranty: {
    code: "",
    message: "",
    payload: {
      prev: "",
      next: "",
      count: 0,
      results: [],
    },
  },
  logLoyalty: {
    code: "",
    message: "",
    payload: {
      prev: "",
      next: "",
      count: 0,
      results: [],
    },
  },
  listLoyaltyLevel: {
    code: "",
    message: "",
    payload: {
      prev: "",
      next: "",
      count: 0,
      results: [],
    },
  },
  loyaltyDetail: {
    loyaltyId: "",
    loyaltyName: "",
    minTotalTransaction: 0,
    maxTotalTransaction: 0,
    coin: 0,
    coinExpired: 0,
    createdAt: "",
    updatedAt: "",
  },

  referralComission: {
    amountRetail: 0,
    amountReferral: 0,
    amountSPG: 0,
    amountPremiumMembership: 0,
  },
  referralInformation: {
    content: "",
  },
  shipmentDiscount: {
    amount: 0,
  },
  extraWarranty: {
    year: "",
  },

  error: {},
  isSuccess: false,
};

export const masterDataReferral: Reducer<ReferralComissionState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ReferralComissionTypes.FIND_LOG_REFERRAL_COMMISSION:
      const results = action.payload.payload.results.map(
        (item: ReferralCommissionResponse) => {
          return {
            ...item,
            amount:
              item.referralType === "referral-commission" ||
              item.referralType === "retail-commission" ||
              item.referralType === "spg-commission"
                ? (item.amount || 0) + "%"
                : item.referralType === "premium-membership"
                ? convertNumToCurrency(item.amount)
                : item.amount,
          };
        }
      );
      return {
        ...state,
        logReferralComission: {
          ...action.payload,
          payload: {
            ...action.payload.payload,
            results,
          },
        },
      };
    case ReferralComissionTypes.CURRENT_REFERRAL_COMMISSION:
      return {
        ...state,
        referralComission: action.payload,
      };
    case ReferralComissionTypes.CREATE_REFERRAL_COMMISSION:
      return {
        ...state,
      };
    case ReferralComissionTypes.SET_LOADING_REFERRAL_COMMISSION:
      return {
        ...state,
        isLoadingReferralComission: action.payload,
      };
    case ReferralComissionTypes.SET_LOADING_RETAIL_COMMISSION:
      return {
        ...state,
        isLoadingRetailComission: action.payload,
      };
    case ReferralComissionTypes.SET_LOADING_SPG_COMMISSION:
      return {
        ...state,
        isLoadingSPGComission: action.payload,
      };
    case ReferralComissionTypes.SET_LOADING_MEMBER_COMMISSION:
      return {
        ...state,
        isLoadingMemberPremium: action.payload,
      };

    case ReferralInformationTypes.CURRENT_INFORMATION:
      return {
        ...state,
        referralInformation: action.payload,
      };
    case ReferralInformationTypes.CREATE_INFORMATION:
      return {
        ...state,
      };
    case ReferralInformationTypes.SET_LOADING_INFORMATION:
      return {
        ...state,
        isLoadingReferralInformation: action.payload,
      };

    case ShipmentDiscountTypes.FIND_LOG_SHIPMENT_DISCOUNT:
      return {
        ...state,
        logShipmentDiscount: action.payload,
      };
    case ShipmentDiscountTypes.CURRENT_SHIPMENT_DISCOUNT:
      return {
        ...state,
        shipmentDiscount: action.payload,
      };
    case ShipmentDiscountTypes.CREATE_SHIPMENT_DISCOUNT:
      return {
        ...state,
      };
    case ShipmentDiscountTypes.SET_LOADING_SHIPMENT_DISCOUNT:
      return {
        ...state,
        isLoadingShipmentDiscount: action.payload,
      };

    case ExtraWarrantyTypes.CURRENT_EXTRA_WARRANTY:
      return {
        ...state,
        extraWarranty: action.payload,
      };

    case ExtraWarrantyTypes.FIND_LOG_EXTRA_WARRANTY:
      return {
        ...state,
        logExtraWarranty: action.payload,
      };

    case LoyaltyTypes.FIND_LOG_LOYALTY:
      return {
        ...state,
        listLoyaltyLevel: action.payload,
      };

    case LoyaltyTypes.FIND_LOG_LOYALTY_HISTORY:
      return {
        ...state,
        logLoyalty: action.payload,
      };

    case LoyaltyTypes.FIND_ONE_LOYALTY:
      return {
        ...state,
        loyaltyDetail: action.payload,
      };
    case LoyaltyTypes.FIND_UPDATE_LOYALTY:
      return {
        ...state,
        isSuccess: action.payload,
      };

    default:
      return state;
  }
};

import React, { useEffect, useState, useMemo } from "react";
import {
  Typography,
  CardMedia,
  Paper,
  createStyles,
  makeStyles,
  Theme,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Select,
  Box,
  Tooltip,
  Chip,
  IconButton,
  Dialog,
  DialogContent,
  Grid,
  Collapse,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { Link, useLocation } from "react-router-dom";
import AppButton from "../Form/AppButton";
import { convertNumToCurrency } from "../../helpers/functions/currency";
import { Edit2, Eye, Trash2, ChevronDown, ChevronUp } from "react-feather";
import COLORS from "../../styles/colors";
import PlaceholderImageSquare from "../../assets/default_1000x1000.png";
import { convertDateTimeToText } from "../../helpers/functions/datetime";
import { CategoryResponse } from "../../types/category.type";
import { TagResponse } from "../../types/tag.type";
import { LabelStatusTransaction } from "../StatusTransaction";
import { CheckCircle, Close, Remove } from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ERecipeStatus } from "../../types/recipe.type";

// TABLE
const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "10px 10px",
    },
    head: {
      // backgroundColor: theme.palette.common.black,
      // color: theme.palette.common.white,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      fontWeight: "bold",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);
export { StyledTableCell };

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const StyledTableRowExpandable = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const StyledPagination = withStyles((theme: Theme) =>
  createStyles({
    ul: {
      margin: 5,
    },
    // '&Item-root': {
    //   height: 28,
    //   minWidth: 28,
    // },
  })
)(Pagination);

export interface ITableHeader {
  title: string;
  align?: "left" | "center" | "right";
  alignContent?: "left" | "center" | "right";
  sortable?: boolean;
  column: string | any;
  type?:
  | "image"
  | "text"
  | "currency"
  | "percentage"
  | "tags"
  | "status"
  | "image-product"
  | "nested"
  | "nested-price"
  | "nested-number"
  | "nested-image"
  | "id"
  | "params"
  | "date"
  | "dd mmm yyyy hh:mm"
  | "status-boolean"
  | "escape-string"
  | "product-categories"
  | "status-membership"
  | "status-transaction"
  | "status-recipe"
  | "nested-stock"
  | "checkmark";
  imageSize?: "small" | "medium" | "small-landscape"; // required only if type = 'image'
  width?: string | number | null;
  format?: any;
  imageResolution?: "500x500" | "1200x600" | "1200x840" | "1200x1200";
}

export interface IPropsCustomizedTable {
  headers: ITableHeader[];
  data: any[];
  totalData: number;
  showAction?: string[];
  showInfo?: "right";
  page: number;
  totalPage: number;
  rowsPerPage: number;
  selection?: boolean;
  selectionDisabled?: boolean;
  selected?: Array<string | number>;
  sort?: { column: string; sort: "DESC" | "ASC" }[];
  onSelected?: (id: string) => void;
  onSelectAll?: () => void;
  onUnselectAll?: () => void;
  onChange: (page: number) => void;
  onRowsPerPageChange: (value: any) => void;
  onPressDetail?: (item: any) => void;
  onPressEdit?: (item: any) => void;
  onPressEditDialog?: (item: any) => void;
  onPressAccept?: (item: any) => void;
  onPressReject?: (item: any) => void;
  onDeleteItem?: (item: any) => void;
  onChangeSort?: (newSort: { column: string; sort: "DESC" | "ASC" }[]) => void;
  hidePagination?: boolean;
  isExpandable?: boolean;
  expanded?: Array<string>;
  onExpanded?: (id: string) => void;
  onExpandAll?: () => void;
  onCollapseAll?: () => void;
  renderExpanded?: (row: any) => React.ReactNode;
}

interface RenderRowProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  row?: any;
  isExpandable?: boolean;
  expanded?: Array<string>;
  onExpanded?: (id: string) => void;
  renderExpanded?: (row: any) => React.ReactNode;
}

// THIS COMPONENT MUST HERE TO PREVENT RECREATE ON REMOUNT
const RenderRow: React.FC<RenderRowProps> = ({
  children,
  style,
  row,
  expanded,
  onExpanded,
  renderExpanded,
  isExpandable,
}) => {
  const open = expanded && expanded.includes(row.id);

  return (
    <React.Fragment>
      <StyledTableRowExpandable style={style}>
        {isExpandable ? (
          <StyledTableCell width={50} align="center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() =>
                row?.isDisabled ? null : onExpanded ? onExpanded(row.id) : null
              }
              disabled={row?.isDisabled}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>
        ) : (
          <></>
        )}
        {children}
      </StyledTableRowExpandable>
      {isExpandable ? (
        <StyledTableRowExpandable style={style}>
          {renderExpanded && open ? renderExpanded(row) : <></>}
        </StyledTableRowExpandable>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default function CustomizedTable(props: IPropsCustomizedTable) {
  const location = useLocation();
  const classes = useStyles();

  const [willBeDeleted, setWillBeDeleted] = useState(false);
  const [isShowDeleteConfirmation, setIsShowDeleteConfirmation] =
    useState(false);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const confirmDelete = (data: any) => {
    setWillBeDeleted(data);
    setIsShowDeleteConfirmation(true);
  };

  const handleClose = () => {
    //
    setIsShowDeleteConfirmation(false);
  };

  const deleteItem = () => {
    props.onDeleteItem ? props.onDeleteItem(willBeDeleted) : console.info("");
    handleClose();
  };

  const approveRecipe = (e: any) => {
    console.log('===')
    props.onPressAccept ? props.onPressAccept(e) : console.log("")
    handleClose();
  }

  const rejectRecipe = (e: any) => {
    props.onPressReject ? props.onPressReject(e) : console.log("")
    handleClose();
  }

  const handleClickSort = (column: string) => {
    let list = props.sort;

    if (list && Array.isArray(list) && list.length > 0) {
      list = list.map((item) => {
        if (item.column === column) {
          if (item.sort === "DESC") {
            item = {
              ...item,
              sort: "ASC",
            };
          } else {
            item = {
              ...item,
              sort: "DESC",
            };
          }
        }
        return item;
      });
      if (props.onChangeSort) {
        props.onChangeSort(list);
      }
    }
  };

  const renderTags = (tags: TagResponse[]) => {
    return tags.map((item: TagResponse, index: number) => {
      return (
        <Chip
          key={index}
          size="small"
          label={item.tagName}
          style={{ marginRight: 5 }}
        />
      );
    });
  };

  const renderStatus = (status: "published" | "unpublished") => {
    const statusRendered = status[0].toUpperCase() + status.substring(1);
    if (status === "published") {
      return (
        <Chip
          size="small"
          color="primary"
          label={statusRendered}
          style={{ marginRight: 5 }}
        />
      );
    } else if (status === "unpublished") {
      return (
        <Chip
          size="small"
          color="primary"
          variant="outlined"
          label={statusRendered}
        />
      );
    }
  };

  const renderStatusBoolean = (status: boolean) => {
    if (status) {
      return <Chip size="small" color="primary" label="Published" />;
    } else {
      return (
        <Chip
          size="small"
          color="primary"
          variant="outlined"
          label="Unpublished"
        />
      );
    }
  };

  const renderStatusMembership = (status: "reguler" | "premium") => {
    if (status === "premium") {
      return <Chip size="small" color="primary" label="Premium" />;
    } else {
      return (
        <Chip size="small" color="primary" variant="outlined" label="Reguler" />
      );
    }
  };

  const renderStatusRecipe = (status: "draft" | "approved" | "rejected" | "waiting_approval") => {
    if (status === "draft") {
      return <Chip style={{
        borderRadius: 5
      }} size="medium" color="primary" label="DRAFT" />;
    } else if (status === "approved") {
      return (
        <Chip style={{
          backgroundColor: "#E3F7EE",
          color: "#0AC46B",
          borderRadius: 5
        }} size="medium" label="APPROVED" />
      );
    } else if (status === "waiting_approval") {
      return (
        <Chip style={{
          backgroundColor: "#FFF3DC",
          color: "black",
          borderRadius: 5
        }} size="medium" color="primary" label="WAITING APPROVAL" />
      );
    } else if (status === "rejected") {
      return (
        <Chip style={{
          backgroundColor: "#FFEEF1",
          color: "#E92548",
          borderRadius: 5
        }} size="medium" color="primary" label="REJECTED" />
      );
    } else {
      return (
        <Chip size="medium" color="primary" label="DRAFT" />
      );
    }

  };

  const renderProductImageList = (item: any, header: string) => {
    if (item[header] && item[header].length > 0) {
      const ini = item[header][0]["imageUrl"];
      return ini;
    }
    return PlaceholderImageSquare;
  };

  const renderNestedValue = (item: any, header: string, type: string | any) => {
    try {
      let result = JSON.parse(header).reduce((a: any, v: any) => a[v], item);
      if (result === 0) {
        if (type === "nested-stock") {
          return (
            <Typography
              style={
                result <= 5 ? { color: COLORS.error, fontWeight: "bold" } : {}
              }
            >
              {result}
            </Typography>
          );
        }
        return result;
      } else if (!result) {
        return <span className={classes.dataEmpty}>Not Set</span>;
      } else if (type && type === "nested-price") {
        return convertNumToCurrency(result);
      } else if (type && type === "nested-number") {
        return result;
      } else if (type && type === "nested-image") {
        return (
          <StyledTableCell
            key={result}
            component="th"
            scope="row"
            width={60}
          >
            <CardMedia
              style={{ borderRadius: 10 }}
              className={classes.smallMedia}
              image={
                result
                  ? result
                  :  "assets/default_500x500.png"
              }
              title={result}
            />
          </StyledTableCell>
        )
      } else if (type && type === "nested-stock") {
        return (
          <Typography
            style={result <= 5 ? { color: COLORS.error, fontWeight: "bold" } : {}}
          >
            {result}
          </Typography>
        );
      }
      return result;
    } catch (error) {
      if (type && type === "nested-image") {
        return (
          <StyledTableCell
            key={"recipe-image"}
            component="th"
            scope="row"
            width={60}
          >
            <CardMedia
              style={{ borderRadius: 10 }}
              className={classes.smallMedia}
              image={"assets/default_500x500.png" }
              title={"recipe-image"}
            />
          </StyledTableCell>
        )
      }
    }
  };

  const getDataId = () => {
    return props.headers.find((header: ITableHeader) => header.type === "id");
  };

  const getDataParams = () => {
    return props.headers.find(
      (header: ITableHeader) => header.type === "params"
    );
  };

  const escapeString = (data: string) => {
    const check = data.split("_");
    if (check.length > 1) {
      return data.split("_").join(" ").toUpperCase();
    } else {
      return data.split("-").join(" ").toUpperCase();
    }
  };

  const renderMultiChip = (data: CategoryResponse[]) => {
    if (Array.isArray(data) && data.length > 0) {
      return data.map((item: CategoryResponse) => {
        const featuredProduct = item.categoryName.includes("Featured");
        if (!featuredProduct) {
          return (
            <Chip
              key={item.categoryId}
              style={{ marginRight: 10, fontSize: 12 }}
              size="small"
              color="primary"
              label={item.categoryName}
            />
          );
        }
        return <Chip size="small" color="primary" label="Featured" />;
      });
    }
    return <Chip size="small" color="primary" label="Uncategorized" />;
  };

  const renderCheckMark = (data: string) => {
    if (data) {
      return (
        <div style={{ marginTop: 8 }}>
          <CheckCircle style={{ color: COLORS.primary }} />
        </div>
      );
    }
    return (
      <div style={{ marginTop: 8 }}>
        <Remove style={{ color: grey[500] }} />
      </div>
    );
  };

  let totalColumn = props.headers.length;
  if (props.selection) {
    totalColumn++;
  }
  if (props.showAction) {
    totalColumn++;
  }
  if (props.showInfo) {
    totalColumn++;
  }

  let isAllSelected = true;
  if (props.selection) {
    if (props.data.length === 0) {
      isAllSelected = false;
    } else {
      props.data.forEach((item) => {
        if (!(props.selected && props.selected.includes(item.id))) {
          isAllSelected = false;
        }
      });
    }
  } else {
    isAllSelected = false;
  }

  let hidePagination = false;

  if (props.hidePagination) {
    hidePagination = true;
  }

  const renderPagination = (val: {
    totalData: number;
    rowsPerPage: number;
    totalPage: number;
    page: number;
    data: number;
  }) => {
    let paginationStatus = "";
    let currentIndex = val.totalData > 0 ? 1 : 0;
    let currentIndexOf =
      val.totalData > val.rowsPerPage ? val.rowsPerPage : val.totalData;

    if (val.page > 1) {
      currentIndex = val.rowsPerPage * (val.page - 1) + 1;
      currentIndexOf =
        val.page === val.totalPage ? val.totalData : val.rowsPerPage * val.page;
    }

    paginationStatus =
      currentIndex + "-" + currentIndexOf + " of " + val.totalData;

    if (val.totalData === 0) {
      paginationStatus = "0 of 0";
    }

    return paginationStatus;
  };

  // a little function to help us with reordering the result
  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result: any) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    console.log(
      `dragEnd ${result.source.index} to  ${result.destination.index}`
    );
    const items = reorder(data, result.source.index, result.destination.index);
    setData(items);
  }

  // EXPANDABLE

  const isExpandAll = useMemo(
    () =>
      props.expanded
        ? props.data.length === 0
          ? false
          : props.data
            .map((item) =>
              props.expanded && props.expanded.includes(item.id)
                ? true
                : false
            )
            .every((v) => v === true)
        : false,
    [props?.expanded]
  );

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
            {props.isExpandable && (
              <StyledTableCell align="center">
                {isExpandAll ? (
                  <IconButton
                    aria-label="select-and-unselect"
                    onClick={() =>
                      props.onCollapseAll
                        ? props.onCollapseAll()
                        : console.info("do nothing onCollapseAll")
                    }
                  >
                    <KeyboardArrowUpIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label="select-and-unselect"
                    onClick={() =>
                      props.onExpandAll
                        ? props.onExpandAll()
                        : console.info("do nothing onExpandAll")
                    }
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                )}
              </StyledTableCell>
            )}
            {props.selection && (
              <StyledTableCell align="center">
                {isAllSelected ? (
                  <IconButton
                    aria-label="select-and-unselect"
                    onClick={() =>
                      props.onUnselectAll
                        ? props.onUnselectAll()
                        : console.info("do nothing onUnselectAll")
                    }
                  >
                    <CheckBoxIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label="select-and-unselect"
                    onClick={() =>
                      props.onSelectAll
                        ? props.onSelectAll()
                        : console.info("do nothing onSelectAll")
                    }
                  >
                    <CheckBoxOutlineBlankIcon />
                  </IconButton>
                )}
              </StyledTableCell>
            )}
            {props.headers.map(
              (header, index) =>
                header.type !== "id" &&
                header.type !== "params" && (
                  <StyledTableCell
                    key={index}
                    align={header.align ? header.align : "left"}
                  >
                    <Box
                      display={header.sortable ? "flex" : "block"}
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                      onClick={() =>
                        header.sortable ? handleClickSort(header.column) : false
                      }
                      style={header.sortable ? { cursor: "pointer" } : {}}
                    >
                      <Box>{header.title}</Box>

                      {header.sortable &&
                        props.sort &&
                        props.sort.find(
                          (item) => item.column === header.column
                        ) &&
                        (
                          props.sort.find(
                            (item) => item.column === header.column
                          ) as any
                        ).sort === "DESC" ? (
                        <ChevronDown
                          onClick={() => handleClickSort(header.column)}
                          size={18}
                          style={{ cursor: "pointer" }}
                          color={COLORS.primary}
                        />
                      ) : header.sortable &&
                        props.sort &&
                        props.sort.find(
                          (item) => item.column === header.column
                        ) &&
                        (
                          props.sort.find(
                            (item) => item.column === header.column
                          ) as any
                        ).sort === "ASC" ? (
                        <ChevronUp
                          onClick={() => handleClickSort(header.column)}
                          size={18}
                          style={{ cursor: "pointer" }}
                          color={COLORS.primary}
                        />
                      ) : header.sortable && props.sort ? (
                        <ChevronUp
                          // onClick={() => handleClickSort(header.column)}
                          size={18}
                          style={{ cursor: "pointer" }}
                          color={COLORS.grey500}
                        />
                      ) : (
                        false
                      )}
                    </Box>
                  </StyledTableCell>
                )
            )}
            {props.showAction ? (
              <StyledTableCell align="center">Actions</StyledTableCell>
            ) : (
              false
            )}
            {props.showInfo === "right" ? (
              <StyledTableCell align="left"></StyledTableCell>
            ) : (
              false
            )}
          </StyledTableRow>
        </TableHead>
        {/* <TableBody component={DroppableComponent(this.onDragEnd)}>
          {[].map((item, index) => (
            <TableRow component={DraggableComponent(item.id, index)} key={item.id} >
              <TableCell scope="row">{index + 1}</TableCell>
              <TableCell>{item.primary}</TableCell>
              <TableCell align="right">{item.secondary}</TableCell>
            </TableRow>
          ))}
        </TableBody> */}
        <TableBody>
          {data.length === 0 && (
            <StyledTableRow>
              <StyledTableCell
                colSpan={totalColumn}
                component="th"
                scope="row"
                style={{
                  textAlign: "center",
                }}
              >
                <Typography variant="subtitle1">Data not found</Typography>
              </StyledTableCell>
            </StyledTableRow>
          )}
          {data.map((row: any, key: number) => (
            <RenderRow
              row={row}
              expanded={props.expanded}
              onExpanded={props.onExpanded}
              renderExpanded={props.renderExpanded}
              isExpandable={props.isExpandable}
              key={"row_" + key}
              style={
                row["backgroundColor"]
                  ? { backgroundColor: row["backgroundColor"] }
                  : {}
              }
            >
              {props.selection && (
                <StyledTableCell align="center">
                  <IconButton
                    aria-label="select-and-unselect"
                    onClick={() =>
                      row.isDisabled
                        ? null
                        : props.onSelected
                          ? props.onSelected(row.id)
                          : console.info("do nothing onSelected: " + row.id)
                    }
                    disabled={row.isDisabled}
                  >
                    {props.selected && props.selected.includes(row.id) ? (
                      <CheckBoxIcon />
                    ) : (
                      <CheckBoxOutlineBlankIcon />
                    )}
                  </IconButton>
                </StyledTableCell>
              )}
              {props.headers.map((header: any, index: any) =>
                header.type === "id" || header.type === "params" ? (
                  false
                ) : header.type === "image-product" ? (
                  <StyledTableCell
                    key={index}
                    component="th"
                    scope="row"
                    width={header.width ? header.width : 60}
                  >
                    <CardMedia
                      style={{ borderRadius: 10 }}
                      className={
                        header.imageSize === "medium"
                          ? classes.mediumMediaLandscape
                          : header.imageSize === "small-landscape"
                            ? classes.smallMediaLandscape
                            : classes.smallMedia
                      }
                      image={renderProductImageList(row, header.column)}
                      title={row[header.column]}
                    />
                  </StyledTableCell>
                ) : header.type === "image" ? (
                  <StyledTableCell
                    key={index}
                    component="th"
                    scope="row"
                    width={header.width ? header.width : 60}
                  >
                    <CardMedia
                      style={{ borderRadius: 10 }}
                      className={
                        header.imageSize === "medium"
                          ? classes.mediumMediaLandscape
                          : header.imageSize === "small-landscape"
                            ? classes.smallMediaLandscape
                            : classes.smallMedia
                      }
                      image={
                        row[header.column]
                          ? row[header.column]
                          : header.imageResolution
                            ? "assets/default_" + header.imageResolution + ".png"
                            : "assets/default_500x500.png"
                      }
                      title={row[header.column]}
                    />
                  </StyledTableCell>
                ) : (
                  <StyledTableCell
                    key={index}
                    component="th"
                    scope="row"
                    width={header.width ? header.width : null}
                    style={{
                      textAlign: header.alignContent || "left",
                      ...(row.hasOwnProperty(header.column + "Style")
                        ? (row[header.column + "Style"] as any)
                        : ({} as any)),
                    }}
                  >
                    {header.type === "currency" ? (
                      convertNumToCurrency(row[header.column])
                    ) : header.type === "percentage" ? (
                      row[header.column] * 100 + "%"
                    ) : header.type === "tags" ? (
                      renderTags(row[header.column])
                    ) : header.type === "status" ? (
                      renderStatus(row[header.column])
                    ) : header.type === "status-boolean" ? (
                      renderStatusBoolean(row[header.column])
                    ) : header.type === "status-membership" ? (
                      renderStatusMembership(row[header.column])
                    ) : header.type === "status-recipe" ? (
                      renderStatusRecipe(row[header.column])
                    ) : header.type === "status-transaction" ? (
                      <LabelStatusTransaction status={row[header.column]} />
                    ) : header.type === "date" ? (
                      convertDateTimeToText(row[header.column], header.format)
                    ) : header.type === "dd mmm yyyy hh:mm" ? (
                      convertDateTimeToText(
                        row[header.column],
                        "dd mmm yyyy hh:mm"
                      )
                    ) : header.type === "escape-string" ? (
                      escapeString(row[header.column])
                    ) : header.type && header.type.includes("nested") ? (
                      renderNestedValue(row, header.column, header.type)
                    ) : header.type === "product-categories" ? (
                      renderMultiChip(row[header.column])
                    ) : header.type === "checkmark" ? (
                      renderCheckMark(row[header.column])
                    ) : row[header.column] ? (
                      <span style={{ lineHeight: 1.4 }}>
                        {row[header.column]}
                      </span>
                    ) : typeof row[header.column] === "number" &&
                      row[header.column] === 0 ? (
                      <span style={{ lineHeight: 1.4 }}>
                        {row[header.column]}
                      </span>
                    ) : (
                      <span className={classes.dataEmpty}>Not Set</span>
                    )}
                  </StyledTableCell>
                )
              )}

              {props.showAction ? (
                <StyledTableCell align="center" width="5%">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {props.showAction.includes("detail-dialog") && (
                      <IconButton
                        size="small"
                        onClick={() =>
                          props.onPressDetail
                            ? props.onPressDetail(row)
                            : console.info("")
                        }
                      >
                        <Eye color={COLORS.primary} size={18} />
                      </IconButton>
                    )}
                    {props.showAction.includes("detail") &&
                      props.onPressDetail ? (
                      <Link
                        // to="#"
                        to={{
                          pathname: `${location.pathname}/${row[getDataId()?.column]
                            }`,
                          search: row[getDataParams()?.column] || "",
                        }}
                        className={classes.actionIcon}
                        onClick={() =>
                          props.onPressDetail
                            ? props.onPressDetail(row)
                            : console.info("")
                        }
                      >
                        <Eye color={COLORS.primary} size={18} />
                      </Link>
                    ) : props.showAction.includes("detail") ? (
                      <Link
                        className={classes.actionIcon}
                        to={{
                          pathname: `${location.pathname}/${row[getDataId()?.column]
                            }`,
                          search: row[getDataParams()?.column] || "",
                        }}
                      >
                        <Eye color={COLORS.primary} size={18} />
                      </Link>
                    ) : (
                      false
                    )}
                    {props.showAction.includes("edit") && props.onPressEdit ? (
                      <Link
                        className={classes.actionIcon}
                        to={{
                          pathname:
                            location.pathname +
                            "/" +
                            row[getDataId()?.column] +
                            "/edit",
                        }}
                        onClick={() =>
                          props.onPressEdit
                            ? props.onPressEdit(row)
                            : console.info("")
                        }
                      >
                        <Edit2 color={COLORS.primary} size={18} />
                      </Link>
                    ) : props.showAction.includes("edit") ? (
                      <Link
                        className={classes.actionIcon}
                        to={{
                          pathname:
                            location.pathname +
                            "/" +
                            row[getDataId()?.column] +
                            "/edit",
                        }}
                      >
                        <Edit2 color={COLORS.primary} size={18} />
                      </Link>
                    ) : (
                      false
                    )}
                    {props.showAction.includes("edit-dialog") && (
                      <IconButton
                        size="small"
                        onClick={() =>
                          props.onPressEditDialog
                            ? props.onPressEditDialog(row)
                            : console.info("")
                        }
                      >
                        <Edit2 color={COLORS.primary} size={18} />
                      </IconButton>
                    )}
                    {props.showAction.includes("delete") && (
                      <Link
                        to="#"
                        className={classes.actionIcon}
                        onClick={() => confirmDelete(row)}
                      >
                        <Trash2 color={COLORS.primary} size={18} />
                      </Link>
                    )}
                  </div>
                </StyledTableCell>
              ) : (
                false
              )}

              {props.showInfo === "right" && row["infoMessage"] ? (
                <StyledTableCell align="right" width="5%">
                  <Tooltip title={row["infoMessage"]} placement="top-end">
                    <InfoRoundedIcon />
                  </Tooltip>
                </StyledTableCell>
              ) : (
                false
              )}
            </RenderRow>
          ))}
        </TableBody>
      </Table>
      {!props.hidePagination && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box p={1}>
              <Typography variant="subtitle1">Rows per page: </Typography>
            </Box>

            <Box p={1}>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={props.rowsPerPage}
                onChange={(event) => {
                  props.onRowsPerPageChange(event.target.value);
                }}
                style={{ fontSize: 12 }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                {/* <MenuItem value={'ALL'}>All</MenuItem> */}
              </Select>
            </Box>

            <Box p={1}>
              <Typography variant="subtitle1">
                {renderPagination({
                  totalData: props.totalData,
                  rowsPerPage: props.rowsPerPage,
                  totalPage: props.totalPage,
                  page: props.page,
                  data: props.data.length,
                })}
                {/* {props.totalData > 0 ? '1-' : '0-'}
              {props.totalData > props.rowsPerPage
                ? props.rowsPerPage
                : props.totalData}{' '}
              of {props.totalData} */}
              </Typography>
            </Box>
          </Box>
          <Box display="flex">
            <StyledPagination
              count={props.totalPage}
              variant="outlined"
              shape="rounded"
              page={props.page}
              onChange={(event, page) => props.onChange(page)}
            />
          </Box>
        </Box>
      )}

      <Dialog
        open={isShowDeleteConfirmation}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogContent style={{ padding: 16, minWidth: 400, borderRadius: 10 }}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography variant="h5" style={{ lineHeight: 1.4 }}>
              Delete Confirmation
            </Typography>

            <button
              style={{
                border: "none",
                backgroundColor: "white",
              }}
              onClick={() => handleClose()}
            >
              <Close />
            </button>
          </Box>

          <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
            <Grid item xs={12}>
              <Typography color="primary" variant="body1">
                Are you sure want to delete this item?
              </Typography>
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="flex-end">
            <AppButton onClick={() => handleClose()} color="secondary">
              Cancel
            </AppButton>
            <AppButton onClick={() => deleteItem()}>Yes</AppButton>
          </Box>
        </DialogContent>
      </Dialog>
    </TableContainer>
  );
}

const getItemStyle = (isDragging: boolean, draggableStyle: any) => {
  const style = {
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
      background: "green",
    }),
  };
  return style;
};

const DraggableComponent: any = (id: any, index: any) => (props: any) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided: any, snapshot: any) => (
        <TableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          // style={{ background: 'red' }}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
          {...props}
        >
          {props.children}
        </TableRow>
      )}
    </Draggable>
  );
};

const DroppableComponent: any =
  (onDragEnd: (result: any, provided: any) => void) => (props: any) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={"1"} direction="vertical">
          {(provided: any) => {
            return (
              <TableBody
                ref={provided.innerRef}
                {...provided.droppableProps}
                {...props}
              >
                {props.children}
                {provided.placeholder}
              </TableBody>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  };

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 700,
  },
  containerModal: {
    position: "absolute",
    width: "60%",
    height: "100%",
    padding: theme.spacing(35, 35, 10, 35),
    alignContent: "center",
  },
  contentModal: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  mediumMedia: {
    width: 197,
    height: 197,
  },
  mediumMediaLandscape: {
    width: 197,
    height: 100,
  },
  smallMedia: {
    width: 50,
    height: 50,
  },
  smallMediaLandscape: {
    width: 120,
    height: 80,
  },
  actionIcon: {
    // margin: theme.spacing(0, 1, 0, 0),
    display: "flex",
    alignItems: "center",
    marginRight: 10,
  },
  dataEmpty: {
    fontStyle: "italic",
    opacity: 0.5,
  },
}));

import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { ApprovedProductProperties } from "../../types/b2b/order/sales-order.type";
import FormInput from "../Form/FormInput";

interface Props {
  showNote?: boolean;
  data: ApprovedProductProperties;
  onChangeValue: (newData: ApprovedProductProperties) => void;
}

const ApprovalItem: React.FC<Props> = ({ data, onChangeValue, showNote }) => {
  return (
    <Grid
      key={data.id}
      container
      style={{
        paddingTop: 10,
        paddingBottom: 10,
        borderBottom: "1px solid #e0e0e0",
      }}
    >
      <Grid item xs={2}>
        <Typography>{data.productMetadata.sku}</Typography>
      </Grid>
      <Grid item xs={showNote ? 3 : 4}>
        <Typography>{data.productMetadata.name}</Typography>
      </Grid>
      <Grid item xs={showNote ? 2 : 3}>
        <Typography>{data.qtyRequested}</Typography>
      </Grid>
      <Grid item xs={showNote ? 2 : 3}>
        <FormInput
          value={data.qtyApproved || ""}
          onChange={(value) => {
            if (value.match(/^\d+$/) || value === "")
              onChangeValue({ ...data, qtyApproved: value });
          }}
          style={{
            marginBottom: 0,
          }}
        />
      </Grid>
      {showNote && (
        <Grid item xs={3}>
          <Typography>{data.note || "Not set"}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default ApprovalItem;

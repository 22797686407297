import { TagResponse, TagResponsePayload, TagTypes } from '../../../types/tag.type';

export function findAllTag(payload: TagResponsePayload) {
  return {
    type: TagTypes.FIND_ALL_TAG,
    payload
  }
}

export function findOneTag(payload: TagResponse) {
  return {
    type: TagTypes.FIND_ONE_TAG,
    payload
  }
}

export function setLoadingTag(payload: boolean) {
  return {
    type: TagTypes.SET_LOADING_TAG,
    payload
  }
}

export function setErrorTag(payload: any) {
  return {
    type: TagTypes.SET_ERROR_TAG,
    payload
  }
}
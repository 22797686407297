import { Reducer } from 'redux'
import { BannerResponse, BannerState, BannerTypes, EActionType } from '../../../types/banner.type'
import { ENewsStatus } from '../../../types/news.type'

export const initBanner: BannerResponse = {
  bannerId: null,
  title: null,
  actionType: EActionType.NO_ACTION,
  status: ENewsStatus.PUBLISHED,
  content: null,
  tableName: null,
  tableId: null,
  imageLink: null,
  adminMetadata: {
    adminName: '',
    role: ''
  },
  contentId: '',
}

const initialState: BannerState = {
  isLoadingBanner: false,
  data: {
    code: null,
    message: null,
    payload: {
      next: '',
      prev: '',
      count: 0,
      results: []
    },
  },
  banner: initBanner,
  deleteResult: {
    results: {
      isSuccess: false,
      bannerId: null
    }
  },
  error: {}
}

export const banner: Reducer<BannerState> = (state = initialState, action) => {
  switch (action.type) {
    case BannerTypes.FIND_ALL_BANNER:
      const results = action.payload.payload.results.map((item: BannerResponse) => {
        return {
          ...item,
          adminMetadata: item.adminMetadata || { adminName: '', role: '' }
        }
      })

      return {
        ...state,
        data: {
          ...action.payload,
          payload: {
            ...action.payload.payload,
            results
          }
        }
      }
    case BannerTypes.FIND_ONE_BANNER:
      return {
        ...state,
        banner: action.payload
      }
    case BannerTypes.CREATE_BANNER:
      return {
        ...state,
        banner: action.payload
      }
    case BannerTypes.UPDATE_BANNER:
      return {
        ...state,
        banner: action.payload
      }
    case BannerTypes.DELETE_BANNER:
      return {
        ...state,
        banner: action.payload
      }
    case BannerTypes.SET_LOADING_BANNER:
      return {
        ...state,
        isLoadingBanner: action.payload
      }
    case BannerTypes.SET_ERROR_BANNER:
      return {
        ...state,
        error: action.payload
      }

    default:
      return state
  }
}

export default banner;

export enum CategoryTypes {
  FIND_ALL_CATEGORY = 'FIND_ALL_CATEGORY',
  FIND_ONE_CATEGORY = 'FIND_ONE_CATEGORY',
  CREATE_CATEGORY = 'CREATE_CATEGORY',
  UPDATE_CATEGORY = 'UPDATE_CATEGORY',
  REMOVE_CATEGORY = 'REMOVE_CATEGORY',
  SET_LOADING_CATEGORY = 'SET_LOADING_CATEGORY',
  SET_ERROR_CATEGORY = 'SET_ERROR_CATEGORY',
  SET_LAST_PATH_CATEGORY = 'SET_LAST_PATH_CATEGORY',
}

export type CategoryResponse = {
  categoryName: string;
  categoryId: string;
  published: boolean;
  updatedAt?: Date | string;
  createdAt?: Date | string;
  adminMetadata: {
    adminName: string,
    role: string
  },
  imageUrl?: string
}

export type CategoryRequest = {
  categoryId?: string;
  categoryName: string;
  image: File;
}

export type CategoryResponsePayload = {
  code: string | null;
  message: string | null;
  payload: {
    next: string;
    prev: string;
    count: number;
    results: CategoryResponse[]
  }
}

export type CategoryState = {
  isLoadingCategory: boolean;
  data: CategoryResponsePayload;
  category: CategoryResponse;
  error: any;
  isSuccess: boolean;
}
export enum ReportReferralTypes {
  FIND_ALL_REPORT_REFERRAL = 'FIND_ALL_REPORT_REFERRAL',
  LOADING_REPORT_REFERRAL = 'LOADING_REPORT_REFERRAL',
}

export type ReportReferralState = {
  loading: boolean;
  datas: ReportReferralPayload;
};

export type ReportReferralPayload = {
  code: string | null;
  message: string | null;
  payload: {
    next: string;
    prev: string;
    count: number;
    results: ReportReferralResponse[];
  };
};

export type ReportReferralResponse = {
  customerId: string;
  customerName: string;
  referralCode: string;
  parentName?: string;
  totalDownline: number;
  totalReferralUsed: number;
  totalReferralAmount: number;
};

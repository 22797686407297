import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  Box,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  DialogContentText,
  Snackbar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Paper,
  Chip,
  Radio,
} from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import Layout from "../../components/Layout/Layout";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import { Link, useParams } from "react-router-dom";
import AppButton from "../../components/Form/AppButton";
import COLORS from "../../styles/colors";
import HeaderPage from "../../components/Layout/HeaderPage";
import Search from "../../components/Form/Search";
import FilterDate from "../../components/Form/FilterDate";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AppTabs from "../../components/Tabs/AppTabs";
import { useSelector, useDispatch } from "react-redux";
import useDebounce from "../../hooks/useDebounce";
import useTransaction from "../../hooks/useTransaction";
import { ApplicationState } from "../../redux/store";
import {
  findAllTransaction,
  setOneTransaction,
} from "../../redux/action/transaction.action";
import {
  convertDateTimeToString,
  convertDateTimeToText,
} from "../../helpers/functions/datetime";
import FormInput from "../../components/Form/FormInput";
import {
  TransactionListResponse,
  TransactionLog,
} from "../../types/transaction.types";
import StatusTransaction from "../../components/StatusTransaction";
import { Alert } from "@material-ui/lab";
import { useAuth } from "../../hooks/useAuth";
import { generateQueryString } from "../../helpers/functions/generateQueryString";
import { useLocation } from "react-router-dom";
import {
  setLatestParams,
  enableUpdateParams,
} from "../../redux/action/config-app.action";
import { useHistory } from "react-router-dom";
import { EStatus, ServiceCenterType } from "../../types/service-center.types";
import useServiceCenter from "../../hooks/useServiceCenter";
import AppMap, { Marker } from "../../components/AppMap";
import { Coords } from "google-map-react";
import IconMarker from "../../assets/distributor-marker.png";
import useCustomer from "../../hooks/useCustomer";
import { Add as AddIcon, Close } from "@material-ui/icons";
import {
  apiCreateCustomerByServiceCenter,
  apiRemoveCustomerByServiceCenter,
  apiUpdateCustomerByServiceCenter,
} from "../../api/customer";
import { toast } from "react-toastify";

const headersTechnicians: ITableHeader[] = [
  {
    title: "ID",
    column: "customerId",
    type: "id",
  },
  {
    title: "Phone",
    column: "phoneNumber",
  },
  {
    title: "Email",
    column: "correspondenceEmail",
  },
  {
    title: "Status",
    column: "status",
  },
  {
    title: "Created At",
    column: "createdAt",
  },
];

let data = [];

const tabs = ["Information", "Technician"];

export default function ServiceCenterDetail() {
  const classes = useStyles();
  const auth = useAuth();
  const date = new Date();

  let { id } = useParams<{ id: string }>();

  const serviceCenterState = useSelector(
    (state: ApplicationState) => state.serviceCenter
  );
  const customerState = useSelector(
    (state: ApplicationState) => state.customer
  );

  const data = serviceCenterState.serviceCenter;
  const [centerMap, setCentermap] = useState<Coords>({
    lat: 0,
    lng: 0,
  });
  const [markers, setMarkers] = useState<Array<Marker>>([
    {
      lat: 0,
      lng: 0,
      content: (
        <></>
      ),
    },
  ]);

  const [status, setStatus] = useState("");
  const [actionType, setActionType] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [lastActiveTab, setLastActiveTab] = useState(0);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const [isShowDialogConfirm, setIsShowDialogConfirm] =
    useState<boolean>(false);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [handleChangeStatus, setHandleChangeStatus] = useState("");
  const [filterStatus, setFilterStatus] = useState("ASC");
  const [search, setSearch] = useState<string | null>(null);
  const [dialogReject, setDialogReject] = useState(false);
  const [dialogWaybill, setDialogWaybill] = useState(false);
  const [remark, setRemark] = useState("");
  const [waybillNumber, setWaybillNumber] = useState("");
  const [query, setQuery] = useState({
    offset: 0,
    limit: 25,
    startAt: "",
    status: "",
    endAt: "",
    orderBy: "ASC",
  });
  const [dateTemp, setDateTemp] = useState({
    startAt: "",
    endAt: "",
  });
  const [customerData, setCustomerData] = useState({
    customerId: "",
    customerName: "",
    phoneNumber: "",
    correspondenceEmail: "",
    status: EStatus.ACTIVE,
    serviceCenterId: id,
    isExist: false
  });
  const [phoneNumberDuplicate, setPhoneNumberDuplicate] = useState<string>("");

  const location = useLocation();
  const history = useHistory();

  const { updateParams, latestParams } = useSelector(
    (state: ApplicationState) => state.configApp
  );

  const dispatch = useDispatch();

  const changeTab = (value: number) => {
    // setQuery(latestParams.query)
    setActiveTab(value);
    setPage(1);

    //   dispatch(
    //     setLatestParams({
    //       ...latestParams,
    //       query: {
    //         ...latestParams.query,
    //       },
    //       activeTab: value,
    //     })
    //   );
  };

  const { fetchOneServiceCenter } = useServiceCenter();
  const { fetchAllCustomerByServiceCenter, createDataCustomerByServiceCenter } =
    useCustomer();

  const debouncedStatus = useDebounce(status, 300);

  useEffect(() => {
    setQuery((prev) => ({
      ...prev,
      status: debouncedStatus,
    }));
  }, [debouncedStatus]);

  useEffect(() => {
    if (updateParams) {
      console.info("update true");
      console.log(query);
      dispatch(
        setLatestParams({
          pathname: location.pathname,
          params: generateQueryString(query),
          query: {
            limit: query["limit"],
            offset: query["offset"],
            page,
          },
        })
      );
      fetchAllCustomerByServiceCenter(id, generateQueryString(query));
      setDateTemp({
        startAt: "",
        endAt: "",
      });
    } else {
      console.info("update false");
      setPage(latestParams.query.page || page || 1);
      fetchAllCustomerByServiceCenter(id, latestParams.query);
      dispatch(enableUpdateParams(true));
      setDateTemp({
        startAt: latestParams.query.startAt,
        endAt: latestParams.query.endAt,
      });
    }
  }, [query]);

  useEffect(() => {
    setTotalData(customerState.data.payload.count);
    if (customerState.data.payload.count > 0) {
      setTotalPage(Math.ceil(customerState.data.payload.count / rowsPerPage));
    } else {
      setTotalPage(1);
    }
  }, [customerState.data]);

  useEffect(() => {
    setCentermap({
      lat: data?.metaAddress?.location?.lat,
      lng: data?.metaAddress?.location?.lng,
    })
  
    setMarkers([
      {
        lat: data?.metaAddress?.location?.lat,
        lng: data?.metaAddress?.location?.lng,
        content: (
          <img
            style={{
              transform: "translateZ(0) translate(-50%, -100%)",
              backfaceVisibility: "hidden",
              height: 50,
            }}
            src={IconMarker}
          />
        ),
      },
    ])
  }, [data])

  function changePagination(value: number) {
    setPage(value);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
      };
    });
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value);
    setPage(1);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        startAt: dateTemp.startAt || oldVal.startAt,
        endAt: dateTemp.endAt || oldVal.endAt,
        offset: 0,
        limit: value,
      };
    });
  }
  
  function isValidEmail(email: string) {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  }

  const handleAddTechnician = () => {
    setIsShowDialog(true);
  };

  const handleCloseDialog = () => {
    setIsShowDialog(false);
    setIsShowDialogConfirm(false);
    setCustomerData({
      customerId: "",
      customerName: "",
      phoneNumber: "",
      correspondenceEmail: "",
      status: EStatus.ACTIVE,
      serviceCenterId: id,
      isExist: false
    });
    setPhoneNumberDuplicate("");
  };

  const handleCloseDialogConfirm = () => {
    setIsShowDialogConfirm(false);
    // setIsShowDialog(false)
    setPhoneNumberDuplicate("");
    setCustomerData({
      ...customerData,
      isExist: false
    });
  };

  const createData = async () => {
    console.log("creaate data");
    // createDataCustomerByServiceCenter(customerData);
    try {
      setLoadingSave(true);

      console.log(`body: ${JSON.stringify(customerData)}`);

      const result = await apiCreateCustomerByServiceCenter(customerData);
      const data = result.data.payload;

      if (data.isDuplicate) {
        setPhoneNumberDuplicate(data.data.phoneNumber);
        setIsShowDialogConfirm(true);
        setCustomerData({
          ...customerData,
          isExist: true
        })
      }

      if (data.dataValues) {
        handleCloseDialog();
        console.log(`Success create technician`);
        toast.success("Success create technician", {
          position: toast.POSITION.TOP_CENTER,
          pauseOnHover: false,
        });
        fetchAllCustomerByServiceCenter(id, generateQueryString(query));
      }
      setLoadingSave(false);
    } catch (error) {
      console.log(error);
      toast.error("Failed create technician", {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
      setLoadingSave(false);
    }
  };

  const updateData = async () => {
    console.log("update data", customerData);
    try {
      setLoadingSave(true);

      console.log(`body: ${JSON.stringify(customerData)}`);

      const result = await apiUpdateCustomerByServiceCenter(
        customerData.customerId,
        customerData
      );

      if (result.data.payload) {
        handleCloseDialog();
        console.log(`Success update technician`);
        toast.success("Success update technician", {
          position: toast.POSITION.TOP_CENTER,
          pauseOnHover: false,
        });
        fetchAllCustomerByServiceCenter(id, generateQueryString(query));
      }
      setLoadingSave(false);
    } catch (error) {
      console.log(error);
      toast.error("Failed update technician", {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
      setLoadingSave(false);
    }
  };

  const handleEditData = (data: any) => {
    console.log(data);
    setCustomerData({
      customerId: data.customerId,
      customerName: data.customerName,
      phoneNumber: data.phoneNumber.props.children,
      correspondenceEmail: data.correspondenceEmail,
      status: data.status.props.label,
      serviceCenterId: data.serviceCenterId,
      isExist: false
    });
    setIsShowDialog(true);
  };

  const removeDataServiceCenterTechnician = async (customerId: string) => {
    try {
      setLoadingSave(true);

      console.log("redmove customerid", customerId);

      const result = await apiRemoveCustomerByServiceCenter(customerId);

      console.log(result);

      if (result.data.payload) {
        handleCloseDialog();
        console.log(`Success remove technician`);
        toast.success("Success remove technician", {
          position: toast.POSITION.TOP_CENTER,
          pauseOnHover: false,
        });
        fetchAllCustomerByServiceCenter(id, generateQueryString(query));
      }
      setLoadingSave(false);
    } catch (error) {
      console.log(error);
      toast.error("Failed remove technician", {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
      setLoadingSave(false);
    }
  };

  const customer = customerState.data.payload.results.map((item: any) => {
    return {
      ...item,
      // aroLabel:item?.aroMetadata?.labelName,
      name: (
        <div>
          <Typography variant="body1">{item.customerName}</Typography>
        </div>
      ),
      phoneNumber: <Typography variant="body1">{item.phoneNumber}</Typography>,
      email: (
        <Typography variant="body1">{item.correspondenceEmail}</Typography>
      ),
      status: (
        <Chip
          size="small"
          color="primary"
          label={item.status}
          style={{ marginRight: 5, textTransform: "uppercase" }}
        />
      ),
      createdAt: (
        <Typography variant="body1">
          {convertDateTimeToText(item.createdAt, "dd mmm yyyy")}
        </Typography>
      ),
    };
  });

  useEffect(() => {
    fetchOneServiceCenter(id);
  }, [id]);

  let title = "Detail Service Center";
  let subtitle = "Detailed information about service center";

  return (
    <Layout title={title} loading={serviceCenterState.isLoadingServiceCenter}>
      <Grid container justify="space-between" spacing={3} alignItems="center">
        <Grid item xs={12} md={6} lg={10}>
          <HeaderPage title={title} subtitle={subtitle} showBackButton />
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          backgroundColor: COLORS.grey200,
          padding: 0,
          borderRadius: 10,
          marginTop: 20,
        }}
      >
        <AppTabs
          active={activeTab}
          tabs={tabs}
          onChange={(value: number) => {
            changeTab(value);
          }}
        />

        {activeTab === 0 && (
          <Paper elevation={3} className={classes.paper}>
            <div className={classes.row}>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <Typography variant="body1" style={{ color: COLORS.grey }}>
                    Name
                  </Typography>
                  <Typography variant="h5" style={{ fontWeight: "normal" }}>
                    {data?.name}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" style={{ color: COLORS.grey }}>
                    Phone
                  </Typography>
                  <Typography variant="h5" style={{ fontWeight: "normal" }}>
                    {data?.phoneNumber}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" style={{ color: COLORS.grey }}>
                    Status
                  </Typography>
                  <Typography
                    variant="h5"
                    style={{ fontWeight: "normal", textTransform: "uppercase" }}
                  >
                    {data?.status}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div className={classes.row}>
              <Grid container spacing={3}>
                <Grid item xs={9}>
                  <Typography variant="body1" style={{ color: COLORS.grey }}>
                    Location
                  </Typography>
                  {
                    data?.metaAddress?.location?.lat !== '' && data?.metaAddress?.location?.lng !== '' ? (
                      <AppMap
                        markers={markers}
                        height={270}
                        centerMap={centerMap}
                      />
                    ) : 
                        <Box
                        style={{
                          backgroundColor: "grey",
                          color: "#fff",
                          width: "30%",
                          textAlign: "center",
                          paddingRight: 10,
                          paddingLeft: 10,
                        }}
                      >
                        <Typography variant="h5" style={{ fontWeight: "normal" }}>
                          TIDAK ADA LOKASI
                        </Typography>
                      </Box>
                  }
                </Grid>
              </Grid>
            </div>
            <div className={classes.row}>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <Typography variant="body1" style={{ color: COLORS.grey }}>
                    Province
                  </Typography>
                  <Typography variant="h5" style={{ fontWeight: "normal" }}>
                    {data?.metaAddress?.province}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" style={{ color: COLORS.grey }}>
                    City
                  </Typography>
                  <Typography variant="h5" style={{ fontWeight: "normal" }}>
                    {data?.metaAddress?.city}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" style={{ color: COLORS.grey }}>
                    Subdistrict
                  </Typography>
                  <Typography variant="h5" style={{ fontWeight: "normal" }}>
                    {data?.metaAddress?.subdistrict}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div className={classes.row}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="body1" style={{ color: COLORS.grey }}>
                    Address
                  </Typography>
                  <Typography variant="h5" style={{ fontWeight: "normal" }}>
                    {data?.metaAddress?.address}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div className={classes.row}>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <Typography variant="body1" style={{ color: COLORS.grey }}>
                    Created At
                  </Typography>
                  <Typography variant="h5" style={{ fontWeight: "normal" }}>
                    {convertDateTimeToText(data?.createdAt, "dd mmm yyyy")}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1" style={{ color: COLORS.grey }}>
                    Updated at
                  </Typography>
                  <Typography variant="h5" style={{ fontWeight: "normal" }}>
                    {convertDateTimeToText(data?.updatedAt, "dd mmm yyyy")}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Paper>
        )}

        {activeTab === 1 && (
          <>
            <Paper elevation={3} className={classes.paper}>
              <Grid
                container
                spacing={3}
                justify="space-between"
                alignItems="center"
                style={{ marginBottom: 1 }}
              >
                <Grid item xs={12} md={12} lg={9}>
                  <AppButton
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleAddTechnician}
                  >
                    Add Technician
                  </AppButton>
                </Grid>

                <Grid item xs={12} md={12} lg={3}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="select-status" shrink>
                      Status
                    </InputLabel>
                    <Select
                      label="Service Center"
                      labelId="select-service-center"
                      // multiple={true}
                      value={status}
                      onChange={(event: any) => {
                        setStatus(event.target.value);
                      }}
                      displayEmpty={true}
                    >
                      <MenuItem value="">ALL</MenuItem>
                      <MenuItem value="active">ACTIVE</MenuItem>
                      <MenuItem value="inactive">IN ACTIVE</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <CustomizedTable
                data={customer}
                headers={headersTechnicians}
                totalData={totalData}
                page={page}
                totalPage={totalPage}
                rowsPerPage={rowsPerPage}
                onChange={changePagination}
                onRowsPerPageChange={changeRowsPerPage}
                showAction={["edit-dialog", "delete"]}
                onPressEditDialog={(data) => handleEditData(data)}
                onDeleteItem={(data: any) => {
                  removeDataServiceCenterTechnician(data.customerId);
                }}
              />
            </Paper>

            <Dialog
              fullWidth
              maxWidth={"sm"}
              open={isShowDialog}
              onClose={handleCloseDialog}
              scroll={"body"}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
            >
              <DialogTitle id="scroll-dialog-title">
                {customerData.customerId ? "Edit Technician" : "Add Technician"}
              </DialogTitle>
              <DialogContent>
                <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <Grid item xs={12}>
                    <FormInput
                      onChange={(value: any) =>
                        setCustomerData({
                          ...customerData,
                          customerName: value,
                        })
                      }
                      value={customerData.customerName}
                      fullWidth
                      label="Nama"
                      required
                      disabled={customerData.customerId ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInput
                      type="number"
                      onChange={(value: any) =>
                        setCustomerData({
                          ...customerData,
                          phoneNumber: value,
                        })
                      }
                      value={customerData.phoneNumber}
                      fullWidth
                      label="Nomor Handphone"
                      required
                      disabled={customerData.customerId ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInput
                      onChange={(value: any) =>
                        setCustomerData({
                          ...customerData,
                          correspondenceEmail: value,
                        })
                      }
                      value={customerData.correspondenceEmail}
                      fullWidth
                      label="Email"
                      required
                      disabled={customerData.customerId ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel id="select-status" shrink={true}>
                      Status
                    </InputLabel>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flex: 1,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: 20,
                        }}
                      >
                        <Radio
                          style={{ paddingLeft: 0 }}
                          color="primary"
                          onClick={() => {
                            setCustomerData({
                              ...customerData,
                              status: EStatus.ACTIVE,
                            });
                            setHandleChangeStatus(EStatus.ACTIVE);
                          }}
                          checked={
                            customerData?.status === EStatus.ACTIVE
                              ? true
                              : false
                          }
                        />
                        <div>Active</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Radio
                          style={{ paddingLeft: 0 }}
                          color="primary"
                          onClick={() => {
                            setCustomerData({
                              ...customerData,
                              status: EStatus.INACTIVE,
                            });
                            setHandleChangeStatus(EStatus.INACTIVE);
                          }}
                          checked={
                            customerData?.status === EStatus.INACTIVE
                              ? true
                              : false
                          }
                        />
                        <div>Inactive</div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <AppButton
                  disabled={loadingSave}
                  color="secondary"
                  onClick={handleCloseDialog}
                >
                  Cancel
                </AppButton>
                <AppButton
                  disabled={
                    /[0-9!@#$%^&*()_+|~=`{}\[\]:";'<>?,./\\]/.test(customerData.customerName) ||
                    customerData.customerName === "" ||
                    customerData.phoneNumber === "" ||
                    customerData.correspondenceEmail === "" ||
                    !isValidEmail(customerData.correspondenceEmail) ||
                    customerData.phoneNumber.length <= 9 ||
                    customerData.phoneNumber.length >= 14
                  }
                  loading={loadingSave}
                  // onClick={customerData.customerId ? updateData : createData}
                  onClick={() => customerData.customerId ? updateData() : setIsShowDialogConfirm(true)}
                >
                  Save
                </AppButton>
              </DialogActions>
            </Dialog>

            <Dialog
              open={isShowDialogConfirm}
              onClose={handleCloseDialogConfirm}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <DialogContent
                style={{ padding: 16, minWidth: 400, borderRadius: 10 }}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" style={{ lineHeight: 1.4 }}>
                    Confirmation
                  </Typography>

                  <button
                    style={{
                      border: "none",
                      backgroundColor: "white",
                    }}
                    onClick={() => handleCloseDialogConfirm()}
                  >
                    <Close />
                  </button>
                </Box>

                <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <Grid item xs={12}>
                    <Typography color="primary" variant="body1">
                      {!phoneNumberDuplicate
                        ? `Are you sure want to add ${customerData.customerName} as technician`
                        : `This phone number “+${phoneNumberDuplicate}” already registered, are you sure you want to add this data as a technician ?`}
                    </Typography>
                  </Grid>
                </Grid>

                <Box display="flex" justifyContent="flex-end">
                  <AppButton
                    onClick={() => handleCloseDialogConfirm()}
                    color="secondary"
                  >
                    Cancel
                  </AppButton>
                  <AppButton
                    disabled={loadingSave}
                    loading={loadingSave}
                    onClick={customerData.customerId ? updateData : createData}
                  >
                    Yes, Sure
                  </AppButton>
                </Box>
              </DialogContent>
            </Dialog>

            {/* <Dialog
              open={isShowDialogConfirm}
              onClose={handleCloseDialogConfirm}
              aria-labelledby="scroll-dialog-title-duplicate"
              aria-describedby="scroll-dialog-description-duplicate"
            >
              <DialogTitle id="scroll-dialog-title-duplicate">
                Confirmation
              </DialogTitle>
              <DialogContent>
                <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <Grid item xs={12}>
                    <FormInput
                      onChange={() => console.log('data found')}
                      value={customerDuplicate.customerName}
                      fullWidth
                      label="Nama"
                      required
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <AppButton
                  disabled={loadingSave}
                  color="secondary"
                  onClick={handleCloseDialogConfirm}
                >
                  Cancel
                </AppButton>
                <AppButton
                  disabled={loadingSave}
                  loading={loadingSave}
                  onClick={customerData.customerId ? updateData : createData}
                >
                  Yes, Sure
                </AppButton>
              </DialogActions>
            </Dialog> */}
          </>
        )}
      </Grid>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formStatus: {
      width: "100%",
      margin: theme.spacing(1, 0, 2, 0),
    },
    paper: {
      padding: theme.spacing(2),
    },
    row: {
      marginBottom: 15,
    },
    cardMedia: {
      borderColor: COLORS.grey,
      borderWidth: 1,
      borderStyle: "solid",
      width: 69,
      height: 69,
      borderRadius: 10,
      backgroundColor: COLORS.accent,
    },
    processCircle: {
      width: 25,
      height: 25,
      backgroundColor: COLORS.accent,
      borderRadius: "50%",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: COLORS.grey,
    },
    rejectContent: {
      width: "400px",
      display: "grid",
      gridTemplateColumns: "140px 1fr",
      marginTop: 10,
      marginBottom: 10,
    },
  })
);

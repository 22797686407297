import React from 'react'
import { Paper, makeStyles, Theme, createStyles, Divider, Typography } from '@material-ui/core';
import HeaderPage from '../../components/Layout/HeaderPage';
import { SalesOrderItemProperties } from '../../types/b2b/order/sales-order.type';
import Layout from '../../components/Layout/Layout';
import useFetch from '../../hooks/useFetch';
import { DeliveryOrderItemProperties, DeliveryOrderProperties, IDeliveryPickupQuery, initialDeliveryOrder } from '../../types/b2b/order/delivery-order.type';
import useFetchOne from '../../hooks/useFetchOne';
import { useParams } from 'react-router-dom';
import { PickupOrderItemProperties, PickupOrderProperties } from '../../types/b2b/return/pickup-order.type';
import { ReplacementDeliveryItemProperties, ReplacementDeliveryProperties } from '../../types/b2b/replacement/replacement-delivery.type';
import { ReplacementPickupItemProperties, ReplacementPickupProperties } from '../../types/b2b/replacement/replacement-pickup.type';
import { convertDateTimeToText } from '../../helpers/functions/datetime';
import GeneralInformationDO from '../../components/B2B/sales-order/GeneralInformationDO';
import { headerDetailDeliveryOrderProduct, headerDetailPickupOrderProduct, headerDetailReplacementDeliveryProduct, headerDetailReplacementPickupProduct } from '../B2BTransaction/headers';
import CustomizedTable from '../../components/Table/CustomizedTable';
import ApprovalNote from '../../components/B2B/ApprovalNote';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../redux/store';
import GeneralInformationPO from '../../components/B2B/sales-return/GeneralInformationPO';
import GeneralInformationReplP from '../../components/B2B/replacement/GeneralInformationReplP';
import GeneralInformationReplD from '../../components/B2B/replacement/GeneralInformationReplD';

interface Props {
  type: 'delivery-order' | 'pickup-order' | 'replacement-pickup' | 'replacement-delivery'
}

const DeliveryPickupDetail: React.FC<Props> = ({ type }) => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>()

  const convertTitle = () => {
    return type.split('-').map(text => text.charAt(0).toUpperCase() + text.slice(1)).join(' ')
  }

  const { oId } = useSelector((state: ApplicationState) => state.b2b)

  const [queryDataProduct, setQueryDataProduct] = React.useState<IDeliveryPickupQuery>({
    limit: 25,
    page: 1,
    status: null,
    id: id,
  })

  const {
    fetchOneData,
    data,
    loading
  } = useFetchOne<DeliveryOrderProperties & PickupOrderProperties & ReplacementDeliveryProperties & ReplacementPickupProperties>({
    url: type,
    initialData: initialDeliveryOrder
  })

  // const convertType = () => {
  //   let url = ''
  //   if (type !== 'delivery-order' || ) {
  //     url = 'sales-order'
  //   } else if (type === 'pickup-order') {
  //     url = 'sales-return'
  //   } else {
  //     url = 'replacement'
  //   }

  //   return url
  // }

  const {
    data: dataProducts,
    pagination: paginationDataProduct,
    fetchDataTable
  } = useFetch<DeliveryOrderItemProperties & PickupOrderItemProperties & ReplacementPickupItemProperties & ReplacementDeliveryItemProperties>({
    url: `${type}/data-products/all`,
    query: queryDataProduct,
    staticUrl: true
  })

  React.useEffect(() => {
    if (id) {
      fetchOneData(id)
      fetchDataTable()
    }
  }, [id])

  const dataProductsMapped = dataProducts.map(item => {
    return {
      deliveryOrderItemId: item.deliveryOrderItemId,
      pickupOrderItemId: item.pickupOrderItemId,
      rplPickupItemId: item.rplPickupItemId,
      sku: item.productMetadata.productCode,
      name: item.productMetadata.productName,
      qtyDelivered: item.qtyDelivered,
      qtyReceived: item.qtyReceived,
      qtyPickup: item.qtyPickup,
      qtyLeftover: item.qtyLeftover,
    }
  })

  const handlePageChange = (page: number) => {
    setQueryDataProduct((query) => {
      return {
        ...query,
        page,
        offset: (page - 1) * paginationDataProduct.perPage
      }
    })
  }

  const handlePerPageChange = (limit: number) => {
    setQueryDataProduct((query) => {
      return {
        ...query,
        limit
      }
    })
  }

  return (
    <Layout title="Sales Order Detail" loading={loading}>
      <HeaderPage
        title={convertTitle()}
        subtitle={`Detailed information about ${type === 'delivery-order' ? 'Delivery Order' : type === 'pickup-order' ? 'Pickup Order' : type === 'replacement-delivery' ? 'Replacement Delivery' : 'Replacement Pickup'}`}
        showBackButton
      />
      <Paper elevation={3} className={classes.paper}>
        {type === 'delivery-order' && (
          <GeneralInformationDO
            orderId={data.doId}
            soId={data.soId}
            createdAt={data.createdAt ? convertDateTimeToText(data.createdAt, 'dd mmm yyyy hh:mm') : null}
            status={data.status}
            distributor={data.distributorMetadata.name}
            branchName={data.branchId ? data.branchMetadata.name : null}
            confirmDate={data.confirmAt ? convertDateTimeToText(data.confirmAt, 'dd mmm yyyy hh:mm') : null}
            driverName={data.driverId ? data.driverMetadata.name : null}
            receivedBy={data.receiverName || null}
            podImages={data.podImages}
            note={data.note}
            tripId={data.tripId}
          />
        )}

        {type === 'pickup-order' && (
          <GeneralInformationPO
            poId={data.poId}
            roId={data.roId}
            createdAt={data.createdAt ? convertDateTimeToText(data.createdAt, 'dd mmm yyyy hh:mm') : null}
            status={data.status}
            distributor={data.distributorMetadata.name}
            branchName={data.branchId ? data.branchMetadata.name : null}
            confirmDate={data.confirmAt ? convertDateTimeToText(data.confirmAt, 'dd mmm yyyy hh:mm') : null}
            driverName={data.driverId ? data.driverMetadata.name : null}
            receivedBy={data.receiverName || null}
            podImages={data.podImages}
            note={data.note}
            tripId={data.tripId}
          />
        )}

        {type === 'replacement-pickup' && (
          <GeneralInformationReplP
            rplpId={data.rplpId}
            rploId={data.rploId}
            createdAt={data.createdAt ? convertDateTimeToText(data.createdAt, 'dd mmm yyyy hh:mm') : null}
            status={data.status}
            distributor={data.distributorMetadata.name}
            branchName={data.branchId ? data.branchMetadata.name : null}
            confirmDate={data.confirmAt ? convertDateTimeToText(data.confirmAt, 'dd mmm yyyy hh:mm') : null}
            driverName={data.driverId ? data.driverMetadata.name : null}
            receivedBy={data.receiverName || null}
            podImages={data.podImages}
            note={data.note}
            tripId={data.tripId}
          />
        )}

        {type === 'replacement-delivery' && (
          <GeneralInformationReplD
            rpldId={data.rpldId}
            rploId={data.rploId}
            createdAt={data.createdAt ? convertDateTimeToText(data.createdAt, 'dd mmm yyyy hh:mm') : null}
            status={data.status}
            distributor={data.distributorMetadata.name}
            branchName={data.branchId ? data.branchMetadata.name : null}
            confirmDate={data.confirmAt ? convertDateTimeToText(data.confirmAt, 'dd mmm yyyy hh:mm') : null}
            driverName={data.driverId ? data.driverMetadata.name : null}
            receivedBy={data.receiverName || null}
            podImages={data.podImages}
            note={data.note}
            tripId={data.tripId}
          />
        )}

        <Divider />

        <div className={classes.boxRow} style={{ paddingBottom: 0 }}>
          {type === 'delivery-order' && (
            <Typography variant="h5">Ordered Products</Typography>
          )}
          {type === 'pickup-order' && (
            <Typography variant="h5">Product List</Typography>
          )}
          {type === 'replacement-pickup' && (
            <Typography variant="h5">Replacement Pickup</Typography>
          )}
          {type === 'replacement-delivery' && (
            <Typography variant="h5">Replacement Delivery</Typography>
          )}

          <CustomizedTable
            data={dataProductsMapped}
            headers={
              type === 'delivery-order' ? headerDetailDeliveryOrderProduct
                : type === 'pickup-order' ? headerDetailPickupOrderProduct
                  : type === 'replacement-pickup' ? headerDetailReplacementPickupProduct : headerDetailReplacementDeliveryProduct}
            totalData={paginationDataProduct.totalData}
            page={paginationDataProduct.page}
            totalPage={paginationDataProduct.countPage}
            rowsPerPage={paginationDataProduct.perPage}
            onChange={(page) => handlePageChange(page)}
            onRowsPerPageChange={(rowPerPage: any) => handlePerPageChange(rowPerPage)}
          />
        </div>

        <div className={classes.boxRow} style={{ paddingBottom: 0 }}>
          {type === 'delivery-order' && (
            <Typography variant="h5">Confirm Order Note</Typography>
          )}
          {type === 'pickup-order' && (
            <Typography variant="h5">Confirm Pickup Order Note</Typography>
          )}
          {type === 'replacement-pickup' && (
            <Typography variant="h5">Confirm Replacement Pickup Note</Typography>
          )}
          {type === 'replacement-delivery' && (
            <Typography variant="h5">Confirm Replacement Delivery Note</Typography>
          )}

          <ApprovalNote note={data.confirmNote} />
        </div>
      </Paper>
    </Layout>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(2)
    },
    boxRow: {
      padding: theme.spacing(2, 0, 2, 0),
    },
  })
);

export default DeliveryPickupDetail
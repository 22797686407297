import { Box } from "@material-ui/core";
import { LocationOn } from "@material-ui/icons";
import React from "react";
import AppMap from "../../components/AppMap";
import GeneralInformation from "../B2BTransaction/GeneralInformation";
import COLORS from "../../styles/colors";
import { Alert } from '@material-ui/lab';

export interface MarkerLocation {
  lat: number,
  lng: number,
  isDriver: boolean
}

interface Props {
  locations: MarkerLocation[]
  destinationName: string
  driverName: string
}

export default function LiveTrackingMap(props: Props) {

  const [markers, setMarkers] = React.useState<{
    lat: number,
    lng: number,
    content: any
  }[]>([])

  React.useEffect(() => {
    if (props) {
      const newMarkers = props.locations.map((item) => {
        if (item.isDriver) {
          return {
            lat: item.lat,
            lng: item.lng,
            content: (
              <LocationOn
                style={{ width: 50, height: 50 }}
              />
            ),
          }
        } else {
          return {
            lat: item.lat,
            lng: item.lng,
            content: (
              <LocationOn
                style={{ width: 50, height: 50, color: COLORS.error }}
              />
            ),
          }
        }
      })

      setMarkers(newMarkers)
    }
  }, [props])

  return (
    <Box>
      <div
        style={{
          paddingLeft: 20
        }}
      >
        <GeneralInformation
          itemPerRow={2}
          data={[
            {
              label: "Penerima",
              value: props.destinationName,
              leftIconComponent: (
                <LocationOn
                  style={{ width: 30, height: 30, color: COLORS.error }}
                />
              ),
            },
            {
              label: "Driver",
              value: props.driverName,
              leftIconComponent: (
                <LocationOn style={{ width: 30, height: 30 }} />
              ),
            },
          ]}
        />
      </div>

      <Alert severity="warning">Live Tracking hanya tersedia ketika driver sedang dalam perjalanan</Alert>

      <AppMap
        markers={markers}
      />
    </Box>
  );
}

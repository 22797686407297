import {
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import { Link } from "react-router-dom";
import useGlobalStyles from "../../styles/globalStyles";
import AppButton from "../../components/Form/AppButton";
import HeaderPage from "../../components/Layout/HeaderPage";
import Search from "../../components/Form/Search";
import AppTabs from "../../components/Tabs/AppTabs";
import { ApplicationState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useAdmin } from "../../hooks/useAdmin";
import { findOneAdmin } from "../../redux/action/admin.action";
import useDebounce from "../../hooks/useDebounce";
import { useLocation, useHistory } from "react-router-dom";
import {
  setLatestParams,
  enableUpdateParams,
} from "../../redux/action/config-app.action";
import { generateQueryString } from "../../helpers/functions/generateQueryString";
import { validateMaxText } from "../../helpers/functions/text";

const headers: ITableHeader[] = [
  {
    title: "Name",
    column: "adminId",
    type: "id",
  },
  {
    title: "Name",
    align: "left",
    sortable: true,
    column: "name",
  },
  {
    title: "Phone",
    align: "left",
    sortable: true,
    column: "phoneNumber",
  },
  {
    title: "Email",
    align: "left",
    sortable: true,
    column: "email",
  },
  {
    title: "Role",
    align: "left",
    sortable: true,
    column: "role",
  },
  {
    title: "Status",
    align: "left",
    sortable: true,
    column: "status",
  },
  {
    title: "Created At",
    align: "left",
    sortable: true,
    column: "createdAt",
    type: "date",
  },
];

// const tabs = ['ALL', 'Admin', 'Admin Trip', 'Agent', 'Driver', 'Customer'];

export default function User() {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  const adminState = useSelector((state: ApplicationState) => state.admin);

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  // const [activeTab, setActiveTab] = useState(0);

  const [search, setSearch] = useState<string | null>(null);
  const [query, setQuery] = useState({ limit: 25, offset: 0, search: "" });
  const location = useLocation();
  const history = useHistory();

  const { updateParams, latestParams } = useSelector(
    (state: ApplicationState) => state.configApp
  );

  const debouncedTitle = useDebounce(search, 300);

  const { fetchAllAdmin, fetchOneAdmin } = useAdmin();

  useEffect(() => {
    searchBank(search);
  }, [debouncedTitle]);

  const searchBank = async (value: any) => {
    if (value !== null && value.length > 0) {
      setQuery((oldVal: any) => {
        return {
          limit: 25,
          offset: 0,
          search: value,
        };
      });
    } else if (value !== null) {
      setQuery({ limit: 25, offset: 0, search: "" });
    }
  };

  useEffect(() => {
    if (updateParams) {
      dispatch(
        setLatestParams({
          pathname: location.pathname,
          params: generateQueryString(query),
          query: {
            limit: query["limit"],
            offset: query["offset"],
            page,
          },
        })
      );
      fetchAllAdmin(query);
    } else {
      setPage(latestParams.query.page || page || 1);
      fetchAllAdmin(latestParams.query);
      dispatch(enableUpdateParams(true));
    }
  }, [query]);

  useEffect(() => {
    setTotalData(adminState.data.payload.count);
    if (adminState.data.payload.count > 0) {
      setTotalPage(Math.ceil(adminState.data.payload.count / rowsPerPage));
    } else {
      setTotalPage(1);
    }
  }, [adminState.data]);

  function changePagination(value: number) {
    setPage(value);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
        limit: rowsPerPage,
      };
    });
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value);
    setPage(1);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: 0,
        limit: value,
      };
    });
  }

  let data: any = [];

  data = adminState.data.payload.results.map((item: any) => {
    return {
      ...item,
      name: <Typography>{validateMaxText(20, item.name)}</Typography>,
    };
  });

  return (
    <Layout title="User" loading={adminState.isLoadingAdmin}>
      <HeaderPage title="User" subtitle="List of all user" />

      <Grid container spacing={3} justify="space-between" alignItems="center">
        <Grid item xs={12} md={6} lg={7}>
          <Link to={{ pathname: "create-user" }} className={globalClasses.link}>
            <AppButton
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
            >
              Add User
            </AppButton>
          </Link>
        </Grid>

        {/* <Grid item xs={12} md={6} lg={2}>
          <FormControl className={classes.formStatus}>
            <InputLabel id="select-status" required>
              Status
            </InputLabel>
            <Select
              required
              label="Status"
              placeholder="Status"
              labelId="select-status"
              id="demo-customized-select"
              value={filterStatus}
              onChange={(event: any) => {
                setFilterStatus(event.target.value);
              }}
            >
              <MenuItem value="1">Published</MenuItem>
              <MenuItem value="0">Unpublished</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item xs={12} md={4} lg={3}>
          <Search
            placeholder="Search by name, email"
            onChange={(value: string) => setSearch(value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12}>
          {/* <AppTabs
            active={activeTab}
            tabs={tabs}
            onChange={(value: number) => setActiveTab(value)}
          /> */}

          <CustomizedTable
            data={data}
            headers={headers}
            totalData={totalData}
            showAction={["detail", "edit"]}
            page={page}
            totalPage={totalPage}
            rowsPerPage={rowsPerPage}
            onPressDetail={(data: any) => {
              dispatch(findOneAdmin(data));
            }}
            onPressEdit={(data: any) => {
              dispatch(findOneAdmin(data));
            }}
            onChange={(newPage) => changePagination(newPage)}
            onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
          />
        </Grid>
      </Grid>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formStatus: {
      width: "100%",
      margin: theme.spacing(1, 0, 2, 0),
    },
  })
);

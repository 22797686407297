import axios from 'axios';
export function parseURLSearch(search: string) {
  const query = search.substring(1);
  const vars = query.split('&');
  let params: any = {}
  for (const idx in vars) {
    const exp = vars[idx].split('=');
    params[exp[0]] = exp[1];
  }

  return params;
}


export const extractVideoTikokId = (url: string) => {
  const regex = /\/(video|photo)\/(\d+)/;
  const match = url.match(regex);

  return match ? match[2] : null;
};

export const getUrlEmbedTiktok = async (url: string) => {
  try {

    const res = await axios.get(`https://www.tiktok.com/oembed?url=${url}`);
    console.log(url);
    console.log(res.data);

    // const res = await axios.get(`https://www.tiktok.com/oembed?url=${url}`);

    if (res) {
      console.log('------------');
      console.log(res.data);
      console.log('------------');

      const {author_url, embed_type, embed_product_id} = res.data;

      const urlEmbed = `${author_url}/${embed_type}/${embed_product_id}`;

      console.log(`urlEmbed tiktok: ${urlEmbed}`);

      return urlEmbed;
    }
  
    
  } catch (error) {
  console.log(error)
}
};

export const extractInstagramId = (url: string) => {
  const regex = /\/(reel|p|tv)\/([A-Za-z0-9_-]+)/;
  const match = url.match(regex);
  console.log(url)
  console.log(regex)
  console.log(match)

  return match ? match[2] : null;
};
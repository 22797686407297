import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import React from "react";
import Layout from "../../components/Layout/Layout";
import ListBranch from "./ListBranch";
import ListUser from "./ListUser";
import DistributorInformation from "./DistributorInformation";
import HeaderPage from "../../components/Layout/HeaderPage";
import { useParams } from 'react-router-dom';
import useFetchOne from '../../hooks/useFetchOne';
import { DistributorProperties, initialDistributor } from '../../types/b2b/distributor/distributor.type';
import axios from 'axios';

export default function DistributorDetail() {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>()

  const {
    fetchOneData,
    data,
    loading
  } = useFetchOne<DistributorProperties>({
    url: 'distributor',
    initialData: initialDistributor
  })

  React.useEffect(() => {
    const source = axios.CancelToken.source()

    if (id) {
      fetchOneData(id, source)
    }

    return () => {
      source.cancel()
    }
  }, [id])

  return (
    <Layout title="Distributor Detail" loading={loading}>
      <HeaderPage
        title="Distributor Detail"
        subtitle="Detailed information about distributor"
        showBackButton
      />

      <div className={classes.boxRow}>
        <DistributorInformation data={data} />
      </div>

      <div className={classes.boxRow}>
        <ListBranch
          distributorName={data.name}
          distributorId={id}
        />
      </div>
      <div className={classes.boxRow}>
        <ListUser
          distributorId={id}
        />
      </div>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxRow: {
      padding: theme.spacing(2, 0, 2, 0),
    },
  })
);

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Paper,
  createStyles,
  makeStyles,
  Theme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Box,
  Typography,
  Radio,
  FormLabel,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import FormInput from "../../components/Form/FormInput";
import AppButton from "../../components/Form/AppButton";
import COLORS from "../../styles/colors";
import { useLocation, useParams } from "react-router-dom";
import UploadImage from "../../components/Form/UploadImage";
import HeaderPage from "../../components/Layout/HeaderPage";
import { CategoryRequest, CategoryResponse } from "../../types/category.type";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import useCategory from "../../hooks/useCategory";
import { useHistory } from "react-router-dom";
import { findOneCategory } from "../../redux/action/category.action";
import { initCategory } from "../../redux/reducer/category.reducer";
import { enableUpdateParams } from "../../redux/action/config-app.action";
import { useAuth } from "../../hooks/useAuth";
import useServiceCenterTransaction from "../../hooks/useServiceCenterTransaction";
import {
  EStatus,
  ServiceCenterTransactionType,
  ServiceCenterType,
} from "../../types/service-center.types";
import { convertDateTimeToText } from "../../helpers/functions/datetime";
import DefaultImg from "../../assets/default_500x500.png";
import useServiceCenter from "../../hooks/useServiceCenter";
import { httpRequest } from "../../helpers/api/api";
import AppMap, { Marker } from "../../components/AppMap";
import IconMarker from "../../assets/distributor-marker.png";
import { Coords } from "google-map-react";

export default function ServiceCenterEdit() {
  const location = useLocation();
  const history = useHistory();
  let { id } = useParams<{ id: string }>();

  const [mode, setMode] = useState<"edit" | "create">("create");
  const [handleChangeStatus, setHandleChangeStatus] = useState("");
  const [serviceCenterId, setServiceCenterId] = useState("");

  const [provinces, setProvinces] = useState<any[]>([]);
  const [citys, setCitys] = useState<any[]>([]);
  const [subdistricts, setSubdistricts] = useState<any[]>([]);

  const [editProvince, setEditProvince] = useState<boolean>(false);
  const [editCity, setEditCity] = useState<boolean>(false);
  const [editsubdistrict, setEditsubdistrict] = useState<boolean>(false);

  const [tmpRegion, setTmpRegion] = useState<boolean>(false);

  const [selectedProvince, setSelectedProvince] = useState({
    provinceId: "",
    provinceName: "",
  });

  const [selectedCity, setSelectedCity] = useState({
    cityId: "",
    cityName: "",
  });
  const [tmpCitys, setTmpCitys] = useState<any[]>([]);

  const [selectedSubdistrict, setSelectedSubdistrict] = useState({
    subdistrictId: "",
    subdistrictName: "",
  });
  const [tmpSubdistricts, setTmpSubdistricts] = useState<any[]>([]);

  const [markers, setMarkers] = useState<Array<Marker>>([]);

  const classes = useStyles();
  const dispatch = useDispatch();
  const auth = useAuth();

  const serviceCenter = useSelector(
    (state: ApplicationState) => state.serviceCenter
  );
  const [centerMap, setCentermap] = useState<Coords>({
    lat: -6.1734251377558955,
    lng: 106.82676927467757,
  });
  const [data, setData] = useState<ServiceCenterType>({
    serviceCenterId: "",
    name: "",
    phoneNumber: "",
    status: EStatus.ACTIVE,
    metaAddress: {
      province: "",
      city: "",
      subdistrict: "",
      address: "",
      location: {
        lat: "",
        lng: "",
      },
      status: EStatus.ACTIVE,
    },
    createdAt: "",
    updatedAt: "",
  });

  const { createDataServiceCenter, updateDataServiceCenter } =
    useServiceCenter();

  useEffect(() => {
    if (id && location.pathname.includes("edit")) {
      setServiceCenterId(id);
      setMode("edit");

      httpRequest
        .get("/service-center/" + id)
        .then((res: any) => {
          setData(res.data.payload);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setMode("create");
    }
  }, []);

  useEffect(() => {
    httpRequest
      .get("/province")
      .then(({ data }) => {
        setProvinces(data.payload.results);
      })
      .catch((err) => {
        console.log(err);
      });

    httpRequest
      .get("/city")
      .then(({ data }) => {
        setCitys(data.payload.results);
      })
      .catch((err) => {
        console.log(err);
      });

    httpRequest
      .get("/subdistrict")
      .then(({ data }) => {
        setSubdistricts(data.payload.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (mode === "edit") {
      setCentermap({
        lat: data?.metaAddress?.location?.lat,
        lng: data?.metaAddress?.location?.lng,
      });

      setMarkers([
        {
          lat: data?.metaAddress?.location?.lat,
          lng: data?.metaAddress?.location?.lng,
          content: (
            <img
              style={{
                transform: "translateZ(0) translate(-50%, -100%)",
                backfaceVisibility: "hidden",
                height: 50,
              }}
              src={IconMarker}
            />
          ),
        },
      ]);
    }
  }, [data]);

  const handleShowCitys = (provinceId: any) => {
    const newData = citys.filter((item: any) => item.provinceId === provinceId);
    setTmpCitys(newData);
  };

  const handleShowSubdistrics = (provinceId: any) => {
    const newData = subdistricts.filter(
      (item: any) => item.cityId === provinceId
    );
    setTmpSubdistricts(newData);
  };

  const cancel = () => {
    dispatch(enableUpdateParams(false));
    history.go(-1);
  };

  const handleChangeMarker = (value: { lat: number; lng: number }) => {
    setMarkers([
      {
        lat: value.lat,
        lng: value.lng,
        content: (
          <img
            style={{
              transform: "translateZ(0) translate(-50%, -100%)",
              backfaceVisibility: "hidden",
              height: 50,
            }}
            src={IconMarker}
          />
        ),
      },
    ]);

    setData({
      ...data,
      metaAddress: {
        ...data.metaAddress,
        location: {
          lat: value.lat,
          lng: value.lng,
        },
      },
    });
  };

  const submitData = () => {
    createDataServiceCenter(data);
  };

  const updateData = () => {
    updateDataServiceCenter(data.serviceCenterId, data);
  };

  let title = `${
    mode.charAt(0).toUpperCase() + mode.substring(1)
  } Service Center`;
  let subtitle = `${
    mode.charAt(0).toUpperCase() + mode.substring(1)
  } Service Center`;

  return (
    <Layout title={title}>
      <Grid container justify="space-between" spacing={3} alignItems="center">
        <Grid item xs={12} md={6} lg={8}>
          <HeaderPage title={title} subtitle={subtitle} showBackButton />
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <AppButton fullWidth color="secondary" onClick={cancel}>
            Cancel
          </AppButton>
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <AppButton
            loading={serviceCenter.isLoadingServiceCenter}
            fullWidth
            color="primary"
            onClick={mode === "create" ? submitData : updateData}
            disabled={
              /[0-9!@#$%^&*()_+|~=`{}\[\]:";'<>?,./\\]/.test(data.name) ||
              data.name === "" ||
              data.metaAddress.province === "" ||
              data.metaAddress.city === "" ||
              data.metaAddress.subdistrict === "" ||
              data.metaAddress.address === "" ||
              data.phoneNumber === "" ||
              data.phoneNumber.length <= 9 ||
              data.phoneNumber.length >= 14 ||
              tmpRegion
                ? true
                : false
            }
          >
            Save
          </AppButton>
        </Grid>
      </Grid>

      <Paper elevation={3} className={classes.paper}>
        <Grid
          container
          spacing={3}
          justify="space-between"
          style={{ marginTop: 10 }}
        >
          <Grid item xs={12} md={6} lg={6} className={classes.input}>
            <FormInput
              onChange={(value: any) =>
                setData({
                  ...data,
                  name: value,
                })
              }
              fullWidth
              required
              value={data.name}
              placeholder="Service Center Name"
              label="Nama Service Center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} className={classes.input}>
            <FormInput
              type="number"
              onChange={(value: any) =>
                setData({
                  ...data,
                  phoneNumber: value,
                })
              }
              fullWidth
              required
              placeholder="Phone Number"
              value={data.phoneNumber}
              label="Phone Number"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} justify="space-between">
          <Grid item xs={12} md={4} lg={4} className={classes.input}>
            {serviceCenterId && !editProvince ? (
              <Box
                onClick={() => {
                  setEditProvince(true);
                  setTmpRegion(true);
                }}
                style={{ cursor: "pointer" }}
              >
                <InputLabel
                  id="Province"
                  style={{ fontSize: 11, marginBottom: 3 }}
                >
                  Province *
                </InputLabel>
                <Typography
                  variant="body1"
                  style={{
                    borderBottom: "1px solid black",
                    paddingBottom: 1.3,
                  }}
                >
                  {data?.metaAddress?.province}
                </Typography>
              </Box>
            ) : (
              <>
                <InputLabel
                  id="Province"
                  style={{ fontSize: 11, marginBottom: 3 }}
                >
                  Province *
                </InputLabel>
                <Select
                  label="Province"
                  labelId="province"
                  // value={mode === 'edit' ? data?.metaAddress?.province : ''}
                  onChange={(event: any) => {
                    setSelectedProvince({
                      provinceId: event.target.value.provinceId,
                      provinceName: event.target.value.provinceName,
                    });
                    setData({
                      ...data,
                      metaAddress: {
                        ...data.metaAddress,
                        province: event.target.value.provinceName,
                        city: "",
                        subdistrict: "",
                      },
                    });
                    handleShowCitys(event.target.value.provinceId);
                    handleShowSubdistrics("");
                    setSelectedCity({
                      cityId: "",
                      cityName: "",
                    });
                  }}
                  displayEmpty={true}
                  fullWidth={true}
                >
                  {provinces?.map((value: any, index: any) => (
                    <MenuItem value={value} key={index}>
                      {value.provinceName}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          </Grid>
          <Grid item xs={12} md={4} lg={4} className={classes.input}>
            {selectedProvince.provinceId === "" &&
            serviceCenterId &&
            !editProvince ? (
              <Box>
                <InputLabel id="City" style={{ fontSize: 11, marginBottom: 3 }}>
                  City *
                </InputLabel>
                <Typography
                  variant="body1"
                  style={{
                    borderBottom: "1px dotted black",
                    paddingBottom: 1.3,
                  }}
                >
                  {data?.metaAddress?.city}
                </Typography>
              </Box>
            ) : (
              <>
                <InputLabel id="city" style={{ fontSize: 11, marginBottom: 3 }}>
                  City *
                </InputLabel>
                <Select
                  fullWidth={true}
                  label="City"
                  labelId="city"
                  // value={item.status}
                  onChange={(event: any) => {
                    setSelectedCity({
                      cityId: event.target.value.cityId,
                      cityName: event.target.value.cityName,
                    });
                    setData({
                      ...data,
                      metaAddress: {
                        ...data.metaAddress,
                        city: event.target.value.cityName,
                        subdistrict: "",
                      },
                    });
                    handleShowSubdistrics(event.target.value.cityId);
                  }}
                  displayEmpty={true}
                  disabled={selectedProvince.provinceId === "" ? true : false}
                >
                  {tmpCitys?.map((value: any, index: any) => (
                    <MenuItem value={value} key={index}>
                      {value.cityName}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          </Grid>
          <Grid item xs={12} md={4} lg={4} className={classes.input}>
            {selectedCity.cityId === "" && serviceCenterId && !editProvince ? (
              <Box>
                <InputLabel
                  id="Subdistrict"
                  style={{ fontSize: 11, marginBottom: 3 }}
                >
                  Subdistrict *
                </InputLabel>
                <Typography
                  variant="body1"
                  style={{
                    borderBottom: "1px dotted black",
                    paddingBottom: 1.3,
                  }}
                >
                  {data?.metaAddress?.subdistrict}
                </Typography>
              </Box>
            ) : (
              <>
                <InputLabel
                  id="subdistrict"
                  style={{ fontSize: 11, marginBottom: 3 }}
                >
                  Subdistrict *
                </InputLabel>
                <Select
                  label="Subdistrict"
                  labelId="subdistrict"
                  // value={item.status}
                  onChange={(event: any) => {
                    setSelectedSubdistrict({
                      subdistrictId: event.target.value.subdistricId,
                      subdistrictName: event.target.value.subdistricName,
                    });
                    setData({
                      ...data,
                      metaAddress: {
                        ...data.metaAddress,
                        subdistrict: event.target.value.subdistricName,
                      },
                    });
                    setTmpRegion(false);
                  }}
                  displayEmpty={true}
                  fullWidth={true}
                  disabled={selectedCity.cityId === "" ? true : false}
                >
                  {tmpSubdistricts?.map((value: any, index: any) => (
                    <MenuItem value={value} key={index}>
                      {value.subdistricName}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          justify="space-between"
          style={{ marginTop: 10 }}
        >
          <Grid item xs={12} md={6} lg={12} className={classes.input}>
            <FormInput
              onChange={(value: any) =>
                setData({
                  ...data,
                  metaAddress: {
                    ...data.metaAddress,
                    address: value,
                  },
                })
              }
              fullWidth
              required
              placeholder="Address"
              value={data?.metaAddress?.address}
              label="Address"
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          justify="space-between"
          style={{ marginTop: 10 }}
        >
          <Grid item xs={12} md={12} lg={12} className={classes.input}>
            <AppMap
              handleOnClick={(value) => handleChangeMarker(value)}
              markers={markers}
              height={270}
              centerMap={centerMap}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          justify="space-between"
          style={{ marginTop: 10 }}
        >
          <Grid item xs={12} md={6} lg={6} className={classes.input}>
            <FormInput
              onChange={(value: any) =>
                setData({
                  ...data,
                  metaAddress: {
                    ...data.metaAddress,
                    location: {
                      ...data.metaAddress.location,
                      lat: value,
                    },
                  },
                })
              }
              fullWidth
              placeholder="Latitude"
              label="Latitude"
              value={data?.metaAddress?.location?.lat}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} className={classes.input}>
            <FormInput
              onChange={(value: any) =>
                setData({
                  ...data,
                  metaAddress: {
                    ...data.metaAddress,
                    location: {
                      ...data.metaAddress.location,
                      lng: value,
                    },
                  },
                })
              }
              fullWidth
              placeholder="Longitude"
              label="Longitude"
              value={data?.metaAddress?.location?.lng}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          justify="space-between"
          style={{ marginTop: 10 }}
        >
          <Grid item xs={12}>
            <InputLabel id="select-status" shrink={true}>
              Status
            </InputLabel>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flex: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: 20,
                }}
              >
                <Radio
                  style={{ paddingLeft: 0 }}
                  color="primary"
                  onClick={() => {
                    setData({
                      ...data,
                      status: EStatus.ACTIVE,
                    });
                    setHandleChangeStatus(EStatus.ACTIVE);
                  }}
                  checked={data?.status === EStatus.ACTIVE ? true : false}
                />
                <div>Active</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Radio
                  style={{ paddingLeft: 0 }}
                  color="primary"
                  onClick={() => {
                    setData({
                      ...data,
                      status: EStatus.INACTIVE,
                    });
                    setHandleChangeStatus(EStatus.INACTIVE);
                  }}
                  checked={data?.status === EStatus.INACTIVE ? true : false}
                />
                <div>Inactive</div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 0, 0, 0),
      padding: theme.spacing(2),
    },
    containerForm: {
      padding: 20,
    },
    input: {
      padding: theme.spacing(0, 0, 1, 0),
    },
    paperTitle: {
      padding: theme.spacing(2),
    },
    containerButton: {
      flexDirection: "row",
      padding: theme.spacing(2),
    },
    formStatus: {
      width: "100%",
      margin: theme.spacing(0, 0, 2, 0),
    },
    row: {
      marginBottom: 15,
    },
    cardRoot: {
      width: "100%",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      backgroundColor: COLORS.greyLighten,
      marginTop: 10,
    },
    cardMedia: {
      height: 200,
      width: 200,
      alignSelf: "center",
      backgroundColor: COLORS.accent,
      borderColor: COLORS.accent,
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 10,
    },
    cardActionArea: {
      display: "flex",
      padding: 10,
    },
    wrapperImg: {
      flexDirection: "row",
      flexWrap: "wrap",
    },
  })
);

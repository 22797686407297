import {
  Box,
  createStyles,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  makeStyles,
  Modal,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from '@material-ui/core';
import { Add, CheckCircle, Close } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import CustomizedTable, {
  ITableHeader,
} from '../../components/Table/CustomizedTable';
import { Link } from 'react-router-dom';
import useGlobalStyles from '../../styles/globalStyles';
import AppButton from '../../components/Form/AppButton';
import HeaderPage from '../../components/Layout/HeaderPage';
import { ApplicationState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { setLatestParams, enableUpdateParams } from '../../redux/action/config-app.action';
import { generateQueryString } from '../../helpers/functions/generateQueryString';
import useVersion from '../../hooks/useVersion';
import FormInput from '../../components/Form/FormInput';
import { findOneVersion } from '../../redux/action/version.action';
import { toast } from 'react-toastify';
import compareVersions from 'compare-versions';
import COLORS from '../../styles/colors';

const headers: ITableHeader[] = [
  {
    title: 'App Version Id',
    column: 'appVersionId',
    type: 'id',
  },
  {
    title: 'Android Version',
    align: 'left',
    column: 'androidVersion',
  },
  {
    title: 'Force Update',
    align: 'left',
    column: 'forceUpdate',
  },
  {
    title: 'iOS Version',
    align: 'left',
    column: 'iosVersion',
  },
  {
    title: 'Force Update IOS',
    align: 'left',
    column: 'forceUpdateIos',
  },
  {
    title: 'Updated By',
    column: 'updatedBy'
    // column: JSON.stringify(['adminMetadata', 'adminName']),
    // type: 'nested'
  },
  {
    title: 'Role',
    column: 'role'
    // column: JSON.stringify(['adminMetadata', 'role']),
    // type: 'nested'
  },
  {
    title: 'Created At',
    align: 'left',
    column: 'createdAt',
    type: 'date',
  },
];

export default function Version() {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const dispatch = useDispatch()
  const location = useLocation();
  const history = useHistory();

  const versionState = useSelector((state: ApplicationState) => state.version)
  const { updateParams, latestParams } = useSelector((state: ApplicationState) => state.configApp)

  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [query, setQuery] = useState({ limit: 25, offset: 0 });
  const [isShowModalVersion, setIsShowModalVersion] = useState(false)
  const [androidVersion, setAndroidVersion] = useState('')
  const [iosVersion, setIosVersion] = useState('')
  const [forceUpdate, setForceUpdate] = useState('nope')
  const [forceUpdateIos, setForceUpdateIos] = useState('nope')
  const [appVersionId, setAppVersionId] = useState('')

  const { fetchAllVersion, getCurrentVersion, createNewVersion, updateCurrentVersion } = useVersion()

  useEffect(() => {
    if (updateParams) {
      dispatch(setLatestParams({
        pathname: location.pathname,
        params: generateQueryString(query),
        query: {
          limit: query['limit'],
          offset: query['offset'],
          page
        }
      }))
      fetchAllVersion(query)
    } else {
      setPage(latestParams.query.page || page || 1)
      fetchAllVersion(latestParams.query)
      dispatch(enableUpdateParams(true))
    }
    getCurrentVersion()
  }, [query])

  useEffect(() => {
    if (versionState.data.payload.count > 0) {
      setTotalData(versionState.data.payload.count)
      setTotalPage(Math.ceil(versionState.data.payload.count / rowsPerPage))
    }
  }, [versionState.data])

  function changePagination(value: number) {
    setPage(value)
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
        limit: rowsPerPage
      }
    })
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value)
    setPage(1)
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: 0,
        limit: value
      }
    })
  }

  function handleCloseModalVersion() {
    setAppVersionId('')
    setAndroidVersion('')
    setIosVersion('')
    setForceUpdate('force')
    setForceUpdateIos('force')
    setIsShowModalVersion(false)
  }

  function handleOpenModalVersion() {
    setIsShowModalVersion(true)
  }

  async function submit(type: 'add' | 'edit') {
    const data = {
      androidVersion: androidVersion || null,
      iosVersion: iosVersion || null,
      forceUpdate: forceUpdate === 'force' ? true : false,
      forceUpdateIos: forceUpdateIos === 'force' ? true : false,
    }

    let androidCompare = 1
    let iosCompare = 1

    if (androidVersion) {
      androidCompare = versionState.currentVersion ? compareVersions(androidVersion, versionState.currentVersion.androidVersion) : 1
    } else {
      iosCompare = versionState.currentVersion ? compareVersions(iosVersion, versionState.currentVersion.iosVersion) : 1
    }

    if (type === 'add') {

      if (androidCompare === 0 || androidCompare === -1 || iosCompare === 0 || iosCompare === -1) {
        toast.error("Versi aplikasi harus lebih besar dari versi sebelumnya", {
          position: toast.POSITION.TOP_CENTER,
          pauseOnHover: false,
        });
      } else {
        // console.info('create', JSON.stringify(data))
        await createNewVersion(data)
        handleCloseModalVersion()
        fetchAllVersion(query)
      }

    } else {

      if (androidCompare === -1 || iosCompare === -1) {
        toast.error("Versi aplikasi minimal sama atau lebih besar dari versi sebelumnya", {
          position: toast.POSITION.TOP_CENTER,
          pauseOnHover: false,
        });
      } else {
        // console.info('update', JSON.stringify(data))
        await updateCurrentVersion({ ...data, appVersionId })
        handleCloseModalVersion()
        fetchAllVersion(query)
      }

    }

    getCurrentVersion()
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = (event.target as HTMLInputElement).value
    setForceUpdate(value);
  };

  function handleChangeIOs(event: React.ChangeEvent<HTMLInputElement>) {
    const value = (event.target as HTMLInputElement).value
    setForceUpdateIos(value);
  };

  const dataMapped = versionState.data.payload.results.map((item) => {
    return {
      appVersionId: item.appVersionId,
      androidVersion: item.androidVersion,
      forceUpdate: (
        item.forceUpdate ? <CheckCircle style={{ color: COLORS.primary, }} /> : null
      ),
      iosVersion: item.iosVersion,
      forceUpdateIos: (
        item.forceUpdateIos ? <CheckCircle style={{ color: COLORS.primary, }} /> : null
      ),
      updatedBy: item.adminMetadata.adminName,
      role: item.adminMetadata.role,
      createdAt: item.createdAt,
    }
  })

  return (
    <Layout title="Version Management" loading={versionState.isLoadingVersion}>
      <HeaderPage title="Version Management" subtitle="List of all version of mobile app" />

      <Grid container spacing={3} justify="space-between" alignItems="center">
        <Grid item xs={12} md={6} lg={7}>
          <AppButton
            onClick={handleOpenModalVersion}
            variant="contained"
            color="primary"
            startIcon={<Add />}
          >
            Add Version
          </AppButton>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CustomizedTable
            data={dataMapped}
            headers={headers}
            totalData={totalData}
            showAction={['edit-dialog']}
            page={page}
            totalPage={totalPage}
            rowsPerPage={rowsPerPage}
            onPressEditDialog={(data: any) => {
              handleOpenModalVersion()
              setAppVersionId(data.appVersionId)
              setAndroidVersion(data.androidVersion)
              setIosVersion(data.iosVersion)
              setForceUpdate(data.forceUpdate ? 'force' : 'nope')
              setForceUpdateIos(data.forceUpdateIos ? 'force' : 'nope')
            }}
            onChange={(newPage) => changePagination(newPage)}
            onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
          />
        </Grid>
      </Grid>

      {/** ADD/EDIT VERSION APP */}
      <Modal
        open={isShowModalVersion}
        onClose={handleCloseModalVersion}
      >
        <div className={classes.containerModal}>
          <div className={classes.contentModal}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h5">Version App</Typography>
            </Box>

            <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
              <Grid item xs={12}>
                <FormInput
                  onChange={(value: any) => setAndroidVersion(value)}
                  type="text"
                  value={androidVersion}
                  fullWidth
                  required
                  label="Android Version"
                  placeholder="ex: 1.0.0"
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="select-status" required shrink={true}>
                  Android Update Version Type
              </InputLabel>
                <RadioGroup aria-label="gender" name="gender1" value={forceUpdate} onChange={handleChange}>
                  <FormControlLabel value="force" control={<Radio disabled={!androidVersion} color="primary" />} label="Force Update" />
                  <FormControlLabel value="nope" control={<Radio disabled={!androidVersion} color="primary" />} label="Recommended Update" />
                </RadioGroup>
              </Grid>

              <div
                style={{
                  width: '100%',
                  marginTop: 20,
                  marginBottom: 20,
                }}
              />

              <Grid item xs={12}>
                <FormInput
                  onChange={(value: any) => setIosVersion(value)}
                  type="text"
                  value={iosVersion}
                  fullWidth
                  required
                  label="iOS Version"
                  placeholder="ex: 1.0.0"
                />
                <Grid item xs={12}>
                  <InputLabel id="select-status" required shrink={true}>
                    IOs Update Version Type
                  </InputLabel>
                  <RadioGroup aria-label="gender" name="gender1" value={forceUpdateIos} onChange={handleChangeIOs}>
                    <FormControlLabel value="force" control={<Radio disabled={!iosVersion} color="primary" />} label="Force Update" />
                    <FormControlLabel value="nope" control={<Radio disabled={!iosVersion} color="primary" />} label="Recommended Update" />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="flex-end">
              <Box>
                <AppButton
                  color="secondary"
                  onClick={handleCloseModalVersion}
                >
                  Cancel
                </AppButton>
              </Box>
              <Box>
                <AppButton disabled={!androidVersion && !iosVersion} loading={versionState.isLoadingVersion} onClick={() => submit(appVersionId ? 'edit' : 'add')}>Save</AppButton>
              </Box>
            </Box>
          </div>
        </div>
      </Modal>

    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formStatus: {
      width: '100%',
      margin: theme.spacing(1, 0, 2, 0),
    },
    containerModal: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      alihandleCloseModalApprovalgnContent: 'center',
    },
    contentModal: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
      width: 400,
      margin: '0 auto',
      marginTop: '10%',
      borderRadius: 10,
    },
  })
);

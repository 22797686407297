import React from 'react'
import { Box, Typography } from '@material-ui/core';
import { ApplicationState } from '../../redux/store';
import { useSelector } from 'react-redux';
import COLORS from '../../styles/colors';
import AppMap, { Marker } from '../AppMap';
import IconMarker from '../../assets/distributor-marker.png';
import { Coords } from 'google-map-react';

const DetailBranch: React.FC = () => {

  const { branch } = useSelector((state: ApplicationState) => state.b2b)
  const [markers, setMarkers] = React.useState<Array<Marker>>([])
  const [centerMap, setCenterMap] = React.useState<Coords>({ lat: 0, lng: 0 })

  React.useEffect(() => {
    if (branch) {
      const coordinate: any = branch.coordinate.split(';')
      setMarkers([{
        lat: Number(coordinate[0]),
        lng: Number(coordinate[1]),
        content: (
          <img
            style={{
              transform: 'translateZ(0) translate(-50%, -100%)',
              backfaceVisibility: 'hidden',
              height: 50
            }}
            src={IconMarker}
          />
        )
      }])
      setCenterMap({
        lat: Number(coordinate[0]),
        lng: Number(coordinate[1]),
      })
    }
  }, [branch])

  return (
    <>
      <Box style={{ marginBottom: 15 }}>
        <Typography variant="body1" color="textSecondary">
          Name
        </Typography>
        <Typography variant="body1">{branch.name}</Typography>
      </Box>
      <Box style={{ marginBottom: 15 }}>
        <div
          style={{
            height: 350,
            width: '100%',
            borderRadius: 10,
            overflow: 'hidden'
          }}
        >
          <AppMap
            markers={markers}
            height={350}
            centerMap={centerMap}
          />
        </div>
      </Box>
      <Box style={{ marginBottom: 15 }}>
        <Typography variant="body1" color="textSecondary">
          Latitude & Longitude
        </Typography>
        {/* <Typography variant="body1">{branch.coordinate}</Typography> */}
      </Box>
      <Box style={{ marginBottom: 15 }}>
        <Typography variant="body1" color="textSecondary">
          Address
        </Typography>
        <Typography variant="body1">{branch.address}</Typography>
      </Box>
      <Box style={{ marginBottom: 15 }}>
        <Typography variant="body1" color="textSecondary">
          Phone
        </Typography>
        <Typography style={{
          fontStyle: !branch.phoneNumber ? 'italic' : 'normal',
          color: !branch.phoneNumber ? COLORS.greyDark : COLORS.primary
        }} variant="body1">{branch.phoneNumber || 'Not set'}</Typography>
      </Box>
    </>
  )
}

export default DetailBranch
import axios from 'axios';
import { getHeaders } from '../helpers/auth';
import { VersionRequest } from '../types/version.type';

export async function apiFindAllVersion(params: string) {
  const query = params || null
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/app-version' + query,
    method: 'GET',
    headers
  });
}

export async function apiFindOneVersion(versionId: string) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/app-version/' + versionId,
    method: 'GET',
    headers
  });
}

export async function apiCreateVersion(data: VersionRequest) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/app-version',
    method: 'POST',
    headers,
    data
  });
}

export async function apiUpdateVersion(data: VersionRequest) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/app-version/' + data.appVersionId,
    method: 'PATCH',
    headers,
    data
  });
}

export async function apiDeleteVersion(versionId: string) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/app-version/' + versionId,
    method: 'DELETE',
    headers,
  });
}

export async function apiCurrentVersion() {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/app-version/app/current-version',
    method: 'GET',
    headers,
  });
}
import { ENewsStatus } from './news.type'

export enum BannerTypes {
  FIND_ALL_BANNER = 'FIND_ALL_BANNER',
  FIND_ONE_BANNER = 'FIND_ONE_BANNER',
  CREATE_BANNER = 'CREATE_BANNER',
  UPDATE_BANNER = 'UPDATE_BANNER',
  DELETE_BANNER = 'DELETE_BANNER',
  SET_LOADING_BANNER = 'SET_LOADING_BANNER',
  SET_ERROR_BANNER = 'SET_ERROR_BANNER',
}

export enum EActionType {
  NEW_CONTENT = 'new_content',
  EXISTING_PRODUCT = 'existing_product',
  EXISTING_PROMOTION = 'existing_promotion',
  EXISTING_NEWS = 'existing_news',
  NO_ACTION = 'no_action',
}

export type BannerRequestImageOnly = {
  bannerId: string;
  image: ImageData;
}

export type BannerRequest = {
  bannerId?: string | null
  title?: string | null
  actionType: EActionType | null
  status: ENewsStatus | null
  content?: string | null
  tableName?: string | null
  contentId?: string | null
}

export type BannerResponse = {
  bannerId: string | null
  title?: string | null
  actionType: EActionType
  status: ENewsStatus
  content?: string | null
  tableName?: string | null
  tableId?: string | null
  contentId: string
  adminMetadata: {
    adminName: string,
    role: string
  },
  imageLink: string | null
}

export type BannerChangeOrderRequest = {
  bannerId: string
  currentIndex: number
  prevIndex: number
}

export type BannerResponsePayload = {
  code: string | null;
  message: string | null;
  payload: {
    next: string;
    prev: string;
    count: number;
    results: BannerResponse[];
  }
}

export type BannerDeleteResponse = {
  results: {
    isSuccess: boolean;
    bannerId: string | null;
  }
}

export type BannerState = {
  isLoadingBanner: boolean;
  data: BannerResponsePayload;
  banner: BannerResponse;
  deleteResult: BannerDeleteResponse;
  error: any;
}
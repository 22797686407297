import { useDispatch } from "react-redux";
import {
  apiAddImageRecipe,
  apiCreateRecipe,
  apiDeleteRecipe,
  apiFindAllRecipe,
  apiFindOneRecipe,
  apiUpdateRecipe,
} from "../api/recipe";
import {
  setLoadingRecipe,
  setErrorRecipe,
  findAllRecipe,
  findOneRecipe,
  createRecipe,
  updateRecipe,
  deleteRecipe,
} from "../redux/action/recipe.action";
import { useHistory } from "react-router-dom";
import { setUpladImageStatus } from "../redux/action/config-app.action";
import { generateQueryString } from "../helpers/functions/generateQueryString";
import { setErrorBanner } from "../redux/action/content/banner.action";

export default function useRecipe() {
  const dispatch = useDispatch();
  const history = useHistory();

  const fetchAllRecipe = async (params: any) => {
    try {
      dispatch(setLoadingRecipe(true));
      let query = generateQueryString(params);

      const result = await apiFindAllRecipe(query);

      dispatch(findAllRecipe(result.data));

      dispatch(setLoadingRecipe(false));
    } catch (error: any) {
      dispatch(setLoadingRecipe(false));
      dispatch(setErrorRecipe(error.response));
    }
  };

  const fetchOneRecipe = async (recipeId: string) => {
    try {
      dispatch(setLoadingRecipe(true));

      const result = await apiFindOneRecipe(recipeId);

      dispatch(findOneRecipe(result.data.payload));

      dispatch(setLoadingRecipe(false));
    } catch (error: any) {
      dispatch(setLoadingRecipe(false));
      dispatch(setErrorRecipe(error.response));
    }
  };

  const addRecipe = async (data: any) => {
    try {
      dispatch(setLoadingRecipe(true));
      const result = await apiCreateRecipe(data);

      if (result.data.payload) {
        dispatch(createRecipe(result.data.payload));
      }

      dispatch(setLoadingRecipe(false));
      return result
    } catch (error: any) {
      dispatch(setLoadingRecipe(false));
      dispatch(setErrorRecipe(error.response));
    }
  };

  const updateOneRecipe = async (data: any) => {
    try {
      dispatch(setLoadingRecipe(true));
      console.log(data)

      const result = await apiUpdateRecipe(data);

      if (result.data.payload) {
        dispatch(updateRecipe(result.data.payload));
        
      }

      dispatch(setLoadingRecipe(false));
      return result
    } catch (error: any) {
      dispatch(setLoadingRecipe(false));
      dispatch(setErrorRecipe(error.response));
    }
  };

  const removeRecipe = async (recipeId: string) => {
    try {
      dispatch(setLoadingRecipe(true));

      await apiDeleteRecipe(recipeId);

      dispatch(deleteRecipe(recipeId));

      dispatch(setLoadingRecipe(false));
    } catch (error: any) {
      dispatch(setLoadingRecipe(false));
      dispatch(setErrorRecipe(error.response));
    }
  };

  return {
    fetchAllRecipe,
    fetchOneRecipe,
    addRecipe,
    updateOneRecipe,
    removeRecipe
  };
}

import { useDispatch, useSelector } from "react-redux";
import {
  apiFindAllTransaction,
  apiFindHistoryTransaction,
  apiFindOneTransaction,
  apiProcessStatusTransaction,
  apiRefundTransaction,
  apiRejectTransaction,
  apiUpdatePickupTransaction,
  apiUpdateWaybillTransaction,
} from "../api/transaction";
import { generateQueryString } from "../helpers/functions/generateQueryString";
import {
  setLoadingTransaction,
  findAllTransaction,
  findOneTransaction,
  findHistoryTransaction,
  setLoadingProcessTransaction,
  setProcessTransaction,
  setLastQueryTransaction,
  setRefundTransaction,
  setRefundAmount,
} from "../redux/action/transaction.action";
import { ApplicationState } from "../redux/store";
import { TransactionResponse } from "../types/transaction.types";

export default function useTransaction() {
  const dispatch = useDispatch();

  const { lastPathQuery } = useSelector(
    (state: ApplicationState) => state.transaction
  );

  const fetchAllTransaction = async (params?: any) => {
    try {
      dispatch(setLoadingTransaction(true));
      dispatch(setLastQueryTransaction(params));

      let query = generateQueryString(params);
      const result = await apiFindAllTransaction(query);

      dispatch(findAllTransaction(result.data));

      if (
        params.filterStatus === "rejected" ||
        params.filterStatus === "refunded"
      ) {
        if (
          result.data &&
          result.data.payload &&
          result.data.payload.results &&
          result.data.payload.results.length > 0
        ) {
          const sum = result.data.payload.results.reduce(
            (acc: number, item: TransactionResponse) => {
              return acc + item.finalTransactionPrice;
            },
            0
          );

          dispatch(setRefundAmount(sum));
        }
      }

      dispatch(setLoadingTransaction(false));
    } catch (error) {
      console.info(error);
      dispatch(setLoadingTransaction(false));
      // dispatch(setErrorTransaction(error.response))
    }
  };

  const fetchOneTransaction = async (transactionId: string) => {
    try {
      dispatch(setLoadingTransaction(true));

      const result = await apiFindOneTransaction(transactionId);

      dispatch(findOneTransaction(result.data.payload));

      dispatch(setLoadingTransaction(false));
    } catch (error) {
      console.info(error);
      dispatch(setLoadingTransaction(false));
      // dispatch(setErrorTransaction(error.response))
    }
  };

  const fetchHistoryTransaction = async (transactionId: string) => {
    try {
      dispatch(setLoadingTransaction(true));

      const result = await apiFindHistoryTransaction("", { transactionId });

      dispatch(findHistoryTransaction(result.data.payload));

      dispatch(setLoadingTransaction(false));
    } catch (error) {
      console.info(error);
      dispatch(setLoadingTransaction(false));
      // dispatch(setErrorTransaction(error.response))
    }
  };

  const processTransaction = async (transactionId: string) => {
    try {
      dispatch(setLoadingProcessTransaction(true));

      const result = await apiProcessStatusTransaction(transactionId);

      if (
        result.data &&
        (result.status === 201 || result.status === 200) &&
        result.data.payload
      ) {
        dispatch(setProcessTransaction(true));

        await fetchAllTransaction(lastPathQuery);
      }

      dispatch(setLoadingProcessTransaction(false));
    } catch (error) {
      console.info(error);
      dispatch(setLoadingProcessTransaction(false));
      // dispatch(setErrorTransaction(error.response))
    }
  };

  const rejectTransaction = async (data: {
    transactionId: string;
    note: string;
  }) => {
    try {
      dispatch(setLoadingProcessTransaction(true));

      const result = await apiRejectTransaction(data);

      if (
        (result.data && result.status === 201) ||
        (200 && result.data.payload)
      ) {
        dispatch(setProcessTransaction(result.data.payload.isSuccess));

        await fetchAllTransaction(lastPathQuery);
      }

      dispatch(setLoadingProcessTransaction(false));
    } catch (error) {
      console.info(error);
      dispatch(setLoadingProcessTransaction(false));
      // dispatch(setErrorTransaction(error.response))
    }
  };

  const updateWaybillTransaction = async (data: {
    waybill: string;
    invoiceId: string;
    earnedCoin?: any;
    note?: string;
    finalTransactionPriceBefore?: any;
    finalTransactionPriceAfter?: any;
  }) => {
    try {
      dispatch(setLoadingProcessTransaction(true));

      const result = await apiUpdateWaybillTransaction(data);

      if (
        (result.data && result.status === 201) ||
        (200 && result.data.payload)
      ) {
        dispatch(setProcessTransaction(result.data.payload.isSuccess));

        await fetchAllTransaction(lastPathQuery);
      }

      dispatch(setLoadingProcessTransaction(false));
    } catch (error) {
      console.info(error);
      dispatch(setLoadingProcessTransaction(false));
      // dispatch(setErrorTransaction(error.response))
    }
  };

  const updatePickupTransaction = async (data: {
    pickupPIN: string;
    invoiceId: string;
    pickupDate: Date;
    earnedCoin?: any;
    note?: string;
    finalTransactionPriceBefore?: any;
    finalTransactionPriceAfter?: any;
  }) => {
    try {
      dispatch(setLoadingProcessTransaction(true));
      console.log(data);

      const result = await apiUpdatePickupTransaction(data);
      console.log(result);

      if (
        (result.data && result.status === 201) ||
        (200 && result.data.payload)
      ) {
        dispatch(setProcessTransaction(result.data.payload.isSuccess));

        await fetchAllTransaction(lastPathQuery);
      }

      dispatch(setLoadingProcessTransaction(false));
    } catch (error) {
      console.info(error);
      dispatch(setLoadingProcessTransaction(false));
      // dispatch(setErrorTransaction(error.response))
    }
  };

  const refundTransaction = async (data: FormData) => {
    try {
      dispatch(setLoadingProcessTransaction(true));

      const result = await apiRefundTransaction(data);

      if (
        (result.data && result.status === 201) ||
        (200 && result.data.payload)
      ) {
        dispatch(setRefundTransaction(result.data.payload.isSuccess));
        await fetchAllTransaction(lastPathQuery);
      }

      dispatch(setLoadingProcessTransaction(false));
    } catch (error) {
      console.info(error);
      dispatch(setLoadingProcessTransaction(false));
      // dispatch(setErrorTransaction(error.response))
    }
  };

  return {
    fetchAllTransaction,
    fetchOneTransaction,
    processTransaction,
    rejectTransaction,
    updateWaybillTransaction,
    updatePickupTransaction,
    fetchHistoryTransaction,
    refundTransaction,
  };
}

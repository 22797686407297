import {
  CategoryResponse,
  CategoryState,
  CategoryTypes,
} from "../../types/category.type";
import { Reducer } from "redux";

export const initCategory = {
  categoryId: "",
  categoryName: "",
  imageUrl: "",
  published: false,
  adminMetadata: {
    adminName: "",
    role: "",
  },
};

const initialState: CategoryState = {
  isLoadingCategory: false,
  data: {
    code: null,
    message: null,
    payload: {
      next: "",
      prev: "",
      count: 0,
      results: [],
    },
  },
  error: {},
  category: { ...initCategory },
  isSuccess: false,
};

export const category: Reducer<CategoryState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CategoryTypes.FIND_ALL_CATEGORY:
      const results = action.payload.payload.results.map(
        (item: CategoryResponse) => {
          return {
            ...item,
            adminMetadata: item.adminMetadata
              ? JSON.parse(item.adminMetadata as any)
              : { adminName: "", role: "" },
          };
        }
      );

      return {
        ...state,
        data: {
          ...action.payload,
          payload: {
            ...action.payload.payload,
            results,
          },
        },
      };
    case CategoryTypes.FIND_ONE_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    case CategoryTypes.CREATE_CATEGORY:
      return {
        ...state,
        category: action.payload,
        isSuccess: true,
      };
    case CategoryTypes.UPDATE_CATEGORY:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case CategoryTypes.REMOVE_CATEGORY:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case CategoryTypes.SET_LOADING_CATEGORY:
      return {
        ...state,
        isLoadingCategory: action.payload,
      };
    case CategoryTypes.SET_ERROR_CATEGORY:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

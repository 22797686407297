import CustomizedTable, {
  ITableHeader,
} from "../../../components/Table/CustomizedTable";
import { Box, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import GeneralInformation from "../../B2BTransaction/GeneralInformation";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../redux/store";
import useCoin from "../../../hooks/useCoin";
import { useEffect, useState } from "react";
import FilterDate from "../../../components/Form/FilterDate";
import {
  setLatestParams,
  enableUpdateParams,
} from "../../../redux/action/config-app.action";
import { generateQueryString } from "../../../helpers/functions/generateQueryString";
import { useLocation } from "react-router";

const CoinHistory = (props: {
  customerId: string;
  phoneNumber: string;
  customerName: string;
  onLoading: (value: boolean) => void;
}) => {
  const classes = useStyles();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const dispatch = useDispatch();
  const { coinHistory } = useSelector((state: ApplicationState) => state.coin);
  const { updateParams, latestParams } = useSelector(
    (state: ApplicationState) => state.configApp
  );
  const [query, setQuery] = useState({
    customerId: props.customerId,
    limit: 25,
    offset: 0,
    startAt: "",
    endAt: "",
  });
  const [dateTemp, setDateTemp] = useState({
    startAt: "",
    endAt: "",
  });

  const { fetchCoinHistory } = useCoin();

  useEffect(() => {
    if (updateParams) {
      console.info("update true");
      dispatch(
        setLatestParams({
          pathname: location.pathname,
          params: generateQueryString(query),
          query: {
            limit: query["limit"],
            offset: query["offset"],
            page,
          },
        })
      );
      fetchCoinHistory(query);
      setDateTemp({
        startAt: "",
        endAt: "",
      });
    } else {
      console.info("update false");
      setPage(latestParams.query.page || page || 1);
      fetchCoinHistory(latestParams.query);
      dispatch(enableUpdateParams(true));
      setDateTemp({
        startAt: latestParams.query.startAt,
        endAt: latestParams.query.endAt,
      });
    }
  }, [query]);

  const headers: ITableHeader[] = [
    {
      title: "Invoice ID",
      align: "left",
      column: "invoiceId",
      type: "text",
    },
    {
      title: "Rank",
      align: "left",
      column: "loyaltyName",
      type: "text",
    },
    {
      title: "Coin  OPA Terpakai",
      align: "left",
      column: "coinUsed",
      type: "text",
    },
    {
      title: "Coin  OPA Didapatkan",
      align: "left",
      column: "coinReceived",
      type: "text",
    },
    {
      title: "Sisa Coin OPA",
      align: "left",
      column: "coinLeftAmount",
      type: "text",
    },
    {
      title: "Transaction Date",
      align: "left",
      column: "createdAt",
      type: "date",
    },
  ];

  useEffect(() => {
    if (coinHistory.payload.count > 0) {
      setTotalData(coinHistory.payload.count);
      setTotalPage(Math.ceil(coinHistory.payload.count / rowsPerPage));
    }
  }, [coinHistory.payload]);

  function changePagination(value: number) {
    setPage(value);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
        limit: rowsPerPage,
      };
    });
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value);
    setPage(1);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: 0,
        limit: value,
      };
    });
  }

  return (
    <Box className={classes.containerForm}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={6}>
          <GeneralInformation
            itemPerRow={2}
            data={[
              {
                label: "Customer",
                value: props.customerName,
              },
              {
                label: "Phone",
                value: props.phoneNumber,
              },
            ]}
          />
        </Grid>
        <Grid item xs={6}>
          <FilterDate
            start_at={dateTemp.startAt || query.startAt}
            end_at={dateTemp.endAt || query.endAt}
            onChange={(start_at: string, end_at: string) =>
              setQuery((val: any) => {
                return {
                  ...val,
                  startAt: start_at,
                  endAt: end_at,
                };
              })
            }
          />
        </Grid>
      </Grid>

      <CustomizedTable
        data={coinHistory.payload.results}
        headers={headers}
        totalData={totalData}
        page={page}
        totalPage={totalPage}
        rowsPerPage={rowsPerPage}
        onChange={(newPage) => changePagination(newPage)}
        onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
      />
    </Box>
  );
};

export default CoinHistory;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 0, 0, 0),
    },
    containerForm: {
      padding: theme.spacing(2),
    },
    input: {
      padding: theme.spacing(0, 2, 1, 2),
    },
    row: {
      padding: theme.spacing(1, 0, 1, 0),
    },
  })
);

import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Layout from '../../components/Layout/Layout';
import {
  Box,
  createStyles,
  makeStyles,
  Modal,
  Paper,
  Theme,
  TextField,
  Typography,
} from '@material-ui/core';
import COLORS from '../../styles/colors';
import HeaderPage from '../../components/Layout/HeaderPage';
import SimpleCard2 from './components/SimpleCard2';
import { convertNumToCurrency } from '../../helpers/functions/currency';
import { User } from 'react-feather';
import { ChevronRight } from 'react-feather';
import AppButton from '../../components/Form/AppButton';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ReferralDiscountHistory from './components/ReferralDiscountHistory';
import { Save, Close } from '@material-ui/icons';
import FormInput from '../../components/Form/FormInput';
import useReferralCommission from '../../hooks/useReferralCommission';
import useReferralInformation from '../../hooks/useReferralInformation';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../redux/store';
import { useAuth } from '../../hooks/useAuth';

export default function Referral() {
  const classes = useStyles();

  const { user } = useAuth()

  const [referralInformationContent, setReferralInformationContent] = useState(
    ''
  );

  const masterData = useSelector((state: ApplicationState) => state.masterDataReferral)

  const { fetchCurrentReferralCommission, updateReferralCommission } = useReferralCommission()
  const { fetchCurrentReferralInformation, updateReferralInformation } = useReferralInformation()
  const [amountRetailCom, setAmountRetailCom] = useState(0)
  const [amountReferralCom, setAmountReferralCom] = useState(0)
  const [amountSPGCom, setAmountSPGCom] = useState(0)
  const [amountMemberPremium, setAmountMemberPremium] = useState(0)

  useEffect(() => {
    fetchCurrentReferralCommission()
    fetchCurrentReferralInformation()
  }, [])

  useEffect(() => {
    if (masterData.referralComission) {
      const amountRetail = masterData.referralComission.amountRetail
      const amountReferral = masterData.referralComission.amountReferral
      const amountSPG = masterData.referralComission.amountSPG
      const amountMemberPremium = masterData.referralComission.amountPremiumMembership

      console.info(amountRetail, amountReferral, amountMemberPremium)

      setAmountRetailCom(amountRetail)
      setAmountReferralCom(amountReferral)
      setAmountSPGCom(amountSPG)
      setAmountMemberPremium(amountMemberPremium)
    }
  }, [masterData.referralComission])

  useEffect(() => {
    if (masterData.referralInformation) {
      setReferralInformationContent(masterData.referralInformation.content)
    }
  }, [masterData.referralInformation])

  function convertCurrency(value: number) {
    return convertNumToCurrency(value)
  }

  const [
    isShowModalEditReferralCommision,
    setIsShowModalEditReferralCommision,
  ] = useState(false);

  const handleCloseModalEditReferralCommision = () => {
    setIsShowModalEditReferralCommision(false);
    setAmountReferralCom(masterData.referralComission.amountReferral)
  };
  const handleOpenModalEditReferralCommision = () => {
    setIsShowModalEditReferralCommision(true);
    setAmountReferralCom(masterData.referralComission.amountReferral)
  };

  const [
    isShowModalEditRetailCommision,
    setIsShowModalEditRetailCommision,
  ] = useState(false);

  const handleCloseModalEditRetailCommision = () => {
    setIsShowModalEditRetailCommision(false);
    setAmountRetailCom(masterData.referralComission.amountRetail)
  };
  const handleOpenModalEditRetailCommision = () => {
    setIsShowModalEditRetailCommision(true);
    setAmountRetailCom(masterData.referralComission.amountRetail)
  };

  const [
    isShowModalEditSPGCommision,
    setIsShowModalEditSPGCommision,
  ] = useState(false);

  const handleCloseModalEditSPGCommision = () => {
    setIsShowModalEditSPGCommision(false);
    setAmountSPGCom(masterData.referralComission.amountSPG)
  };
  const handleOpenModalEditSPGCommision = () => {
    setIsShowModalEditSPGCommision(true);
    setAmountSPGCom(masterData.referralComission.amountSPG)
  };

  const [
    isShowModalEditNominalMemberPremium,
    setIsShowModalEditNominalMemberPremium,
  ] = useState(false);

  const handleCloseModalEditNominalMemberPremium = () => {
    setIsShowModalEditNominalMemberPremium(false);
    setAmountMemberPremium(masterData.referralComission.amountPremiumMembership)
  };
  const handleOpenModalEditNominalMemberPremium = () => {
    setIsShowModalEditNominalMemberPremium(true);
    setAmountMemberPremium(masterData.referralComission.amountPremiumMembership)
  };

  const handleChange = (html: any) => {
    setReferralInformationContent(html);
  };

  async function submit(type: 'referral' | 'retail' | 'member' | 'spg') {
    let data = {
      amount: 0,
      referralType: '',
      adminMetadata: {
        adminName: user.name,
        role: user.role
      }
    }
    if (type === 'referral') {
      data = {
        ...data,
        amount: (amountReferralCom as any),
        referralType: 'referral-commission',
      }
    }
    if (type === 'retail') {

      data = {
        ...data,
        amount: (amountRetailCom as any),
        referralType: 'retail-commission',
      }
    }
    if (type === 'spg') {

      data = {
        ...data,
        amount: (amountSPGCom as any),
        referralType: 'spg-commission',
      }
    }
    if (type === 'member') {
      data = {
        ...data,
        amount: (amountMemberPremium as any),
        referralType: 'premium-membership',
      }
    }

    await updateReferralCommission({ data, type })
    setIsShowModalEditReferralCommision(false);
    setIsShowModalEditRetailCommision(false);
    setIsShowModalEditSPGCommision(false);
    setIsShowModalEditNominalMemberPremium(false);
  }

  function updateReferralInfo() {
    updateReferralInformation({ content: referralInformationContent })
  }

  return (
    <Layout title="Referral Commissions" loading={masterData.isLoadingReferralComission || masterData.isLoadingReferralInformation}>
      <HeaderPage
        title="Referral Commissions"
        subtitle="Referral commission configuration"
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <SimpleCard2
            loading={masterData.isLoadingReferralComission}
            title="Referral Commision"
            value={masterData.referralComission.amountReferral + '%'}
            topRightIconComponent={<User style={{ width: 20, height: 20 }} />}
            bottomRightComponent={
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpenModalEditReferralCommision()}
              >
                <Typography variant="subtitle1" color="primary">
                  Edit Commision
                </Typography>
                <ChevronRight color={COLORS.greyDark} size={15} />
              </Box>
            }
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SimpleCard2
            loading={masterData.isLoadingRetailComission}
            title="Retail Commision"
            value={masterData.referralComission.amountRetail + '%'}
            topRightIconComponent={<User style={{ width: 20, height: 20 }} />}
            bottomRightComponent={
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpenModalEditRetailCommision()}
              >
                <Typography variant="subtitle1" color="primary">
                  Edit Commision
                </Typography>
                <ChevronRight color={COLORS.greyDark} size={15} />
              </Box>
            }
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SimpleCard2
            loading={masterData.isLoadingRetailComission}
            title="SPG Commision"
            value={masterData.referralComission.amountSPG + '%'}
            topRightIconComponent={<User style={{ width: 20, height: 20 }} />}
            bottomRightComponent={
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpenModalEditSPGCommision()}
              >
                <Typography variant="subtitle1" color="primary">
                  Edit Commision
                </Typography>
                <ChevronRight color={COLORS.greyDark} size={15} />
              </Box>
            }
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SimpleCard2
            loading={masterData.isLoadingMemberPremium}
            title="Nominal Member Premium"
            value={convertCurrency(masterData.referralComission.amountPremiumMembership)}
            topRightIconComponent={<User style={{ width: 20, height: 20 }} />}
            bottomRightComponent={
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpenModalEditNominalMemberPremium()}
              >
                <Typography variant="subtitle1" color="primary">
                  Edit Nominal
                </Typography>
                <ChevronRight color={COLORS.greyDark} size={15} />
              </Box>
            }
          />
        </Grid>
      </Grid>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: 20, paddingTop: 10, paddingBottom: 10 }}
      >
        <Typography style={{ fontWeight: 'bold' }}>Referral Information</Typography>

        <AppButton loading={masterData.isLoadingReferralInformation} onClick={() => updateReferralInfo()}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Save style={{ marginRight: 8, fontSize: 20 }} />
            <Typography>Save Referral Information</Typography>
          </Box>
        </AppButton>
      </Box>

      <Paper elevation={3}>
        <ReactQuill
          value={referralInformationContent}
          onChange={(content: string) => handleChange(content)}
        />
      </Paper>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: 20, paddingTop: 10, paddingBottom: 10 }}
      >
        <Typography style={{ fontWeight: 'bold' }}>Referral & Nominal Member Premium Log</Typography>
      </Box>

      <Paper elevation={3}>
        <ReferralDiscountHistory />
      </Paper>

      {/** EDIT REFERRAL COMMISION */}
      <Modal
        open={isShowModalEditReferralCommision}
        onClose={handleCloseModalEditReferralCommision}
      >
        <div className={classes.containerModal}>
          <div className={classes.contentModal}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h5">Referral Commission</Typography>

              <div onClick={() => handleCloseModalEditReferralCommision()}>
                <Close />
              </div>
            </Box>

            <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
              <Grid item xs={12}>
                <FormInput
                  onChange={(value: any) => setAmountReferralCom(value)}
                  type="discount"
                  value={amountReferralCom}
                  fullWidth
                  required
                  label="Commision"
                  placeholder="Commision in percent"
                  helperText="Tulis nominal referral tanpa tanda '%'"
                />
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="flex-end">
              <Box>
                <AppButton
                  color="secondary"
                  onClick={handleCloseModalEditReferralCommision}
                >
                  Cancel
                </AppButton>
              </Box>
              <Box>
                <AppButton disabled={!amountReferralCom} onClick={() => submit('referral')}>Save</AppButton>
              </Box>
            </Box>
          </div>
        </div>
      </Modal>

      {/** EDIT RETAIL COMMISION */}
      <Modal
        open={isShowModalEditRetailCommision}
        onClose={handleCloseModalEditRetailCommision}
      >
        <div className={classes.containerModal}>
          <div className={classes.contentModal}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h5">Retail Commission</Typography>

              <div onClick={() => handleCloseModalEditRetailCommision()}>
                <Close />
              </div>
            </Box>

            <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
              <Grid item xs={12}>
                <FormInput
                  onChange={(value: any) => setAmountRetailCom(value)}
                  type="discount"
                  value={amountRetailCom}
                  fullWidth
                  required
                  label="Commision"
                  placeholder="Commision in percent"
                  helperText="Tulis nominal referral tanpa tanda '%'"
                />
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="flex-end">
              <Box>
                <AppButton
                  color="secondary"
                  onClick={handleCloseModalEditRetailCommision}
                >
                  Cancel
                </AppButton>
              </Box>
              <Box>
                <AppButton disabled={!amountRetailCom} onClick={() => submit('retail')}>Save</AppButton>
              </Box>
            </Box>
          </div>
        </div>
      </Modal>

      {/** EDIT SPG COMMISION */}
      <Modal
        open={isShowModalEditSPGCommision}
        onClose={handleCloseModalEditSPGCommision}
      >
        <div className={classes.containerModal}>
          <div className={classes.contentModal}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h5">SPG Commission</Typography>

              <div onClick={() => handleCloseModalEditSPGCommision()}>
                <Close />
              </div>
            </Box>

            <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
              <Grid item xs={12}>
                <FormInput
                  onChange={(value: any) => setAmountSPGCom(value)}
                  type="discount"
                  value={amountSPGCom}
                  fullWidth
                  required
                  label="Commision"
                  placeholder="Commision in percent"
                  helperText="Tulis nominal referral tanpa tanda '%'"
                />
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="flex-end">
              <Box>
                <AppButton
                  color="secondary"
                  onClick={handleCloseModalEditSPGCommision}
                >
                  Cancel
                </AppButton>
              </Box>
              <Box>
                <AppButton disabled={!amountSPGCom} onClick={() => submit('spg')}>Save</AppButton>
              </Box>
            </Box>
          </div>
        </div>
      </Modal>

      {/** EDIT NOMINAL MEMBER PREMIUM */}
      <Modal
        open={isShowModalEditNominalMemberPremium}
        onClose={handleCloseModalEditNominalMemberPremium}
      >
        <div className={classes.containerModal}>
          <div className={classes.contentModal}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h5">Nominal Member Premium</Typography>

              <div onClick={() => handleCloseModalEditNominalMemberPremium()}>
                <Close />
              </div>
            </Box>

            <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
              <Grid item xs={12}>
                <FormInput
                  onChange={(value: any) => setAmountMemberPremium(value)}
                  type="currency"
                  value={amountMemberPremium}
                  fullWidth
                  required
                  label="Nominal"
                  placeholder="Nominal in IDR"
                />
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="flex-end">
              <Box>
                <AppButton
                  color="secondary"
                  onClick={handleCloseModalEditNominalMemberPremium}
                >
                  Cancel
                </AppButton>
              </Box>
              <Box>
                <AppButton disabled={!amountMemberPremium} onClick={() => submit('member')}>Save</AppButton>
              </Box>
            </Box>
          </div>
        </div>
      </Modal>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerModal: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      alihandleCloseModalApprovalgnContent: 'center',
    },
    contentModal: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
      width: 400,
      margin: '0 auto',
      marginTop: '10%',
      borderRadius: 10,
    },
  })
);

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  apiFetchListCommissionHistorical,
  apiGetTotalCommission,
  SingleCommission,
} from '../../../api/commission';
import FilterDate from '../../../components/Form/FilterDate';
import CustomizedTable, {
  ITableHeader,
} from '../../../components/Table/CustomizedTable';
import { generateQueryString } from '../../../helpers/functions/generateQueryString';
import usePaginateTable, {
  IPaginateTable,
} from '../../../hooks/usePaginateTable';
import GeneralInformation from '../../B2BTransaction/GeneralInformation';
import { convertNumToCurrency } from '../../../helpers/functions/currency';
import AppButton from '../../../components/Form/AppButton';
import { SaveAlt } from '@material-ui/icons';
import { format } from 'date-fns';
import { apiExportCustomerComission } from '../../../api/commission';
import {
  apiExportDownline,
  apiFindAllReportReferralDetail,
} from '../../../api/report-referral';
import moment from 'moment';

const headers: ITableHeader[] = [
  {
    title: 'Downline Name',
    align: 'left',
    column: 'downlineName',
  },
  {
    title: 'Referral Code',
    align: 'left',
    column: 'downlineReferralCode',
  },
  {
    title: 'Total Transaction',
    align: 'left',
    column: 'totalTransaction',
    alignContent: 'left',
  },
  {
    title: 'Total Amount Recieve',
    align: 'left',
    column: 'totalReferralAmount',
    alignContent: 'left',
  },
  {
    title: 'Last Transaction',
    align: 'left',
    column: 'lastTransaction',
    alignContent: 'left',
  },
];

export default function ReferralDownline(props: {
  customerId: string;
  customerName: string;
  customerUplineName?: string;
  onLoading: (value: boolean) => void;
}) {
  const [loadingExport, setLoadingExport] = useState(false);
  const classes = useStyles();

  const date = new Date();
  const defaultParamsStartAt = new Date(
    date.getFullYear(),
    date.getMonth(),
    1,
    0,
    0,
    0,
    0
  );
  const defaultParamsEndAt = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
    23,
    59,
    59,
    999
  );
  const {
    onChangePage,
    onChangeRowsPerPage,
    fetchList,
    loading,
    params,
    data,
    page,
    totalPage,
    totalData,
    rowsPerPage,
  }: IPaginateTable & { data: SingleCommission[] } = usePaginateTable(
    apiFindAllReportReferralDetail,
    {
      userId: props.customerId,
      limit: 25,
      offset: 0,
      startAt: defaultParamsStartAt.toISOString(),
      endAt: defaultParamsEndAt.toISOString(),
      // filterSource: 'downline_transaction',
    }
  );

  useEffect(() => {
    props.onLoading(loading);
  }, [loading]);

  let listDownlines: any[] = [];
  if (data.length > 0) {
    listDownlines = data.map((item: any) => ({
      ...item,
      totalReferralAmount: item.totalReferralAmount
        ? convertNumToCurrency(item.totalReferralAmount)
        : convertNumToCurrency(0),
      lastTransaction: item.lastTransaction
        ? moment(item.lastTransaction).format('DD MMM YYYY, hh:mm')
        : null,
    }));
  }

  // totalCommission
  const [totalCommission, setTotalCommission] = useState(0);
  const [paramsTotalCommission, setParamsTotalCommission] = useState({
    userId: props.customerId,
    // startAt: defaultParamsStartAt.toISOString(),
    // endAt: defaultParamsEndAt.toISOString(),
    // filterSource: 'downline_transaction'
  });

  useEffect(() => {
    getTotalCommission();
  }, []);

  const getTotalCommission = (incomingParams: any = {}) => {
    const newParams = {
      ...paramsTotalCommission,
      ...incomingParams,
    };
    setParamsTotalCommission(newParams);
    apiGetTotalCommission(generateQueryString(newParams))
      .then((res) => {
        setTotalCommission(res.totalCommission);
      })
      .catch((err) => {
        console.error('Error getTotalCommission', err);
      });
  };

  const onChangeFilterData = (startAt: string, endAt: string) => {
    console.info('onChangeFilterData', startAt, endAt);
    fetchList({ startAt, endAt });
    getTotalCommission({ startAt, endAt });
  };

  async function exportDownline() {
    try {
      setLoadingExport(true);
      const excel = await apiExportDownline(props.customerId);

      if (excel.data) {
        const url = window.URL.createObjectURL(new Blob([excel.data]));
        const link = document.createElement('a');
        link.href = url;
        const downloadDate = format(new Date(), 'dd/MM/yyyy');
        link.setAttribute(
          'download',
          `oxone-${props.customerName}-downline-${downloadDate}.xlsx`
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
        setLoadingExport(false);
      }
    } catch (error) {
      setLoadingExport(false);
    }
  }

  return (
    <Box className={classes.containerForm}>
      <Grid container spacing={3} alignItems='center'>
        <Grid item xs={8}>
          <GeneralInformation
            itemPerRow={3}
            data={[
              {
                label: 'Customer',
                value: props.customerName,
              },
              {
                label: 'Total All Referral Amount',
                value: convertNumToCurrency(totalCommission),
              },
              {
                label: 'Upline',
                value: props.customerUplineName,
              },
            ]}
          />
        </Grid>
        <Grid item xs={3}>
          <AppButton
            loading={loadingExport}
            color='primary'
            onClick={() => exportDownline()}
          >
            <SaveAlt />
            <Typography component='span' style={{ marginLeft: 10 }}>
              Export XLSX
            </Typography>
          </AppButton>
        </Grid>
      </Grid>

      <CustomizedTable
        data={listDownlines}
        headers={headers}
        totalData={totalData}
        page={page}
        totalPage={totalPage}
        rowsPerPage={rowsPerPage}
        onChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 0, 0, 0),
    },
    containerForm: {
      padding: theme.spacing(2),
    },
    input: {
      padding: theme.spacing(0, 2, 1, 2),
    },
    row: {
      padding: theme.spacing(1, 0, 1, 0),
    },
  })
);

import { FeaturedProductResponsePayload, FeaturedProductTypes, FeaturedProductResponse } from '../../types/featured-product.type'

export function findAllFeaturedProduct(payload: FeaturedProductResponsePayload) {
  return {
    type: FeaturedProductTypes.FIND_ALL_FEATURED_PRODUCT,
    payload
  }
}

export function findOneFeaturedProduct(payload: FeaturedProductResponse) {
  return {
    type: FeaturedProductTypes.FIND_ONE_FEATURED_PRODUCT,
    payload
  }
}

export function createFeaturedProduct(payload: FeaturedProductResponse) {
  return {
    type: FeaturedProductTypes.CREATE_FEATURED_PRODUCT,
    payload
  }
}

export function updateFeaturedProduct(payload: FeaturedProductResponse) {
  return {
    type: FeaturedProductTypes.UPDATE_FEATURED_PRODUCT,
    payload
  }
}

export function deleteFeaturedProduct(payload: any) {
  return {
    type: FeaturedProductTypes.REMOVE_FEATURED_PRODUCT,
    payload
  }
}

export function setLoadingFeaturedProduct(payload: boolean) {
  return {
    type: FeaturedProductTypes.SET_LOADING_FEATURED_PRODUCT,
    payload
  }
}

export function setErrorFeaturedProduct(payload: any) {
  return {
    type: FeaturedProductTypes.SET_ERROR_FEATURED_PRODUCT,
    payload
  }
}
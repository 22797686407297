import axios from 'axios';
import { getHeaders } from '../../helpers/auth';

export async function apiFindAllTag() {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/tag',
    method: 'GET',
    headers
  });
}
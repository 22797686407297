import React, { useState, useEffect } from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Paper,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  DialogActions,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import HeaderPage from "../../components/Layout/HeaderPage";
import { httpRequest } from "../../helpers/api/api";
import { Info } from "react-feather";
import COLORS from "../../styles/colors";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import FormInput from "../../components/Form/FormInput";
import AppMap from "../../components/AppMap";
import AppButton from "../../components/Form/AppButton";
import PickupPoint from "../PickupPoint";

const headers: ITableHeader[] = [
  {
    title: "pickupPointId",
    column: "pickupPointId",
    type: "id",
  },
  {
    title: "Pickup Point",
    column: "namePickupPoint",
    sortable: true,
  },
  {
    title: "Phone Number",
    column: "phoneNumber",
  },
  {
    title: "Status",
    column: "status",
  },
  {
    title: "Status",
    column: "published",
    type: "status-boolean",
  },
  {
    title: "Created At",
    column: "createdAt",
    type: "date",
  },
];

interface ParamsType {
  aroId: string;
}

const FormInputProps = {
  disabled: true,
  onChange: () => {},
  fullWidth: true,
};

const DetailAro: React.FC = () => {
  const { aroId } = useParams<ParamsType>();
  const classes = useStyles();

  const [aroData, setAroData] = useState<any>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    httpRequest
      .get("/aros/" + aroId)
      .then(({ data }) => {
        let aro = data.payload;
        setAroData(aro);
      })
      .finally(() => setLoading(false));
  }, [aroId]);

  return (
    <Layout loading={loading} title="Agent Region Details">
      <HeaderPage
        title="Agent Region"
        subtitle="Detail agent region"
        showBackButton={true}
      />
      {aroData ? (
        <>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <Paper className={classes.paper}>
                <div className={classes.paperTitle}>
                  <Typography variant="h5">Agent Profile</Typography>
                </div>
                <Grid container className={classes.containerForm}>
                  <Grid item xs={12} md={12} lg={12} className={classes.input}>
                    <Typography variant="body1" color="textSecondary">
                      Distributor
                    </Typography>
                    <Typography variant="body1">
                      {aroData?.distributor?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} className={classes.input}>
                    <Typography variant="body1" color="textSecondary">
                      Label Agent Region
                    </Typography>
                    <Typography variant="body1">
                      {aroData?.labelName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} className={classes.input}>
                    <Typography variant="body1" color="textSecondary">
                      Phone Number
                    </Typography>
                    <Typography variant="body1">
                      {aroData?.customer?.phoneNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} className={classes.input}>
                    <Paper elevation={2} className={classes.infoContainer}>
                      <Box display="flex" alignItems="center">
                        <Info
                          color={COLORS.warn}
                          size={20}
                          style={{ marginRight: 10 }}
                        />
                        Nomor HP tersebut akan digunakan untuk akses login CMS
                        Agent Region.
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} className={classes.input}>
                    <Typography variant="body1" color="textSecondary">
                      Status
                    </Typography>
                    <Typography variant="body1">
                      {aroData?.customer?.status.toUpperCase()}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <Paper className={classes.paper}>
                <div className={classes.paperTitle}>
                  <Typography variant="h5">Pickup Point</Typography>
                </div>
                <Grid container className={classes.containerForm}>
                  <Grid item xs={12} md={12} lg={12} className={classes.input}>
                    <PickupPoint aroId={aroId} type="component" />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={12} lg={6}>
                  <Paper className={classes.paper}>
                    <div className={classes.paperTitle}>
                      <Typography variant="h5">Shipment Discount</Typography>
                    </div>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      className={classes.input}
                    >
                      <Typography variant="body1" color="textSecondary">
                        Nominal
                      </Typography>
                      <Typography variant="body1">
                        {aroData?.aroShipmentDiscount?.[0]?.amount}
                      </Typography>
                    </Grid>
                    <div className={classes.paperTitle}>
                      <Typography variant="h5">
                        Agent Referral Discount
                      </Typography>
                    </div>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      className={classes.input}
                    >
                      <Typography variant="body1" color="textSecondary">
                        Nominal Diskon Referral
                      </Typography>
                      <Typography variant="body1">
                        {aroData?.referralDiscount?.discountNote}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      className={classes.input}
                    >
                      <Typography variant="body1" color="textSecondary">
                        Nominal Diskon Non Referral
                      </Typography>
                      <Typography variant="body1">
                        {aroData?.nonReferralDiscount?.discountNote}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      className={classes.input}
                    >
                      <Typography variant="body1" color="textSecondary">
                        Voucher Participation
                      </Typography>
                      <Typography variant="body1">
                        {aroData?.voucherParticipation ? "Active" : "Inactive"}
                      </Typography>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <Paper elevation={0} className={classes.paper}>
                    <div className={classes.paperTitle}>
                      <Typography variant="h5">Bank Account</Typography>
                    </div>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      className={classes.input}
                    >
                      <Typography variant="body1" color="textSecondary">
                        Bank Name
                      </Typography>
                      <Typography variant="body1">
                        {aroData?.aroBankAccount?.bank?.bankName}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      className={classes.input}
                    >
                      <Typography variant="body1" color="textSecondary">
                        Bank Account Name
                      </Typography>
                      <Typography variant="body1">
                        {aroData?.aroBankAccount?.accountName}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      className={classes.input}
                    >
                      <Typography variant="body1" color="textSecondary">
                        Bank Account Number
                      </Typography>
                      <Typography variant="body1">
                        {aroData?.aroBankAccount?.accountNumber}
                      </Typography>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </Layout>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    field: {
      marginBottom: theme.spacing(3),
    },
    infoContainer: {
      padding: theme.spacing(0.5, 1, 0.5, 1),
      margin: theme.spacing(2, 0, 2, 0),
    },
    paper: {
      margin: theme.spacing(2, 0, 2, 0),
      padding: theme.spacing(0, 0, 3, 0),
    },
    stepper: {
      background: "transparent",
    },
    containerForm: {
      // padding: theme.spacing(0, 2, 0, 2),
    },
    input: {
      padding: theme.spacing(0, 3, 0, 3),
    },
    paperTitle: {
      padding: theme.spacing(3),
    },
    containerButton: {
      flexDirection: "row",
      padding: theme.spacing(2),
    },
  })
);
export default DetailAro;

import { ConfigAppTypes } from '../../types/config.type';

export function setUpladImageStatus(payload: boolean) {
  return {
    type: ConfigAppTypes.SET_UPLOAD_IMAGE_PAYLOAD,
    payload
  }
}

export function setLatestParams(payload: { pathname: string, params: string, query: any, activeTab?: number }) {
  return {
    type: ConfigAppTypes.SET_LATEST_PARAMS,
    payload
  }
}

export function enableUpdateParams(payload: boolean) {
  return {
    type: ConfigAppTypes.UPDATE_PARAM,
    payload
  }
}

export function setLatestState(payload: any) {
  return {
    type: ConfigAppTypes.SET_LATEST_STATE,
    payload
  }
}

export function setLatestActiveTab(payload: number | null) {
  return {
    type: ConfigAppTypes.SET_LATEST_ACTIVE_TAB,
    payload
  }
}

import axios from 'axios';
import { getHeadersMultiPart, getHeaders } from '../../helpers/auth';
import { BannerChangeOrderRequest, BannerRequest, BannerRequestImageOnly } from '../../types/banner.type';

export async function apiFindAllBanner(params: string) {
  const query = params || null
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/banner' + query,
    method: 'GET',
    headers
  });
}

export async function apiFindOneBanner(bannerId: string) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/banner/' + bannerId,
    method: 'GET',
    headers
  });
}

export async function apiCreateBanner(data: BannerRequest) {
  const headers = await getHeadersMultiPart()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/banner',
    method: 'POST',
    headers,
    data
  });
}

export async function apiUpdateBanner(data: FormData) {
  const headers = await getHeadersMultiPart()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/banner/' + data.get('bannerId'),
    method: 'PATCH',
    headers,
    data
  });
}

export async function apiChangeOrderBanner(data: BannerChangeOrderRequest) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/banner/change-order/' + data.bannerId,
    method: 'PATCH',
    headers,
    data: {
      currentIndex: data.currentIndex + 1,
      prevIndex: data.prevIndex + 1
    }
  });
}

export async function apiUpdateImageBanner(data: BannerRequestImageOnly) {
  const headers = await getHeadersMultiPart()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/banner/upload-image/' + data.bannerId,
    method: 'PUT',
    headers,
    data
  });
}

export async function apiDeleteBanner(bannerId: string) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/banner/' + bannerId,
    method: 'DELETE',
    headers,
  });
}
import {
  Button,
  Menu,
  MenuItem,
  Grid,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import React, { useEffect } from "react";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import { convertDateTimeToText } from "../../helpers/functions/datetime";
import useDebounce from "../../hooks/useDebounce";
import useFetch from "../../hooks/useFetch";
import {
  DeliveryOrderProperties,
  ETripStatus,
  IDeliveryPickupQuery,
} from "../../types/b2b/order/delivery-order.type";
import { ReplacementDeliveryProperties } from "../../types/b2b/replacement/replacement-delivery.type";
import { ReplacementPickupProperties } from "../../types/b2b/replacement/replacement-pickup.type";
import { PickupOrderProperties } from "../../types/b2b/return/pickup-order.type";
import {
  headerDeliveryOrder,
  headerPickupOrder,
  headerReplacementPickup,
  headerReplacementDelivery,
  headerDeliveryOrderAll,
  headerPickupOrderAll,
  headerReplacementPickupAll,
  headerReplacementDeliveryAll,
} from "../B2BTransaction/headers";
import { ChevronDown } from "react-feather";
import COLORS from "../../styles/colors";
import useFetchOne from "../../hooks/useFetchOne";
import {
  DistributorProperties,
  initialDistributor,
} from "../../types/b2b/distributor/distributor.type";
import Layout from "../../components/Layout/Layout";
import HeaderPage from "../../components/Layout/HeaderPage";
import AppTabs from "../../components/Tabs/AppTabs";
import { httpRequest } from "../../helpers/api/api";
import FilterDate from "../../components/Form/FilterDate";
import Search from "../../components/Form/Search";
import { useDispatch, useSelector } from "react-redux";
import { setB2BID } from "../../redux/action/b2b.action";
import {
  setLatestActiveTab,
  setLatestState,
} from "../../redux/action/config-app.action";
import { ApplicationState } from "../../redux/store";

const tabs = [
  "Created",
  "Loading",
  "Loading Complete",
  "OTW",
  "Ganti Rute",
  "Arrived",
  "Confirmed",
  "Cancel by System",
  "ALL",
];

type Props = {
  type:
  | "delivery-order"
  | "pickup-order"
  | "replacement-pickup"
  | "replacement-delivery";
};

interface DefaultPropsSelectedData {
  distributorId: string;
  type: string
  idx: number
}

interface SelectedDOProps extends DefaultPropsSelectedData {
  event: React.MouseEvent<HTMLButtonElement>;
  item: DeliveryOrderProperties &
  PickupOrderProperties &
  ReplacementDeliveryProperties &
  ReplacementPickupProperties;
}

const initialQuery: IDeliveryPickupQuery = {
  limit: 25,
  page: 1,
  status: ETripStatus.created,
  sort: "createdAt,DESC",
};

const initialActiveTab = 0;

const DeliveryPickup: React.FC<Props> = (props) => {
  let title = "";
  let subtitle = "";
  let headers: ITableHeader[] = [];

  if (props.type === "delivery-order") {
    headers = headerDeliveryOrder;
    title = "Delivery Order";
    subtitle = "List of delivery order";
  } else if (props.type === "pickup-order") {
    headers = headerPickupOrder;
    title = "Pickup Order";
    subtitle = "List of pickup order";
  } else if (props.type === "replacement-pickup") {
    headers = headerReplacementPickup;
    title = "Replacement Pickup";
    subtitle = "List of replacement pickup";
  } else if (props.type === "replacement-delivery") {
    headers = headerReplacementDelivery;
    title = "Replacement Delivery";
    subtitle = "List of replacement delivery";
  }

  const dispatch = useDispatch();
  const { state: locationState, activeTab: currentActiveTab } = useSelector(
    (state: ApplicationState) => state.configApp
  );
  const {
    data,
    loading,
    pagination,
    query,
    search,
    activeTab,
    handlePerPageChange,
    handlePageChange,
    setQuery,
    changeHandler,
    setActiveTab,
  } = useFetch<
    DeliveryOrderProperties &
    PickupOrderProperties &
    ReplacementDeliveryProperties &
    ReplacementPickupProperties
  >({
    url: props.type,
    query: initialQuery,
    activeTab: initialActiveTab,
  });

  const {
    data: dataDistributor,
    loading: loadingOneData,
    fetchOneData,
  } = useFetchOne<DistributorProperties>({
    url: "distributor",
    initialData: initialDistributor,
  });

  const parseStatus = (value: number) => {
    if (value === 0) {
      return ETripStatus.created;
    } else if (value === 1) {
      return ETripStatus.loading;
    } else if (value === 2) {
      return ETripStatus.loading_complete;
    } else if (value === 3) {
      return ETripStatus.otw;
    } else if (value === 4) {
      return ETripStatus.change_route;
    } else if (value === 5) {
      return ETripStatus.arrived;
    } else if (value === 6) {
      return ETripStatus.confirmed;
    } else if (value === 7) {
      return ETripStatus.cancel_by_system;
    }
  };

  const changeTab = (value: number) => {
    setActiveTab(value);

    let status: ETripStatus | null | undefined = null;

    status = parseStatus(value);

    setQuery((val: IDeliveryPickupQuery) => {
      return {
        ...val,
        offset: 0,
        status: status,
      };
    });
  };

  const [anchorElPickupDO, setAnchorElPickupDO] = React.useState<any>();
  const [currentDataDO, setCurrentDataDO] = React.useState<
    DeliveryOrderProperties &
    PickupOrderProperties &
    ReplacementDeliveryProperties &
    ReplacementPickupProperties
  >();

  const searchCurrentDistributor = async (id: string) => {
    await fetchOneData(id);
  };

  const handleClickDO = (data: SelectedDOProps) => {
    setAnchorElPickupDO(data.event.currentTarget);
    setCurrentDataDO(data.item);
    searchCurrentDistributor(data.distributorId);

    // let copyAnchor = [...data.event.currentTarget];
    // let a = copyAnchor.filter((x) => x.rowIndex == data.idx)[0];

    console.info('copyAnchor', data.event.currentTarget)
    // console.info('a', a)
  };

  const handleClose = () => {
    setAnchorElPickupDO(null);
  };

  const [currBranchIdx, setCurrentBranchIdx] = React.useState<number | null>(
    null
  );

  const [loadingUpdateBranch, setLoadingUpdateBranch] = React.useState<boolean>(
    false
  );

  const confirmUpdateBranch = async (
    branchId: string,
    id: string,
    idx: number | null
  ) => {
    try {
      setLoadingUpdateBranch(true)
      if (id) {
        console.info('curr idx', idx)
        setAnchorElPickupDO(null);
        await httpRequest.patch<any>(props.type + "/" + id, {
          branchId,
        });
        setCurrentBranchIdx(null);
      }
    } catch (error) {
      setAnchorElPickupDO(null);
      throw error;
    } finally {
      setQuery((value: any) => {
        return {
          ...value,
          limit: value.limit,
        };
      });
      setLoadingUpdateBranch(false)
    }
  };

  const _renderQty = (
    item: DeliveryOrderProperties &
      PickupOrderProperties &
      ReplacementDeliveryProperties &
      ReplacementPickupProperties
  ) => {
    let qtyLeft = 0;
    let qtyRight = 0;

    if (
      props.type === "delivery-order" ||
      props.type === "replacement-delivery"
    ) {
      qtyLeft =
        props.type === "replacement-delivery"
          ? item.totalQtyDelivery
          : item.totalQtySend;
      qtyRight = item.totalQtyReceive || 0;
    } else if (
      props.type === "pickup-order" ||
      props.type === "replacement-pickup"
    ) {
      qtyLeft = item.totalQtyPickup;
      qtyRight = item.totalQtyPickup;
    }

    return (
      <div style={{ flexDirection: "row", display: "flex" }}>
        <span>{qtyLeft === null ? 0 : qtyLeft}</span>
        <span>/</span>
        <span
          style={{
            color: qtyRight && qtyRight < qtyLeft ? "red" : "black",
            fontWeight: qtyRight && qtyRight < qtyLeft ? "bold" : "normal",
          }}
        >
          {qtyRight}
        </span>
      </div>
    );
  };

  const _renderSelectBranch = (
    item: DeliveryOrderProperties &
      PickupOrderProperties &
      ReplacementDeliveryProperties &
      ReplacementPickupProperties,
    idx: number
  ) => {
    return (
      <>
        {/* <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          disabled={item.status !== ETripStatus.created}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setCurrentBranchIdx(idx);
            handleClickDO({
              event,
              item,
              distributorId: item.distributorMetadata.distributorId,
              type: item.hasOwnProperty('doId')
                ? 'delivery-order'
                : item.hasOwnProperty('poId')
                  ? 'pickup-order'
                  : item.hasOwnProperty('rplpId')
                    ? 'replacement-pickup'
                    : 'replacement-delivery',
              idx
            })
          }}
        >
          {idx === currBranchIdx && loadingUpdateBranch ? (
            <>
              Loading
              <CircularProgress style={{ marginLeft: 10 }} size={16} />
            </>
          ) : (
              <>
                {item.branchId ? item.branchMetadata.name : "Select branch"}
                <ChevronDown
                  color={COLORS.greyDark}
                  size={15}
                  style={{ marginLeft: 8 }}
                />
              </>
            )}
        </Button> */}
        <Menu
          id="simple-menu"
          anchorEl={anchorElPickupDO}
          keepMounted
          open={Boolean(anchorElPickupDO)}
          onClose={handleClose}
          elevation={1}
        >
          {loadingOneData && <MenuItem disabled>Loading data...</MenuItem>}
          {!loadingOneData && dataDistributor.branches &&
            dataDistributor.branches.map((branch, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  if (currentDataDO) {
                    const id =
                      props.type === "delivery-order"
                        ? currentDataDO.doId
                        : props.type === "pickup-order"
                          ? currentDataDO.poId
                          : props.type === "replacement-pickup"
                            ? currentDataDO.rplpId
                            : currentDataDO.rpldId;
                    if (!currentDataDO.branchId) {
                      confirmUpdateBranch(branch.branchId, id, idx);
                    } else if (branch.branchId !== currentDataDO.branchId) {
                      confirmUpdateBranch(branch.branchId, id, idx);
                    } else {
                      setAnchorElPickupDO(null);
                    }
                  }
                }}
              >
                {branch.name}
              </MenuItem>
            ))}
        </Menu>
      </>
    )
  };

  const parseIdTable = (
    item: DeliveryOrderProperties &
      PickupOrderProperties &
      ReplacementDeliveryProperties &
      ReplacementPickupProperties
  ) => {
    let id = "";
    if (props.type === "delivery-order") {
      id = item.doId;
    } else if (props.type === "pickup-order") {
      id = item.poId;
    } else if (props.type === "replacement-pickup") {
      id = item.rplpId;
    } else {
      id = item.rpldId;
    }

    return id;
  };

  const dataDO = data.map((item, idx) => {
    return {
      id: parseIdTable(item),
      doId: item.doId,
      soId: item.soId,
      poId: item.poId,
      roId: item.roId,
      rplpId: item.rplpId,
      rpldId: item.rpldId,
      rploId: item.rploId,
      orderId: item.salesOrder ? item.salesOrder.orderId : null,
      returnId: item.salesReturn ? item.salesReturn.returnId : null,
      rplId: item.replacement ? item.replacement.rplId : null,
      updatedAt: convertDateTimeToText(item.updatedAt, "dd mmm yyyy hh:mm"),
      note: item.note,
      noteSO: item.salesOrder?.note || '',
      noteReturn: item.salesReturn?.note || '',
      noteRepl: item.replacement?.note || '',
      status: item.status,
      qty: _renderQty(item),
      branch: (_renderSelectBranch(item, idx)),
    };
  });

  const _renderSearchPlaceholder = () => {
    let text = "";
    if (props.type === "delivery-order") {
      text = "Search by DO ID, SO ID, Lokasi";
    } else if (props.type === "pickup-order") {
      text = "Search by PO ID, RO ID, Lokasi";
    } else if (props.type === "replacement-pickup") {
      text = "Search by RPLP ID, RPLO ID, Lokasi";
    } else if (props.type === "replacement-delivery") {
      text = "Search by RPLD ID, RPLO ID, Lokasi";
    }
    return text;
  };

  useEffect(() => {
    changeTab(0);
  }, []);

  return (
    <Layout title={title} loading={loading}>
      <HeaderPage title={title} subtitle={subtitle} />

      <Grid container spacing={3} justify="space-between">
        <Grid item xs={12} md={12} lg={5} />
        <Grid item xs={12} md={6} lg={4}>
          <FilterDate
            start_at={
              locationState && locationState.startAt
                ? locationState.startAt
                : query.startAt
            }
            end_at={
              locationState && locationState.endAt
                ? locationState.endAt
                : query.endAt
            }
            onChange={(start_at: string, end_at: string) => {
              setQuery((val: any) => {
                return {
                  ...val,
                  startAt: start_at,
                  endAt: end_at,
                };
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Search
            placeholder={_renderSearchPlaceholder()}
            onChange={changeHandler}
            value={
              locationState && locationState.search
                ? locationState.search
                : search
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AppTabs
            active={currentActiveTab ? currentActiveTab : activeTab}
            tabs={tabs}
            onChange={(value: number) => changeTab(value)}
          />
          <CustomizedTable
            data={dataDO}
            headers={
              activeTab !== 6
                ? headers
                : props.type === "delivery-order"
                  ? headerDeliveryOrderAll
                  : props.type === "pickup-order"
                    ? headerPickupOrderAll
                    : props.type === "replacement-pickup"
                      ? headerReplacementPickupAll
                      : props.type === "replacement-delivery"
                        ? headerReplacementDeliveryAll
                        : headerDeliveryOrderAll
            }
            totalData={pagination.totalData}
            showAction={["detail"]}
            page={pagination.page}
            onPressDetail={(data) => {
              console.info(props.type, data);
              dispatch(setLatestState(query));
              dispatch(setLatestActiveTab(activeTab));
              dispatch(
                setB2BID(
                  props.type === "delivery-order"
                    ? data.orderId
                    : props.type === "pickup-order"
                      ? data.returnId
                      : data.rplId
                )
              );
            }}
            totalPage={pagination.countPage}
            rowsPerPage={pagination.perPage}
            onChange={(page: number) => handlePageChange(page)}
            onRowsPerPageChange={(value: number) => handlePerPageChange(value)}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default DeliveryPickup;

import axios from "axios";
import { getHeadersMultiPart, getHeaders } from "../helpers/auth";

export type MetaProductType = {
  productId: string;
  productName: string;
  noSerial: string;
  sku: string;
  initialWarranty: Date;
  categories: {
    categoryId: string;
    categoryName: string;
  }[];
  images: any[];
};

export type MetaUserType = {
  customerId?: string;
  phoneNumber: string;
  customerName: string;
  profilePic?: string;
};

export type ServiceCenterType = {
  serviceCenterId: string;
  name: string;
  phoneNumber: string;
  status: string;
  metaAddress: {
    province: string;
    city: string;
    subdistrict: string;
    address: string;
    location: {
      lat: string;
      lng: string;
    };
  };
  createdAt: string | Date;
  updatedAt: string | Date;
  deletedAt: string | Date;
  customers: any[];
};

export type ServiceCenterTransactionType = {
  serviceCenterTransactionId: string;
  serviceStartAt: string | Date;
  serviceFinishAt: string | Date;
  serviceCenterName: string;
  problem: string;
  note: string;
  solution: string;
  metaProduct: MetaProductType;
  metaUser: MetaUserType;
  images?: any[];
  createdAt?: Date;
  updatedAt?: Date;
};

export type CreateServiceCenterTransactionRequest = {
  serviceStartAt: string;
  serviceFinishAt: string;
  serviceCenterName: string;
  problem: string;
  note?: string;
  solution: string;
  metaProduct: MetaProductType;
  metaUser: MetaUserType;
};

export type GetListServiceCenterResponse = {
  code: string;
  message: string;
  payload: {
    results: ServiceCenterType[];
  };
};

export type GetListServiceCenterTransactionResponse = {
  code: string;
  message: string;
  payload: {
    results: ServiceCenterTransactionType[];
  };
};

export type GetOneServiceCenterResponse = {
  code: string;
  message: string;
  payload: ServiceCenterType;
};

export type GetOneServiceCenterTransactionResponse = {
  code: string;
  message: string;
  payload: ServiceCenterTransactionType;
};

export async function apiFetchListServiceCenter(params?: string): Promise<{
  data: GetListServiceCenterResponse;
}> {
  const headers = await getHeaders();
  return axios({
    method: "GET",
    url: process.env.REACT_APP_BASE_URL + "/service-center/" + params,
    headers,
  });
}

export async function apiFetchOneServiceCenter(
  serviceCenterId: string
): Promise<{
  data: GetOneServiceCenterResponse;
}> {
  const headers = await getHeaders();
  return axios({
    method: "GET",
    url: process.env.REACT_APP_BASE_URL + "/service-center/" + serviceCenterId,
    headers,
  });
}

export async function apiCreateServiceCenter(data: any): Promise<{
  data: GetOneServiceCenterResponse;
}> {
  const headers = await getHeaders();
  return axios({
    method: "POST",
    url: process.env.REACT_APP_BASE_URL + "/service-center",
    data,
    headers,
  });
}

export async function apiUpdateServiceCenter(
  serviceCenterId: string,
  data: any
): Promise<{
  data: GetOneServiceCenterResponse;
}> {
  const headers = await getHeaders();
  return axios({
    method: "PATCH",
    url: process.env.REACT_APP_BASE_URL + "/service-center/" + serviceCenterId,
    data,
    headers,
  });
}

export async function apiRemoveServiceCenter(serviceCenterId: string) {
  const headers = await getHeaders();
  return axios({
    method: "DELETE",
    url: process.env.REACT_APP_BASE_URL + "/service-center/" + serviceCenterId,
    headers,
  });
}

export async function apiFetchListServiceCenterTransaction(
  params: string
): Promise<{
  data: GetListServiceCenterTransactionResponse;
}> {
  const headers = await getHeaders();
  return axios({
    method: "GET",
    url:
      process.env.REACT_APP_BASE_URL + "/service-center-transaction/" + params,
    headers,
  });
}

export async function apiFetchOneServiceCenterTransaction(
  serviceCenterTransactionId: any
): Promise<{ data: GetOneServiceCenterTransactionResponse }> {
  const headers = await getHeaders();
  return axios({
    method: "GET",
    url:
      process.env.REACT_APP_BASE_URL +
      "/service-center-transaction/" +
      serviceCenterTransactionId,
    headers,
  });
}

export async function apiFetchOneByCustomerServiceCenterTransaction(
  customerId: string
): Promise<{ data: GetListServiceCenterTransactionResponse }> {
  const headers = await getHeaders();
  return axios({
    method: "GET",
    url:
      process.env.REACT_APP_BASE_URL +
      "/service-center-transaction/customer/" +
      customerId,
    headers,
  });
}

export async function apiCreateServiceCenterTransaction(
  data: CreateServiceCenterTransactionRequest
): Promise<any> {
  const headers = await getHeaders();
  return axios({
    method: "POST",
    url: process.env.REACT_APP_BASE_URL + "/service-center-transaction",
    headers,
    data,
  });
}

export async function apiCreateImagesServiceCenterTransaction(
  serviceCenterTransactionId: string,
  data: any
): Promise<any> {
  const headers = await getHeadersMultiPart();
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      "/service-center-transaction/upload/" +
      serviceCenterTransactionId,
    method: "POST",
    headers,
    data,
  });
}

import axios from "axios";
import { getHeaders, getHeadersMultiPart } from "../helpers/auth";

export async function apiFindAllTransaction(params: any) {
  const headers = await getHeaders();
  const query = params || null;
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/transaction-invoice" + query,
    method: "GET",
    headers,
  });
}

export async function apiFindOneTransaction(transactionId: string) {
  const headers = await getHeaders();
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      "/transaction-invoice/" +
      transactionId +
      "/by-transaction",
    method: "GET",
    headers,
  });
}

export async function apiFindHistoryTransaction(
  queryString: string,
  specialParams: { transactionId: string }
) {
  console.info("apiFindHistoryTransaction::: specialParams", specialParams);
  const headers = await getHeaders();
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      "/transaction/" +
      specialParams.transactionId +
      "/history_status" +
      queryString,
    method: "GET",
    headers,
  });
}

export async function apiProcessStatusTransaction(transactionId: string) {
  const headers = await getHeaders();
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      "/transaction-invoice/transaction/" +
      transactionId +
      "/process",
    method: "POST",
    headers,
  });
}

export async function apiRejectTransaction(data: {
  transactionId: string;
  note: string;
}) {
  const headers = await getHeaders();
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      "/transaction-invoice/transaction/" +
      data.transactionId +
      "/reject",
    method: "POST",
    headers,
    data,
  });
}

export async function apiUpdateWaybillTransaction(data: any) {
  const headers = await getHeaders();
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      "/transaction-invoice/" +
      data.invoiceId +
      "/waybill",
    method: "POST",
    headers,
    data: {
      ...data,
    },
  });
}

export async function apiUpdatePickupTransaction(data: any) {
  const headers = await getHeaders();
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      "/transaction-invoice/" +
      data.invoiceId +
      "/pickup",
    method: "POST",
    headers,
    data: {
      ...data,
    },
  });
}

export async function apiRefundTransaction(data: FormData) {
  const headers = await getHeadersMultiPart();
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      "/transaction-invoice/transaction/" +
      data.get("transactionId") +
      "/refund",
    method: "POST",
    headers,
    data,
  });
}

export async function apiSummaryListTransaction() {
  const headers = await getHeaders();
  return axios({
    url:
      process.env.REACT_APP_BASE_URL + "/transaction/summary/list_transaction",
    method: "GET",
    headers,
  });
}

export async function apiSummaryTotalTransaction(params?: any) {
  const headers = await getHeaders();
  const query = params || null;
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      "/transaction/summary/list_transaction" +
      query,
    method: "GET",
    headers,
  });
}

export async function apiSummaryMostActiveTransaction() {
  const headers = await getHeaders();
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      "/transaction/summary/most_active_transaction",
    method: "GET",
    headers,
  });
}

export async function apiSummaryMostActiveUpline(query?: string) {
  const headers = await getHeaders();
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      "/referral/summary/most_active_upline?" +
      query,
    method: "GET",
    headers,
  });
}

export async function apiCalculateEarnedCoin(data: any) {
  const headers = await getHeaders();
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      "/transaction-invoice/calculate/earned-coin",
    method: "POST",
    headers,
    data: {
      ...data,
    },
  });
}

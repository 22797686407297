import { useDispatch } from 'react-redux';
import {
  apiFindAllReportReferral,
  apiFindAllReportReferralSimple,
} from '../api/report-referral';
import {
  findAllReportReferral,
  setLoadingReportReferral,
} from '../redux/action/report-referral.action';

export default function useReportReferral() {
  const dispatch = useDispatch();

  const fetchAllReportReferral = async (query: string) => {
    try {
      dispatch(setLoadingReportReferral(true));

      const result = await apiFindAllReportReferral(query);

      dispatch(findAllReportReferral(result.data));
    } catch (error) {
      console.info(error);
    } finally {
      dispatch(setLoadingReportReferral(false));
    }
  };

  const fetchAllReportReferralSimple = async (query: string) => {
    try {
      dispatch(setLoadingReportReferral(true));

      const result = await apiFindAllReportReferralSimple(query);

      dispatch(findAllReportReferral(result.data));
    } catch (error) {
      console.info(error);
    } finally {
      dispatch(setLoadingReportReferral(false));
    }
  };

  return {
    fetchAllReportReferral,
    fetchAllReportReferralSimple,
  };
}

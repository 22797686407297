/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Paper,
  createStyles,
  makeStyles,
  Theme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  Divider,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/Layout/Layout";
import AppButton from "../../components/Form/AppButton";
import COLORS from "../../styles/colors";
import { useLocation, useParams } from "react-router-dom";
import UploadImage from "../../components/Form/UploadImage";
import FormInput from "../../components/Form/FormInput";
import HeaderPage from "../../components/Layout/HeaderPage";
import useTag from '../../hooks/useTag';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../redux/store';
import { TagResponse } from '../../types/tag.type';
import usePromotion from '../../hooks/usePromotion';
import useNews from '../../hooks/useNews';
import { LoadingScreen } from '../../components/LoadingScreen';
import { useHistory } from 'react-router-dom';
import { findOneNews } from '../../redux/action/content/news.action';
import { initNews } from '../../redux/reducer/content/news.reducer';
import YouTube from 'react-youtube';
import { getYouTubeId } from '../../helpers/functions/media';
import { findOnePromotion } from '../../redux/action/content/promotion.action';
import { enableUpdateParams } from '../../redux/action/config-app.action';

type Props = {
  type: "news-event" | "promotion";
};

export default function ContentEdit(props: Props) {
  const location = useLocation();
  let { id } = useParams<{ id: string }>();
  const history = useHistory()
  const dispatch = useDispatch()

  const classes = useStyles();

  const [mode, setMode] = useState<"detail" | "edit" | "create">("detail");

  const [invalidYoutubeId, setInvalidYoutubeId] = useState(false);
  const [youtubeId, setYoutubeId] = useState<string>('');
  const [youtubeLink, setYoutubeLink] = useState('');
  const [mediaType, setMediaType] = useState('image');
  const [titleNew, setTitleNews] = useState('');
  const [status, setStatus] = useState('published');
  const [content, setContent] = useState<string>("");
  const [tags, setTags] = useState<Omit<TagResponse, 'createdAt' | 'updatedAt'>[]>([]);
  const [file, setFile] = useState<any>(null);
  const [defaultTags, setDefaultTags] = useState<Omit<TagResponse, 'createdAt' | 'updatedAt'>[]>([]);

  const { fetchAllTags } = useTag()
  const { createNewPromotion, updateCurrentPromotion, fetchOnePromotion } = usePromotion()
  const { createNewNews, updateCurrentNews, fetchOneNews } = useNews()

  const tagState = useSelector((state: ApplicationState) => state.tag)
  const promotionState = useSelector((state: ApplicationState) => state.promotion)
  const newsState = useSelector((state: ApplicationState) => state.news)
  const inputRef: any = useRef(null)

  useEffect(() => {
    if (id && location.pathname.includes("edit")) {
      // edit
      if (props.type === 'promotion') {
        fetchOnePromotion(id)
      }
      if (props.type === 'news-event') {
        fetchOneNews(id)
      }
      setMode("edit");
    } else if (id) {
      // detail
      if (props.type === 'promotion') {
        fetchOnePromotion(id)
      }
      if (props.type === 'news-event') {
        fetchOneNews(id)
      }
      setMode("detail");
    } else {
      // create
      setMode("create");
    }
    fetchAllTags()
  }, []);

  useEffect(() => {
    if (promotionState.promotion) {
      setTags((promotionState.promotion.tags as any))
      setTitleNews((promotionState.promotion.title as string))
      setStatus((promotionState.promotion.status as string))
      setContent((promotionState.promotion.content as string))
      if (promotionState.promotion.youtubeLink) {
        parseYoutubeId((promotionState.promotion.youtubeLink as string))
      }
    }
  }, [promotionState.promotion])

  useEffect(() => {
    if (newsState.news) {
      setTitleNews((newsState.news.title as string))
      setStatus((newsState.news.status as string))
      setContent((newsState.news.content as string))
      setTags((newsState.news.tags as any))
      if (newsState.news.youtubeLink as string) {
        parseYoutubeId((newsState.news.youtubeLink as string))
      }
    }
  }, [newsState.news])

  useEffect(() => {
    setDefaultTags(tagState.data.payload.results)
  }, [tagState.data.payload.results])

  const submit = async () => {
    const formData = new FormData();

    if (file) {
      formData.append('image', file)
    }

    formData.append('youtubeLink', youtubeLink)
    formData.append('title', titleNew)
    formData.append('content', content)
    formData.append('status', status)
    formData.append('tags', JSON.stringify(tags))

    if (id) {
      formData.append('newsId', id)
      if (props.type === 'promotion') {
        await updateCurrentPromotion(formData)
        cleanData()
      }
      if (props.type === 'news-event') {
        await updateCurrentNews(formData)
        cleanData()
      }
    } else {
      if (props.type === 'promotion') {
        await createNewPromotion(formData)
        cleanData()
      }

      if (props.type === 'news-event') {
        await createNewNews(formData)
        cleanData()
      }
    }
  };

  const cleanData = async () => {
    dispatch(enableUpdateParams(false))
    if (props.type === 'news-event') {
      dispatch(findOneNews(initNews))
    }
    if (props.type === 'promotion') {
      dispatch(findOnePromotion(initNews))
    }
    await history.go(-1)
    setTitleNews('')
    setYoutubeLink('')
    setYoutubeId('')
    setStatus('published')
    setContent('')
    setTags([])
    setFile(null)
  }

  let title = "";
  let subtitle = "";

  let type =
    props.type === "promotion"
      ? "Promotion"
      : props.type === "news-event"
        ? "News & Event"
        : "Content";

  if (mode === "create") {
    title = "Create " + type;
    subtitle = "Create new " + type.toLowerCase();
  } else if (mode === "edit") {
    title = "Edit " + type;
    subtitle = "Edit " + type.toLowerCase();
  } else if (mode === "detail") {
    title = "Detail " + type;
    subtitle = "Detail " + type.toLowerCase();
  }

  const handleMediaTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMediaType((event.target as HTMLInputElement).value)
  }

  const opts: any = {
    height: '300',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const _onReady = (event: any) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  const parseYoutubeId = (value: string) => {
    const youtubeId = getYouTubeId(value)
    setYoutubeLink(value)
    if (youtubeId === 'invalid') {
      setInvalidYoutubeId(true)
      setYoutubeId('')
    } else {
      setInvalidYoutubeId(false)
      setYoutubeId(youtubeId)
    }
  }

  return (
    <Layout title={title}>
      { promotionState.isLoadingPromotion || newsState.isLoadingNews ? <LoadingScreen /> :
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <HeaderPage title={title} subtitle={subtitle} showBackButton onBack={() => cleanData()} />
            {(mode === "edit" || mode === "create") && (
              <Box display="flex" justifyContent="flex-end">
                <AppButton color="secondary" onClick={() => cleanData()}>
                  Cancel
            </AppButton>
                <AppButton
                  disabled={
                    mode === 'create' &&
                    (titleNew === '' || status === '' || content === '' || tags === [] || invalidYoutubeId || !file)
                  }
                  color="primary"
                  onClick={submit}
                  loading={promotionState.isLoadingPromotion || newsState.isLoadingNews}
                >
                  Save
            </AppButton>
              </Box>
            )}
            {mode === "detail" && (
              <Box display="flex" justifyContent="flex-end">
                <AppButton onClick={() => setMode("edit")} color="primary">
                  Edit
            </AppButton>
              </Box>
            )}
          </Box>

          <Paper elevation={3} className={classes.paper}>
            <Box display="flex" className={classes.containerForm}>
              <Box width="40%" className={classes.input}>
                <UploadImage
                  image={
                    mode === 'create' ? '' :
                      props.type === 'promotion' ?
                        (promotionState.promotion.imageLink as string) :
                        props.type === 'news-event' ?
                          (newsState.news.imageLink as string) :
                          ''
                  }
                  title="Content Image"
                  imageResolution="1200x600"
                  onFileSelected={(e: any) => setFile(e)}
                  editable={mode === "edit" || mode === "create"}
                />
                <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                <FormInput
                  value={youtubeLink}
                  fullWidth
                  error={invalidYoutubeId}
                  helperText={'Invalid youtube link'}
                  readOnly={mode === 'detail'}
                  placeholder="ex. https://www.youtube.com/watch?v=8BMfLk65iVA"
                  label="Youtube Link (optional)"
                  onChange={(value: string) => parseYoutubeId(value)}
                />
                <YouTube videoId={youtubeId} opts={opts} onReady={_onReady} />
              </Box>

              <Box width="60%" className={classes.input}>
                <Box>
                  <FormInput
                    value={titleNew}
                    fullWidth
                    readOnly={mode === 'detail'}
                    required
                    placeholder="Title"
                    label="Title"
                    onChange={(value: string) => setTitleNews(value)}
                  />
                  <FormInput
                    fullWidth
                    readOnly={mode === 'detail'}
                    required
                    placeholder="Tags"
                    value={tags}
                    label="Tags"
                    type="tags"
                    dataTags={defaultTags || []}
                    onChange={(value: TagResponse[]) => {
                      if (typeof value === 'string') {
                        setTags((oldValue: TagResponse[]) => oldValue.concat({ tagName: value }))
                        setDefaultTags((oldValue: TagResponse[]) => oldValue.concat({ tagName: value }))
                      } else {
                        setTags(value)
                      }
                    }}
                  />
                  <FormControl className={classes.formStatus}>
                    <InputLabel id="select-status" required shrink>
                      Status
                    </InputLabel>
                    <Select
                      readOnly={mode === 'detail'}
                      required
                      label="Status"
                      placeholder="Status"
                      labelId="select-status"
                      id="demo-customized-select"
                      value={status}
                      onChange={(event: any) => {
                        setStatus(event.target.value);
                      }}
                    >
                      <MenuItem value="published">Published</MenuItem>
                      <MenuItem value="unpublished">Unpublished</MenuItem>
                    </Select>
                  </FormControl>
                  <FormInput
                    readOnly={mode === 'detail'}
                    type="wyswyg"
                    fullWidth
                    required
                    placeholder="Content"
                    label="Content"
                    value={content}
                    onChange={(html: string) => setContent(html)}
                  />
                </Box>
              </Box>
            </Box>
          </Paper>
        </>
      }
    </Layout >
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 0, 0, 0),
      padding: theme.spacing(2),
    },
    containerForm: {},
    input: {
      padding: theme.spacing(0, 2, 1, 2),
    },
    paperTitle: {
      padding: theme.spacing(2),
    },
    containerButton: {
      flexDirection: "row",
      padding: theme.spacing(2),
    },
    formStatus: {
      width: "100%",
      margin: theme.spacing(0, 0, 3, 0),
    },
    cardRoot: {
      width: "100%",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      backgroundColor: COLORS.greyLighten,
      marginTop: 10,
    },
    cardMedia: {
      height: 200,
      width: 200,
      alignSelf: "center",
      backgroundColor: COLORS.accent,
      borderColor: COLORS.accent,
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 10,
    },
    cardActionArea: {
      display: "flex",
      padding: 10,
    },
  })
);

import axios from 'axios';
import { getHeaders } from '../../helpers/auth';

export async function apiCurrentReferralInformation() {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/referral-information/current-value',
    method: 'GET',
    headers
  });
}

export async function apiUpdateReferralInformation(data: any) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/referral-information',
    method: 'POST',
    headers,
    data
  });
}

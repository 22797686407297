import axios from 'axios';
import { getHeaders } from '../helpers/auth';

export interface IDisburseDto {
  periodStart: string; // Date ISO
  periodEnd: string; // Date ISO

  users: {
    userId: string;
    commissionIds: string[];
  }[];
}

export interface IExportDisburseDto {
  periodStart: string; // Date ISO
  periodEnd: string; // Date ISO

  users: {
    userId: string;
    commissionIds: string[];
  }[];
}

export type DisburseResponse = {
  isSuccess: boolean;
};

export type IRISBalanceResponse = {
  balance: string;
};

export async function apiDisburse(
  data: IDisburseDto
): Promise<DisburseResponse> {
  const headers = await getHeaders();
  const res: {
    data: {
      code: string;
      message: string;
      payload: DisburseResponse;
    };
  } = await axios({
    url: process.env.REACT_APP_BASE_URL + '/commission/disburse',
    method: 'POST',
    data,
    headers,
  });

  return res.data.payload;
}

export async function apiExportCommission(
  data: IDisburseDto
): Promise<any> {
  const headers = await getHeaders();
  const res: {
    data: {
      code: string;
      message: string;
    };
  } = await axios({
    url: process.env.REACT_APP_BASE_URL + '/commission/export/xlsx',
    method: 'POST',
    data,
    headers,
    responseType: 'blob',
  });

  return res.data;
}

export async function apiExportDetailCommission(data: IDisburseDto): Promise<any> {
  const headers = await getHeaders();
  const res: {
    data: {
      code: string;
      message: string;
    };
  } = await axios({
    url: process.env.REACT_APP_BASE_URL + '/commission/export-detail/xlsx',
    method: 'POST',
    data,
    headers,
    responseType: 'blob',
  });

  return res.data;
}

export async function apiCheckBalance(): Promise<IRISBalanceResponse> {
  const headers = await getHeaders();
  const res: {
    data: {
      code: string;
      message: string;
      payload: IRISBalanceResponse;
    };
  } = await axios({
    url: process.env.REACT_APP_BASE_URL + '/disbursement/iris/balance',
    method: 'GET',
    headers,
  });

  return res.data.payload;
}

import { ITableHeader } from "../../components/Table/CustomizedTable";

export const headersProductBySuperAdmin: ITableHeader[] = [
  {
    title: "#",
    sortable: true,
    column: "id",
    width: "5%",
  },
  {
    title: "Image",
    sortable: true,
    column: "image",
    type: "image",
    width: "10%",
  },
  {
    title: "SKU",
    sortable: true,
    column: "product_code",
  },
  {
    title: "Name",
    sortable: true,
    column: "name",
  },
  {
    title: "Retail Price",
    type: "currency",
    sortable: true,
    column: "retail_price",
  },
  {
    title: "Agent Price",
    type: "currency",
    sortable: true,
    column: "agent_price",
  },
  {
    title: "Status",
    sortable: true,
    column: "status_text",
  },
];

export const headersProductByAdminB2C: ITableHeader[] = [
  {
    title: "productId",
    column: "productId",
    type: "id",
  },
  {
    title: "Image",
    column: "image",
    type: "image-product",
    // width: '10%'
  },
  {
    title: "SKU",
    column: "sku",
    width: '15%'
  },
  {
    title: "Product",
    sortable: true,
    column: "productInfo",
    width: '40%'
  },
  {
    title: "Type",
    column: "type",
  },
  {
    title: "Catalog",
    column: 'catalogPrice',
    align: "right",
    alignContent: "right",
    type: "currency",
  },
  {
    title: "Agent",
    column: 'agentPrice',
    align: "right",
    alignContent: "right",
    type: "currency",
  },
  {
    title: "Retail",
    column: "retailPrice",
    align: "right",
    alignContent: "right",
    type: "currency",
  },
  {
    title: "Retail Disc. (%)",
    column: 'retailDiscount',
    align: "right",
    alignContent: "right",
    width: '4%'
  },
  {
    title: "Retail Disc. (Rp)",
    column: 'retailDiscountAmount',
    align: "right",
    alignContent: "right",
    width: '4%'
  },
];

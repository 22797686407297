import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { apiCreateFeaturedProduct, apiUpdateFeaturedProduct, apiDeleteFeaturedProduct, apiFindAllFeaturedProduct } from '../api/featured-product';
import { apiFindAllProduct } from '../api/product';
import { generateQueryString } from '../helpers/functions/generateQueryString';
import { setLoadingFeaturedProduct, findAllFeaturedProduct, setErrorFeaturedProduct, createFeaturedProduct, updateFeaturedProduct, deleteFeaturedProduct } from '../redux/action/featured-product';


export default function useFeaturedProduct() {
  const dispatch = useDispatch()
  const history = useHistory();

  const fetchAllFeaturedProduct = async (params: any) => {
    try {
      dispatch(setLoadingFeaturedProduct(true))
      let query = generateQueryString(params)
      const result = await apiFindAllFeaturedProduct(query)

      dispatch(findAllFeaturedProduct(result.data))

      dispatch(setLoadingFeaturedProduct(false))
    } catch (error) {
      dispatch(setLoadingFeaturedProduct(false))
      dispatch(setErrorFeaturedProduct(error.response))
    }
  }

  const addFeaturedProduct = async (data: FormData) => {
    try {
      dispatch(setLoadingFeaturedProduct(true))
      const result = await apiCreateFeaturedProduct(data)

      if (result.data.payload) {
        dispatch(createFeaturedProduct(result.data.payload))
        history.go(-1)
      }

      dispatch(setLoadingFeaturedProduct(false))
    } catch (error) {
      dispatch(setLoadingFeaturedProduct(false))
      dispatch(setErrorFeaturedProduct(error.response))
    }
  }

  const updateOneFeaturedProduct = async (data: FormData) => {
    try {
      dispatch(setLoadingFeaturedProduct(true))
      const result = await apiUpdateFeaturedProduct(data)

      if (result.data.payload) {
        dispatch(updateFeaturedProduct(result.data.payload))
        history.go(-1)
      }

      dispatch(setLoadingFeaturedProduct(false))
    } catch (error) {
      dispatch(setLoadingFeaturedProduct(false))
      dispatch(setErrorFeaturedProduct(error.response))
    }
  }

  const removeFeaturedProduct = async (featuredProductId: string) => {
    try {
      dispatch(setLoadingFeaturedProduct(true))

      await apiDeleteFeaturedProduct(featuredProductId)

      dispatch(deleteFeaturedProduct(featuredProductId))

      await fetchAllFeaturedProduct({ limit: 25, offset: 0, search: '' })

      dispatch(setLoadingFeaturedProduct(false))
    } catch (error) {
      dispatch(setLoadingFeaturedProduct(false))
      dispatch(setErrorFeaturedProduct(error.response))
    }
  }

  return {
    fetchAllFeaturedProduct,
    addFeaturedProduct,
    updateOneFeaturedProduct,
    removeFeaturedProduct
  }
}
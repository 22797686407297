import React from "react";
import {
  Typography,
  Box,
  CardMedia,
  createStyles,
  makeStyles,
  Theme,
  Chip,
} from "@material-ui/core";
import COLORS from "../../styles/colors";
import { CategoryResponse } from '../../types/category.type';

export type Props = {
  product: any
}
export default function ProductPreview(props: Props) {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      style={{
        padding: '4px 16px',
        borderRadius: 10,
        borderColor: COLORS.grey,
        borderWidth: 1,
        borderStyle: 'solid',
      }}
    >
      <Box
        style={{
          borderRadius: 10,
          borderColor: COLORS.grey,
          borderWidth: 1,
          borderStyle: 'solid',
        }}
      >
        <CardMedia
          className={classes.cardMedia}
          image={
            props.product.images && props.product.images.length > 0
              ? props.product.images[0].imageUrl
              : '/assets/default_500x500.png'
          }
        />
      </Box>
      <Box style={{ padding: 10 }}>
        <Typography variant="body1" color="textSecondary">
          Product Name
        </Typography>
        <Typography variant="h5">
          {props.product.sku} {props.product.productName}
        </Typography>
        <Typography variant="body1"> </Typography>
        <Typography variant="body1" color="textSecondary">
          Categories
        </Typography>

        {props.product.categories && props.product.categories.length > 0 ? (
          <Box display="flex" flexDirection="row">
            {props.product.categories.map((item: CategoryResponse) => {
              return (
                <Chip
                  key={item.categoryId}
                  label={item.categoryName}
                  style={{ marginRight: 8 }}
                ></Chip>
              );
            })}
          </Box>
        ) : (
          <Chip label="Uncategorized" />
        )}
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardMedia: {
      width: 120,
      height: 120,
      borderRadius: 10,
      backgroundColor: COLORS.accent,
    },
    categoryItem: {
      padding: theme.spacing(0.5, 1, 0.5, 1),
      backgroundColor: COLORS.grey,
      borderRadius: 10,
      marginRight: 5,
    },
  })
);

import React, { useState } from "react";
import {
  Button,
  CardMedia,
  createStyles,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Chip,
} from "@material-ui/core";
import { ChevronDown } from "react-feather";
import COLORS from "../../../styles/colors";
import GeneralInformation from "../../B2BTransaction/GeneralInformation";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../redux/store";
import useCustomer from "../../../hooks/useCustomer";
import AppButton from "../../../components/Form/AppButton";
import { useParams } from "react-router-dom";
import LogoInstagram from "../../../assets/instagram.svg";
import LogoFacebook from "../../../assets/facebook.svg";
import LogoTwitter from "../../../assets/twitter.svg";
import LogoTikTok from "../../../assets/tik-tok.svg";

export default function PersonalInformation() {
  let { id } = useParams<{ id: string }>();
  const classes = useStyles();

  const customerState = useSelector(
    (state: ApplicationState) => state.customer
  );
  const { updateDataCustomer } = useCustomer();

  const [dialogStatus, setDialogStatus] = useState(false);
  const [dialogMembership, setDialogMembership] = useState(false);
  const [dialogCustomerType, setDialogCustomerType] = useState(false);

  const [statusTmp, setStatusTmp] = useState("");
  const [membershipStatusTmp, setMembershipStatusTmp] = useState("");
  const [customerTypeTmp, setCustomerTypeTmp] = useState("");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElMembership, setAnchorElMembership] =
    React.useState<null | HTMLElement>(null);
  const [anchorElCustomerType, setAnchorElCustomerType] =
    React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickMembership = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElMembership(event.currentTarget);
  };

  const handleClickCustomerType = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElCustomerType(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElMembership(null);
    setAnchorElCustomerType(null);
    setStatusTmp("");
    setMembershipStatusTmp("");
    setCustomerTypeTmp("");
  };

  const confirmUpdateStatus = (value: string) => {
    setAnchorEl(null);
    setDialogStatus(true);
    setStatusTmp(value.toLowerCase());
  };

  const confirmUpdateMembership = (value: string) => {
    setAnchorElMembership(null);
    setDialogMembership(true);
    setMembershipStatusTmp(value.toLowerCase());
  };

  const confirmUpdateCustomerType = (value: string) => {
    setAnchorElCustomerType(null);
    setDialogCustomerType(true);
    setCustomerTypeTmp(value.toLowerCase());
  };

  const updateCustomer = (type: string) => {
    let data = customerState.customer;
    if (type === "status") {
      data = {
        ...data,
        customerId: id,
        status: statusTmp,
        customerType:
          data.customerType === "reguler" ? null : data.customerType,
      };
    } else if (type === "customer-type") {
      data = {
        ...data,
        customerId: id,
        customerType: customerTypeTmp === "reguler" ? null : "spg",
      };
    } else if (type === "status-membership") {
      data = {
        ...data,
        customerId: id,
        membershipStatus: membershipStatusTmp,
        customerType:
          data.customerType === "reguler" ? null : data.customerType,
      };
    }
    updateDataCustomer(data);
    setStatusTmp("");
    setMembershipStatusTmp("");
    setCustomerTypeTmp("");
    setDialogMembership(false);
    setDialogStatus(false);
    setDialogCustomerType(false);
  };

  return (
    <Box display="flex" flexWrap="wrap" className={classes.containerForm}>
      <img
        src={
          customerState.customer.profilePic
            ? customerState.customer.profilePic
            : "/assets/default_500x500.png"
        }
        style={{
          width: 200,
          height: 200,
          borderRadius: 10,
          objectFit: "cover",
        }}
      />

      <Box style={{ flex: 1 }} className={classes.input}>
        <Typography color="textSecondary">Personal Information</Typography>
        <GeneralInformation
          itemPerRow={3}
          data={[
            {
              label: "Name",
              value: customerState.customer.customerName,
            },
            {
              label: "Gender",
              value: customerState.customer.gender,
            },
            {
              label: "Birth Date",
              value: customerState.customer.dateOfBirth,
            },
            {
              label: "Phone",
              value: customerState.customer.phoneNumber,
            },
            {
              label: "Email",
              value: customerState.customer.correspondenceEmail,
            },
            {},
            {
              label: "Status",
              value: (
                <>
                  <Button
                    variant="text"
                    style={{ padding: 0 }}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    {customerState.customer.status}
                    <ChevronDown
                      color={COLORS.greyDark}
                      size={15}
                      style={{ marginLeft: 8 }}
                    />
                  </Button>
                  <Menu
                    id="lock-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {["ACTIVE", "INACTIVE"].map(
                      (option: string, index: number) => (
                        <MenuItem
                          key={index}
                          selected={option === customerState.customer.status}
                          onClick={(event: any) => confirmUpdateStatus(option)}
                        >
                          {option}
                        </MenuItem>
                      )
                    )}
                  </Menu>
                </>
              ),
            },
            {
              label: "Status Membership",
              value: (
                <>
                  <Button
                    disabled={customerState.customer.customerType === "spg"}
                    variant="text"
                    style={{ padding: 0 }}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClickMembership}
                  >
                    {customerState.customer.membershipStatus}
                    <ChevronDown
                      color={COLORS.greyDark}
                      size={15}
                      style={{ marginLeft: 8 }}
                    />
                  </Button>
                  <Menu
                    id="lock-menu"
                    anchorEl={anchorElMembership}
                    keepMounted
                    open={Boolean(anchorElMembership)}
                    onClose={handleClose}
                  >
                    {["REGULER", "PREMIUM"].map(
                      (option: string, index: number) => (
                        <MenuItem
                          key={index}
                          selected={option === customerState.customer.status}
                          onClick={(event: any) =>
                            confirmUpdateMembership(option)
                          }
                        >
                          {option}
                        </MenuItem>
                      )
                    )}
                  </Menu>
                </>
              ),
            },
            {
              label: "Customer Type",
              value: (
                <>
                  <Button
                    variant="text"
                    style={{ padding: 0 }}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    disabled={
                      customerState.customer.membershipStatus === "premium"
                    }
                    onClick={handleClickCustomerType}
                  >
                    {customerState.customer.customerType}
                    <ChevronDown
                      color={COLORS.greyDark}
                      size={15}
                      style={{ marginLeft: 8 }}
                    />
                  </Button>
                  <Menu
                    id="lock-menu"
                    anchorEl={anchorElCustomerType}
                    keepMounted
                    open={Boolean(anchorElCustomerType)}
                    onClose={handleClose}
                  >
                    {["REGULER", "SPG"].map((option: string, index: number) => (
                      <MenuItem
                        key={index}
                        selected={
                          option.toLocaleLowerCase() ===
                          customerState.customer.customerType
                        }
                        onClick={(event: any) =>
                          confirmUpdateCustomerType(option)
                        }
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              ),
            },
            // {
            //   label: "Province",
            //   value: customerState.customer.province,
            // },
            // {
            //   label: "City",
            //   value: customerState.customer.city,
            // },
          ]}
        />

        <hr color={COLORS.greyLight} />

        <Typography color="textSecondary">Social Media</Typography>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
          {
            customerState.customer.socialMedia ? (
              
                customerState?.customer?.socialMedia.map(
                (item: any, index: number) => {
                  return (
                    <Box
                      alignItems="center"
                      display="flex"
                      key={index}
                      style={{ marginTop: 8, marginBottom: 8 }}
                    >
                      <img
                        style={{ marginRight: 16 }}
                        width={24}
                        src={
                          item.channel === "instagram"
                            ? LogoInstagram
                            : item.channel === "facebook"
                              ? LogoFacebook
                              : item.channel === "twitter"
                                ? LogoTwitter
                                : LogoTikTok
                        }
                      />
                      {item.username ? (
                        <Typography variant="body1">{item.username}</Typography>
                      ) : (
                          <Typography
                            variant="body1"
                            style={{ fontStyle: "italic", color: COLORS.grey }}
                          >
                            Not Set
                          </Typography>
                        )}
                    </Box>
                  );
                }
              )
            ) : null
          }
        </div>

        <hr color={COLORS.greyLight} />

        <Typography color="textSecondary">Address</Typography>
        {customerState?.customer?.addresses?.length > 0 ? (
          customerState?.customer?.addresses?.map((item: any) => (
            <Box key={item.customerAddressId}>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography
                  style={{ fontWeight: 700, fontSize: 16, marginRight: 8 }}
                >
                  {item.alias}
                </Typography>
                {item.customerAddressId ===
                  customerState?.customer?.mainAddressId && (
                    <Chip label="Alamat Utama" size="small" />
                  )}
              </Box>

              <Typography variant="body1" style={{ marginTop: 8 }}>
                {item.name} ({item.phoneNumber})
              </Typography>
              <Typography variant="body1" style={{ marginTop: 3 }}>
                {item.address}
                {/* {item.subdistrictId} {item.cityId} {item.provinceId} */}
              </Typography>

              <hr color={COLORS.greyLight} />
            </Box>
          ))
        ) : (
            <Typography
              variant="body1"
              style={{ fontStyle: "italic", color: COLORS.grey }}
            >
              Address empty
            </Typography>
          )}
      </Box>

      <Dialog
        open={dialogStatus}
        onClose={() => {
          setDialogStatus(false);
          setStatusTmp("");
          handleClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Konfirmasi ubah status
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="primary">
            Apakah anda yakin ingin mengubah status keaktifan customer ini
            menjadi <strong>"{statusTmp.toUpperCase()}"</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <AppButton
            color="secondary"
            onClick={() => {
              setDialogStatus(false);
              setStatusTmp("");
              handleClose();
            }}
          >
            Batal
          </AppButton>
          <AppButton
            loading={customerState.isLoadingCustomer}
            color="primary"
            onClick={() => updateCustomer("status")}
          >
            Ya
          </AppButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogMembership}
        onClose={() => {
          setMembershipStatusTmp("");
          setDialogMembership(false);
          handleClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Konfirmasi ubah status
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="primary">
            Apakah anda yakin ingin mengubah status membership customer ini
            menjadi <strong>"{membershipStatusTmp}"</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <AppButton
            color="secondary"
            onClick={() => {
              setMembershipStatusTmp("");
              setDialogMembership(false);
              handleClose();
            }}
          >
            Batal
          </AppButton>
          <AppButton
            loading={customerState.isLoadingCustomer}
            color="primary"
            onClick={() => updateCustomer("status-membership")}
          >
            Ya
          </AppButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogCustomerType}
        onClose={() => {
          setCustomerTypeTmp("");
          setDialogCustomerType(false);
          handleClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Konfirmasi ubah tipe customer
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="primary">
            Apakah anda yakin ingin mengubah tipe customer ini menjadi{" "}
            <strong>"{customerTypeTmp.toLocaleUpperCase()}"</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <AppButton
            color="secondary"
            onClick={() => {
              setCustomerTypeTmp("");
              setDialogCustomerType(false);
              handleClose();
            }}
          >
            Batal
          </AppButton>
          <AppButton
            loading={customerState.isLoadingCustomer}
            color="primary"
            onClick={() => updateCustomer("customer-type")}
          >
            Ya
          </AppButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 0, 0, 0),
    },
    containerForm: {
      padding: theme.spacing(2),
    },
    input: {
      padding: theme.spacing(0, 2, 1, 2),
    },
    row: {
      padding: theme.spacing(1, 0, 1, 0),
    },
  })
);

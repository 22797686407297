import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  apiAddImageProduct,
  apiAddProductVideo,
  apiFindAllProduct,
  apiFindHistoryProductPrices,
  apiFindOneProduct,
  apiRemoveImageProduct,
  apiRemoveProductVideo,
  apiUpdateProduct,
  apiUpdateProductInitialWarranty,
  apiUpdateProductVideo,
} from "../api/product";
import { generateQueryString } from "../helpers/functions/generateQueryString";
import {
  findAllProduct,
  findHistoryProductPrices,
  findOneProduct,
  setErrorProduct,
  setLoadingProduct,
  setLoadingProductImageAdd,
  setLoadingProductImageRemove,
} from "../redux/action/product.action";
import { ApplicationState } from "../redux/store";
import { ProductRequestRemoveImage } from "../types/product.type";

export default function useProduct() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { latestParams } = useSelector(
    (state: ApplicationState) => state.configApp
  );

  const fetchAllProduct = async (params?: any) => {
    try {
      dispatch(setLoadingProduct(true));

      let query = generateQueryString(params);

      const result = await apiFindAllProduct(query);

      dispatch(findAllProduct(result.data));

      dispatch(setLoadingProduct(false));
    } catch (error: any) {
      console.info(error);
      dispatch(setLoadingProduct(false));
      dispatch(setErrorProduct(error.response));
    }
  };

  const fetchHistoryProductPrices = async (params?: any) => {
    try {
      // dispatch(setLoadingProduct(true));

      let query = generateQueryString(params);
      const results = await apiFindHistoryProductPrices(query);

      dispatch(findHistoryProductPrices(results.data));
      // dispatch(setLoadingProduct(false));
    } catch (error: any) {
      console.info(error);
      dispatch(setLoadingProduct(false));
      dispatch(setErrorProduct(error.response));
    }
  };

  const fetchOneProduct = async (productId: string | any) => {
    try {
      dispatch(setLoadingProduct(true));

      const result = await apiFindOneProduct(productId);

      dispatch(findOneProduct(result.data.payload));

      dispatch(setLoadingProduct(false));
    } catch (error: any) {
      console.info(error);
      dispatch(setLoadingProduct(false));
      dispatch(setErrorProduct(error.response));
    }
  };

  const addProductImage = async (data: FormData) => {
    try {
      dispatch(setLoadingProductImageAdd(true));
      console.log(data);

      await apiAddImageProduct(data);

      await fetchOneProduct(data.get("productId") as string);

      dispatch(setLoadingProductImageAdd(false));
    } catch (error: any) {
      console.info(error);
      dispatch(setLoadingProductImageAdd(false));
      dispatch(setErrorProduct(error.response));
    }
  };

  const removeProductImage = async (data: ProductRequestRemoveImage) => {
    try {
      dispatch(setLoadingProductImageRemove(true));

      await apiRemoveImageProduct(data.fileId);

      await fetchOneProduct(data.productId);

      dispatch(setLoadingProductImageRemove(false));
    } catch (error: any) {
      console.info(error);
      dispatch(setLoadingProductImageRemove(false));
      dispatch(setErrorProduct(error.response));
    }
  };

  const updateProductDiscount = async (data: {
    productId: string;
    catalogPrice: number;
    retailDiscountAmount: number;
    retailDiscountNote: string;
    goldDiscountAmount: number;
    goldDiscountNote: string;
    platinumDiscountAmount: number;
    platinumDiscountNote: string;
    note?: string;
    updatedBy?: string;
  }) => {
    try {
      dispatch(setLoadingProduct(true));

      const result = await apiUpdateProduct(data);

      dispatch(findOneProduct(result.data.payload));

      dispatch(setLoadingProduct(false));
    } catch (error: any) {
      console.info(error);
      dispatch(setLoadingProduct(false));
      dispatch(setErrorProduct(error.response));
    }
  };

  const addProductVideo = async (data: {
    productId: string;
    title: string;
    type: string;
    url: string;
  }) => {
    try {
      dispatch(setLoadingProduct(true));
      await apiAddProductVideo(data);
      await fetchOneProduct(data.productId);
      dispatch(setLoadingProduct(false));
    } catch (error: any) {
      console.info(error);
      dispatch(setLoadingProduct(false));
      dispatch(setErrorProduct(error.response));
    }
  };

  const updateProductVideo = async (data: {
    productId: string;
    index: number;
    title: string;
    type: string;
    url: string;
  }) => {
    try {
      dispatch(setLoadingProduct(true));
      await apiUpdateProductVideo(data);
      await fetchOneProduct(data.productId);
      dispatch(setLoadingProduct(false));
    } catch (error: any) {
      console.info(error);
      dispatch(setLoadingProduct(false));
      dispatch(setErrorProduct(error.response));
    }
  };

  const updateProductInitialWarranty = async (data: {
    productId: string;
    date: Date | string | undefined;
  }) => {
    try {
      dispatch(setLoadingProduct(true));
      await apiUpdateProductInitialWarranty(data);
      await fetchOneProduct(data.productId);
    } catch (error: any) {
      console.info(error);
      dispatch(setLoadingProduct(false));
      dispatch(setErrorProduct(error.response));
    }
  };

  const removeProductVideo = async (data: {
    productId: string;
    index: number;
  }) => {
    try {
      // dispatch(setLoadingProduct(true));
      await apiRemoveProductVideo(data);
      await fetchOneProduct(data.productId);
      dispatch(setLoadingProduct(false));
    } catch (error: any) {
      console.info(error);
      dispatch(setLoadingProduct(false));
      dispatch(setErrorProduct(error.response));
    }
  };

  return {
    fetchAllProduct,
    fetchOneProduct,
    addProductImage,
    removeProductImage,
    updateProductDiscount,
    fetchHistoryProductPrices,
    addProductVideo,
    updateProductVideo,
    updateProductInitialWarranty,
    removeProductVideo,
  };
}

import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  Box,
  Paper,
  FormControlLabel,
  Link,
  Modal,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import Layout from "../../components/Layout/Layout";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import COLORS from "../../styles/colors";
import HeaderPage from "../../components/Layout/HeaderPage";
import Search from "../../components/Form/Search";
import { ChevronRight, Info, Upload } from "react-feather";
import { CheckCircleOutlined, Close } from "@material-ui/icons";
import { convertNumToCurrency } from "../../helpers/functions/currency";
import { useSelector, useDispatch } from 'react-redux';
import useDebounce from '../../hooks/useDebounce';
import useProduct from '../../hooks/useProduct';
import { ApplicationState } from '../../redux/store';
import AppTabs from '../../components/Tabs/AppTabs';
import { ProductResponse } from '../../types/product.type';
import { findOneProduct } from '../../redux/action/product.action';
import AppButton from '../../components/Form/AppButton';
import FormInput from '../../components/Form/FormInput';
import useStock from '../../hooks/useStock';
import { Alert } from '@material-ui/lab';
import { useLocation } from 'react-router-dom';
import { generateQueryString } from '../../helpers/functions/generateQueryString';
import { setLatestParams, enableUpdateParams } from '../../redux/action/config-app.action';

let headers: ITableHeader[] = [
  {
    title: "productId",
    column: "productId",
    type: "id",
  },
  {
    title: "SKU",
    align: "left",
    column: "sku",
  },
  {
    title: "Name",
    align: "left",
    sortable: true,
    column: "productName",
  },
  {
    title: "Stock",
    align: "left",
    column: JSON.stringify(['stock', 'currentStock']),
    type: "nested-stock"
  },
];

type QueryType = {
  limit: number
  offset: number
  search: string
  maxStock?: number
}

export default function StockRetail() {
  const classes = useStyles();

  const productState = useSelector((state: ApplicationState) => state.product)
  const stockState = useSelector((state: ApplicationState) => state.stockRetail)

  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [activeTab, setActiveTab] = useState(0);

  const [isShowModalFormStock, setIsShowModalFormStock] = useState(false);
  const [willDoEditStock, setWillDoEditStock] = useState("add-stock");
  const [newStockAmount, setNewStockAmount] = useState(0);
  const [remarks, setRemarks] = useState('');
  const [stockResult, setStockResult] = useState(0);

  const [search, setSearch] = useState<string | null>(null);
  const [query, setQuery] = useState<QueryType>({ limit: 25, offset: 0, search: '' });

  const { fetchAllProduct } = useProduct()
  const { addStock, removeStock } = useStock()
  const dispatch = useDispatch()
  const location = useLocation();

  const { updateParams, latestParams } = useSelector((state: ApplicationState) => state.configApp)

  const debouncedTitle = useDebounce(search, 300);

  useEffect(() => {
    searchBank(search)
  }, [debouncedTitle]);

  const searchBank = async (value: any) => {
    if (value !== null && value.length > 0) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          limit: 25,
          offset: 0,
          search: value
        }
      })
    } else if (value !== null) {
      if (activeTab === 0) {
        setQuery({ limit: 25, offset: 0, search: '' })
      } else {
        setQuery({ limit: 25, offset: 0, search: '', maxStock: 5 })
      }
    }
  }

  useEffect(() => {
    console.info('updateParams', updateParams)
    console.info('latestParams', latestParams.query)
    if (updateParams) {
      dispatch(setLatestParams({
        pathname: location.pathname,
        params: generateQueryString(query),
        query: {
          limit: query['limit'],
          offset: query['offset'],
          page
        }
      }))
      fetchAllProduct(query)
    } else {
      setPage(latestParams.query.page || page || 1)
      fetchAllProduct(latestParams.query)
      dispatch(enableUpdateParams(true))
    }
    setStockResult(0)
  }, [query])

  useEffect(() => {
    if (stockState.stock.payload.isSuccess) {
      fetchAllProduct(query)
      handleCloseModalFormStock()
    }
  }, [stockState.stock])

  useEffect(() => {
    if (productState.data.payload.count > 0) {
      setTotalData(productState.data.payload.count)
      setTotalPage(Math.ceil(productState.data.payload.count / rowsPerPage))
    }
  }, [productState.data])

  useMemo(() => {
    let newStock = 0
    if (willDoEditStock === 'add-stock') {
      newStock = productState.product.stock.currentStock + newStockAmount
    } else if (willDoEditStock === 'remove-stock') {
      newStock = productState.product.stock.currentStock - newStockAmount
    }
    setStockResult(newStock)
  }, [newStockAmount])

  function changePagination(value: number) {
    setPage(value)
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
        limit: rowsPerPage
      }
    })
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value)
    setPage(1)
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: 0,
        limit: value
      }
    })
  }

  const handleCloseModalFormStock = () => {
    setIsShowModalFormStock(false);
    setRemarks('')
    setNewStockAmount(0)
    setStockResult(0)
  };

  const handleChangeWillDoEditStock = (event: any, newValue: string) => {
    setWillDoEditStock(newValue);
    setNewStockAmount(0)
    setRemarks('')
  };

  const changeActiveTab = (value: number) => {
    if (value === 1) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          maxStock: 5
        }
      })
    } else {
      setQuery({ limit: 25, offset: 0, search: '' })
    }
    setActiveTab(value)
  }

  return (
    <Layout title="Stock Retail" loading={productState.isLoadingProduct}>
      <HeaderPage
        title="Stock Retail"
        subtitle="List of all Stock Retail product"
      />

      <Grid
        spacing={3}
        container
        alignItems="flex-end"
        style={{
          marginBottom: 10
        }}
      >
        <Grid item xs={12} md={8} lg={9} />
        <Grid item xs={12} md={4} lg={3}>
          <Search
            placeholder="Search name, SKU"
            onChange={(value: string) => setSearch(value)}
          />
        </Grid>

      </Grid>

      {stockState.limitedStock > 0 && (
        <Alert
          variant="filled"
          severity="warning"
          style={{
            marginBottom: 10
          }}
        >
          Segera perbarui data stok produk, ada {stockState.limitedStock} produk yang akan segera habis
        </Alert>
      )}

      <Grid container spacing={3} className={classes.containerContent}>
        {/* Recent Orders */}
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: COLORS.grey200,
            padding: 0,
            borderRadius: 10,
          }}
        >
          <AppTabs
            active={activeTab}
            tabs={[
              'Semua stok',
              `Stok segera habis ${stockState.limitedStock > 0 ? '(' + stockState.limitedStock + ')' : ''}`,
            ]}
            onChange={(value: number) => changeActiveTab(value)}
          />
          <CustomizedTable
            data={productState.data.payload.results}
            headers={headers}
            showAction={["detail", "edit-dialog"]}
            totalData={totalData}
            page={page}
            totalPage={totalPage}
            rowsPerPage={rowsPerPage}
            onChange={(newPage) => changePagination(newPage)}
            onPressEditDialog={(data: ProductResponse) => {
              dispatch(findOneProduct(data))
              setIsShowModalFormStock(true)
            }}
            onPressDetail={(data: any) => {
              dispatch(findOneProduct(data))
            }}
            onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
          />
        </Grid>
      </Grid>

      <Modal
        open={isShowModalFormStock}
        onClose={handleCloseModalFormStock}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.containerModal}>
          <div className={classes.contentModal}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h5">Product Stock</Typography>

              <div onClick={() => handleCloseModalFormStock()}>
                <Close />
              </div>
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              style={{ paddingTop: 10, paddingBottom: 10 }}
            >
              <Box width="100%">
                <Typography component="span" style={{ fontWeight: "bold" }}>
                  {productState.product.sku}
                  {" "}
                </Typography>
                <Typography component="span" style={{ fontWeight: "bold" }}>
                  {productState.product.productName}
                </Typography>
              </Box>
            </Box>

            <hr color={COLORS.greyLight} />

            <div style={{ paddingTop: 10, paddingBottom: 10 }}>
              <Typography>Jenis Perubahan</Typography>
              <RadioGroup
                row
                aria-label="action_type"
                name="action_type"
                onChange={handleChangeWillDoEditStock}
                value={willDoEditStock}
              >
                {[
                  {
                    key: "add-stock",
                    label: "Tambah Stok",
                  },
                  {
                    key: "remove-stock",
                    label: "Kurangi Stok",
                  },
                ].map((item, index) => (
                  <FormControlLabel
                    key={index}
                    value={item.key}
                    control={<Radio color="primary" />}
                    label={item.label}
                  />
                ))}
              </RadioGroup>
            </div>

            {willDoEditStock === "remove-stock" && productState.product.stock.currentStock === 0 && (
              <Alert
                variant="filled"
                severity="warning"
                style={{
                  marginBottom: 10
                }}
              >
                Stock 0 tidak dapat dikurangi
              </Alert>
            )}

            <Box display="flex" flexDirection="row" alignItems="center">
              <Box>
                <Typography color="textSecondary">Stok Awal</Typography>
                <FormInput
                  onChange={() => console.info('')}
                  value={productState.product.stock.currentStock}
                  disabled
                />
              </Box>
              {willDoEditStock === "add-stock" ? (
                <Box
                  style={{
                    paddingLeft: 15,
                    paddingRight: 15,
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                >
                  +
                </Box>
              ) : (
                  <Box
                    style={{
                      paddingLeft: 15,
                      paddingRight: 15,
                      fontSize: 20,
                      fontWeight: "bold",
                    }}
                  >
                    -
                  </Box>
                )}
              {willDoEditStock === "add-stock" ? (
                <Box>
                  <Typography color="textSecondary">Penambahan</Typography>
                  <FormInput
                    onChange={(value: any) => {
                      setNewStockAmount(Number(value))
                    }}
                    value={newStockAmount}
                  />
                </Box>
              ) : (
                  <Box>
                    <Typography color="textSecondary">Pengurangan</Typography>
                    <FormInput
                      disabled={productState.product.stock.currentStock === 0}
                      onChange={(value: any) => setNewStockAmount(Number(value))}
                      value={newStockAmount}
                    />
                  </Box>
                )}
              <Box
                style={{
                  paddingLeft: 15,
                  paddingRight: 15,
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                =
              </Box>
              <Box>
                <Typography color="textSecondary">Stok Akhir</Typography>
                <FormInput
                  onChange={(value: any) => console.info(value)}
                  value={stockResult}
                  disabled
                />
              </Box>
            </Box>

            {willDoEditStock === "remove-stock" && (
              <Box>
                <Typography color="textSecondary">
                  Catatan Pengurangan
                </Typography>
              </Box>
            )}
            {willDoEditStock === "remove-stock" && (
              <Box>
                <FormInput
                  onChange={(value: any) => setRemarks(value)}
                  type="address"
                  fullWidth
                  required
                  disabled={productState.product.stock.currentStock === 0}
                  placeholder={"Tulis catatan pengurangan stok"}
                />
              </Box>
            )}

            <Box
              display="flex"
              flexDirection="row"
              style={{ paddingTop: 5, paddingBottom: 5 }}
            ></Box>

            <Box display="flex" justifyContent="flex-end">
              <Box>
                <AppButton
                  loading={stockState.isLoadingStock}
                  color="secondary"
                  onClick={handleCloseModalFormStock}
                >
                  Cancel
                </AppButton>
              </Box>
              <Box>
                <AppButton
                  loading={stockState.isLoadingStock}
                  onClick={() => {
                    if (willDoEditStock === 'add-stock') {
                      addStock({
                        productId: productState.product.productId,
                        changes: newStockAmount,
                        note: ''
                      })
                    } else if (willDoEditStock === 'remove-stock') {
                      removeStock({
                        productId: productState.product.productId,
                        changes: newStockAmount,
                        note: remarks
                      })
                    }
                  }}
                >Save</AppButton>
              </Box>
            </Box>

          </div>
        </div>
      </Modal>

    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(0, 0, 3, 0),
      padding: theme.spacing(3, 2, 0.5, 2),
    },
    paperSlim: {
      margin: theme.spacing(0, 0, 3, 0),
      padding: theme.spacing(1, 1, 1, 1),
    },
    formStatus: {
      width: "100%",
      margin: theme.spacing(1, 0, 2, 0),
    },
    cardMedia: {
      borderColor: COLORS.grey,
      borderWidth: 1,
      borderStyle: "solid",
      width: 69,
      height: 69,
      borderRadius: 10,
      backgroundColor: COLORS.accent,
    },
    processCircle: {
      width: 25,
      height: 25,
      backgroundColor: COLORS.accent,
      borderRadius: "50%",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: COLORS.grey,
    },
    containerContent: {
      padding: theme.spacing(1),
    },
    containerModal: {
      position: "absolute",
      width: "100%",
      height: "100%",
      alihandleCloseModalFormCreategnContent: "center",
    },
    contentModal: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
      width: 600,
      margin: "0 auto",
      marginTop: "10%",
      borderRadius: 10,
    },
  })
);

import React from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import Layout from '../../components/Layout/Layout';
import useFetch, { DefaultResponse } from '../../hooks/useFetch';
import HeaderPage from '../../components/Layout/HeaderPage';
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import { Link } from "react-router-dom";
import Search from "../../components/Form/Search";
import useGlobalStyles from "../../styles/globalStyles";
import AppButton from "../../components/Form/AppButton";
import { Add as AddIcon } from "@material-ui/icons";

export interface IAroQuery {
	limit: number;
	page: number;
}
const headers: ITableHeader[] = [
	{
		title:'aroId',
		type:'id',
		column:'aroId'
	},
  {
    title: "Label",
    column: "labelName",
  },
  {
    title: "Phone",
    type: "nested",
    column: JSON.stringify(['customer','phoneNumber'])
  },
  {
    title: "Created At",
    column: "createdAt",
    type: "date"
  }
];

const AgentRegion: React.FC = () => {

	const globalClasses = useGlobalStyles();

	const initialQuery: IAroQuery = {
		limit: 25,
		page: 1,
	}

	const {
		data,
		loading,
		pagination,
		query,
		search,
		handlePerPageChange,
		handlePageChange,
		setQuery,
		changeHandler,
		fetchDataTableAlt,
	} = useFetch<any>({
		url: 'aros',
		query: initialQuery,
	}, false)

	return (
	    <Layout loading={loading} title="Agent Region">
		    <HeaderPage title="Agent Region" subtitle="List of all agent region oxone" />
		    <Grid container spacing={3} justify="space-between">
		        <Grid item xs={12} md={5} lg={7}>
							<Link
							to={{ pathname: "create-agent-region" }}
							className={globalClasses.link}
							>
								<AppButton
								variant="contained"
								color="primary"
								startIcon={<AddIcon />}
								>
									Add Agent
								</AppButton>
							</Link>
		        </Grid>
		        <Grid item xs={12} md={4} lg={3}>
							<Search
							value={search}
							placeholder="Search by label"
							onChange={changeHandler}
							/>
		        </Grid>
			</Grid>
			<Grid container spacing={3}>
        <Grid item xs={12}>
					<CustomizedTable
						data={data}
						headers={headers}
						totalData={pagination.totalData}
						showAction={["detail"]}
						page={pagination.page}
            totalPage={pagination.countPage}
            rowsPerPage={pagination.perPage}
            onChange={(newPage) => handlePageChange(newPage)}
            onRowsPerPageChange={(value: any) => handlePerPageChange(value)}
					/>
				</Grid>
			</Grid>
	    </Layout>
	);
}

export default AgentRegion;
import { useDispatch } from "react-redux";
import { apiGetCoinHistory } from "../api/coin";
import { generateQueryString } from "../helpers/functions/generateQueryString";
import {
  findLogCoinHistory,
  setLoadingCoin,
} from "../redux/action/coin.action";

export default function useCoin() {
  const dispatch = useDispatch();

  const fetchCoinHistory = async (params: any) => {
    try {
      dispatch(setLoadingCoin(true));
      let query = generateQueryString(params);
      const result = await apiGetCoinHistory(query);

      dispatch(findLogCoinHistory(result.data));

      dispatch(setLoadingCoin(false));
    } catch (error) {
      dispatch(setLoadingCoin(false));
    }
  };

  return { fetchCoinHistory };
}

import {
  Paper,
  Box,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Modal,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { useState } from "react";
import AppButton from "../../components/Form/AppButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import FormInput from "../../components/Form/FormInput";
import { Close, LocationOn } from "@material-ui/icons";
import { DistributorProperties, initialDistributor } from '../../types/b2b/distributor/distributor.type';
import AppMap from '../../components/AppMap';
import COLORS from '../../styles/colors';
import { X } from 'react-feather';

interface Props {
  data: DistributorProperties
}

export default function DistributorInformation({ data }: Props) {
  const classes = useStyles();

  const [currentDistributor, setCurrentDistributor] = React.useState<DistributorProperties>(initialDistributor)

  const [isShowModalForm, setIsShowModalForm] = useState(false);

  React.useEffect(() => {
    setCurrentDistributor(data)
  }, [data])

  const handleOpenModalForm = () => {
    setIsShowModalForm(true);
  };

  const handleCloseModalForm = () => {
    setIsShowModalForm(false);
  };

  const submit = () => {
    setIsShowModalForm(false);
  };

  return (
    <Paper elevation={3} className={classes.paper}>
      <Typography variant="h5">Distributor Information</Typography>

      {/* <Box display="flex" flexWrap="wrap" className={classes.boxRow}>
        <AppButton onClick={handleOpenModalForm}>
          <EditOutlinedIcon /> Edit Distributor
        </AppButton>
      </Box> */}

      <Box display="flex" flexWrap="wrap" className={classes.boxRow}>
        <Box width="25%">
          <Typography variant="body1" color="textSecondary">
            Distributor Name
          </Typography>
          <Typography variant="body1">{data.name}</Typography>
        </Box>
        <Box width="25%">
          <Typography variant="body1" color="textSecondary">
            Distributor Phone
          </Typography>
          <Typography variant="body1">{data.phoneNumber}</Typography>
        </Box>
        <Box width="25%">
          <Typography variant="body1" color="textSecondary">
            Distributor Discount
          </Typography>
          <Typography variant="body1">{data.distributorDiscount}</Typography>
        </Box>
        <Box width="25%">
          <Typography variant="body1" color="textSecondary">
            Latitude & Longitude
          </Typography>
          <Typography variant="body1">{data.coordinate.split(';').join(', ')}</Typography>
        </Box>
      </Box>

      <Box display="flex" flexWrap="wrap" className={classes.boxRow}>
        <Box>
          <Typography variant="body1" color="textSecondary">
            Address
          </Typography>
          <Typography variant="body1">
            {data.address}
          </Typography>
        </Box>
      </Box>

      <Dialog
        fullWidth
        maxWidth="md"
        open={isShowModalForm}
        onClose={handleCloseModalForm}
        scroll={'body'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <Typography variant="h4">Edit Distributor</Typography>
            <div onClick={handleCloseModalForm} style={{ cursor: 'pointer' }}>
              <X />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <FormInput
            onChange={(value: any) => {
              setCurrentDistributor((oldValue) => (
                { ...oldValue, name: value }
              ))
            }}
            value={currentDistributor.name}
            fullWidth
            required
            label="Distributor Name"
          />
          {/* <AppMap
              markers={[
                {
                  lat: 59.955413,
                  lng: 30.337844,
                  content: (
                    <LocationOn
                      style={{ width: 50, height: 50, color: COLORS.error }}
                    />
                  ),
                },
              ]}
            /> */}
          <FormInput
            onChange={(value: any) => {
              setCurrentDistributor((oldValue) => (
                { ...oldValue, address: value }
              ))
            }}
            type="address"
            value={currentDistributor.address}
            fullWidth
            required
            label="Address"
          />
          <FormInput
            onChange={(value: any) => {
              setCurrentDistributor((oldValue) => (
                { ...oldValue, phoneNumber: value }
              ))
            }}
            type="phone"
            value={currentDistributor.phoneNumber}
            fullWidth
            required
            label="Phone"
          />
          <FormInput
            onChange={(value: any) => {
              setCurrentDistributor((oldValue) => (
                { ...oldValue, distributorDiscount: value }
              ))
            }}
            type="discount"
            value={currentDistributor.distributorDiscount}
            fullWidth
            label="Distributor Discount"
          />
        </DialogContent>
        <DialogActions>
          <AppButton
            color="secondary"
            autoFocus
            onClick={() => {
              handleCloseModalForm();
            }}
          >
            Cancel
          </AppButton>
          <AppButton
            color="primary"
            onClick={() => {
              submit()
            }}
          >
            Save
          </AppButton>
        </DialogActions>
      </Dialog>

    </Paper>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
    boxRow: {
      padding: theme.spacing(2, 0, 2, 0),
    },
    containerModal: {
      position: "absolute",
      width: "100%",
      height: "100%",
      alignContent: "center",
    },
    contentModal: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
      width: 600,
      margin: "0 auto",
      marginTop: "10%",
      borderRadius: 10,
    },
  })
);

import {
  Box,
  Typography,
  Paper,
  createStyles,
  makeStyles,
  Theme,
  Stepper,
  Step,
  StepLabel,
  StepIconProps,
  withStyles,
  StepConnector,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Grid,
  Chip,
  Button,
  Modal,
  Checkbox,
  Avatar
} from '@material-ui/core';
import React, { useState } from 'react';
import Layout from '../../components/Layout/Layout';
import { useParams, useHistory } from 'react-router-dom';
import HeaderPage from '../../components/Layout/HeaderPage';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import placeholder from '../../assets/chef'
import clsx from 'clsx';
import COLORS from '../../styles/colors';
import { ChevronRight, Close, Note } from '@material-ui/icons';
import AppButton from '../../components/Form/AppButton';
import { convertDateTimeToText } from '../../helpers/functions/datetime';
import { httpRequest } from '../../helpers/api/api';
import { useDispatch } from 'react-redux';
import UploadImage from '../../components/Form/UploadImage';
import useFetch, { DefaultResponse, DefaultResponseList } from '../../hooks/useFetch';
import { ERecipeStatus, ERecipeVideoType, RecipeLogsProperties, RecipeProperties, initialRecipe } from '../../types/recipe.type';
import CustomizedTable, { ITableHeader } from '../../components/Table/CustomizedTable';
import { getYouTubeId } from '../../helpers/functions/media';
import FormInput from '../../components/Form/FormInput';
import chefLogo from '../../assets/chef_choice.png'
import { extractInstagramId, getUrlEmbedTiktok } from '../../helpers/functions/url';
import { InstagramEmbed, TikTokEmbed } from 'react-social-media-embed';
import axios from 'axios';




const date = new Date();
const dateString = date.toISOString();

export default function RecipeDetail() {
  let { id } = useParams<{ id: string }>();




  const headers: ITableHeader[] = [
    {
      title: "recipeLogId",
      column: "recipeLogId",
      type: "id",
    },
    {
      title: "Status Transaksi",
      column: "recipeStatusAfter",
      type: "status-recipe",
    },
    {
      title: "Tanggal",
      column: "createdAt",
      type: "date",
    },
    {
      title: "Aktor",
      column: "updateByName",
      type: "text"
    },
    {
      title: "Role",
      column: "updateByRoleId",
      type: "text"

    },
    {
      title: "Remark",
      column: "remark",
      type: "text"
    }

  ];
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);

  const [dataRecipe, setDataRecipe] = useState<RecipeProperties>(initialRecipe)
  const [chefChoice, setChefChoice] = React.useState<any>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [isShowModalApproval, setIsShowModalApproval] = React.useState(false);
  const [isShowModalReject, setIsShowModalReject] = React.useState(false);
  const [idRecipe, setIdRecipe] = React.useState<string>('')
  const [remark, setRemark] = React.useState<string>('')
  const [changeRouteCount, setChangeRouteCount] = useState<number>(0)
  const [totalIngredients, setTotalIngredients] = useState<number>(0)

  const convertToYoutube = (url: any) => {
    const data = getYouTubeId(url)
    if (data) {
      return `https://www.youtube.com/embed/${data}`;
    } else {
      return data
    }
  }

  const RenderStatusRecipe = (data: { status: string, isChefChoice: boolean }) => {
    if (data.status === "draft") {
      return <Chip size="medium" color="primary" label="DRAFT" />;
    } else if (data.status === ERecipeStatus.approved) {
      if (data.isChefChoice) {
        return (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Chip style={{
                backgroundColor: "#E3F7EE",
                color: "#0AC46B",
                borderRadius: 5
              }} size="medium" label="APPROVED" />
            </Grid>
            <Grid item xs={12}>

              <Chip style={{
                backgroundColor: "#0C0C0C",
                color: "#E3F7EE",
                borderRadius: 5
              }} avatar={<Avatar alt="Chef Choice" src={chefLogo} />}
                size="medium" label="Chef Choice" />
            </Grid>
          </Grid>
        )
      }
      return (
        <Chip style={{
          backgroundColor: "#E3F7EE",
          color: "#0AC46B",
          borderRadius: 5
        }} size="medium" label="APPROVED" />
      );
    } else if (data.status === ERecipeStatus.waiting_approval) {
      return (
        <Chip style={{
          backgroundColor: "#FFF3DC",
          color: "black",
          borderRadius: 5
        }} size="medium" color="primary" label="WAITING APPROVAL" />
      );
    } else if (data.status === ERecipeStatus.rejected) {
      return (
        <Chip style={{
          backgroundColor: "#FFEEF1",
          color: "#E92548",
          borderRadius: 5
        }} size="medium" color="primary" label="REJECTED" />
      );
    } else {
      return (
        <Chip size="medium" color="primary" label="DRAFT" />
      );
    }

  };

  const submit = async (type: 'approved' | 'rejected') => {
    setIsLoading(true)
    if (type === 'approved') {
      onApprove()
      handleCloseModalApproval()
      await fetchRecipe()
      setIsLoading(false)
      history.go(-1)
    }
    if (type === 'rejected') {
      onReject()
      handleCloseModalReject()
      await fetchRecipe()
      setIsLoading(false)
      history.go(-1)

    }
  }

  const onApprove = async () => {
    const recipe = dataRecipe
    const approve = {
      ...recipe,
      recipeStatus: ERecipeStatus.approved,
      isChefChoice: chefChoice
    }
    const res = await httpRequest.patch<any>(`/recipe/${id}`, approve)
  }

  const onReject = async () => {
    const recipe = dataRecipe
    const rejected = {
      ...recipe,
      recipeStatus: ERecipeStatus.rejected,
      recipeRemark: recipe.recipeRemark
    }
    const res = await httpRequest.patch<any>(`/recipe/${id}`, rejected)
  }

  const handleCloseModalApproval = () => {
    setIsShowModalApproval(false);
  };
  const handleOpenModalApproval = () => {
    setIsShowModalApproval(true);
  };

  const handleCloseModalReject = () => {
    setIsShowModalReject(false);
  };
  const handleOpenModalReject = () => {
    setIsShowModalReject(true);
  };

  const handleVideoUrl = async (type: ERecipeVideoType, link: any): Promise<string> => {
    if (type === ERecipeVideoType.instagram_feed) {
      return `https://www.instagram.com/p/${extractInstagramId(link)}/embed`;
    } else if (type === ERecipeVideoType.tiktok) {
      const handleGetTiktokEmbedUrl = async (link: string) => {
        const urlEmbed: any = await getUrlEmbedTiktok(link);
        console.log(urlEmbed)
        return urlEmbed;
      };
      return await handleGetTiktokEmbedUrl(link);
    } else if (type === ERecipeVideoType.youtube) {
      return `https://www.youtube.com/embed/${getYouTubeId(link)}`;
    }
    return "";
  };

  React.useEffect(() => {
    
  }, [dataRecipe]);

  const handleChangeStatus = (newStatus: string) => {
    console.info("newStatus", newStatus);
  };

  const ReadMore = ({ text, maxLength }: any) => {
    const [isReadMore, setIsReadMore] = React.useState(true);

    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };

    return (
      <div>
        <Typography variant="body1">
          {isReadMore ? `${text.slice(0, maxLength)}...` : text}
        </Typography>
        {text.length > maxLength ? <>
          <Typography style={{
            fontWeight: "bold",
            cursor: "pointer"
          }} onClick={toggleReadMore} >
            {isReadMore ? "Lihat Semua" : "Lihat Sedikit"}
          </Typography>

        </> : <></>}
      </div>
    );
  };


  let title = "Detail Recipe";
  let subtitle = "Detailed information about recipe";

  const {
    data,
    pagination,
    query,
    search,
    activeTab,
    handlePerPageChange,
    handlePageChange,
    setQuery,
    fetchDataTable,
    fetchDataTableAlt,
    changeHandler,
    setActiveTab
  } = useFetch<RecipeLogsProperties>({
    url: 'recipe-logs?recipeId=' + id,
    query: '',
  })

  const fetchRecipe = async () => {
    const res = await httpRequest.get<DefaultResponse<RecipeProperties>>("recipe/" + id + "?include=customer%2Ccategory%2Ctag")
    const fetchVideoUrl = async () => {
      if(res.data.payload.recipeVideoType !== ERecipeVideoType.instagram_feed){
        const url = await handleVideoUrl(res.data.payload.recipeVideoType, res.data.payload.recipeVideoUrl);
        console.log(url)
        url && setVideoUrl(url); // Set URL video ke dalam state
      }
    };
    fetchVideoUrl();
    setDataRecipe(res.data.payload)

    const total = res.data.payload.recipeIngredients.reduce((total, recipe) => {
      return total + recipe.ingridients.length;
    }, 0);
    setTotalIngredients(total)
    setIsLoading(false)
  }
  React.useEffect(() => {
    setIsLoading(true)
    fetchRecipe()
  }, [id])


  const history = useHistory();
  const dispatch = useDispatch();


  return (
    <Layout title={title} loading={isLoading}>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginBottom: 15 }}
      >
        <HeaderPage title={title} subtitle={subtitle} showBackButton />
      </Box>

      <Paper elevation={3} className={classes.paper} style={{ marginBottom: 20, padding: 10 }}>
        <Grid container spacing={3}>
          <Grid item md={3}>
            <Typography
              style={{
                color: COLORS.greyDark,
                fontSize: 12,
              }}
            >
              Preview
            </Typography>
            <UploadImage
              image={dataRecipe.recipeImage ? dataRecipe.recipeImage[0].imageUrl : ''}
              title="Recipe Image"
              imageResolution="500x500"
              editable={false}
            />



          </Grid>
          <Grid item md={3}>
            <Typography
              style={{
                color: COLORS.greyDark,
                fontSize: 12,
              }}
            >
              Recipe Info
            </Typography>
            <div>
              <Typography style={{
                fontSize: 16,
                fontWeight: 700
              }}>{dataRecipe.recipeName}</Typography>
              <Typography>
                Oleh : {dataRecipe.customer.customerName}
              </Typography>
              <Typography>
                Phone Number : {dataRecipe.customer.phoneNumber}
              </Typography>

            </div>
            <br />
          </Grid>
          <Grid item md={2}>
            <Typography
              style={{
                color: COLORS.greyDark,
                fontSize: 12,
              }}
            >
              Tanggal Dibuat
            </Typography>
            <Typography>{convertDateTimeToText(dataRecipe.createdAt, 'dd mmm yyyy')}</Typography>
          </Grid>
          <Grid item md={2}>
            <Typography
              style={{
                color: COLORS.greyDark,
                fontSize: 12,
              }}
            >
              Status Pesanan
            </Typography>
            <RenderStatusRecipe status={dataRecipe.recipeStatus} isChefChoice={dataRecipe.isChefChoice} />

          </Grid>
          {
            dataRecipe.recipeStatus === ERecipeStatus.waiting_approval ?
              (
                <Grid item md={2}>
                  <Typography
                    style={{
                      color: COLORS.greyDark,
                      fontSize: 12,
                    }}
                  >
                    Action
                  </Typography>
                  <div style={{ display: 'flex', flexDirection: "column" }}>
                    <AppButton style={{ marginBottom: "10px" }} onClick={() => setIsShowModalApproval(true)}>Approve & Publish </AppButton>
                    <AppButton onClick={() => setIsShowModalReject(true)} color="secondary">
                      Reject Recipe
                    </AppButton>
                  </div>
                </Grid>

              ) :
              (
                <></>
              )

          }

        </Grid>
      </Paper>


      <Paper elevation={3} className={classes.paper} style={{ marginBottom: 20, padding: 10 }}>
        <Grid container spacing={3}>
          <Grid item md={4}>
            <Typography
              style={{
                color: COLORS.greyDark,
                fontSize: 12,
              }}
            >
              Deskripsi
            </Typography>
            {dataRecipe.recipeDescription.length > 1 ? (
              <ReadMore text={dataRecipe.recipeDescription} maxLength={200} />
            ) : (
              <Typography
                variant="body1"
                style={{ fontStyle: 'italic', color: COLORS.grey }}
              >
                Not set
              </Typography>
            )}
            <br />
          </Grid>
          <Grid item md={2}>
            <Typography
              style={{
                color: COLORS.greyDark,
                fontSize: 12,
              }}
            >
              Ingredients, time, porsi
            </Typography>
            <div style={{ display: "flex" }}>
              <Typography style={{
                color: COLORS.greyDark,
              }}>Total ingredients: </Typography>
              <Typography style={{ fontWeight: "bold" }}> {totalIngredients}</Typography>
            </div>
            <div style={{ display: "flex" }}>
              <Typography style={{
                color: COLORS.greyDark,
              }}>Lama Pembuatan: </Typography>
              <Typography style={{ fontWeight: "bold" }}>{`${dataRecipe.recipeCookingDuration.hour > 0 ? `${dataRecipe.recipeCookingDuration.hour} jam` : ''}  ${dataRecipe.recipeCookingDuration.minute > 0 ? `${dataRecipe.recipeCookingDuration.minute} menit` : ''}`}</Typography>
            </div>
            <div style={{ display: "flex" }}>
              <Typography style={{
                color: COLORS.greyDark,
              }}>Jumlah Porsi: </Typography>
              <Typography style={{ fontWeight: "bold" }}>{dataRecipe.recipeCookingPortion}</Typography>
            </div>
          </Grid>
          <Grid item md={2}>
            <Typography
              style={{
                color: COLORS.greyDark,
                fontSize: 12,
              }}
            >
              Like & Save
            </Typography>
            <div style={{ display: "flex" }}>
              <Typography style={{
                color: COLORS.greyDark,
              }}>Jumlah Like: </Typography>
              <Typography style={{ fontWeight: "bold" }}> {dataRecipe.likeCount}</Typography>
            </div>
            <div style={{ display: "flex" }}>
              <Typography style={{
                color: COLORS.greyDark,
              }}>Jumlah Simpan: </Typography>
              <Typography style={{ fontWeight: "bold" }}>{dataRecipe.saveCount}</Typography>
            </div>
          </Grid>
          <Grid item md={2}>
            <Typography
              style={{
                color: COLORS.greyDark,
                fontSize: 12,
              }}
            >
              Tag
            </Typography>
            {dataRecipe.tags > 0 ? dataRecipe.tags.map((x: any) => {
              return (
                <Chip style={{
                  borderRadius: 5,
                  margin: 5
                }}
                  color="default"
                  label={x.recipeTagName}

                />
              )

            }) : (
              <Typography
                variant="body1"
                style={{ fontStyle: 'italic', color: COLORS.grey }}
              >
                Not set
              </Typography>
            )}
          </Grid>


        </Grid>
      </Paper>


      <Grid container spacing={2} style={{ marginBottom: 20 }}>
        <Grid md={4} item>
          <Paper className={classes.paper} style={{ marginBottom: 20, height: "100%", padding: 10 }}>
            <Typography
              style={{
                fontSize: 14,
                fontWeight: "bold"
              }}
            >
              Video
            </Typography>
            {/* iframe */}
            {dataRecipe.recipeVideoUrl ? (
              <div style={{ display: 'flex', height: "100%", width: "100%" }}>
               <div style={{marginLeft: "auto", marginRight: "auto", height: "100%", width: "100%"}}>
                {
                  dataRecipe.recipeVideoType === ERecipeVideoType.youtube && videoUrl.length > 0? (
                    <iframe
                      title="Video Content"
                      style={{ marginLeft: 'auto', marginRight: 'auto' }}
                      src={videoUrl}
                      height="100%"
                      width="100%"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  ) : dataRecipe.recipeVideoType === ERecipeVideoType.tiktok && videoUrl.length > 0? (
                    <TikTokEmbed style={{marginLeft: "auto", marginRight: "auto"}} url={videoUrl ? videoUrl: ""} />
                  ) : (
                    <InstagramEmbed style={{marginLeft: "auto", marginRight: "auto"}} url={dataRecipe.recipeVideoUrl} />
                  )
                }
               </div>
              </div>
            ) : (
              <Typography
                variant="body1"
                style={{ fontStyle: 'italic', color: COLORS.grey }}
              >
                Not set
              </Typography>
            )}
          </Paper>
        </Grid>
        <Grid md={4} item>
          <Paper className={classes.paper} style={{ marginBottom: 20, height: "100%", padding: 10 }}>
            <Typography
              style={{
                fontSize: 14,
                fontWeight: "bold"
              }}
            >
              Ingredients
            </Typography>
            {dataRecipe.recipeIngredients.length > 0 ? dataRecipe.recipeIngredients.map((x) => {
              return (
                <>
                  <Typography
                    style={{
                      marginLeft: 5,
                      fontSize: 14,
                      fontWeight: "bold",
                      marginTop: 10,
                      marginBottom: 10
                    }}
                  >
                    {x.title}
                  </Typography>
                  {x.ingridients.map((y) => {
                    return (
                      <>
                        <Typography
                          style={{
                            fontSize: 14,
                            marginLeft: 10,
                            marginTop: 5,
                            marginBottom: 5

                          }}
                        >
                          {y.description}
                        </Typography>
                        <Divider />
                      </>
                    )
                  })}
                </>

              )
            }) : (
              <Typography
                variant="body1"
                style={{ fontStyle: 'italic', color: COLORS.grey }}
              >
                Not set
              </Typography>
            )}
          </Paper>
        </Grid>
        <Grid md={4} item>
          <Paper className={classes.paper} style={{ marginBottom: 20, padding: 10, height: "100%" }}>
            <Typography
              style={{
                fontSize: 14,
                fontWeight: "bold"
              }}
            >
              Cara Membuat
            </Typography>

            {
              dataRecipe.recipeInstructions.length > 0 ?
                dataRecipe.recipeInstructions.sort((a, b) => a.order - b.order).map((x, y) => {
                  return (
                    <>
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: "bold"
                        }}
                      >
                        Step {y + 1}
                      </Typography>
                      <Typography>
                        {x.description}
                      </Typography>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {x.imageIds?.map((y) => {
                          return (
                            <>
                              <img
                                width={100}
                                height={80}
                                style={{ objectFit: 'contain', marginLeft: 10, marginRight: 10 }}
                                src={y.imageUrl}
                              ></img>
                            </>
                          )
                        })}

                      </div>
                    </>

                  )
                }) : (
                  <Typography
                    variant="body1"
                    style={{ fontStyle: 'italic', color: COLORS.grey }}
                  >
                    Not set
                  </Typography>
                )
            }
          </Paper>
        </Grid>
      </Grid>
      <Paper elevation={3} className={classes.paper} style={{ marginBottom: 20, padding: data ? 10 : 0 }}>
        <Typography
          style={{
            fontSize: 14,
            fontWeight: "bold"
          }}
        >
          Riwayat Status Approval
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CustomizedTable
              data={data}
              headers={headers}
              totalData={pagination.totalData}
              page={pagination.countPage}
              totalPage={pagination.countPage}
              rowsPerPage={pagination.countPage}
              onChange={(newPage: number) => handlePageChange(newPage)}
              onRowsPerPageChange={(value: number) => handlePerPageChange(value)} />
          </Grid>
        </Grid>
      </Paper>

      <Modal
        open={isShowModalApproval}
        onClose={handleCloseModalApproval}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.containerModal}>
          <div className={classes.contentModal}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h5">Approve Recipe</Typography>

              <button onClick={() => handleCloseModalApproval()}>
                <Close />
              </button>
            </Box>

            <Grid container>
              <Grid item xs={12}>
                <Typography>Approve this recipe and the recipe will be published</Typography>
              </Grid>

            </Grid>

            <Grid container>
              <Grid item xs={1}>
                <Checkbox
                  checked={chefChoice}
                  onChange={(e) => setChefChoice(e.target.checked)}
                  inputProps={{ "aria-label": "Mark as Chef Choice" }}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography style={{ marginTop: 8 }}>Mark as Chef Choice</Typography>
              </Grid>
            </Grid>


            <Box display="flex" justifyContent="flex-end">
              <Box>
                <AppButton color="secondary" onClick={handleCloseModalReject}>
                  Cancel
                </AppButton>
              </Box>
              <Box>
                <AppButton loading={isLoading} onClick={() => submit('approved')}>Approve & Publish</AppButton>
              </Box>
            </Box>
          </div>
        </div>
      </Modal>

      <Modal
        open={isShowModalReject}
        onClose={handleCloseModalReject}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.containerModal}>
          <div className={classes.contentModal}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h5">Reject Recipe</Typography>

              <button onClick={() => handleCloseModalReject()}>
                <Close />
              </button>
            </Box>

            <Grid container>
              <Grid item xs={12}>
                <Typography>Are you sure want to reject this recipe?</Typography>
              </Grid>

            </Grid>


            <Grid container>
              <Grid item xs={12}>
                <Typography>Remark</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormInput
                  onChange={(value: any) =>
                    setRemark(value)
                  }
                  type="address"
                  fullWidth
                  required
                  placeholder="Alasan Pembatalan"
                />
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="flex-end">
              <Box>
                <AppButton color="secondary" onClick={handleCloseModalReject}>
                  Cancel
                </AppButton>
              </Box>
              <Box>
                <AppButton onClick={() => submit('rejected')}>Save</AppButton>
              </Box>
            </Box>
          </div>
        </div>
      </Modal>


    </Layout >
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(0, 0, 0, 0),
      padding: 0,
    },
    containerForm: {},
    input: {
      padding: theme.spacing(0, 2, 1, 2),
    },
    row: {
      padding: theme.spacing(1, 0, 1, 0),
    },
    stepper: {
      background: "transparent",
    },
    formStatus: {
      width: "100%",
      margin: theme.spacing(1, 0, 2, 0),
    },
    paperTitle: {
      // padding: theme.spacing(2),
    },
    containerButton: {
      flexDirection: 'row',
      margin: theme.spacing(2, 0, 1, 0),
    },
    containerTable: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    containerModal: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      alihandleCloseModalApprovalgnContent: 'center',
    },
    contentModal: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
      width: 600,
      margin: '0 auto',
      marginTop: '10%',
      borderRadius: 10,
    },
  })
);
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 20,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: COLORS.accent,
    zIndex: 1,
    color: COLORS.primary,
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    borderColor: COLORS.primary,
    borderWidth: 1,
    borderStyle: "solid",
    fontSize: 16,
  },
  active: {
    background: COLORS.primary,
    color: COLORS.accent,
    fontWeight: "bold",
  },
  completed: {
    background: COLORS.primary,
    color: COLORS.accent,
  },
});

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {active || completed ? <CheckCircleIcon /> : <ChevronRight />}
    </div>
  );
}


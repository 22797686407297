import axios from "axios";
import { getHeaders } from "../helpers/auth";
import { PickupPointType } from "../types/pickup-point";

export async function apiFindAllPickupPoint(params: string) {
  const query = params || null;
  const headers = await getHeaders();
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/pickup-point" + query,
    method: "GET",
    headers,
  });
}

export async function apiFindAllPickupPointByAro(
  aroId: string,
  params: string
) {
  const query = params || null;
  const headers = await getHeaders();
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/pickup-point/aro/" + aroId + query,
    method: "GET",
    headers,
  });
}

export async function apiFindOnePickupPoint(pickupPointId: string) {
  const headers = await getHeaders();
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/pickup-point/" + pickupPointId,
    method: "GET",
    headers,
  });
}

export async function apiCreatePickupPoint(data: PickupPointType) {
  const headers = await getHeaders();
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/pickup-point",
    method: "POST",
    headers: headers,
    data,
  });
}

export async function apiUpdatePickupPoint(
  pickupPointId: string,
  data: PickupPointType
) {
  const headers = await getHeaders();
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/pickup-point/" + pickupPointId,
    method: "PATCH",
    headers: headers,
    data,
  });
}

export async function apiDeletePickupPoint(pickupPointId: string) {
  const headers = await getHeaders();
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/pickup-point/" + pickupPointId,
    method: "DELETE",
    headers,
  });
}

export enum ESparepartTransactionStatus {
  CREATED = "created",
  PROCESS = "process",
  SENT = "sent",
  CANCELED = "canceled",
}

export enum SparepartTransactionTypes {
  FIND_ALL_FULFILLMENT_SPAREPART = "FIND_ALL_FULFILLMENT_SPAREPART",
  FIND_ONE_FULFILLMENT_SPAREPART = "FIND_ONE_FULFILLMENT_SPAREPART",
  SET_ONE_FULFILLMENT_SPAREPART = "SET_ONE_FULFILLMENT_SPAREPART",
  SET_FULFILLMENT_SPAREPART_PROCESS_RESULT = "SET_FULFILLMENT_SPAREPART_PROCESS_RESULT",
  FIND_HISTORY_FULFILLMENT_SPAREPART = "FIND_HISTORY_FULFILLMENT_SPAREPART",
  SET_LOADING_FULFILLMENT_SPAREPART = "SET_LOADING_FULFILLMENT_SPAREPART",
  SET_LOADING_PROCESS = "SET_LOADING_PROCESS",
  SET_ERROR_FULFILLMENT_SPAREPART = "SET_ERROR_FULFILLMENT_SPAREPART",
  SET_LAST_QUERY_FULFILLMENT_SPAREPART = "SET_LAST_QUERY_FULFILLMENT_SPAREPART",
}

export type SparepartTransactionItem = {
  transactionId: string;
  invoiceId: string;
  externalId: string;
  transactionMetadata: {
    buyer: any;
    summaryPaymentTransaction: {
      totalPay: number;
    };
  };
  transactionPrice: number;
  status: ESparepartTransactionStatus;
  createdAt: Date;
  updatedAt: Date;
  items: Sparepart[];
  status_logs: SparepartStatusLog[];
};

export type Sparepart = {
  transactionId: string;
  sparepartId: string;
  sparepartMetadata: {
    price: number;
    stock: number;
    createdAt: Date;
    updatedAt: Date;
    productCode: string;
    sparepartId: string;
    sparepartCode: string;
    sparepartName: string;
  };
  price: number;
  qty: number;
  createdAt: Date;
  updatedAt: Date;
};

export const initialSparepartTransactions: SparepartTransactionItem = {
  items: [],
  status: ESparepartTransactionStatus.CREATED,
  externalId: "",
  invoiceId: "",
  status_logs: [],
  transactionId: "",
  transactionMetadata: {
    buyer: { name: "" },
    summaryPaymentTransaction: { totalPay: 0 },
  },
  transactionPrice: 0,
  createdAt: new Date(),
  updatedAt: new Date(),
};

export type SparepartTransactionState = {
  isLoadingTransaction: boolean;
  isLoadingProcess: boolean;
  data: SparepartTransactionResponsePayload;
  transaction: SparepartTransactionItem[];
  error: any;
  lastPathQuery: {
    limit?: number;
    offset?: number;
    search?: string;
    filterStatus?: string;
    orderBy: string;
    startAt?: string;
    endAt?: string;
  };
  isSuccess: boolean;
};

export type SparepartStatusLog = {
  id: number;
  transactionId: string;
  statusBefore: ESparepartTransactionStatus | null;
  statusAfter: ESparepartTransactionStatus;
  updatedBy: string;
  remark: string;
  createdAt: Date;
  updatedAt: Date;
};

export type SparepartTransactionResponsePayload = {
  code: string | null;
  message: string | null;
  payload: {
    count: number;
    next: number | string | null;
    prev: number | string | null;
    results: SparepartTransactionItem[];
  };
};

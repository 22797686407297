import { CustomerResponsePayload } from '../../types/customer.type';
import { ReportReferralTypes } from '../../types/report-referral.type';

export function findAllReportReferral(payload: CustomerResponsePayload) {
  return {
    type: ReportReferralTypes.FIND_ALL_REPORT_REFERRAL,
    payload,
  };
}

export function setLoadingReportReferral(payload: boolean) {
  return {
    type: ReportReferralTypes.LOADING_REPORT_REFERRAL,
    payload,
  };
}

import { RecipeTagResponse, RecipeTagResponsePayload, RecipeTagTypes } from '../../types/recipe-tag.type'


export function findAllRecipeTag(payload: RecipeTagResponsePayload) {
  return {
    type: RecipeTagTypes.FIND_ALL_RECIPE_TAG,
    payload
  }
}

export function findOneRecipeTag(payload: RecipeTagResponse) {
  return {
    type: RecipeTagTypes.FIND_ONE_RECIPE_TAG,
    payload
  }
}

export function createRecipeTag(payload: RecipeTagResponse) {
  return {
    type: RecipeTagTypes.CREATE_RECIPE_TAG,
    payload
  }
}

export function updateRecipeTag(payload: RecipeTagResponse) {
  return {
    type: RecipeTagTypes.UPDATE_RECIPE_TAG,
    payload
  }
}

export function deleteRecipeTag(payload: any) {
  return {
    type: RecipeTagTypes.REMOVE_RECIPE_TAG,
    payload
  }
}

export function setLoadingRecipeTag(payload: boolean) {
  return {
    type: RecipeTagTypes.SET_LOADING_RECIPE_TAG,
    payload
  }
}

export function setErrorRecipeTag(payload: any) {
  return {
    type: RecipeTagTypes.SET_ERROR_RECIPE_TAG,
    payload
  }
}
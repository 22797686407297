import {
  createStyles,
  makeStyles,
  Theme,
  TextField,
  InputAdornment,
  Typography,
  Box,
  Chip,
  FormControl,
  IconButton,
  Input,
  InputLabel,
} from '@material-ui/core';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TagResponse } from '../../types/tag.type';
import COLORS from '../../styles/colors';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    field: {
      marginBottom: theme.spacing(3),
    },
    'ql-container': {
      minHeight: 300
    }
  })
);

type Props = {
  type?:
  | 'username'
  | 'email'
  | 'password'
  | 'phone'
  | 'address'
  | 'number'
  | 'discount'
  | 'currency'
  | 'text'
  | 'tags'
  | 'wyswyg'
  | 'number-only';
  rows?: number;
  fullWidth?: boolean;
  id?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  autoFocus?: boolean;
  multiline?: boolean;
  required?: boolean;

  error?: boolean;
  helperText?: string;

  endAdornment?: any;

  onChange: (value: any) => void;
  value?: any | string;

  style?: any;
  disabled?: any;
  readOnly?: boolean;

  dataTags?: TagResponse[];
};
export default function FormInput(props: Props) {
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false)

  const handleChange = (html: any) => {
    if (props.onChange) props.onChange(html);
  };

  if (props.type === 'wyswyg') {
    if (props.readOnly) {
      return (
        <>
          <Typography variant="caption" display="block" style={{ color: COLORS.grey }}>
            {props.label || props.placeholder} {props.required && '*'}
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: props.value }} />
        </>
      )
    }
    return (
      <Box>
        <Typography style={{ color: COLORS.grey, fontSize: 11, marginBottom: 3 }}>
          {props.label || props.placeholder} {props.required && '*'}
        </Typography>

        <ReactQuill
          style={{ minHeight: 300 }}
          className={classes['ql-container']}
          value={props.value}
          onChange={(content: string, delta: any, source: any, editor: any) =>
            handleChange(content)
          }
        />
      </Box>
    );
  } else if (props.type === 'tags') {
    if (props.readOnly) {
      return (
        <>
          <Typography variant="caption" display="block" style={{ color: COLORS.grey, marginBottom: 8 }}>
            {props.label || props.placeholder} {props.required && '*'}
          </Typography>
          { props.value.map((item: TagResponse, index: number) => (<Chip key={index} label={item.tagName} style={{ marginRight: 8 }} />))}
        </>
      )
    }
    return (
      <Autocomplete
        {...props}
        multiple
        id="tags-standard"
        options={props.dataTags || []}
        getOptionLabel={(option: TagResponse) => option.tagName}
        noOptionsText={'Not found - Press "enter" to Add new item'}
        onChange={(event: any, newValue: TagResponse[]) => props.onChange(newValue)}
        renderInput={(params) => (
          <TextField
            className={classes.field}
            {...params}
            InputLabelProps={{
              shrink: true,
            }}
            variant="standard"
            label="Tags"
            placeholder="Add tag"
            size="small"
            onKeyDown={(e: any) => {
              if (e.nativeEvent.key === "Enter" && e.target.value) {
                props.onChange(e.target.value)
              }
            }}
          />
        )}
      />
    );
  } if (props.type === 'password') {
    return (<FormControl style={{ width: '100%' }}>
      <InputLabel htmlFor="standard-adornment-password" shrink> {props.label || props.placeholder} {props.required && '*'}</InputLabel>
      <Input
        {...props}
        id="standard-adornment-password"
        type={showPassword ? 'text' : 'password'}
        onChange={(e: any) => props.onChange(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>)
  } else {
    return (
      <TextField
        {...props}
        type={
          props.type === 'number-only'
          ? 'text'
          : props.type
        }
        error={props.error}
        helperText={props.error || props.helperText ? props.helperText : null}
        multiline={props.type === 'address' || props.multiline}
        rows={props.rows ? props.rows : props.type === 'address' ? 3 : 1}
        className={classes.field}
        // type={props.type === 'currency' || props.type === 'discount' ? 'number' : props.type}
        InputProps={
          props.readOnly ?
            { readOnly: props.readOnly } :
            props.type === 'discount'
              ? {
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              } :
              props.type === 'currency'
                ? {
                  startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                }
                : {}
        }
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e: any) => {
          if (props.type === 'currency' || props.type === 'discount' || props.type === 'number' || props.type === 'phone' || props.type === 'number-only') {
            if (e.target.value === '') {
              props.onChange(e.target.value)
            }
            const valid = /^\d*\.?\d*$/.test(e.target.value);
            if (valid) {
              const newValue = e.target.value.replace(/[^0-9.]/g, '');
              props.onChange(newValue < 0 ? 0 : newValue)
            }
          } else {
            props.onChange(e.target.value)
          }
        }}
      />
    );
  }
}

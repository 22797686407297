import {
  RecipeCategoryResponse,
  RecipeCategoryState,
  RecipeCategoryTypes,
} from "../../types/recipe-category.type";
import { Reducer } from "redux";

export const initialRecipeCategory = {
  recipeCategoryId: "",
  recipeCategoryName: "",
  imageUrl: "",
};

const initialState: RecipeCategoryState = {
  isLoadingRecipeCategory: false,
  data: {
    code: null,
    message: null,
    payload: {
      next: "",
      prev: "",
      count: 0,
      results: [],
    },
  },
  error: {},
  recipeCategory: { ...initialRecipeCategory },
  isSuccess: false,
};

export const recipeCategory: Reducer<RecipeCategoryState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case RecipeCategoryTypes.FIND_ALL_RECIPE_CATEGORY:
      const results = action.payload.payload.results.map(
        (item: RecipeCategoryResponse) => {
          return {
            ...item,

          };
        }
      );

      return {
        ...state,
        data: {
          ...action.payload,
          payload: {
            ...action.payload.payload,
            results,
          },
        },
      };
    case RecipeCategoryTypes.FIND_ONE_RECIPE_CATEGORY:
      return {
        ...state,
        recipeCategory: action.payload,
      };
    case RecipeCategoryTypes.CREATE_RECIPE_CATEGORY:
      return {
        ...state,
        recipeCategory: action.payload,
        isSuccess: true,
      };
    case RecipeCategoryTypes.UPDATE_RECIPE_CATEGORY:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case RecipeCategoryTypes.REMOVE_RECIPE_CATEGORY:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case RecipeCategoryTypes.SET_LOADING_RECIPE_CATEGORY:
      return {
        ...state,
        isLoadingRecipeCategory: action.payload,
      };
    case RecipeCategoryTypes.SET_ERROR_RECIPE_CATEGORY:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

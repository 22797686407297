import React from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";

type Props = {
  label?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  value?: string
};

export default function Search(props: Props) {
  return (
    <TextField
      {...props}
      id="standard-search"
      label={props.label || "Search"}
      type="search"
      placeholder={props.placeholder || "Search by name"}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      onChange={(event) => {
        props.onChange(event.target.value);
      }}
      value={props.value}
      style={{ width: "100%" }}
    />
  );
}

import { FeedbackResponsePayload, FeedbackTypes, FeedbackResponse } from '../../types/feedback.types'


export function findAllFeedback(payload: FeedbackResponsePayload) {
  return {
    type: FeedbackTypes.FIND_ALL_FEEDBACK,
    payload
  }
}

export function findOneFeedback(payload: FeedbackResponse) {
  return {
    type: FeedbackTypes.FIND_ONE_FEEDBACK,
    payload
  }
}

export function updateFeedback(payload: FeedbackResponse) {
  return {
    type: FeedbackTypes.UPDATE_FEEDBACK,
    payload
  }
}

export function setLoadingFeedback(payload: boolean) {
  return {
    type: FeedbackTypes.SET_LOADING_FEEDBACK,
    payload
  }
}

import { Reducer } from 'redux';
import { AdminSingle, initialUserB2B } from '../../types/admin.type';
import { BranchProperties, initialBranch } from '../../types/b2b/branch/branch.type';
import { DistributorProperties, initialDistributor } from '../../types/b2b/distributor/distributor.type';
import { B2BTypes } from '../action/b2b.action';
import { CustomerProperties, initialCustomer } from '../../types/customer.type';

export interface B2BState {
  oId: string,
  distributor: DistributorProperties
  branch: BranchProperties
  branches: Array<BranchProperties>
  userB2B: AdminSingle
  agent: CustomerProperties
}

const initialState: B2BState = {
  oId: '',
  distributor: initialDistributor,
  branch: initialBranch,
  branches: [],
  userB2B: initialUserB2B,
  agent: initialCustomer
}

export const b2b: Reducer<B2BState> = (state = initialState, action) => {
  switch (action.type) {
    case B2BTypes.SET_B2B_ID:
      console.info(action.payload)
      return {
        ...state,
        oId: action.payload
      }
    case B2BTypes.SET_AGENT:
      return {
        ...state,
        agent: action.payload
      }
    case B2BTypes.SET_BRANCH:
      return {
        ...state,
        branch: action.payload
      }
    default:
      return state
  }
}
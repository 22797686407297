import axios from 'axios';
import { getHeaders } from '../helpers/auth';
import '../helpers/interceptor';
import {
  ISingleAroCustomer,
  IAroBankAccount,
  ISingleAro
} from '../types/aro.type';
import {
  DisbursementProperties
} from '../types/disbursement.type';


export type SingleAroDisbursementAvailableResult = {
  aroId: string;
  distributorId: string;
  customerId: string;
  labelName:string;
  voucherParticipation: boolean;
  createdAt: Date;
  updatedAt: Date;
  customer: ISingleAroCustomer;
  aroBankAccount: IAroBankAccount;
  amount: number,
  transactionIds: string[];
}

export type GetListAroDisbursementAvailableResponse = {
  count: number;
  next: string | null;
  prev: string | null;
  results: SingleAroDisbursementAvailableResult[];
};

export type AroMetadata = {
  aroId: string;
  labelName: string;
  phoneNumber: string;
}

export type AroDisbursementProperties = {
  aroDisbursementId: string;
  disbursementId?: string | null;
  aroId: string;
  aroMetadata: AroMetadata;
  totalPrice: number;
  notaPrice: number;
  remark?: string;
  payoutPrice: number;
  periodStart: Date;
  periodEnd: Date;
  updatedAt?: Date;
  createdAt?: Date;
  aro?: ISingleAro;
  disbursement?: DisbursementProperties;
  aroDisbursementItem?: AroDisbursementItemProperties[];
}

export type AroDisbursementItemProperties = {
  transactionId: string;
  aroDisbursementId: string;
}

export type GetListAroDisbursementHistoryResponse = {
   count: number;
  next: string | null;
  prev: string | null;
  results: AroDisbursementProperties[];
}

export type IAroDisburseDto = {
  aroId: string;
  transactionIds: string[];
  remark?: string;
  notaPrice?: string;
}

export type CreateAroDisbursement = {
  periodStart: Date;
  periodEnd: Date;
  items: IAroDisburseDto[]
}

export type GetSingleAroDisbursement = {
  aroId: string;
  startAt: string;
  endAt: string;
}

export type ExportAvailableAroParams = {
  startAt: string;
  endAt: string;
  aroIds: string[];
}
export type ExportHistoryAroParams = {
  aroDisbursementIds: string[];
}

export async function apiFetchListAroDisbursementAvailable(params: string): Promise<{
  data: {
    code: string;
    message: string;
    payload: GetListAroDisbursementAvailableResponse;
  };
}> {
  const headers = await getHeaders()
  const query = params || null;
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/aro-disbursements'+query,
    method: 'GET',
    headers
  });
}

export async function apiGetSingleAroDisbursementAvailable(params: GetSingleAroDisbursement): Promise<{
  data: {
    code: string;
    message: string;
    payload: SingleAroDisbursementAvailableResult;
  };
}> {
  const headers = await getHeaders()
  const query = params || null;
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/aro-disbursements/'+params.aroId +
    '?startAt='+params.startAt+
    '&endAt='+params.endAt,
    method: 'GET',
    headers
  });
}

export async function apiGetSingleAroDisbursementHistory(aroDisbursementId: string): Promise<{
  data: {
    code: string;
    message: string;
    payload: AroDisbursementProperties;
  };
}> {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/aro-disbursements/history/'+aroDisbursementId,
    method: 'GET',
    headers
  });
}

export async function apiCreateAroDisbursement(data: CreateAroDisbursement): Promise<{
  data: {
    code: string;
    message: string;
    payload: {
      isSuccess: boolean;
    };
  };
}> {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/aro-disbursements',
    method: 'POST',
    headers,
    data
  });
}

export async function apiRecreateAroDisbursement(data: {items: string[]}): Promise<{
  data: {
    code: string;
    message: string;
    payload: {
      isSuccess: boolean;
    };
  };
}> {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/aro-disbursements/recreate',
    method: 'POST',
    headers,
    data
  });
}

export async function apiFetchListAroDisbursementHistory(params: string): Promise<{
  data: {
    code: string;
    message: string;
    payload: GetListAroDisbursementHistoryResponse;
  };
}> {
  const headers = await getHeaders()
  const query = params || null;
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/aro-disbursements/history'+query,
    method: 'GET',
    headers
  });
}

export async function apiExportDisbursementAvailable(
  data: ExportAvailableAroParams
): Promise<any> {
  const headers = await getHeaders();
  const res: {
    data: {
      code: string;
      message: string;
    };
  } = await axios({
    url: process.env.REACT_APP_BASE_URL + '/aro-disbursements/export/xlsx',
    method: 'POST',
    data,
    headers,
    responseType: 'blob',
  });

  return res.data;
}

export async function apiExportDisbursementHistory(
  data: ExportHistoryAroParams
): Promise<any> {
  const headers = await getHeaders();
  const res: {
    data: {
      code: string;
      message: string;
    };
  } = await axios({
    url: process.env.REACT_APP_BASE_URL + '/aro-disbursements/history/export/xlsx',
    method: 'POST',
    data,
    headers,
    responseType: 'blob',
  });

  return res.data;
}
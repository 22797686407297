import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { enableUpdateParams } from '../../redux/action/config-app.action';

type Props = {
  title: string;
  subtitle?: string;
  marginBottom?: number;
  marginLeft?: number;
  showBackButton?: boolean;
  onBack?: () => void;
};

export default function HeaderPage(props: Props) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Box
      alignItems="center"
      style={{
        marginBottom: props.marginBottom ? props.marginBottom : props.showBackButton ? 0 : 10,
        marginLeft: props.showBackButton ? -10 : props.marginLeft ? props.marginLeft : 0,
      }}
    >
      <Box display="flex" alignItems="flex-start">
        {props.showBackButton && (
          <IconButton
            style={{ marginTop: 3 }}
            size="small"
            aria-label="delete"
            onClick={() => {
              dispatch(enableUpdateParams(false))
              if (props.onBack) {
                props.onBack()
              } else {
                history.go(-1)
              }
            }}
          >
            <ChevronLeft className={classes.icon} />
          </IconButton>
        )}
        <Box>
          <Typography variant="h2" component="h2">
            {props.title}
          </Typography>
          {props.subtitle && (
            <Typography variant="subtitle1" color="secondary">
              {props.subtitle}
            </Typography>
          )}
          <Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leftIcon: {
      margin: theme.spacing(0, 2, 0, 0),
      alignItems: 'flex-start',
    },
    icon: { height: 30, width: 30 },
  })
);

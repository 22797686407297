import {
  CollectionResponsePayload,
  CollectionTypes,
} from "../../types/collection.type";

export function findAllCollection(payload: CollectionResponsePayload) {
  return {
    type: CollectionTypes.FIND_ALL_COLLECTION,
    payload,
  };
}

export function updateCollection(payload: CollectionResponsePayload) {
  return {
    type: CollectionTypes.UPDATE_COLLECTION,
    payload,
  };
}

export function setLoadingCollection(payload: boolean) {
  return {
    type: CollectionTypes.SET_LOADING_COLLECTION,
    payload,
  };
}

export function setErrorCollection(payload: any) {
  return {
    type: CollectionTypes.SET_ERROR_COLLECTION,
    payload,
  };
}

import { TagResponse } from './tag.type';

export enum PromotionTypes {
  FIND_ALL_PROMOTION = 'FIND_ALL_PROMOTION',
  FIND_ONE_PROMOTION = 'FIND_ONE_PROMOTION',
  CREATE_PROMOTION = 'CREATE_PROMOTION',
  UPDATE_PROMOTION = 'UPDATE_PROMOTION',
  DELETE_PROMOTION = 'DELETE_PROMOTION',
  SET_LOADING_PROMOTION = 'SET_LOADING_PROMOTION',
  SET_ERROR_PROMOTION = 'SET_ERROR_PROMOTION',
}

export enum NewsTypes {
  FIND_ALL_NEWS = 'FIND_ALL_NEWS',
  FIND_ONE_NEWS = 'FIND_ONE_NEWS',
  CREATE_NEWS = 'CREATE_NEWS',
  UPDATE_NEWS = 'UPDATE_NEWS',
  DELETE_NEWS = 'DELETE_NEWS',
  SET_LOADING_NEWS = 'SET_LOADING_NEWS',
  SET_ERROR_NEWS = 'SET_ERROR_NEWS',
}

export enum ENewsStatus {
  PUBLISHED = 'published',
  UNPUBLISHED = 'unpublished'
}

export enum ENewsType {
  NEWS = 'news',
  PROMOTION = 'promotion'
}

export type NewsRequestImageOnly = {
  newsId: string;
  image: ImageData;
}

export type NewsRequest = {
  newsId?: string;
  image: ImageData;
  title: string;
  status: ENewsStatus;
  content: string;
  tags: TagResponse[];
}

export type NewsResponse = {
  newsId: string | null;
  title: string | null;
  status: ENewsStatus | null;
  content: string | null;
  imageLink: string | null;
  youtubeLink: string | null;
  newsType: ENewsType | null;
  tags: TagResponse[] | null;
}

export type NewsResponsePayload = {
  code: string | null;
  message: string | null;
  payload: {
    next: string;
    prev: string;
    count: number;
    results: NewsResponse[];
  }
}

export type NewsDeleteResponse = {
  results: {
    isSuccess: boolean;
    newsId: string | null;
  }
}

export type PromotionState = {
  isLoadingPromotion: boolean;
  data: NewsResponsePayload;
  promotion: NewsResponse;
  deleteResult: NewsDeleteResponse;
  error: any;
}

export type NewsState = {
  isLoadingNews: boolean;
  data: NewsResponsePayload;
  news: NewsResponse;
  deleteResult: NewsDeleteResponse;
  error: any;
}
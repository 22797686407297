import { IconProps } from './IconProps';

export default function Feedback(props: IconProps) {
  return (
    <svg
      width={props.size || 24}
      height={props.size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 7.5001V13.5001M5.25 7.5001V13.5001M6.75 13.5V21.375C6.75 21.4745 6.78951 21.5698 6.85983 21.6402C6.93016 21.7105 7.02554 21.75 7.125 21.75H9.60938C9.72686 21.75 9.84272 21.7225 9.9476 21.6695C10.0525 21.6166 10.1435 21.5397 10.2132 21.4452C10.2829 21.3506 10.3295 21.241 10.3491 21.1252C10.3687 21.0093 10.3608 20.8905 10.3261 20.7783C9.93328 19.5183 9 18.0975 9 15.75H9.75C9.94891 15.75 10.1397 15.671 10.2803 15.5303C10.421 15.3897 10.5 15.1989 10.5 15V14.25C10.5 14.0511 10.421 13.8603 10.2803 13.7197C10.1397 13.579 9.94891 13.5 9.75 13.5H9M19.1222 2.44791C19.1222 2.44791 15.0609 7.5001 11.25 7.5001H3.75C3.55109 7.5001 3.36032 7.57911 3.21967 7.71977C3.07902 7.86042 3 8.05118 3 8.2501V12.7501C3 12.949 3.07902 13.1398 3.21967 13.2804C3.36032 13.4211 3.55109 13.5001 3.75 13.5001H11.25C15.0609 13.5001 19.1222 18.5734 19.1222 18.5734C19.4063 18.9484 20.25 18.6915 20.25 18.1126V2.90635C20.25 2.32931 19.4531 2.02416 19.1222 2.44791ZM3 12.0001C3 12.0001 2.25 11.7188 2.25 10.5001C2.25 9.28135 3 9.0001 3 9.0001V12.0001ZM21 11.5313C21 11.5313 21.75 11.3284 21.75 10.5001C21.75 9.67181 21 9.46885 21 9.46885V11.5313Z"
        stroke={props.color || '#201D1D'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import axios from 'axios';
import { getHeaders } from '../helpers/auth';
import { StockRequest } from '../types/stock-retail.type';

export async function apiHistoryStockRetail(productId: string, params?: string) {
  const headers = await getHeaders()
  const query = params || null
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/product/' + productId + '/stock-history' + query,
    method: 'GET',
    headers
  });
}

export async function apiFindAllLimitedStock(params?: string) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/product/' + params,
    method: 'GET',
    headers
  });
}

export async function apiAddProductStock(params: StockRequest) {
  const headers = await getHeaders()
  const data = {
    changes: params.changes,
    note: params.note
  }
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/product/' + params.productId + '/add-stock',
    method: 'POST',
    headers,
    data
  });
}

export async function apiRemoveProductStock(params: StockRequest) {
  const headers = await getHeaders()
  const data = {
    changes: params.changes,
    note: params.note
  }
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/product/' + params.productId + '/remove-stock',
    method: 'POST',
    headers,
    data
  });
}
import { ServiceCenterTypes } from "../../types/service-center.types";

export function findAllServiceCenter(payload: any) {
  return {
    type: ServiceCenterTypes.FIND_ALL_SERVICE_CENTER,
    payload,
  };
}

export function findOneServiceCenter(payload: any) {
  return {
    type: ServiceCenterTypes.FIND_ONE_SERVICE_CENTER,
    payload,
  };
}

export function createServiceCenter(payload: any) {
  return {
    type: ServiceCenterTypes.CREATE_SERVICE_CENTER,
    payload,
  };
}
export function updateServiceCenter(payload: any) {
  return {
    type: ServiceCenterTypes.UPDATE_SERVICE_CENTER,
    payload,
  };
}

export function removeServiceCenter(payload: any) {
  return {
    type: ServiceCenterTypes.REMOVE_SERVICE_CENTER,
    payload,
  };
}

export function setLoadingServiceCenter(payload: boolean) {
  return {
    type: ServiceCenterTypes.SET_LOADING_SERVICE_CENTER,
    payload,
  };
}

export function setErrorServiceCenter(payload: any) {
  return {
    type: ServiceCenterTypes.SET_ERROR_SERVICE_CENTER,
    payload,
  };
}

export function setLastQueryServiceCenter(payload: any) {
  return {
    type: ServiceCenterTypes.SET_LAST_QUERY_SERVICE_CENTER,
    payload,
  };
}

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Paper,
  createStyles,
  makeStyles,
  Theme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Box,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import FormInput from "../../components/Form/FormInput";
import AppButton from "../../components/Form/AppButton";
import COLORS from "../../styles/colors";
import { useLocation, useParams } from "react-router-dom";
import UploadImage from "../../components/Form/UploadImage";
import HeaderPage from "../../components/Layout/HeaderPage";
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../redux/store';
import { useHistory } from 'react-router-dom';
import { enableUpdateParams } from '../../redux/action/config-app.action';
import { useAuth } from '../../hooks/useAuth';
import useRecipeTag from "../../hooks/useRecipeTag";
import { initialRecipeTag } from "../../redux/reducer/recipe-tag.reducer";
import { findOneRecipeTag } from "../../redux/action/recipe-tag.action";

export default function RecipeTagEdit() {
  const location = useLocation();
  const history = useHistory();
  let { id } = useParams<{ id: string }>();

  const classes = useStyles();
  const dispatch = useDispatch();
  const auth = useAuth();

  const [mode, setMode] = useState<"detail" | "edit" | "create">("detail");

  const [recipeTagName, setRecipeTagName] = useState<string>('')
  const [file, setFile] = useState<any>(null);

  const recipeTagState = useSelector((state: ApplicationState) => state.recipeTag)

  const { addRecipeTag, updateOneRecipeTag } = useRecipeTag()

  useEffect(() => {
    if (id && location.pathname.includes("edit")) {
      // edit
      setMode("edit");
      setRecipeTagName(recipeTagState.recipeTag.recipeTagName)
    } else if (id) {
      // detail
      setMode("detail");
      setRecipeTagName(recipeTagState.recipeTag.recipeTagName)
    } else {
      // create
      dispatch(findOneRecipeTag(initialRecipeTag))
      setMode("create");
    }
  }, []);

  const cancel = () => {
    dispatch(enableUpdateParams(false))
    history.go(-1)
  };

  const submit = () => {
    let data: any = {
      recipeTagName
    }

    if (id) {
      data = {
        ...data,
        recipeTagId: id
      }
    }


    if (id) {
      updateOneRecipeTag(data)
    } else {
      addRecipeTag(data)
    }

    setRecipeTagName('')
    setFile(null)
  };

  let title = "";
  let subtitle = "";

  if (mode === "create") {
    title = "Create Recipe Tag";
    subtitle = "Create new recipe tag";
  } else if (mode === "edit") {
    title = "Edit Recipe Tag";
    subtitle = "Edit recipe tag";
  } else if (mode === "detail") {
    title = "Detail Recipe Tag";
    subtitle = "Detail recipe tag";
  }

  return (
    <Layout title={title}>
      <Grid container justify="space-between" spacing={3} alignItems="center">
        <Grid item xs={12} md={6} lg={mode === "detail" ? 10 : 8}>
          <HeaderPage title={title} subtitle={subtitle} showBackButton />
        </Grid>

        {(mode === "edit" || mode === "create") && (
          <Grid item xs={6} md={6} lg={2}>
            <AppButton fullWidth color="secondary" onClick={cancel}>
              Cancel
              </AppButton>
          </Grid>
        )}
        {(mode === "edit" || mode === "create") && (
          <Grid item xs={6} md={6} lg={2}>
            <AppButton loading={recipeTagState.isLoadingRecipeTag} fullWidth color="primary" onClick={submit}>
              Save
              </AppButton>
          </Grid>
        )}
        {mode === "detail" && (
          <Grid item xs={12} md={6} lg={2} alignItems="flex-end" justify="flex-end">
            <AppButton onClick={() => setMode("edit")} color="primary" style={{ width: '100%' }}>
              Edit
              </AppButton>
          </Grid>
        )}
      </Grid>

      <Paper elevation={3} className={classes.paper}>
        <Grid container spacing={3} className={classes.containerForm}>
          {/* <Box width="100%" className={classes.input}>
            <Typography>Tag Image</Typography>

            <Card className={classes.cardRoot}>
              <CardActionArea className={classes.cardActionArea}>
                <CardMedia
                  className={classes.cardMedia}
                  image="assets/default_500x500.png"
                  title="Contemplative Reptile"
                  component="img"
                />
              </CardActionArea>
            </Card>

            <Box
              display="flex"
              alignItems="center"
              style={{
                marginTop: 10,
                marginBottom: 10,
                backgroundColor: COLORS.greyLighten,
                borderRadius: 10,
              }}
            >
              <AppButton color="secondary">
                <CloudUploadOutlined />
                &nbsp;&nbsp;&nbsp;Browse Image
              </AppButton>
              <Box flexGrow={1}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ fontStyle: "italic" }}
                >
                  No file selected.
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography variant="subtitle1">
                Recommended image dimension: <b>500x500 px</b>, Max image size:
                <b>1MB</b>, Supported file type are <b>jpg, jpeg and png</b>.
              </Typography>
            </Box>
          </Box> */}
          <Grid container spacing={3} justify="space-between" style={{ marginTop: 16 }}>
            <Grid item xs={12} className={classes.input}>
              <FormInput
                readOnly={mode === 'detail'}
                onChange={(value: any) => setRecipeTagName(value)}
                fullWidth
                required
                placeholder="Recipe Tag name"
                value={recipeTagName}
                label="Recipe Tag Name"
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 0, 0, 0),
      padding: theme.spacing(2),
    },
    containerForm: {
      padding: 20,
    },
    input: {
      padding: theme.spacing(0, 0, 1, 0),
    },
    paperTitle: {
      padding: theme.spacing(2),
    },
    containerButton: {
      flexDirection: "row",
      padding: theme.spacing(2),
    },
    formStatus: {
      width: "100%",
      margin: theme.spacing(0, 0, 2, 0),
    },
    cardRoot: {
      width: "100%",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      backgroundColor: COLORS.greyLighten,
      marginTop: 10,
    },
    cardMedia: {
      height: 200,
      width: 200,
      alignSelf: "center",
      backgroundColor: COLORS.accent,
      borderColor: COLORS.accent,
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 10,
    },
    cardActionArea: {
      display: "flex",
      padding: 10,
    },
  })
);

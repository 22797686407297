import { ITableHeader } from "../../components/Table/CustomizedTable";

/* 
 *
 * Header Sales
 * 
 */
export const headerOrder: ITableHeader[] = [
  {
    title: "Id",
    column: "id",
    type: "id",
  },
  {
    title: "Order ID",
    align: "left",
    column: "orderId",
  },
  {
    title: "SO ID",
    align: "left",
    column: "soId",
  },
  {
    title: "Order Date",
    align: "left",
    column: "orderDate",
  },
  {
    title: "Distributor",
    align: "left",
    column: "distributor",
  },
  {
    title: "Order Note",
    align: "left",
    column: "note",
  },
];

export const headerOrderAll: ITableHeader[] = [
  {
    title: "Id",
    column: "id",
    type: "id",
  },
  {
    title: "Order ID",
    align: "left",
    column: "orderId",
  },
  {
    title: "SO ID",
    align: "left",
    column: "soId",
  },
  {
    title: "Order Date",
    align: "left",
    column: "orderDate",
  },
  {
    title: "Distributor",
    align: "left",
    column: "distributor",
  },
  {
    title: "Order Note",
    align: "left",
    column: "note",
  },
  {
    title: "Order Status",
    align: "left",
    column: "status",
  },
];

export const headerDeliveryOrder: ITableHeader[] = [
  {
    title: "Id",
    column: "id",
    type: "id",
  },
  {
    title: "DO ID",
    align: "left",
    column: "doId",
  },
  {
    title: "SO ID",
    align: "left",
    column: "soId",
  },
  {
    title: "Last Updated",
    align: "left",
    column: "updatedAt",
  },
  {
    title: "DO Note",
    align: "left",
    column: "note",
  },
  {
    title: "SO Note",
    align: "left",
    column: "noteSO",
    width: '20%'
  },
  {
    title: "Lokasi",
    align: "left",
    column: "branch",
  },
  {
    title: "Qty",
    align: "left",
    column: "qty",
  },
];

export const headerDeliveryOrderAll: ITableHeader[] = [
  {
    title: "Id",
    column: "id",
    type: "id",
  },
  {
    title: "DO ID",
    align: "left",
    column: "doId",
  },
  {
    title: "SO ID",
    align: "left",
    column: "soId",
  },
  {
    title: "Last Updated",
    align: "left",
    column: "updatedAt",
  },
  {
    title: "DO Note",
    align: "left",
    column: "note",
  },
  {
    title: "Lokasi",
    align: "left",
    column: "branch",
  },
  {
    title: "Qty",
    align: "left",
    column: "qty",
  },
];

export const headerDetailDeliveryOrderProduct: ITableHeader[] = [
  {
    title: 'id',
    column: 'deliveryOrderItemId',
    type: 'id',
  },
  {
    title: 'Product Code',
    column: 'sku',
  },
  {
    title: 'Name',
    column: 'name',
  },
  {
    title: 'Delivered',
    column: 'qtyDelivered',
  },
  {
    title: 'Diterima',
    column: 'qtyReceived',
  },
]

/* 
 *
 * Header Return
 * 
 */

export const headerSalesReturn: ITableHeader[] = [
  {
    title: "Id",
    column: "id",
    type: "id",
  },
  {
    title: "Retur ID",
    align: "left",
    column: "returnId",
  },
  {
    title: "RO ID",
    align: "left",
    column: "roId",
  },
  {
    title: "Retur Date",
    align: "left",
    column: "returnDate",
  },
  {
    title: "Distributor",
    align: "left",
    column: "distributor",
  },
  {
    title: "Note",
    align: "left",
    column: "note",
  },
];

export const headerSalesReturnAll: ITableHeader[] = [
  {
    title: "Id",
    column: "id",
    type: "id",
  },
  {
    title: "Retur ID",
    align: "left",
    column: "returnId",
  },
  {
    title: "RO ID",
    align: "left",
    column: "roId",
  },
  {
    title: "Retur Date",
    align: "left",
    column: "returnDate",
  },
  {
    title: "Distributor",
    align: "left",
    column: "distributor",
  },
  {
    title: "Lokasi",
    align: "left",
    column: "note",
  },
  {
    title: "Status",
    align: "left",
    column: "status",
  },
];

export const headerPickupOrder: ITableHeader[] = [
  {
    title: "Id",
    column: "id",
    type: "id",
  },
  {
    title: "PO ID",
    align: "left",
    column: "poId",
  },
  {
    title: "RO ID",
    align: "left",
    column: "roId",
  },
  {
    title: "Last Updated",
    align: "left",
    column: "updatedAt",
  },
  {
    title: "PO Note",
    align: "left",
    column: "note",
  },
  {
    title: "Return Note",
    align: "left",
    column: "noteReturn",
    width: '20%'
  },
  {
    title: "Lokasi",
    align: "left",
    column: "branch",
  },
  {
    title: "Qty",
    align: "left",
    column: "qty",
  },
];

export const headerPickupOrderAll: ITableHeader[] = [
  {
    title: "Id",
    column: "id",
    type: "id",
  },
  {
    title: "PO ID",
    align: "left",
    column: "poId",
  },
  {
    title: "RO ID",
    align: "left",
    column: "roId",
  },
  {
    title: "Last Updated",
    align: "left",
    column: "updatedAt",
  },
  {
    title: "Lokasi",
    align: "left",
    column: "branch",
  },
  {
    title: "Qty",
    align: "left",
    column: "qty",
  },
  {
    title: "Status",
    align: "left",
    column: "status",
  },
];

export const headerDetailPickupOrderProduct: ITableHeader[] = [
  {
    title: 'id',
    column: 'pickupOrderItemId',
    type: 'id',
  },
  {
    title: 'Product Code',
    column: 'sku',
  },
  {
    title: 'Name',
    column: 'name',
  },
  {
    title: 'Pickup',
    column: 'qtyPickup',
  },
]

/* 
 * 
 * Header Replacement
 * 
 */

export const headerReplacement: ITableHeader[] = [
  {
    title: "Id",
    column: "id",
    type: "id",
  },
  {
    title: "Rpl. ID",
    align: "left",
    column: "rplId",
  },
  {
    title: "Rpl.O ID",
    align: "left",
    column: "rploId",
  },
  {
    title: "Rpl. Date",
    align: "left",
    column: "rplDate",
  },
  {
    title: "Distributor",
    align: "left",
    column: "distributor",
  },
  {
    title: "Note",
    align: "left",
    column: "note",
  },
];

export const headerReplacementAll: ITableHeader[] = [
  {
    title: "Id",
    column: "id",
    type: "id",
  },
  {
    title: "Rpl. ID",
    align: "left",
    column: "rplId",
  },
  {
    title: "Rpl.O ID",
    align: "left",
    column: "rploId",
  },
  {
    title: "Rpl. Date",
    align: "left",
    column: "rplDate",
  },
  {
    title: "Distributor",
    align: "left",
    column: "distributor",
  },
  {
    title: "Note",
    align: "left",
    column: "note",
  },
  {
    title: "Status",
    align: "left",
    column: "status",
  },
];

export const headerReplacementDelivery: ITableHeader[] = [
  {
    title: "Id",
    column: "id",
    type: "id",
  },
  {
    title: "RPLD ID",
    align: "left",
    column: "rpldId",
  },
  {
    title: "RPLO ID",
    align: "left",
    column: "rploId",
  },
  {
    title: "Last Updated",
    align: "left",
    column: "updatedAt",
  },
  {
    title: "Relp.D Note",
    align: "left",
    column: "note",
  },
  {
    title: "Repl. Note",
    align: "left",
    column: "noteRepl",
    width: '20%'
  },
  {
    title: "Lokasi",
    align: "left",
    column: "branch",
  },
  {
    title: "Qty",
    align: "left",
    column: "qty",
  },
];

export const headerReplacementDeliveryAll: ITableHeader[] = [
  {
    title: "Id",
    column: "id",
    type: "id",
  },
  {
    title: "RPLD ID",
    align: "left",
    column: "rpldId",
  },
  {
    title: "RPLO ID",
    align: "left",
    column: "rploId",
  },
  {
    title: "Last Updated",
    align: "left",
    column: "updatedAt",
  },
  {
    title: "Lokasi",
    align: "left",
    column: "branch",
  },
  {
    title: "Qty",
    align: "left",
    column: "qty",
  },
  {
    title: "Status",
    align: "left",
    column: "status",
  },
];

export const headerReplacementPickup: ITableHeader[] = [
  {
    title: "Id",
    column: "id",
    type: "id",
  },
  {
    title: "RPLP ID",
    align: "left",
    column: "rplpId",
  },
  {
    title: "RPLO ID",
    align: "left",
    column: "rploId",
  },
  {
    title: "Last Updated",
    align: "left",
    column: "updatedAt",
  },
  {
    title: "Relp.P Note",
    align: "left",
    column: "note",
  },
  {
    title: "Repl. Note",
    align: "left",
    column: "noteRepl",
    width: '20%'
  },
  {
    title: "Lokasi",
    align: "left",
    column: "branch",
  },
  {
    title: "Qty",
    align: "left",
    column: "qty",
  },
];

export const headerReplacementPickupAll: ITableHeader[] = [
  {
    title: "Id",
    column: "id",
    type: "id",
  },
  {
    title: "RPLP ID",
    align: "left",
    column: "rplpId",
  },
  {
    title: "RPLO ID",
    align: "left",
    column: "rploId",
  },
  {
    title: "Last Updated",
    align: "left",
    column: "updatedAt",
  },
  {
    title: "Lokasi",
    align: "left",
    column: "branch",
  },
  {
    title: "Qty",
    align: "left",
    column: "qty",
  },
  {
    title: "Status",
    align: "left",
    column: "status",
  },
];

export const headerRplpDetail: ITableHeader[] = [
  {
    title: "RPLP ID",
    align: "left",
    column: "rplpId",
  },
  {
    title: "Last Updated",
    align: "left",
    column: "lastUpdated",
  },
  {
    title: "Status",
    align: "left",
    column: "status",
  },
]

export const headerRpldDetail: ITableHeader[] = [
  {
    title: "RPLD ID",
    align: "left",
    column: "rpldId",
  },
  {
    title: "Last Updated",
    align: "left",
    column: "lastUpdated",
  },
  {
    title: "Status",
    align: "left",
    column: "status",
  },
]

export const headerRPLItemDetail: ITableHeader[] = [
  {
    title: "Product Code",
    align: "left",
    column: "sku",
  },
  {
    title: "Name",
    align: "left",
    column: "name",
  },
  {
    title: "Qty. Requested",
    align: "left",
    column: "qtyReplaced",
  },
  {
    title: "Qty. Approved",
    align: "left",
    column: "qtyApproved",
  },
  {
    title: "Replacement Note",
    align: "left",
    column: "note",
  },
]

export const headerDetailReplacementPickupProduct: ITableHeader[] = [
  {
    title: 'id',
    column: 'rplPickupItemId',
    type: 'id',
  },
  {
    title: 'Product Code',
    column: 'sku',
  },
  {
    title: 'Name',
    column: 'name',
  },
  {
    title: 'Pickup',
    column: 'qtyPickup',
  },
]

export const headerDetailReplacementDeliveryProduct: ITableHeader[] = [
  {
    title: 'id',
    column: 'rplDeliveryItemId',
    type: 'id',
  },
  {
    title: 'Product Code',
    column: 'sku',
  },
  {
    title: 'Name',
    column: 'name',
  },
  {
    title: 'Delivered',
    column: 'qtyDelivered',
  },
  {
    title: 'Diterima',
    column: 'qtyReceived',
  },
]


// SO DETAIL ORDERED PRODUCTS

export const headerSOItemDetail: ITableHeader[] = [
  {
    title: "Product Code",
    align: "left",
    column: "sku",
  },
  {
    title: "Name",
    align: "left",
    column: "name",
  },
  {
    title: "Qty. Ordered",
    align: "left",
    column: "qtyOrdered",
  },
  {
    title: "Qty. Approved",
    align: "left",
    column: "qtyApproved",
  },
  {
    title: "Diterima",
    align: "left",
    column: "qtyReceived",
  },
  {
    title: "Sisa",
    align: "left",
    column: "qtyLeftover",
  },
];

export const headerDODetail: ITableHeader[] = [
  {
    title: "DO ID",
    align: "left",
    column: "doId",
  },
  {
    title: "Last Updated",
    align: "left",
    column: "lastUpdated",
  },
  {
    title: "Status",
    align: "left",
    column: "status",
  },
];

// RO DETAIL PRODUCTS

export const headerSRItemDetail: ITableHeader[] = [
  {
    title: "Product Code",
    align: "left",
    column: "sku",
  },
  {
    title: "Name",
    align: "left",
    column: "name",
  },
  {
    title: "Qty. Requested",
    align: "left",
    column: "qtyReturned",
  },
  {
    title: "Qty. Approved",
    align: "left",
    column: "qtyApproved",
  },
  {
    title: "Retur Note",
    align: "left",
    column: "note",
  },
]

export const headerPODetail: ITableHeader[] = [
  {
    title: "PO ID",
    align: "left",
    column: "poId",
  },
  {
    title: "Last Updated",
    align: "left",
    column: "lastUpdated",
  },
  {
    title: "Status",
    align: "left",
    column: "status",
  },
];


import React, { useEffect, useState } from 'react';
import {
  Button,
  Chip,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from '@material-ui/core';
import Layout from '../../components/Layout/Layout';
import CustomizedTable, {
  ITableHeader,
} from '../../components/Table/CustomizedTable';
import AppButton from '../../components/Form/AppButton';
import HeaderPage from '../../components/Layout/HeaderPage';
import Search from '../../components/Form/Search';
import { ChevronDown, Download } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import useCustomer from '../../hooks/useCustomer';
import useDebounce from '../../hooks/useDebounce';
import { ApplicationState } from '../../redux/store';
import { apiExportCustomer, apiUpdateCustomer } from '../../api/customer';
import { format } from 'date-fns';
import { Refresh, SaveAlt } from '@material-ui/icons';
import { initCustomer } from '../../redux/reducer/customer.reducer';
import COLORS from '../../styles/colors';
import { convertNumToCurrency } from '../../helpers/functions/currency';
import { generateQueryString } from '../../helpers/functions/generateQueryString';
import { useHistory, useLocation } from 'react-router-dom';
import {
  setLatestParams,
  enableUpdateParams,
} from '../../redux/action/config-app.action';
import useReportReferral from '../../hooks/useReportReferral';
import { ReportReferralResponse } from '../../types/report-referral.type';
import FilterDate from '../../components/Form/FilterDate';
import moment from 'moment';

const formatDate = 'YYYY-MM-DD';

const headers: ITableHeader[] = [
  {
    title: 'customerId',
    column: 'customerId',
    type: 'id',
  },
  {
    title: 'Customer Name',
    align: 'left',
    sortable: true,
    column: 'customerName',
  },
  {
    title: 'Referral Code',
    align: 'left',
    column: 'referralCode',
  },
  {
    title: 'Total Downline',
    align: 'left',
    column: 'totalDownline',
  },
];

let data: any = [];

export default function DashboardReferral() {
  const classes = useStyles();

  const referral = useSelector((state: ApplicationState) => {
    return state.reportReferral;
  });

  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingUpdateStatus, setLoadingUpdateStatus] = useState(false);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const getQueryParamAsJSON = (param: string): any | null => {
    const queryParams = new URLSearchParams(location.search);
    const paramValue = queryParams.get(param);
    if (paramValue) {
      try {
        return JSON.parse(paramValue); // Try to parse the JSON string
      } catch (error) {
        console.error('Error parsing JSON from query param:', error);
        return null; // If parsing fails, return null
      }
    }
    return null;
  };

  const setQueryParamAsJSON = (param: string, data: any) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set(param, JSON.stringify(data)); // Convert JSON to string and set the query param
    history.replace({ search: `?${queryParams.toString()}` }); // Update URL
  };

  // const [filterStatus, setFilterStatus] = useState();
  // const [sort, setSort] = useState<any[]>([
  //   {
  //     column: 'customerName',
  //     sort: 'ASC',
  //   },
  //   {
  //     column: 'createdAt',
  //     sort: '',
  //   },
  // ]);
  // const [search, setSearch] = useState<string | null>(null);
  const [query, setQuery] = useState(
    () =>
      getQueryParamAsJSON('q') || {
        limit: 25,
        offset: 0,
        startAt: moment().format(formatDate),
        endAt: moment().format(formatDate),
      }
  );

  const { fetchAllReportReferralSimple } = useReportReferral();

  // const debouncedTitle = useDebounce(search, 300);

  const { updateParams, latestParams } = useSelector(
    (state: ApplicationState) => state.configApp
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [customerSelected, setCustomerSelected] = useState(initCustomer);

  // useEffect(() => {
  //   searchBank(search);
  // }, [debouncedTitle]);

  const searchBank = async (value: any) => {
    if (value !== null && value.length > 0) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          limit: 25,
          offset: 0,
          search: value,
        };
      });
    } else if (value !== null) {
      setQuery({
        limit: 25,
        offset: 0,
        search: '',
        startAt: moment().format(formatDate),
        endAt: moment().format(formatDate),
      });
    }
  };

  async function exportCustomer() {
    try {
      setLoadingExport(true);
      const excel = await apiExportCustomer();

      if (excel.data) {
        const url = window.URL.createObjectURL(new Blob([excel.data]));
        const link = document.createElement('a');
        link.href = url;
        const downloadDate = format(new Date(), 'dd/MM/yyyy');
        link.setAttribute('download', `oxone-customer-${downloadDate}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
        setLoadingExport(false);
      }
    } catch (error) {
      setLoadingExport(false);
    }
  }

  useEffect(() => {
    if (updateParams) {
      // console.log('1');
      dispatch(
        setLatestParams({
          pathname: location.pathname,
          params: generateQueryString(query),
          query: {
            limit: query['limit'],
            offset: query['offset'],
            page,
          },
        })
      );
      fetchAllReportReferralSimple(generateQueryString(query));
    } else {
      // console.log('2');
      setPage(latestParams.query.page || page || 1);
      fetchAllReportReferralSimple(latestParams.params);
      dispatch(enableUpdateParams(true));
    }

    setQueryParamAsJSON('q', query);
  }, [query]);

  useEffect(() => {
    if (referral.datas.payload.count > 0) {
      setTotalData(referral.datas.payload.count);
      setTotalPage(Math.ceil(referral.datas.payload.count / rowsPerPage));
    }
  }, [referral]);

  function changePagination(value: number) {
    setPage(value);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
        limit: rowsPerPage,
      };
    });
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value);
    setPage(1);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: 0,
        limit: value,
      };
    });
  }

  data = referral.datas.payload.results.map((item: ReportReferralResponse) => {
    return {
      ...item,
      totalReferralAmount: item.totalReferralAmount
        ? convertNumToCurrency(item.totalReferralAmount)
        : convertNumToCurrency(0),
    };
  });

  const [dateTemp, setDateTemp] = useState({
    startAt: '',
    endAt: '',
  });

  function refreshData() {
    console.log('refresh data');
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: 0,
        limit: 25,
      };
    });
  }

  useEffect(() => {
    refreshData();

    const intervalId = setInterval(refreshData, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Layout title='Most Active Upline' loading={referral.loading}>
      <HeaderPage
        title='Most Active Upline'
        subtitle='List of most active upline based on total downlines'
      />

      <Grid container spacing={3} justify='space-between'>
        <Grid item xs={12} md={4} lg={4}>
          {/* <Search
            placeholder='Search by name'
            onChange={(value: string) => setSearch(value)}
          /> */}
          <FilterDate
            start_at={dateTemp.startAt || query.startAt}
            end_at={dateTemp.endAt || query.endAt}
            onChange={(start_at: string, end_at: string) =>
              setQuery((val: any) => {
                return {
                  ...val,
                  startAt: moment(start_at).format(formatDate),
                  endAt: moment(end_at).format(formatDate),
                };
              })
            }
          />
        </Grid>
        <Grid item>
          <AppButton
            // loading={referral.loading}
            color='primary'
            // onClick={() => exportCustomer()}
            onClick={() => refreshData()}
          >
            <Refresh />
            <Typography component='span' style={{ marginLeft: 10 }}>
              Refresh
            </Typography>
          </AppButton>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <CustomizedTable
            data={data}
            headers={headers}
            totalData={totalData}
            // showAction={['detail']}
            page={page}
            totalPage={totalPage}
            rowsPerPage={rowsPerPage}
            // sort={sort}
            // onChangeSort={(value) => handleSort(value)}
            onChange={(newPage) => changePagination(newPage)}
            onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
            hidePagination
          />
        </Grid>
      </Grid>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formStatus: {
      width: '100%',
      margin: theme.spacing(1, 0, 2, 0),
      marginBottom: 10,
    },
  })
);

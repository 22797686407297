import { IconProps } from './IconProps';

export default function Discount(props: IconProps) {
  return (
    <svg
      width={props.size || 24}
      height={props.size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.375 7.625L7.625 16.375M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM17 15.4375C17 16.3004 16.3004 17 15.4375 17C14.5746 17 13.875 16.3004 13.875 15.4375C13.875 14.5746 14.5746 13.875 15.4375 13.875C16.3004 13.875 17 14.5746 17 15.4375ZM10.125 8.5625C10.125 9.42545 9.42545 10.125 8.5625 10.125C7.69956 10.125 7 9.42545 7 8.5625C7 7.69956 7.69956 7 8.5625 7C9.42545 7 10.125 7.69956 10.125 8.5625Z"
        stroke={props.color || '#201D1D'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

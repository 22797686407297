import React from 'react'
import { Box, Typography } from '@material-ui/core';
import { ApplicationState } from '../../redux/store';
import { useSelector } from 'react-redux';
import { ECustomerRole } from '../../types/customer.type';
import COLORS from '../../styles/colors';

const DetailAgent: React.FC = () => {

  const { agent } = useSelector((state: ApplicationState) => state.b2b)

  return (
    <>
      <Box style={{ marginBottom: 15 }}>
        <Typography variant="body1" color="textSecondary">
          Name
        </Typography>
        <Typography variant="body1">{agent.customerAgentName}</Typography>
      </Box>
      <Box style={{ marginBottom: 15 }}>
        <Typography variant="body1" color="textSecondary">
          Email
        </Typography>
        <Typography style={{
          fontStyle: !agent.correspondenceEmail ? 'italic' : 'normal',
          color: !agent.correspondenceEmail ? COLORS.greyDark : COLORS.primary
        }} variant="body1">{agent.correspondenceEmail || 'Not set'}</Typography>
      </Box>
      <Box style={{ marginBottom: 15 }}>
        <Typography variant="body1" color="textSecondary">
          Phone
        </Typography>
        <Typography variant="body1">{agent.phoneNumber}</Typography>
      </Box>
      <Box style={{ marginBottom: 15 }}>
        <Typography variant="body1" color="textSecondary">
          Role
        </Typography>
        <Typography variant="body1">{(agent as any).customerRole[1] === ECustomerRole.warehouse ? 'Gudang' : 'Purchaser'}</Typography>
      </Box>
      {agent.branch && (agent as any).customerRole[1] === ECustomerRole.warehouse && (
        <Box style={{ marginBottom: 15 }}>
          <Typography variant="body1" color="textSecondary">
            Branch
          </Typography>
          <Typography variant="body1">{agent.branch.name}</Typography>
        </Box>
      )}
    </>
  )
}

export default DetailAgent
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import { Add, TimerOutlined } from "@material-ui/icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AppButton from "../../components/Form/AppButton";
import FilterDate from "../../components/Form/FilterDate";
import Search from "../../components/Form/Search";
import HeaderPage from "../../components/Layout/HeaderPage";
import Layout from "../../components/Layout/Layout";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import useFetch, { DefaultResponse } from "../../hooks/useFetch";
import { ApplicationState } from "../../redux/store";
import {
  EVoucherDiscountType,
  EVoucherStatus,
  EVoucherType,
  IVoucherQuery,
  IVoucherTags,
  VoucherProperties,
  VoucherTagProperties,
} from "../../types/voucher.type";
import { convertNumToCurrency } from "../../helpers/functions/currency";
import { convertDateTimeToText } from "../../helpers/functions/datetime";
import { setLatestState } from "../../redux/action/config-app.action";
import FormInput from "../../components/Form/FormInput";
import axios from "axios";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { generateQueryString } from "../../helpers/functions/generateQueryString";
import { cancelTokenSource, httpRequest } from "../../helpers/api/api";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "date-fns";
import SimpleCard2 from "../DiscountReferral/components/SimpleCard2";
import VoucherGenerateIcon from "../../components/Icon/VoucherGenerate";
import { Calendar, Download, PieChart } from "react-feather";
import { httpRequestExport } from "../../helpers/api/apiExport";
import { format } from "date-fns";
import { toast } from "react-toastify";

const headers: ITableHeader[] = [
  {
    title: "Code",
    column: "id",
    type: "id",
  },
  {
    title: "Voucher Code",
    column: "voucherCode",
  },
  {
    title: "Event Name",
    column: "eventName",
  },
  {
    title: "Discount Value",
    column: "discountValue",
  },
  {
    title: "Detail Voucher",
    column: "detailVoucher",
  },
  {
    title: "Status",
    column: "voucherStatus",
  },
  {
    title: "Period",
    column: "period",
  },
  {
    title: "Created",
    column: "created",
  },
  {
    title: "Tags",
    column: "tags",
  },
];

const initialQuery: IVoucherQuery = {
  limit: 25,
  page: 1,
  voucherType: EVoucherType.generate,
  tags: ["All"],
};
const date = new Date();

let defaultStartDate: Date | string = new Date(
  date.getFullYear(),
  date.getMonth(),
  1,
  0,
  0,
  0,
  0
);

let defaultEndDate: Date | string = new Date(
  date.getFullYear(),
  date.getMonth() + 1,
  0,
  23,
  59,
  59,
  999
);

const filter = createFilterOptions();

export const VoucherGenerate: React.FC = () => {
  const dispatch = useDispatch();
  const [isShowDialog, setIsShowDialog] = React.useState<boolean>(false);

  const { state: locationState, activeTab: currentActiveTab } = useSelector(
    (state: ApplicationState) => state.configApp
  );
  const {
    data,
    loading,
    pagination,
    query,
    search,
    handlePerPageChange,
    handlePageChange,
    setQuery,
    changeHandler,
    fetchDataTableAlt,
  } = useFetch<VoucherProperties>({
    url: "voucher",
    query: initialQuery,
  });

  const convertStatus = (voucherStatus: EVoucherStatus) => {
    const newStatus =
      voucherStatus.charAt(0).toUpperCase() + voucherStatus.slice(1);

    if (voucherStatus === EVoucherStatus.pending) {
      return (
        <div
          style={{
            paddingTop: 2,
            paddingLeft: 8,
            paddingRight: 8,
            textAlign: "center",
            fontSize: 12,
            display: "inline-block",
            paddingBottom: 2,
            borderRadius: 100,
            border: "1px solid #FABB43",
            backgroundColor: "#ffe9c1",
            color: "#000",
          }}
        >
          {newStatus}
        </div>
      );
    } else if (voucherStatus === EVoucherStatus.available) {
      return (
        <div
          style={{
            paddingTop: 2,
            paddingLeft: 8,
            paddingRight: 8,
            textAlign: "center",
            fontSize: 12,
            display: "inline-block",
            paddingBottom: 2,
            borderRadius: 100,
            border: "1px solid #0AC46B",
            backgroundColor: "#c8fce3",
            color: "#059650",
          }}
        >
          {newStatus}
        </div>
      );
    } else if (voucherStatus === EVoucherStatus.used) {
      return (
        <div
          style={{
            paddingTop: 2,
            paddingLeft: 8,
            paddingRight: 8,
            textAlign: "center",
            fontSize: 12,
            display: "inline-block",
            paddingBottom: 2,
            borderRadius: 100,
            border: "1px solid #2B4BF2",
            backgroundColor: "#cdd5fc",
            color: "#0d29b3",
          }}
        >
          {newStatus}
        </div>
      );
    } else if (voucherStatus === EVoucherStatus.expired) {
      return (
        <div
          style={{
            paddingTop: 2,
            paddingLeft: 8,
            paddingRight: 8,
            textAlign: "center",
            fontSize: 12,
            display: "inline-block",
            paddingBottom: 2,
            borderRadius: 100,
            border: "1px solid #E92548",
            backgroundColor: "#ffc5d0",
            color: "#a50c28",
          }}
        >
          {newStatus}
        </div>
      );
    }
  };

  const dataMapped = data.map((item) => {
    return {
      id: item.voucherCode,
      // eventName: item.event.title,
      eventName: <div>{item.event.title}</div>,
      voucherCode: item.voucherCode,
      discountValue:
        item.discountType === EVoucherDiscountType.nominal
          ? convertNumToCurrency(Number(item.discountValue))
          : `${item.discountValue}%`,
      detailVoucher: (
        <div>
          <div style={{ display: "flex", alignItems: "center", fontSize: 12 }}>
            <div style={{ width: 100 }}>Max. discount:</div>
            <div style={{ flex: 1 }}>
              {item.maxDiscountValue && item.maxDiscountValue > 0
                ? convertNumToCurrency(item.maxDiscountValue)
                : "None"}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", fontSize: 12 }}>
            <div style={{ width: 100 }}>Min. transaction:</div>
            <div style={{ flex: 1 }}>
              {item.requirement.minTotalTransaction &&
              item.requirement.minTotalTransaction > 0
                ? convertNumToCurrency(item.requirement.minTotalTransaction)
                : "None"}
            </div>
          </div>
        </div>
      ),
      voucherStatus: convertStatus(item.voucherStatus),
      period: (
        <div style={{ fontSize: 12 }}>
          <div>
            {convertDateTimeToText(item.validStartAt, "dd mmm yyyy hh:mm")}
          </div>
          <div>
            {convertDateTimeToText(item.validEndAt, "dd mmm yyyy hh:mm")}
          </div>
        </div>
      ),
      created: convertDateTimeToText(item.createdAt, "dd mmm yyyy"),
      tags: item.event.tags.map((tag) => {
        return (
          <Chip
            color="primary"
            size="small"
            key={tag.voucherTagId}
            label={tag.name}
            variant="outlined"
            style={{ marginRight: 5 }}
          />
        );
      }),
    };
  });

  const handleGenerateVoucher = () => {
    setIsShowDialog(true);
  };

  const handleCloseDialog = () => {
    setIsShowDialog(false);
    resetData();
  };

  const handleChangeDiscountType = (value: EVoucherDiscountType) => {
    setVoucherDiscountNominal("");
    setMaxDiscountValue("");
    setVoucherDiscountType(value);
  };

  const [queryTag, setQueryTag] = React.useState<IVoucherTags>({
    search: null,
    published: true,
    exclude: null,
  });
  const [eventName, setEventName] = React.useState("");
  const [eventDescription, setEventDescription] = React.useState("");
  const [voucherDiscountType, setVoucherDiscountType] =
    React.useState<EVoucherDiscountType>(EVoucherDiscountType.percentage);
  const [voucherDiscountNominal, setVoucherDiscountNominal] =
    React.useState("");
  const [maxDiscountValue, setMaxDiscountValue] = React.useState("");
  const [maxDiscountValueType, setMaxDiscountValueType] = React.useState(true);
  const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
  const [tagsData, setTagsData] = React.useState<VoucherTagProperties[]>([]);
  const [valueTags, setValueTags] = React.useState<any>([]);
  const [validStartAt, setValidStartAt] = React.useState<Date | string>(
    new Date()
  );
  const [validEndAt, setValidEndAt] = React.useState<Date | string>(new Date());
  const [limitMaxUsePerPerson, setLimitMaxUsePerPerson] = React.useState("1");
  const [limitMaxUsePerPersonType, setLimitMaxUsePerPersonType] =
    React.useState<number>(1);
  const [minTotalTransaction, setMinTotalTransaction] = React.useState<
    string | null
  >();
  const [minTotalTransactionType, setMinTotalTransactionType] =
    React.useState<boolean>(true);
  const [totalGenerateVoucher, setTotalGenerateVoucher] = React.useState<
    string | null
  >();
  const [loadingGenerate, setLoadingGenerate] = React.useState<boolean>(false);
  const [loadingTags, setLoadingTags] = React.useState<boolean>(false);

  const [availableVoucher, setAvailableVoucher] = React.useState<number>(0);
  const [totalVoucher, setTotalVoucher] = React.useState<number>(0);
  const [totalEvent, setTotalEvent] = React.useState<number>(0);
  const [usageRate, setUsageRate] = React.useState<number>(0);

  const [filterDiscountType, setFilterDiscountType] = React.useState<
    "all" | "nominal" | "percentage"
  >("all");
  const [filterVoucherStatus, setFilterVoucherStatus] = React.useState<
    "all" | "pending" | "available" | "used" | "expired"
  >("all");
  const [filterDataTags, setFilterDataTags] = React.useState<
    VoucherTagProperties[]
  >([]);
  const [filterTags, setFilterTags] = React.useState<string[]>(["All"]);

  const [loadingExportVoucherGenerate, setLoadingVoucherGenerate] =
    React.useState(false);

  const handleDateStartChange = (date: any) => {
    setValidStartAt(date);
  };

  const handleDateEndChange = (date: any) => {
    setValidEndAt(date);
  };

  const handleChangeLimitMaxUsePerPerson = (value: number) => {
    setLimitMaxUsePerPersonType(value);
  };

  const fetchSummaryTotalAvailable = async () => {
    const summaryTotalAvailable = await httpRequest.get<
      DefaultResponse<{ result: number }>
    >(
      "voucher/summary/by-status" +
        generateQueryString({
          status: "available",
        }),
      { cancelToken: cancelTokenSource.token }
    );
    setAvailableVoucher(summaryTotalAvailable.data.payload.result);
  };

  const fetchSummaryTotalVoucher = async () => {
    const summaryTotalVoucher = await httpRequest.get<
      DefaultResponse<{ result: number }>
    >("voucher/summary/total-voucher", {
      cancelToken: cancelTokenSource.token,
    });
    setTotalVoucher(summaryTotalVoucher.data.payload.result);
  };

  const fetchSummaryTotalEvent = async () => {
    const summaryTotalEvent = await httpRequest.get<
      DefaultResponse<{ result: number }>
    >("voucher/summary/total-event", { cancelToken: cancelTokenSource.token });
    setTotalEvent(summaryTotalEvent.data.payload.result);
  };

  const fetchSummaryTotalUsage = async () => {
    const summaryTotalUsage = await httpRequest.get<
      DefaultResponse<{ result: number }>
    >("voucher/summary/usage-rate", { cancelToken: cancelTokenSource.token });
    setUsageRate(summaryTotalUsage.data.payload.result);
  };

  React.useEffect(() => {
    const getSummary = async () => {
      try {
        // get total available
        await fetchSummaryTotalAvailable();

        // get total voucher
        await fetchSummaryTotalVoucher();

        // get total event
        await fetchSummaryTotalEvent();

        // get total usage
        await fetchSummaryTotalUsage();
      } catch (error) {}
    };

    getSummary();
  }, []);

  React.useEffect(() => {
    let active = true;

    setLoadingTags(true);
    setTagsData([]);

    if (openAutocomplete) {
      (async () => {
        try {
          const exclude: string[] = [];

          if (valueTags && valueTags.length > 0) {
            for (const iterator of valueTags) {
              if (iterator.voucherTagId) {
                exclude.push(iterator.voucherTagId);
              }
            }
          }

          const newQuery = generateQueryString({
            ...queryTag,
            exclude,
          });

          const res = await httpRequest.get<
            DefaultResponse<VoucherTagProperties[]>
          >("voucher/tags" + newQuery, {
            cancelToken: cancelTokenSource.token,
          });

          setTagsData(() => {
            return res.data.payload.map((item) => {
              return {
                voucherTagId: item.voucherTagId,
                name: item.name,
              };
            });
          });
        } catch (error) {
          console.error("Failed get data tags", JSON.stringify(error));
        } finally {
          setLoadingTags(false);
        }
      })();
    }

    return () => {
      active = false;
    };
  }, [openAutocomplete]);

  React.useEffect(() => {
    let active = true;

    const fetchDataTags = async () => {
      const res = await httpRequest.get<
        DefaultResponse<VoucherTagProperties[]>
      >("voucher/tags", { cancelToken: cancelTokenSource.token });

      if (active) {
        setFilterDataTags([
          ...[
            {
              voucherTagId: "All",
              name: "All",
            },
          ],
          ...res.data.payload,
        ]);
      }
    };

    fetchDataTags();

    return () => {
      active = false;
    };
  }, []);

  const filterName = (value: string) => {
    const val = value.split(" ");
    if (val && val.length > 1 && val[0] === "Add") {
      return val.slice(1).join(" ");
    } else if (val) {
      return val.join(" ");
    }
  };

  const resetData = () => {
    setEventName("");
    setEventDescription("");
    setVoucherDiscountType(EVoucherDiscountType.percentage);
    setVoucherDiscountNominal("");
    setMaxDiscountValue("");
    setMaxDiscountValueType(true);
    setOpenAutocomplete(false);
    setTagsData([]);
    setValueTags([]);
    setValidStartAt(new Date());
    setValidEndAt(new Date());
    setLimitMaxUsePerPerson("");
    setLimitMaxUsePerPersonType(1);
    setMinTotalTransactionType(true);
    setMinTotalTransaction(null);
    setTotalGenerateVoucher(null);
    setLoadingGenerate(false);
    setIsShowDialog(false);
  };

  const submit = async () => {
    const data = {
      dataEvent: {
        title: eventName,
        description: eventDescription,
        tags: valueTags.map((item: VoucherTagProperties) => {
          if (item.voucherTagId) {
            return {
              ...item,
              published: true,
            };
          } else {
            return {
              ...item,
            };
          }
        }),
      },
      dataVoucher: {
        voucherType: EVoucherType.generate,
        discountType: voucherDiscountType,
        discountValue: Number(voucherDiscountNominal),
        maxDiscountValue: maxDiscountValueType ? Number(maxDiscountValue) : 0,
        requirement: {
          minTotalTransaction: minTotalTransactionType
            ? Number(minTotalTransaction)
            : 0,
        },
        limitMaxUsePerPerson: Number(limitMaxUsePerPerson),
        // limitMaxUseUser: number,
        validStartAt: validStartAt,
        validEndAt: validEndAt,
        totalGenerateVoucher: Number(totalGenerateVoucher),
      },
    };
    try {
      setLoadingGenerate(true);

      await httpRequest.post<DefaultResponse<VoucherProperties[]>>(
        "voucher",
        data
      );
      await fetchDataTableAlt(cancelTokenSource);

      await fetchSummaryTotalAvailable();
      await fetchSummaryTotalVoucher();
      await fetchSummaryTotalEvent();
      await fetchSummaryTotalUsage();

      resetData();
    } catch (error) {
      console.error("Failed generate voucher", error);
    } finally {
      setLoadingGenerate(false);
    }
  };

  const exportVoucherGenerate = async () => {
    try {
      setLoadingVoucherGenerate(true);

      const res: any = await httpRequestExport.post(
        "voucher/export-voucher-generate/xlsx",
        {
          voucherType: EVoucherType.generate,
          filter: {
            ...query,
            startAt: query.startAt || defaultStartDate,
            endAt: query.endAt || defaultEndDate,
          },
        }
      );

      if (res) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        const downloadDate = format(new Date(), "dd/MM/yyyy");
        link.setAttribute(
          "download",
          `oxone-voucher-generate-${downloadDate}.xlsx`
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
        setLoadingVoucherGenerate(false);
      }
    } catch (error) {
      if ((error as { status: number }).status === 404) {
        toast.error("No vouchers data found", {
          position: toast.POSITION.TOP_CENTER,
          pauseOnHover: false,
        });
      }
      console.error("Error exportVoucherGenerate", JSON.stringify(error));
      setLoadingVoucherGenerate(false);
    }
  };

  console.info(locationState);

  return (
    <Layout loading={loading} title="Voucher Generate">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <HeaderPage
          title="Voucher Generate"
          subtitle="List of all voucher generate"
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <AppButton
            onClick={handleGenerateVoucher}
            variant="contained"
            color="primary"
            startIcon={<Add />}
          >
            Generate Voucher
          </AppButton>
          <AppButton
            loading={loadingExportVoucherGenerate}
            onClick={() => exportVoucherGenerate()}
            variant="contained"
            color="primary"
            startIcon={<Download size={20} />}
          >
            Export
          </AppButton>
        </div>
      </div>

      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <SimpleCard2
            loading={false}
            title="Available Voucher"
            value={String(availableVoucher)}
            topRightIconComponent={
              <VoucherGenerateIcon color="#000" size={18} />
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SimpleCard2
            loading={false}
            title="Total Voucher"
            value={String(totalVoucher)}
            topRightIconComponent={
              <VoucherGenerateIcon color="#000" size={18} />
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SimpleCard2
            loading={false}
            title="Total Event"
            value={String(totalEvent)}
            topRightIconComponent={<Calendar color="#000" size={18} />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SimpleCard2
            loading={false}
            title="Usage Rate"
            value={`${usageRate ? usageRate.toFixed(2) + "%" : "0%"}`}
            topRightIconComponent={<PieChart color="#000" size={18} />}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={12} md={3} style={{ marginTop: 15 }}>
          <Search
            placeholder="Search by Voucher Code, Event Name"
            onChange={changeHandler}
            value={
              locationState && locationState.search
                ? locationState.search
                : search
            }
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <FormControl style={{ width: "100%", marginTop: 15 }}>
            <InputLabel id="select-status" shrink>
              Type
            </InputLabel>
            <Select
              label="Status"
              placeholder="Status"
              labelId="select-status"
              id="demo-customized-select"
              value={filterDiscountType}
              onChange={(event: any) => {
                const value = event.target.value;
                setFilterDiscountType(value);
                if (value === "all") {
                  setQuery((oldVal: IVoucherQuery) => {
                    const { discountType, ...rest } = query;
                    return {
                      ...rest,
                    };
                  });
                } else {
                  setQuery((oldVal: IVoucherQuery) => {
                    return {
                      ...oldVal,
                      discountType: value,
                    };
                  });
                }
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="nominal">Nominal (Rp)</MenuItem>
              <MenuItem value="percentage">Percentage (%)</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2}>
          <FormControl style={{ width: "100%", marginTop: 15 }}>
            <InputLabel id="select-status" shrink>
              Status
            </InputLabel>
            <Select
              label="Status"
              placeholder="Status"
              labelId="select-status"
              id="demo-customized-select"
              value={filterVoucherStatus}
              onChange={(event: any) => {
                const value = event.target.value;
                setFilterVoucherStatus(value);
                if (value === "all") {
                  setQuery((oldVal: IVoucherQuery) => {
                    const { voucherStatus, ...rest } = query;
                    return {
                      ...rest,
                    };
                  });
                } else {
                  setQuery((oldVal: IVoucherQuery) => {
                    return {
                      ...oldVal,
                      voucherStatus: value,
                    };
                  });
                }
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="available">Available</MenuItem>
              <MenuItem value="used">Used</MenuItem>
              <MenuItem value="expired">Expired</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2}>
          <FormControl style={{ width: "100%", marginTop: 15 }}>
            <InputLabel id="select-status" shrink>
              Tags
            </InputLabel>
            <Select
              label="Tag"
              placeholder="Choose tag"
              multiple
              labelId="select-status"
              id="demo-customized-select"
              value={filterTags}
              defaultValue={filterDataTags[0]}
              onChange={(event: any) => {
                const value = event.target.value;

                if (value.includes("All")) {
                  setFilterTags(value);
                  setQuery((oldVal: IVoucherQuery) => {
                    const { tags, ...rest } = query;
                    return {
                      ...oldVal,
                    };
                  });
                } else {
                  setFilterTags(value);
                  setQuery((oldVal: IVoucherQuery) => {
                    return {
                      ...oldVal,
                      tags: value,
                    };
                  });
                }
              }}
            >
              {filterDataTags.map((item) => (
                <MenuItem value={String(item.voucherTagId)}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3} style={{ marginTop: 15 }}>
          <FilterDate
            start_at={
              locationState && locationState.startAt
                ? locationState.startAt
                : query.startAt
            }
            end_at={
              locationState && locationState.endAt
                ? locationState.endAt
                : query.endAt
            }
            onChange={(start_at: string, end_at: string) => {
              setQuery((val: any) => {
                return {
                  ...val,
                  startAt: start_at,
                  endAt: end_at,
                };
              });
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CustomizedTable
            data={dataMapped}
            headers={headers}
            totalData={pagination.totalData}
            showAction={["detail"]}
            onPressDetail={() => {
              // dispatch(setLatestState(query))
            }}
            onPressEdit={(item: VoucherProperties) => {
              console.info(item);
            }}
            page={pagination.page}
            totalPage={pagination.countPage}
            rowsPerPage={pagination.perPage}
            onPressEditDialog={(data: any) => {}}
            onChange={(newPage: number) => handlePageChange(newPage)}
            onRowsPerPageChange={(value: number) => handlePerPageChange(value)}
          />
        </Grid>
      </Grid>

      <Dialog
        fullWidth
        maxWidth={"lg"}
        open={isShowDialog}
        onClose={handleCloseDialog}
        scroll={"body"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Create Voucher Generate
        </DialogTitle>
        <DialogContent>
          <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
            <Grid item md={7}>
              <Grid item xs={12}>
                <FormInput
                  onChange={(value: any) => setEventName(value)}
                  value={eventName}
                  fullWidth
                  label="Event Name"
                  placeholder="Tulis nama event disini"
                />
              </Grid>

              <Grid item xs={12}>
                <FormInput
                  onChange={(value: any) => setEventDescription(value)}
                  value={eventDescription}
                  type="address"
                  multiline
                  fullWidth
                  label="Event Description"
                  placeholder="Tulis deskripsi event disini"
                />
              </Grid>

              <Grid item xs={12} style={{ marginBottom: 20 }}>
                <Autocomplete
                  loading={loadingTags}
                  onChange={(event, newValue: any) => {
                    const foundFiltered = newValue.map(
                      (item: VoucherTagProperties) => {
                        if (item.name.includes("Add")) {
                          return {
                            voucherTagId: null,
                            name: filterName(item.name),
                          };
                        } else {
                          return {
                            ...item,
                          };
                        }
                      }
                    );
                    setValueTags(foundFiltered);
                  }}
                  filterOptions={(options: any, params: any) => {
                    const filtered: any = filter(options, params);

                    // Suggest the creation of a new value
                    if (params.inputValue !== "") {
                      filtered.push({
                        name: `Add ${params.inputValue}`,
                        voucherTagId: null,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="free-solo-with-text-demo"
                  options={tagsData}
                  getOptionLabel={(option: any) => {
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.name;
                  }}
                  // renderOption={(option: any) => option.title}
                  freeSolo
                  renderInput={(params) => {
                    return (
                      <>
                        <InputLabel id="select-status" shrink={true}>
                          Tags
                        </InputLabel>
                        <TextField
                          {...params}
                          // label="Add tag"
                          variant="standard"
                          // helperText="Some important text"
                        />
                      </>
                    );
                  }}
                  // multiple
                  multiple
                  onOpen={() => setOpenAutocomplete(true)}
                  onClose={() => setOpenAutocomplete(false)}
                  renderTags={(values: VoucherTagProperties[], getTagProps) => {
                    return values.map(
                      (option: VoucherTagProperties, index: number) => (
                        <Chip
                          variant="outlined"
                          label={filterName(option.name)}
                          {...getTagProps({ index })}
                        />
                      )
                    );
                  }}
                />
              </Grid>

              <Grid xs={12}>
                <InputLabel id="select-status" shrink={true}>
                  Discount Type
                </InputLabel>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: 20,
                    }}
                  >
                    <Radio
                      style={{ paddingLeft: 0 }}
                      color="primary"
                      onClick={() =>
                        handleChangeDiscountType(
                          EVoucherDiscountType.percentage
                        )
                      }
                      checked={
                        voucherDiscountType === EVoucherDiscountType.percentage
                      }
                    />
                    <div>Persen</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Radio
                      style={{ paddingLeft: 0 }}
                      color="primary"
                      onClick={() =>
                        handleChangeDiscountType(EVoucherDiscountType.nominal)
                      }
                      checked={
                        voucherDiscountType === EVoucherDiscountType.nominal
                      }
                    />
                    <div>Nominal</div>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} style={{ marginTop: 20 }}>
                {voucherDiscountType === EVoucherDiscountType.percentage && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      flex: 1,
                    }}
                  >
                    <Grid item md={6}>
                      <InputLabel id="select-status" shrink={true}>
                        Discount Value
                      </InputLabel>
                      <Input
                        style={{ width: "100%" }}
                        disabled={
                          voucherDiscountType !==
                          EVoucherDiscountType.percentage
                        }
                        endAdornment={
                          <InputAdornment position="end">%</InputAdornment>
                        }
                        value={
                          voucherDiscountType ===
                          EVoucherDiscountType.percentage
                            ? voucherDiscountNominal
                            : ""
                        }
                        onChange={(
                          value: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setVoucherDiscountNominal(value.target.value);
                        }}
                      />
                    </Grid>

                    <Grid item md={1}></Grid>

                    <Grid item md={5}>
                      <InputLabel id="select-status" shrink={true}>
                        Max. Discount
                      </InputLabel>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flex: 1,
                        }}
                      >
                        <Radio
                          disableRipple
                          disableTouchRipple
                          disableFocusRipple
                          style={{ paddingLeft: 0 }}
                          color="primary"
                          onClick={() => setMaxDiscountValueType(true)}
                          checked={maxDiscountValueType}
                        />
                        <Input
                          disabled={!maxDiscountValueType}
                          style={{ width: "100%" }}
                          startAdornment={
                            <InputAdornment position="start">Rp</InputAdornment>
                          }
                          value={maxDiscountValue}
                          onChange={(
                            value: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setMaxDiscountValue(value.target.value);
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flex: 1,
                        }}
                      >
                        <Grid item md={5}>
                          <Radio
                            disableRipple
                            disableTouchRipple
                            style={{ paddingLeft: 0 }}
                            color="primary"
                            onClick={() => {
                              setMaxDiscountValueType(false);
                              setMaxDiscountValue("");
                            }}
                            checked={!maxDiscountValueType}
                          />
                          <div
                            style={{
                              width: 60,
                              display: "inline-block",
                            }}
                          >
                            Tidak ada
                          </div>
                        </Grid>
                      </div>
                    </Grid>
                  </div>
                )}

                {voucherDiscountType === EVoucherDiscountType.nominal && (
                  <Grid item md={12}>
                    <InputLabel id="select-status" shrink={true}>
                      Discount Value
                    </InputLabel>
                    <Input
                      style={{ width: "50%" }}
                      disabled={
                        voucherDiscountType !== EVoucherDiscountType.nominal
                      }
                      value={
                        voucherDiscountType !== EVoucherDiscountType.nominal
                          ? ""
                          : voucherDiscountNominal
                      }
                      onChange={(
                        value: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (value.target.value === "") {
                          setVoucherDiscountNominal(value.target.value);
                        }
                        const valid = /^\d*\.?\d*$/.test(value.target.value);
                        if (valid) {
                          const newValue = value.target.value.replace(
                            /[^0-9.]/g,
                            ""
                          );
                          setVoucherDiscountNominal(newValue);
                        }
                      }}
                      startAdornment={
                        <InputAdornment position="start">Rp</InputAdornment>
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item md={4}>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <KeyboardDatePicker
                      autoOk
                      disableToolbar
                      variant="inline"
                      format="dd MMM yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Date Start"
                      value={validStartAt}
                      onChange={handleDateStartChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      style={{ marginTop: 0 }}
                      minDate={new Date()}
                    />
                    <KeyboardTimePicker
                      autoOk
                      margin="normal"
                      id="time-picker"
                      label="Time Start"
                      ampm={false}
                      value={validStartAt}
                      onChange={handleDateStartChange}
                      clearable
                      style={{ width: 100, marginLeft: 10, marginTop: 0 }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <KeyboardDatePicker
                      autoOk
                      disableToolbar
                      variant="inline"
                      format="dd MMM yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Date End"
                      value={validEndAt}
                      onChange={handleDateEndChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      style={{ marginTop: 0 }}
                      minDate={validStartAt}
                    />
                    <KeyboardTimePicker
                      autoOk
                      margin="normal"
                      id="time-picker"
                      label="Time End"
                      ampm={false}
                      value={validEndAt}
                      onChange={handleDateEndChange}
                      clearable
                      style={{ width: 100, marginLeft: 10, marginTop: 0 }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
              </Grid>

              {/* <Grid item xs={12} style={{ marginTop: 20 }}>
                <InputLabel id="select-status" shrink={true} style={{ marginBottom: 6 }}>
                  Batas pemakaian / user
                </InputLabel>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                  }}
                >
                  <Radio
                    style={{ paddingLeft: 0 }}
                    color="primary"
                    onClick={() => handleChangeLimitMaxUsePerPerson(1)}
                    checked={limitMaxUsePerPersonType === 1}
                  />
                  <Input
                    disabled={limitMaxUsePerPersonType !== 1}
                    value={limitMaxUsePerPerson}
                    onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                      setLimitMaxUsePerPerson(value.target.value)
                    }}
                    style={{
                      width: 100
                    }}
                  />
                  <div style={{
                    width: 60,
                    display: 'inline-block',
                    color: limitMaxUsePerPersonType !== 1 ? "#979797" : '#000'
                  }}>Kali</div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                  }}
                >
                  <Grid item md={5}>
                    <Radio
                      style={{ paddingLeft: 0 }}
                      color="primary"
                      onClick={() => handleChangeLimitMaxUsePerPerson(0)}
                      checked={limitMaxUsePerPersonType === 0}
                    />
                    <div style={{
                      width: 60,
                      display: 'inline-block'
                    }}>Tidak ada</div>
                  </Grid>
                </div>
              </Grid> */}

              <Grid item xs={12} style={{ marginTop: 20 }}>
                <InputLabel
                  id="select-status"
                  shrink={true}
                  style={{ marginBottom: 6 }}
                >
                  Min. Transaction
                </InputLabel>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <Radio
                    style={{ paddingLeft: 0 }}
                    color="primary"
                    onClick={() => setMinTotalTransactionType(true)}
                    checked={minTotalTransactionType}
                  />

                  <Input
                    disabled={!minTotalTransactionType}
                    value={minTotalTransaction}
                    startAdornment={
                      <InputAdornment position="start">Rp</InputAdornment>
                    }
                    onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                      setMinTotalTransaction(value.target.value);
                    }}
                    style={{
                      width: "100%",
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <Grid item md={5}>
                    <Radio
                      style={{ paddingLeft: 0 }}
                      color="primary"
                      onClick={() => {
                        setMinTotalTransaction("");
                        setMinTotalTransactionType(false);
                      }}
                      checked={!minTotalTransactionType}
                    />
                    <div
                      style={{
                        width: 60,
                        display: "inline-block",
                      }}
                    >
                      Tidak ada
                    </div>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <FormInput
                  onChange={(value: any) => setTotalGenerateVoucher(value)}
                  value={totalGenerateVoucher}
                  fullWidth
                  type="number"
                  label="Total Generate"
                  placeholder="Tulis jumlah voucher"
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <AppButton
            disabled={loadingGenerate}
            color="secondary"
            onClick={handleCloseDialog}
          >
            Cancel
          </AppButton>
          <AppButton
            disabled={loadingGenerate}
            loading={loadingGenerate}
            onClick={() => submit()}
          >
            Generate Voucher
          </AppButton>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default VoucherGenerate;

import { useDispatch } from 'react-redux';
import { apiFindAllBankApproval, apiUpdateBankApproval } from '../api/bank-approval';
import { generateQueryString } from '../helpers/functions/generateQueryString';
import { setLoadingBankApproval, findAllBankApproval } from '../redux/action/bank-approval.action';

export default function useBankApproval() {
  const dispatch = useDispatch()

  const fetchAllBankApproval = async (params: any) => {
    try {
      dispatch(setLoadingBankApproval(true))

      let query = generateQueryString(params)
      const result = await apiFindAllBankApproval(query)

      dispatch(findAllBankApproval(result.data))

      dispatch(setLoadingBankApproval(false))
    } catch (error) {
      dispatch(setLoadingBankApproval(false))
    }
  }


  const updateBankApproval = async (data: any) => {
    try {
      dispatch(setLoadingBankApproval(true))

      await apiUpdateBankApproval(data)

      await fetchAllBankApproval({ limit: 25, offset: 0, status: 'pending' })

      dispatch(setLoadingBankApproval(false))
    } catch (error) {
      dispatch(setLoadingBankApproval(false))
    }
  }

  return {
    fetchAllBankApproval,
    updateBankApproval
  }
}
import {
  Box,
  Typography,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import React from "react";
import COLORS from "../../styles/colors";

type Data = {
  label?: string | null;
  value?: any;
  url?: string | null;
  leftIconComponent?: any;
  signature?: boolean;
};

type Props = {
  itemPerRow?: number;
  data: Array<Data>;
  showTitle?: boolean;
};

export default function GeneralInformation(props: Props) {
  const classes = useStyles();

  return (
    <div>
      {props.showTitle && (
        <Typography variant="h5">General Information</Typography>
      )}

      <Box display="flex" flexWrap="wrap" className={classes.boxRow}>
        {props.data.map((item, index) =>
          item.label || item.value ? (
            <Box
              display="flex"
              flexDirection="row"
              key={index}
              width={props.itemPerRow ? 100 / props.itemPerRow + "%" : "25%"}
              className={classes.boxItem}
            >
              {item.leftIconComponent ? (
                <Box width={40}>{item.leftIconComponent}</Box>
              ) : (
                  false
                )}
              <Box>
                {item.label ? (
                  <Typography variant="body1" color="textSecondary">
                    {item.label}
                  </Typography>
                ) : (
                    false
                  )}
                {
                  item.signature ? (
                    <img src={item.value} style={{
                      width: 150,
                      border: '1px solid #ddd',
                      borderRadius: 10
                    }} />
                  ) : item.url && item.value ? (
                    <a href={item.url} target="_blank" rel="noreferrer">
                      <Typography variant="body1">{item.value}</Typography>
                    </a>
                  ) : item.value ? (
                    <Typography variant="body1">{item.value}</Typography>
                  ) : (
                          <Typography
                            variant="body1"
                            style={{ fontStyle: 'italic', color: COLORS.grey }}
                          >
                            Not available yet
                          </Typography>
                        )}
              </Box>
            </Box>
          ) : (
              <Box key={index} width="25%" className={classes.boxItem} />
            )
        )}
      </Box>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxRow: {
      padding: theme.spacing(2, 0, 2, 0),
    },
    boxItem: {
      margin: theme.spacing(0, 0, 2, 0),
    },
    signatureContainer: {
      maxWidth: "100%",
      maxHeight: 200,
    },
  })
);

export enum ERecipeVideoType {
    tiktok = 'tiktok',
    instagram_feed = 'instagram',
    youtube = 'youtube',
}

export enum ERecipeStatus {
    draft = 'draft',
    waiting_approval = 'waiting_approval',
    approved = 'approved',
    rejected = 'rejected',
}

export interface IRecipeIngridientProperties {
    id: string;
    title: string;
    ingridients: {
        order: number;
        description: string;
    }[];
}

export interface IRecipeInstructionProperties {
    id: string;
    description: string;
    order: number;
    imageIds?: {
        fileId: string;
        imageUrl: string;
    }[];
}

export interface IRecipeCookingDurationProperties {
    hour: number;
    minute: number;
}

export type RecipeProperties = {
    recipeId: string,
    customerId: string,
    recipeName: string,
    recipeDescription: string,
    recipeCategoryId: string,
    recipeCookingDuration: IRecipeCookingDurationProperties,
    recipeTotalCookingDuration: number,
    recipeCookingPortion: number,
    recipeVideoType: ERecipeVideoType,
    recipeVideoUrl: string,
    recipeStatus: ERecipeStatus,
    recipeRemark: string,
    recipeImage: any,
    recipeIngredients: IRecipeIngridientProperties[],
    recipeInstructions: IRecipeInstructionProperties[],
    isChefChoice: boolean,
    likeCount: number,
    saveCount: number,
    createdAt: Date,
    updatedAt: Date,
    publishedAt: Date,
    categories: any,
    tags: any,
    customer: any;
}

export type RecipeLogsProperties = {
    recipeLogId: string,
    recipeId: string,
    recipeStatusAfter: ERecipeStatus,
    recipeStatusBefore: ERecipeStatus,
    updateByName: string,
    updateByRoleId: string,
    remark?: string,
    metadata?: any,
    createdAt: Date,
    updatedAt: Date,
}

export const initialRecipe: RecipeProperties = {
    categories: '',
    createdAt: new Date(),
    customer: '',
    customerId: '',
    isChefChoice: true,
    likeCount: 0,
    publishedAt: new Date(),
    recipeCategoryId: '',
    recipeCookingPortion: 0,
    recipeCookingDuration: {
        hour: 0,
        minute: 1
    },
    recipeDescription: '',
    recipeId: '',
    recipeImage: '',
    recipeIngredients: [{
        id: '',
        ingridients: [],
        title: ''
    }],
    recipeInstructions: [],
    recipeName: '',
    recipeRemark: '',
    recipeStatus: ERecipeStatus.draft,
    recipeTotalCookingDuration: 0,
    recipeVideoType: ERecipeVideoType.instagram_feed,
    recipeVideoUrl: '',
    saveCount: 0,
    tags: [],
    updatedAt: new Date()
}


export enum RecipeTypes {
  FIND_ALL_RECIPE = 'FIND_ALL_RECIPE',
  FIND_ONE_RECIPE = 'FIND_ONE_RECIPE',
  CREATE_RECIPE = 'CREATE_RECIPE',
  UPDATE_RECIPE = 'UPDATE_RECIPE',
  REMOVE_RECIPE = 'REMOVE_RECIPE',
  SET_LOADING_RECIPE = 'SET_LOADING_RECIPE',
  SET_ERROR_RECIPE = 'SET_ERROR_RECIPE',
  SET_LAST_PATH_RECIPE = 'SET_LAST_PATH_RECIPE',
}

export type RecipeResponse = {
    recipeId: string,
    customerId: string,
    recipeName: string,
    recipeDescription: string,
    recipeCategoryId: string,
    recipeCookingDuration: IRecipeCookingDurationProperties,
    recipeTotalCookingDuration: number,
    recipeCookingPortion: number,
    recipeVideoType: ERecipeVideoType,
    recipeVideoUrl: string,
    recipeStatus: ERecipeStatus,
    recipeRemark: string,
    recipeImage: any,
    recipeIngredients: IRecipeIngridientProperties[],
    recipeInstructions: IRecipeInstructionProperties[],
    isChefChoice: boolean,
    likeCount: number,
    saveCount: number,
    createdAt: Date,
    updatedAt: Date,
    publishedAt: Date,
    categories?: any,
    tags?: any,
    customer?: any;
}

export type RecipeRequest = {
    customerId: string,
    recipeName: string,
    recipeDescription: string,
    recipeCategoryId: string,
    recipeCookingDuration: IRecipeCookingDurationProperties,
    recipeTotalCookingDuration: number,
    recipeCookingPortion: number,
    recipeVideoType: ERecipeVideoType,
    recipeVideoUrl: string,
    recipeStatus: ERecipeStatus,
    recipeRemark: string,
    recipeImage: any,
    recipeIngredients: IRecipeIngridientProperties[],
    recipeInstructions: IRecipeInstructionProperties[],
    isChefChoice: boolean,
    likeCount: number,
    saveCount: number,
    createdAt: Date,
    updatedAt: Date,
    publishedAt: Date,
}

export type RecipeResponsePayload = {
  code: string | null;
  message: string | null;
  payload: {
    next: string;
    prev: string;
    count: number;
    results: RecipeResponse[]
  }
}

export type RecipeState = {
  isLoadingRecipe: boolean;
  data: RecipeResponsePayload;
  recipe: RecipeResponse;
  error: any;
  isSuccess: boolean;
}
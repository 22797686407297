import { Reducer } from "redux";
import {
  CustomerResponse,
  CustomerState,
  CustomerTypes,
} from "../../types/customer.type";

export const initCustomer: CustomerResponse = {
  customerName: "",
  dateOfBirth: "",
  correspondenceEmail: "",
  status: "",
  membershipStatus: "",
  gender: "",
  addresses: "",
  bankAccount: "",
  phoneNumber: "",
  profilePic: "",
  socialMedia: [],
  transactionSummary: {
    countTransaction: "0",
    externalId: "0",
    totalFinalTransactionPrice: "0",
    totalTransactionPrice: "0",
  },
  serviceCenterId: "",
};

const initialState: CustomerState = {
  isLoadingCustomer: false,
  data: {
    code: "",
    message: "",
    payload: {
      next: "",
      prev: "",
      count: 0,
      results: [],
    },
  },
  customer: initCustomer,
  error: {},
  lastPathCustomer: "",
};

export const customer: Reducer<CustomerState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CustomerTypes.FIND_ALL_CUSTOMER:
      const newResults = action.payload.payload.results.map(
        (item: CustomerResponse) => {
          let res = item;
          if (!item.transactionSummary) {
            res = {
              ...item,
              transactionSummary: {
                countTransaction: "0",
                externalId: "0",
                totalFinalTransactionPrice: "0",
                totalTransactionPrice: "0",
              },
            };
          }
          console.info("cara yang tadi", item.customerType);
          return {
            ...res,
            customerType: !item.customerType ? "reguler" : "spg",
          };
        }
      );
      return {
        ...state,
        data: {
          ...action.payload,
          payload: {
            ...action.payload.payload,
            results: newResults,
          },
        },
      };
    case CustomerTypes.FIND_ALL_CUSTOMER_BY_SERVICE_CENTER:
      return {
        ...state,
        data: {
          ...action.payload,
          payload: {
            ...action.payload.payload,
          },
        },
      };
    case CustomerTypes.CREATE_CUSTOMER_BY_SERVICE_CENTER:
      return {
        ...state,
        customer: {
          ...action.payload,
        },
      };
    case CustomerTypes.FIND_ONE_CUSTOMER:
      return {
        ...state,
        customer: {
          ...action.payload,
          customerType: !action.payload.customerType ? "reguler" : "spg",
        },
      };
    case CustomerTypes.SET_LOADING_CUSTOMER:
      return {
        ...state,
        isLoadingCustomer: action.payload,
      };
    default:
      return state;
  }
};

import axios from 'axios';
import { getHeadersMultiPart, getHeaders } from '../helpers/auth';

export async function apiFindAllRecipe(params: string) {
  const query = params || null
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/recipe' + query,
    method: 'GET',
    headers
  });
}

export async function apiFindOneRecipe(recipeId: string) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/recipe/' + recipeId,
    method: 'GET',
    headers
  });
}

export async function apiCreateRecipe(data: any) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/recipe',
    method: 'POST',
    headers: headers,
    data
  });
}

export async function apiUpdateRecipe(data: any) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/recipe/' + data.recipeId,
    method: 'PATCH',
    headers: headers,
    data
  });
}

export async function apiDeleteRecipe(recipeId: string) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/recipe/' + recipeId,
    method: 'DELETE',
    headers
  });
}

export async function apiAddImageRecipe(data: FormData) {
  const headers = await getHeadersMultiPart();
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      "/recipe/upload-image?recipeId=" + data.get("recipeId"),
    method: "POST",
    headers,
    data,
  });
}
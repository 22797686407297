import { StockChangeResponse, StockHistoryResponsePayload, StockRetailTypes } from '../../types/stock-retail.type';

export function findStockHistory(payload: StockHistoryResponsePayload) {
  return {
    type: StockRetailTypes.FIND_STOCK_HISTORY,
    payload
  }
}

export function setLimitedStock(payload: number) {
  return {
    type: StockRetailTypes.SET_LIMITED_STOCK,
    payload
  }
}

export function changeProductStock(payload: StockChangeResponse) {
  return {
    type: StockRetailTypes.CHANGE_STOCK,
    payload
  }
}

export function setLoadingStock(payload: boolean) {
  return {
    type: StockRetailTypes.SET_LOADING_STOCK,
    payload
  }
}

export function setErrorStock(payload: any) {
  return {
    type: StockRetailTypes.SET_ERROR_STOCK,
    payload
  }
}

import { IconProps } from "./IconProps";

export default function VoucherPartnerIcon(props: IconProps) {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.20834 15.0333V15.042L7.20956 15.0507L7.94264 20.2738C7.94268 20.2741 7.94272 20.2745 7.94276 20.2748C8.04289 21.0718 8.73896 21.6666 9.53334 21.6666H12.4667C13.2611 21.6666 13.9571 21.0718 14.0573 20.2748C14.0573 20.2745 14.0573 20.2741 14.0574 20.2738L14.79 15.0542C14.8905 14.5446 14.6813 14.1417 14.4 13.7666C14.0988 13.365 13.6015 13.1666 13.2 13.1666H8.80001C8.30525 13.1666 7.90335 13.3661 7.60527 13.7597C7.29872 14.0718 7.20834 14.5701 7.20834 15.0333ZM11 13.05C12.6274 13.05 13.9667 11.7107 13.9667 10.0833C13.9667 8.45594 12.6274 7.11664 11 7.11664C9.37264 7.11664 8.03334 8.45594 8.03334 10.0833C8.03334 11.7107 9.37264 13.05 11 13.05Z" fill="#FAFAFA" stroke="white" strokeWidth="0.25" />
      <path d="M17.7403 4.97738C14.0063 1.50754 8.00453 1.50754 4.27059 4.97738C0.520926 8.46183 0.520926 14.0849 4.27059 17.5693C4.58963 17.8658 5.07504 17.8658 5.39407 17.5693C5.55213 17.4224 5.64514 17.2235 5.64514 17.0098C5.64514 16.7962 5.55213 16.5972 5.39407 16.4503C2.31662 13.5906 2.31062 8.95965 5.3959 6.01329C8.49193 3.13809 13.5215 3.13866 16.6168 6.01499C19.6969 8.87728 19.6969 13.588 16.6168 16.4503C16.4587 16.5972 16.3657 16.7962 16.3657 17.0098C16.3657 17.2235 16.4587 17.4224 16.6168 17.5693C16.8132 17.7519 17.0296 17.7513 17.1249 17.751C17.1283 17.751 17.1316 17.751 17.1348 17.751C17.1379 17.751 17.1412 17.751 17.1446 17.751C17.2396 17.7513 17.4549 17.7519 17.6508 17.5711C21.4903 14.0868 21.4903 8.46213 17.7403 4.97738Z" fill="#FAFAFA" stroke="#FAFAFA" strokeWidth="0.75" />
    </svg>

  );
}

import { RecipeResponse, RecipeResponsePayload, RecipeTypes } from '../../types/recipe.type'


export function findAllRecipe(payload: RecipeResponsePayload) {
  return {
    type: RecipeTypes.FIND_ALL_RECIPE,
    payload
  }
}

export function findOneRecipe(payload: RecipeResponse) {
  return {
    type: RecipeTypes.FIND_ONE_RECIPE,
    payload
  }
}

export function createRecipe(payload: RecipeResponse) {
  return {
    type: RecipeTypes.CREATE_RECIPE,
    payload
  }
}

export function updateRecipe(payload: RecipeResponse) {
  return {
    type: RecipeTypes.UPDATE_RECIPE,
    payload
  }
}

export function deleteRecipe(payload: any) {
  return {
    type: RecipeTypes.REMOVE_RECIPE,
    payload
  }
}

export function setLoadingRecipe(payload: boolean) {
  return {
    type: RecipeTypes.SET_LOADING_RECIPE,
    payload
  }
}

export function setErrorRecipe(payload: any) {
  return {
    type: RecipeTypes.SET_ERROR_RECIPE,
    payload
  }
}
import { Reducer } from 'redux';
import { DefaultResponseList } from '../../hooks/useFetch';
import { ProductResponseCustom } from '../../screens/Storefront/Edit';

export enum EStorefrontType {
  SET_DATA_PRODUCTS = 'SET_DATA_PRODUCTS',
  SET_DATA_RELATED_PRODUCT = 'SET_DATA_RELATED_PRODUCT'
} 

export interface StorefrontAppState {
  data: {
    prev: string
    next: string
    count: number
    results: ProductResponseCustom[]
  },
  relatedProductIds: ProductResponseCustom[]
}

const initialStorefront: StorefrontAppState = {
  data: {
    prev: '',
    next: '',
    count: 0,
    results: []
  },
  relatedProductIds: []
}

export const storefront: Reducer<StorefrontAppState> = (state = initialStorefront, action) => {
  switch (action.type) {
    case EStorefrontType.SET_DATA_PRODUCTS:
      const newResults = action.payload.results.map((item: any) => {
        return {
          ...item,
          selected: false
        }
      })
      return {
        ...state,
        data: {
          ...action.payload,
          results: newResults
        }
      }
      
    case EStorefrontType.SET_DATA_RELATED_PRODUCT:

      // const newSelectedProducts: ProductResponseCustom[] = []
      // const newUnselectedProducts: ProductResponseCustom[] = []

      // for (const item of state.data.results) {
      //   const found = (action.payload as any).find((id: any) => id === item.productId)

      //   if (found) {
      //     newSelectedProducts.push({
      //       ...item,
      //       selected: true
      //     })
      //   } else {
      //     newUnselectedProducts.push({
      //       ...item,
      //       selected: false
      //     })
      //   }
      // }

      // console.info('action.payload', action.payload)
      // console.info('results', state.data.results)
      // console.info('first top', newSelectedProducts)
      // console.info('first bottom', newUnselectedProducts)

      return {
        ...state,
        // data: {
        //   prev: state.data.prev,
        //   next: state.data.next,
        //   count: state.data.count,
        //   results: [...newSelectedProducts, ...newUnselectedProducts],
        // },
        relatedProductIds: action.payload
      }
  
    default:
      return state
  }
}
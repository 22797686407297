import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomizedTable, {
  ITableHeader,
} from "../../../components/Table/CustomizedTable";
import useWarranty from "../../../hooks/useWarranty";
import { ApplicationState } from "../../../redux/store";

export default function ExtraWarrantyHistory() {
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [query, setQuery] = useState({ limit: 25, offset: 0 });
  const { logExtraWarranty } = useSelector(
    (state: ApplicationState) => state.masterDataReferral
  );

  const headers: ITableHeader[] = [
    {
      title: "Updated Date",
      align: "left",
      sortable: true,
      column: "updatedAt",
      type: "date",
    },
    {
      title: "Warranty",
      align: "left",
      type: "text",
      column: "year",
    },
    {
      title: "Updated By",
      align: "left",
      type: "nested",
      column: JSON.stringify(["adminMetaData", "adminName"]),
    },
    {
      title: "Role",
      align: "left",
      type: "nested",
      column: JSON.stringify(["adminMetaData", "role"]),
    },
  ];

  const { fetchLogExtraWarranty } = useWarranty();

  useEffect(() => {
    fetchLogExtraWarranty(query);
  }, [query]);

  useEffect(() => {
    if (logExtraWarranty.payload.count > 0) {
      setTotalData(logExtraWarranty.payload.count);
      setTotalPage(Math.ceil(logExtraWarranty.payload.count / rowsPerPage));
    }
  }, [logExtraWarranty.payload]);

  function changePagination(value: number) {
    setPage(value);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
        limit: rowsPerPage,
      };
    });
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value);
    setPage(1);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: 0,
        limit: value,
      };
    });
  }

  return (
    <CustomizedTable
      data={logExtraWarranty.payload.results}
      headers={headers}
      totalData={totalData}
      page={page}
      totalPage={totalPage}
      rowsPerPage={rowsPerPage}
      onChange={(newPage) => changePagination(newPage)}
      onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
    />
  );
}

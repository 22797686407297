import { BankApprovalResponsePayload, BankApprovalTypes, BankApprovalResponse } from '../../types/bank-approval.types'


export function findAllBankApproval(payload: BankApprovalResponsePayload) {
  return {
    type: BankApprovalTypes.FIND_ALL_BANK_APPROVAL,
    payload
  }
}

export function updateBankApproval(payload: BankApprovalResponse) {
  return {
    type: BankApprovalTypes.UPDATE_BANK_APPROVAL,
    payload
  }
}

export function setLoadingBankApproval(payload: boolean) {
  return {
    type: BankApprovalTypes.SET_LOADING_BANK_APPROVAL,
    payload
  }
}
import { Reducer } from 'redux';
import { BankApprovalResponse, BankApprovalState, BankApprovalTypes } from '../../types/bank-approval.types';


export const initialState: BankApprovalState = {
  isLoadingBankApproval: false,
  data: {
    code: '',
    message: '',
    payload: {
      prev: '',
      next: '',
      count: 0,
      results: []
    },
  },
}

export const bankApproval: Reducer<BankApprovalState> = (state = initialState, action) => {
  switch (action.type) {
    case BankApprovalTypes.FIND_ALL_BANK_APPROVAL:
      const payload = action.payload.payload
      if (payload && payload.results && payload.results.length > 0) {
        const newResults = payload.results.map((item: BankApprovalResponse) => {
          return {
            ...item,
            bankAccount: `${item.customerBankMetadata.bank.bankName} ${item.customerBankMetadata.accountNumber} a.n. ${item.customerBankMetadata.accountName}`,
            adminMetadata: item.adminMetadata || {
              adminName: '',
              role: '',
            }
          }
        })

        return {
          ...state,
          data: {
            ...action.payload,
            payload: {
              ...action.payload.payload,
              results: newResults
            }
          }
        }
      }
      return {
        ...state,
        data: action.payload
      }
    case BankApprovalTypes.UPDATE_BANK_APPROVAL:
      return {
        ...state,
      }
    case BankApprovalTypes.SET_LOADING_BANK_APPROVAL:
      return {
        ...state,
        isLoadingBankApproval: action.payload
      }

    default:
      return {
        ...state
      }
  }
}
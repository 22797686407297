import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Layout from '../../components/Layout/Layout';
import {
  Box,
  createStyles,
  makeStyles,
  Modal,
  Paper,
  Theme,
  TextField,
  Typography,
} from '@material-ui/core';
import COLORS from '../../styles/colors';
import HeaderPage from '../../components/Layout/HeaderPage';
import SimpleCard2 from './components/SimpleCard2';
import { convertNumToCurrency } from '../../helpers/functions/currency';
import { User } from 'react-feather';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'react-feather';
import AppButton from '../../components/Form/AppButton';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ReferralDiscountHistory from './components/ReferralDiscountHistory';
import { Save, Close } from '@material-ui/icons';
import FormInput from '../../components/Form/FormInput';
import useReferralCommission from '../../hooks/useReferralCommission';
import useReferralInformation from '../../hooks/useReferralInformation';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../redux/store';
import { useAuth } from '../../hooks/useAuth';
import useReferralDiscount from '../../hooks/useReferralDiscount';
import { ERetailDiscount, ReferralDiscountRequest } from '../../types/referral-discount.type';
import Discount from '../../components/Icon/Discount';
import { discountValidation } from '../../helpers/functions/validation';

export default function DiscountReferral() {
  const classes = useStyles();

  const data = useSelector((state: ApplicationState) => state.referralDiscount)

  const { getCurrentReferralDiscount, createNewReferralDiscount, fetchAllReferralDiscount } = useReferralDiscount()
  const [amountNonReferralCom, setAmountNonReferralCom] = useState<string>('')
  const [amountReferralCom, setAmountReferralCom] = useState<string>('')
  const [formErrors, setFormErrors] = useState({
    referral: false,
    nonReferral: false
  })
  const [modalType, setModalType] = useState<ERetailDiscount | null>(null)
  const [query, setQuery] = useState({ limit: 25, offset: 0 });

  useEffect(() => {
    setFormErrors({
      referral: false,
      nonReferral: false
    })
    getCurrentReferralDiscount()
  }, [])

  useEffect(() => {
    if (data.current && data.current.referral && data.current.nonReferral) {
      const amountReferral = data.current.referral.discountNote
      const amountNonReferral = data.current.nonReferral.discountNote
      setAmountReferralCom(amountReferral)
      setAmountNonReferralCom(amountNonReferral)
    }
  }, [data.current])

  const handleCloseModal = (type?: ERetailDiscount) => {
    setFormErrors({
      referral: false,
      nonReferral: false,
    })
    setModalType(null)
    if (type === ERetailDiscount.REFERRAL) {
      setAmountReferralCom(data.current.referral.discountNote)
    } else if (type === ERetailDiscount.NON_REFERRAL) {
      setAmountNonReferralCom(data.current.nonReferral.discountNote)
    }
  };

  const handleOpenModal = (type: ERetailDiscount) => {
    setModalType(type)
  };

  async function submit() {
    let newData: ReferralDiscountRequest = {
      discountNote: '',
      discountType: ERetailDiscount.REFERRAL,
    }

    if (modalType === ERetailDiscount.REFERRAL) {
      if (/^(\d+(\.\d+)?[%]([+]\d+(\.\d+)*[%])?)$/gm.test(amountReferralCom)) {
        newData = {
          ...newData,
          discountNote: amountReferralCom,
          discountType: ERetailDiscount.REFERRAL,
        }
        setFormErrors({
          ...formErrors,
          referral: false,
        })
        handleCloseModal();
        await createNewReferralDiscount(newData)
        await getCurrentReferralDiscount()
        await fetchAllReferralDiscount(query)
      } else {
        setFormErrors({
          ...formErrors,
          referral: true,
        })
      }

    }

    if (modalType === ERetailDiscount.NON_REFERRAL) {
      if (/^(\d+(\.\d+)?[%]([+]\d+(\.\d+)*[%])?)$/gm.test(amountNonReferralCom)) {
        newData = {
          ...newData,
          discountNote: amountNonReferralCom,
          discountType: ERetailDiscount.NON_REFERRAL,
        }
        setFormErrors({
          ...formErrors,
          nonReferral: false,
        })
        handleCloseModal();
        await createNewReferralDiscount(newData)
        await getCurrentReferralDiscount()
        await fetchAllReferralDiscount(query)
      } else {
        setFormErrors({
          ...formErrors,
          nonReferral: true,
        })
      }
    }
  }

  return (
    <Layout title="Discount Referral" loading={data.isLoadingReferralDiscount}>
      <HeaderPage
        title="Discount Referral"
        subtitle="Discount Referral configuration"
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <SimpleCard2
            loading={false}
            title="Discount Referral"
            value={data.current.referral.discountNote}
            topRightIconComponent={<Discount size={22} />}
            bottomRightComponent={
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpenModal(ERetailDiscount.REFERRAL)}
              >
                <Typography variant="subtitle1" color="primary">
                  Edit Amount
                </Typography>
                <ChevronRight color={COLORS.greyDark} size={15} />
              </Box>
            }
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <SimpleCard2
            loading={false}
            title="Non Discount Referral"
            value={data.current.nonReferral.discountNote}
            topRightIconComponent={<Discount size={22} />}
            bottomRightComponent={
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpenModal(ERetailDiscount.NON_REFERRAL)}
              >
                <Typography variant="subtitle1" color="primary">
                  Edit Amount
                </Typography>
                <ChevronRight color={COLORS.greyDark} size={15} />
              </Box>
            }
          />
        </Grid>
      </Grid>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: 20, paddingTop: 10, paddingBottom: 10 }}
      >
        <Typography style={{ fontWeight: 'bold' }}>Referral & Non Referral Log</Typography>
      </Box>

      <Paper elevation={3}>
        <ReferralDiscountHistory />
      </Paper>

      {/** EDIT Discount Referral */}
      <Modal
        open={modalType === 'referral'}
        onClose={() => handleCloseModal(ERetailDiscount.REFERRAL)}
      >
        <div className={classes.containerModal}>
          <div className={classes.contentModal}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h5">Discount Referral</Typography>

              <button onClick={() => handleCloseModal(ERetailDiscount.REFERRAL)}>
                <Close />
              </button>
            </Box>

            <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
              <Grid item xs={12}>
                <FormInput
                  onChange={(value: any) => {
                    setAmountReferralCom(value)
                  }}
                  type="text"
                  value={amountReferralCom}
                  fullWidth
                  error={formErrors.referral}
                  label="Nominal Discount Referral"
                  placeholder="Discount Referral in percent"
                  helperText={formErrors.referral ? 'Cek kembali penulisan anda. Ex. 7% atau 7%+2%' : 'Diskon dalam persen, Ex. 7% atau 7%+2%'}
                />
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="flex-end">
              <Box>
                <AppButton
                  color="secondary"
                  onClick={() => handleCloseModal(ERetailDiscount.REFERRAL)}
                >
                  Cancel
                </AppButton>
              </Box>
              <Box>
                <AppButton disabled={!amountReferralCom} onClick={() => submit()}>Save</AppButton>
              </Box>
            </Box>
          </div>
        </div>
      </Modal>

      {/** EDIT NON Discount Referral */}
      <Modal
        open={modalType === 'non-referral'}
        onClose={() => handleCloseModal(ERetailDiscount.NON_REFERRAL)}
      >
        <div className={classes.containerModal}>
          <div className={classes.contentModal}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h5">Non Discount Referral</Typography>

              <button onClick={() => handleCloseModal(ERetailDiscount.NON_REFERRAL)}>
                <Close />
              </button>
            </Box>

            <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
              <Grid item xs={12}>
                <FormInput
                  onChange={(value: any) => setAmountNonReferralCom(value)}
                  type="text"
                  value={amountNonReferralCom}
                  fullWidth
                  error={formErrors.nonReferral}
                  label="Nominal Non Discount Referral"
                  placeholder="Discount Referral in percent"
                  helperText={formErrors.referral ? 'Cek kembali penulisan anda. Ex. 5% atau 5%+2%' : 'Diskon dalam persen, Ex. 5% atau 5%+2%'}
                />
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="flex-end">
              <Box>
                <AppButton
                  color="secondary"
                  onClick={() => handleCloseModal(ERetailDiscount.NON_REFERRAL)}
                >
                  Cancel
                </AppButton>
              </Box>
              <Box>
                <AppButton disabled={!amountReferralCom} onClick={() => submit()}>Save</AppButton>
              </Box>
            </Box>
          </div>
        </div>
      </Modal>

    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerModal: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      alihandleCloseModalApprovalgnContent: 'center',
    },
    contentModal: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
      width: 400,
      margin: '0 auto',
      marginTop: '10%',
      borderRadius: 10,
    },
  })
);

import React, {
	useState,
	useEffect,
	useMemo,
	useRef
} from 'react';
import Layout from '../../components/Layout/Layout';
import HeaderPage from '../../components/Layout/HeaderPage';
import FilterDate from '../../components/Form/FilterDate';
import AppButton from '../../components/Form/AppButton';
import { httpRequest } from "../../helpers/api/api";
import {
	Grid,
	InputLabel,
	Select,
	FormControl,
	MenuItem,
	Typography,
	Box,
	CircularProgress
} from '@material-ui/core';
import { ISingleAro } from '../../types/aro.type';
import SimpleCard from '../../components/Card/SimpleCard';
import {
	CheckCircle
} from 'react-feather';
import {
	apiGetAroFullfillment
} from '../../api/aro';
import CustomizedTable, {
  ITableHeader,
  StyledTableCell
} from '../../components/Table/CustomizedTable';
import usePaginateTable, {
	IPaginateTable
} from '../../hooks/usePaginateTable';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { convertDateTimeToText } from '../../helpers/functions/datetime';
import {
	AroFullfillmentChartItem
} from '../../types/aro.type';
import {
	useHistory
} from 'react-router-dom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const date = new Date();
const defaultParamsStartAt = new Date(
	date.getFullYear(),
	date.getMonth(),
	date.getDate()-7,
	0,
	0,
	0,
	0
);
const defaultParamsEndAt = new Date(
	date.getFullYear(),
	date.getMonth(),
	date.getDate(),
	23,
	59,
	59,
	999
);

const headers: ITableHeader[] = [
	{
		title:'Agent Region',
		column:'labelName'
	},
	{
		title:'Total Order',
		column:'totalOrder'
	},
	{
		title:'Belum Diproses',
		column: 'notProccessed'
	},
	{
		title:'Diproses',
		column:'processed'
	},
	{
		title:'Selesai',
		column:'sent'
	},
	{
		title:'Actions',
		column:'actions'
	}
]

const AroFullfillment: React.FC = () => {
	const history = useHistory();
	const [query, setQuery] = useState({
		aroId:'',
		startAt: defaultParamsStartAt.toISOString(),
		endAt: defaultParamsEndAt.toISOString()
	})
	const [aroList, setAroList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isChartLoading, setIsChartLoading] = useState(false);

	const [paidOrderCount, setPaidOrderCount] = useState<number|null>(0);
	const [processOrderCount, setProcessOrderCount] = useState<number|null>(0);
	const [sentOrderCount, setSentOrderCount] = useState<number|null>(0);
	const [chartData, setChartData] = useState<AroFullfillmentChartItem[]>([]);

	const buildQuery = useMemo(()=>
		'aroId='+query.aroId+
		'&startAt='+query.startAt+
		'&endAt='+query.endAt+
		'&showAro=true&limit=0&offset=0'
	,[query]);

	const chartOptions = {
		responsive: true,
		plugins: {
	    legend: {
	      position: 'bottom' as const,
	    }
	  }
	}
	const chartDataFormated = useMemo(()=>({
		labels: chartData.map((item: AroFullfillmentChartItem)=>convertDateTimeToText(item.date,'ddd dd/mm/yy')),
		datasets: [
			{
				label:'Order diterima',
				data: chartData.map((item: AroFullfillmentChartItem)=>item.orderTotal),
				backgroundColor: '#747EDA'
			},
			{
				label:'Order selesai',
				data: chartData.map((item: AroFullfillmentChartItem)=>item.sentTotal),
				backgroundColor: '#FFB4B9'
			}
		]
	}),[chartData]);

	const {
    onChangePage,
    onChangeRowsPerPage,
    loading,
    params,
    data,
    page,
    totalPage,
    totalData,
    rowsPerPage,
  }: IPaginateTable = usePaginateTable(
    apiGetAroFullfillment,
    {
      limit: 25,
      offset: 0
    }
  );

  const _detailAction = (aroId: string)=>{
  	history.push('/aro-transactions?aroId='+aroId);
  }

  const dataTable = data.map((item)=>({
  	...item,
  	actions:(
  		<AppButton color="primary" onClick={()=>_detailAction(item.aroId)}>Lihat Detail</AppButton>
		)
  }))


	useEffect(()=>{
	    httpRequest.get('/transaction/summary/aro-fullfillment?'+buildQuery)
	    .then(({data})=>{
	      setChartData(data.payload)
	    });
	},[query]);

	useEffect(()=>{
		setIsChartLoading(true);
    httpRequest.get('/aros')
    .then(({data})=>{
      setAroList(data.payload.results)
    })
	},[]);

	useEffect(()=>{
		setIsLoading(true);
		Promise.all([
			httpRequest.get('/transaction-invoice?'+buildQuery+'&filterStatus=paid,process,sent')
			.then(({data})=>{
				setPaidOrderCount(data.payload.count)
			}),
			httpRequest.get('/transaction-invoice?'+buildQuery+'&filterStatus=process,sent')
	    .then(({data})=>{
	      setProcessOrderCount(data.payload.count)
	    }),
	    httpRequest.get('/transaction-invoice?'+buildQuery+'&filterStatus=sent')
	    .then(({data})=>{
	      setSentOrderCount(data.payload.count)
	    })
    ])
    .finally(()=>setIsLoading(false))
	},[query]);

	const _renderLoading = () => {
    return (
      <Box
        width="100%"
        height="100"
        alignItems="center"
        justifyContent="center"
        display="flex"
        marginTop={10}
      >
        <CircularProgress size={50} thickness={6} color="primary" />
      </Box>
    );
  };

	return (
	<Layout
	title="Fullfillment Agent Region"
	loading={loading||isLoading}
	>
		<HeaderPage title="Fullfillment Agent Region" subtitle="Summary of Agent Region Fulfillment" />
		<Grid
			container
			spacing={3}
			justify="space-between"
			alignItems="center"
			style={{ marginBottom: 10 }}
		>
			<Grid item xs={12} md={12} lg={4}>
				<FormControl style={{ width: '100%' }}>
					<InputLabel id="select-status" shrink>
						Agent Region
					</InputLabel>
					<Select
					label="Agent Region"
					placeholder="All"
					labelId="select-aro"
					value={query.aroId}
					onChange={(event: any) => {
						setQuery({
							...query,
							aroId: event.target.value
						})
					}}
					displayEmpty={true}
					>
						<MenuItem value="">All</MenuItem>
						{aroList.map((aro: ISingleAro)=><MenuItem key={aro.aroId} value={aro.aroId}>{aro.labelName}</MenuItem>)}
					</Select>
				</FormControl>
			</Grid>

			<Grid item xs={12} md={6} lg={4}>
				<FilterDate
					start_at={query.startAt}
					end_at={query.endAt}
					onChange={(start_at: string, end_at: string) =>
						setQuery((val: any) => {
							return {
								...val,
								startAt: start_at,
								endAt: end_at,
							};
						})
					}
				/>
			</Grid>
		</Grid>

		<Grid container spacing={3}>
			<Grid item xs={12} md={6} lg={4}>
				{paidOrderCount === null
					? _renderLoading()
					: <SimpleCard
						valueOnly={true}
						title="Order Diterima"
						count_before={paidOrderCount}
						count_current={paidOrderCount}
						topRightIconComponent={
							<CheckCircle style={{ width: 20, height: 20 }} />
						}
						/>
				}
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				{processOrderCount === null
					? _renderLoading()
					: <SimpleCard
						valueOnly={true}
						title="Diproses"
						count_before={processOrderCount}
						count_current={processOrderCount}
						topRightIconComponent={
							<CheckCircle style={{ width: 20, height: 20 }} />
						}
						/>
				}
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				{sentOrderCount === null
					? _renderLoading()
					: <SimpleCard
						valueOnly={true}
						title="Order Selesai"
						count_before={sentOrderCount}
						count_current={sentOrderCount}
						topRightIconComponent={
							<CheckCircle style={{ width: 20, height: 20 }} />
						}
						/>
				}
			</Grid>
		</Grid>

		<Grid container spacing={3}>
			<Grid item xs={12}>
				<HeaderPage
				title={`Grafik Order/Pemenuhan (${convertDateTimeToText(query.startAt)} - ${convertDateTimeToText(query.endAt)})`}
				/>
				<Bar
				options={chartOptions}
				data={chartDataFormated}
				height={100}
				/>
	    </Grid>
    </Grid>

		<Grid container spacing={3}>
			<Grid item xs={12}>
				<HeaderPage
				title="Data Order/Pemenuhan"
				subtitle="Menampilkan semua hasil tanpa filter tanggal"
				/>
				<CustomizedTable
		      data={dataTable}
		      headers={headers}
		      totalData={totalData}
		      page={page}
		      totalPage={totalPage}
		      rowsPerPage={rowsPerPage}
		      onChange={onChangePage}
		      onRowsPerPageChange={onChangeRowsPerPage}
		    />
	    </Grid>
    </Grid>
	</Layout>
	);
}
export default AroFullfillment;
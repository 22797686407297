import React from "react";
import { createStyles, Grid, makeStyles, TextField, Theme } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { convertDateTimeToString } from "../../helpers/functions/datetime";
import { useRef } from "react";

type Props = {
  start_at?: string;
  end_at?: string;
  onChange: (start_at: string, end_at: string) => void;
  notSetDefaultDate?: boolean;
};

export default function FilterDate(props: Props) {
  const date = new Date();
  let defaultStartDate: Date | string = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0);
  let defaultEndDate: Date | string = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 999);
  defaultStartDate = convertDateTimeToString(defaultStartDate);
  defaultEndDate = convertDateTimeToString(defaultEndDate);

  const handleChange = (value: any, type: "start_at" | "end_at") => {
    let start_at: any = props.start_at || defaultStartDate || "";
    let end_at: any = props.end_at || defaultEndDate || "";
    if (type === "start_at") {
      start_at = new Date(value);
      start_at.setHours(0, 0, 0, 0);
      start_at = start_at.toISOString();
    } else if (type === "end_at") {
      end_at = new Date(value);
      end_at.setHours(23, 59, 59, 999);
      end_at = end_at.toISOString();
    }
    props.onChange(start_at, end_at);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            autoOk={true}
            disableToolbar
            variant="inline"
            format="dd MMM yyyy"
            margin="normal"
            maxDate={props.end_at ? props.end_at : props.notSetDefaultDate ? null : defaultEndDate}
            id="date-picker-inline"
            label="From"
            value={props.start_at ? props.start_at : props.notSetDefaultDate ? null : defaultStartDate}
            onChange={(value: any) => {
              handleChange(value, "start_at");
              // (refStartAt as any).current.open(false);
            }}
            style={{ width: "100%", marginTop: 0, marginBottom: 0 }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
        {/* <TextField
          id="start_date"
          label="From"
          type="date"
          defaultValue={props.start_at ? props.start_at : defaultStartDate}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth={true}
          onChange={(event: any) =>
            handleChange(event.target.value, 'start_at')
          }
        /> */}
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            autoOk={true}
            disableToolbar
            variant="inline"
            format="dd MMM yyyy"
            margin="normal"
            id="date-picker-inline"
            label="To"
            minDate={props.start_at ? props.start_at : props.notSetDefaultDate ? null : defaultStartDate}
            value={props.end_at ? props.end_at : props.notSetDefaultDate ? null : defaultEndDate}
            onChange={(value: any) => {
              handleChange(value, "end_at");
            }}
            style={{ width: "100%", marginTop: 0, marginBottom: 0 }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
        {/* <TextField
          id="end_date"
          label="To"
          type="date"
          defaultValue={props.end_at ? props.end_at : defaultEndDate}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth={true}
          onChange={(event: any) => handleChange(event.target.value, 'end_at')}
        /> */}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      width: "100%",
    },
  })
);

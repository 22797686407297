import { Chip, InputLabel, Typography } from '@material-ui/core';
import React from 'react';
import { convertDateTimeToText } from '../helpers/functions/datetime';
import { EVoucherDiscountType, EVoucherStatus, EVoucherType, VoucherTagProperties } from '../types/voucher.type';
import { convertNumToCurrency } from '../helpers/functions/currency';

interface Props {
  voucherType: EVoucherType
  voucherCode: string
  eventName: string
  validStartAt: Date | string
  validEndAt: Date | string
  voucherStatus: EVoucherStatus
  discountType: EVoucherDiscountType
  discountValue: string
  maxDiscountValue: number
  minTotalTransaction: number
  limitMaxUsePerPerson: number
  totalUsed?: number
  tags?: VoucherTagProperties[]
  refCommissionValue?: number
}

const VoucherCard: React.FC<Props> = (props) => {

  const renderDescriptionItem = (key: string, value: string) => {
    return (
      <div>
        <InputLabel shrink={true}>
          {key}
        </InputLabel>
        <Typography>{value}</Typography>
      </div>
    )
  }

  const convertStatus = (voucherStatus: EVoucherStatus) => {

    const newStatus = voucherStatus.charAt(0).toUpperCase() + voucherStatus.slice(1)

    if (voucherStatus === EVoucherStatus.pending) {
      return (
        <div style={{
          paddingTop: 2,
          paddingLeft: 8,
          paddingRight: 8,
          textAlign: 'center',
          fontSize: 12,
          display: 'inline-block',
          paddingBottom: 2,
          borderRadius: 100,
          border: '1px solid #FABB43',
          backgroundColor: '#ffe9c1',
          color: "#000"
        }}>{newStatus}</div>
      )
    } else if (voucherStatus === EVoucherStatus.available) {
      return (
        <div style={{
          paddingTop: 2,
          paddingLeft: 8,
          paddingRight: 8,
          textAlign: 'center',
          fontSize: 12,
          display: 'inline-block',
          paddingBottom: 2,
          borderRadius: 100,
          border: '1px solid #0AC46B',
          backgroundColor: '#e2fff1',
          color: "#059650"
        }}>{newStatus}</div>
      )
    } else if (voucherStatus === EVoucherStatus.used) {
      return (
        <div style={{
          paddingTop: 2,
          paddingLeft: 8,
          paddingRight: 8,
          textAlign: 'center',
          fontSize: 12,
          display: 'inline-block',
          paddingBottom: 2,
          borderRadius: 100,
          border: '1px solid #2B4BF2',
          backgroundColor: '#cdd5fc',
          color: "#0d29b3"
        }}>{newStatus}</div>
      )
    } else if (voucherStatus === EVoucherStatus.expired) {
      return (
        <div style={{
          paddingTop: 2,
          paddingLeft: 8,
          paddingRight: 8,
          textAlign: 'center',
          fontSize: 12,
          display: 'inline-block',
          paddingBottom: 2,
          borderRadius: 100,
          border: '1px solid #E92548',
          backgroundColor: '#ffc5d0',
          color: "#a50c28"
        }}>{newStatus}</div>
      )
    }
  }

  return (
    <div>
      <div style={{
        marginTop: 10,
        marginBottom: 10,
        borderColor: '#000',
        backgroundColor: '#fff',
        boxShadow: "0 3px 3px 0px rgba(0,0,0,0.3)",
        borderRadius: 10,
        borderWidth: 1,
        borderLeftWidth: 5,
        padding: '20px 25px',
        paddingBottom: '40px'
      }}>
        {/* Header */}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
          marginBottom: 15,
        }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1
          }}>
            <Typography variant="h2" style={{ fontSize: 30, marginRight: 20 }}>{props.voucherCode}</Typography>
            {convertStatus(props.voucherStatus)}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <Typography>{convertDateTimeToText(props.validStartAt, 'dd mmm yyyy hh:mm')}</Typography>
            <Typography style={{
              marginLeft: 5,
              marginRight: 5,
            }}>to</Typography>
            <Typography>{convertDateTimeToText(props.validEndAt, 'dd mmm yyyy hh:mm')}</Typography>
          </div>
        </div>

        {/* Content */}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          flex: 1,
          width: '100%',
          justifyContent: 'space-between'
        }}>
          {renderDescriptionItem('Event Name', props.eventName)}
          {renderDescriptionItem('Discount Type', props.discountType)}
          {renderDescriptionItem('Discount Value  ', props.discountType === EVoucherDiscountType.nominal ? convertNumToCurrency(Number(props.discountValue)) : props.discountValue + '%')}
          {renderDescriptionItem('Max Discount', String(convertNumToCurrency(props.maxDiscountValue)))}
          {renderDescriptionItem('Min Belanja', convertNumToCurrency(props.minTotalTransaction))}
          {props.voucherType === EVoucherType.generate && renderDescriptionItem('Batas Pemakaian', String(props.limitMaxUsePerPerson))}
          {props.voucherType === EVoucherType.generate && renderDescriptionItem('Tags', 'Tags')}

          {props.voucherType === EVoucherType.partner && renderDescriptionItem('Total Pemakaian', String(props.totalUsed))}
          {props.voucherType === EVoucherType.partner && renderDescriptionItem('Komisi', String(props.refCommissionValue) + '%')}
        </div>
      </div>
    </div>
  )
}

export default VoucherCard

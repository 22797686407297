import { FormControl, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import { Link } from "react-router-dom";
import useGlobalStyles from "../../styles/globalStyles";
import AppButton from "../../components/Form/AppButton";
import HeaderPage from "../../components/Layout/HeaderPage";
import Search from "../../components/Form/Search";
import useCategory from '../../hooks/useCategory';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from '../../hooks/useDebounce';
import { ApplicationState } from '../../redux/store';
import { generateQueryString } from '../../helpers/functions/generateQueryString';
import { useLocation } from 'react-router-dom';
import { setLatestParams, enableUpdateParams } from '../../redux/action/config-app.action';
import useRecipeCategory from "../../hooks/useRecipeCategory";
import { deleteRecipeCategory, findOneRecipeCategory } from "../../redux/action/recipe-category.action";

const headers: ITableHeader[] = [
  {
    title: "recipeCategoryId",
    column: "recipeCategoryId",
    type: "id",
  },
  {
    title: "Recipe Category ID",
    column: "recipeCategoryId",
    width: '20%'
  },
  {
    title: "Image",
    column: "imageUrl",
    type: "image",
  },
  {
    title: "Name",
    sortable: true,
    column: "recipeCategoryName",
    width: "20%",
  },
  {
    title: "Created At",
    column: "createdAt",
    type: 'date'
  },
];

export default function RecipeCategory() {
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();

  const recipeCategoryState = useSelector((state: ApplicationState) => state.recipeCategory);

  const [page, setPage] = useState(1);
  const [filterStatus, setFilterStatus] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState<string | null>(null);
  const [query, setQuery] = useState({ limit: 25, offset: 0, search: '', published: 1 });

  const { fetchAllRecipeCategory, removeRecipeCategory } = useRecipeCategory();
  const debouncedTitle = useDebounce(search, 300);

  const location = useLocation();

  const { updateParams, latestParams } = useSelector((state: ApplicationState) => state.configApp)




  useEffect(() => {
    if (updateParams) {
      console.info('update true')
      dispatch(setLatestParams({
        pathname: location.pathname,
        params: generateQueryString(query),
        query: {
          limit: query['limit'],
          offset: query['offset'],
          published: query['published'],
          page
        }
      }))
      fetchAllRecipeCategory(query)
    } else {
      console.info('update false')
      setPage(latestParams.query.page || page || 1)
      fetchAllRecipeCategory(latestParams.query)
      dispatch(enableUpdateParams(true))
    }
  }, [query])

  useEffect(() => {
    searchCategoryName(search);
  }, [debouncedTitle]);

  const searchCategoryName = async (value: any) => {
    if (value !== null && value.length > 0) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          search: value,
        };
      });
      setPage(1)
    } else if (value !== null) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          search: '',
        };
      });
    }
  };

  useEffect(() => {
    setTotalData(recipeCategoryState.data.payload.count);
    if (recipeCategoryState.data.payload.count > 0) {
      setTotalPage(Math.ceil(recipeCategoryState.data.payload.count / rowsPerPage));
    } else {
      setTotalPage(1)
    }
  }, [recipeCategoryState.data]);

  function changePagination(value: number) {
    setPage(value);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
        limit: rowsPerPage
      };
    });
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value);
    setPage(1)
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: 0,
        limit: value
      };
    });
  }

  function setFilter(value: number) {
    setFilterStatus(value);
    setQuery((val: any) => {
      return {
        ...val,
        published: value,
      };
    });
  }

  return (
    <Layout title="Recipe Category" loading={recipeCategoryState.isLoadingRecipeCategory}>
      <HeaderPage title="Recipe Category" subtitle="List of all recipe category" />

      <Grid container spacing={3} justify="space-between">
        <Grid item xs={12} md={5} lg={7}>
          <Link
            to={{ pathname: "create-recipe-category" }}
            className={globalClasses.link}
          >
            <AppButton
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
            >
              Add Recipe Category
            </AppButton>
          </Link>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Search
            placeholder="Search by category name"
            onChange={(value: string) => setSearch(value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <CustomizedTable
            data={recipeCategoryState.data.payload.results}
            headers={headers}
            showAction={["detail", "edit", "delete"]}
            page={page}
            totalData={totalData}
            totalPage={totalPage}
            rowsPerPage={rowsPerPage}
            onPressDetail={(data: any) => {
              dispatch(findOneRecipeCategory(data));
            }}
            onPressEdit={(data: any) => {
              dispatch(findOneRecipeCategory(data));
            }}
            onDeleteItem={(data: any)=> {
              removeRecipeCategory(data.recipeCategoryId)
              fetchAllRecipeCategory(query)
            }}
            onChange={(newPage) => changePagination(newPage)}
            onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
          />
        </Grid>
      </Grid>
    </Layout>
  );
}

import React from 'react'
import { httpRequest } from '../helpers/api/api';
import { DefaultResponse } from './useFetch';
import { CancelTokenSource } from 'axios';

interface Props<DataType = any> {
  url: string,
  initialData: DataType
}

export default function useFetchOne<T = any>(props: Props) {
  const [data, setData] = React.useState<T>(props.initialData);
  const [loading, setLoading] = React.useState(false);

  const fetchOneData = async (id: string, source?: CancelTokenSource) => {
    try {
      setLoading(true);
      const res = await httpRequest.get<DefaultResponse<T>>(props.url + '/' + id, { cancelToken: source ? source.token : undefined })

      if (res && res.status === 200) {
        setData(res.data.payload)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      throw error
    }
  }

  return {
    fetchOneData,
    loading,
    data
  }
}
import { Box, Paper, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import { useLocation, useParams } from 'react-router-dom';
import HeaderPage from '../../components/Layout/HeaderPage';
import AppTabs from '../../components/Tabs/AppTabs';
import useCustomer from '../../hooks/useCustomer';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../redux/store';
import { LoadingScreen } from '../../components/LoadingScreen';
import ReferralCommision from '../User/components/ReferralCommision';
import DisbursementHistory from '../User/components/DisbursementHistory';
import ReferralDownline from './components/ReferralDownline';

export default function ReportReferralDetail() {
  const location = useLocation();
  let { id } = useParams<{ id: string }>();

  const [isLoading, setIsLoading] = useState(false);
  const isShowCustomer = location.pathname.includes('customer') ? true : false;

  const customerState = useSelector(
    (state: ApplicationState) => state.customer
  );

  const classes = useStyles();

  const [activeTab, setActiveTab] = useState(0);

  const { fetchOneCustomer } = useCustomer();

  let title = isShowCustomer ? 'Detail Customer' : 'Detail User';
  let subtitle = isShowCustomer
    ? 'Detailed information about customer'
    : 'Detailed information about user';

  useEffect(() => {
    fetchOneCustomer(id);
  }, []);

  return (
    <Layout
      title={title}
      loading={customerState.isLoadingCustomer || isLoading}
    >
      {customerState.isLoadingCustomer ? (
        <LoadingScreen />
      ) : (
        <>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <HeaderPage title={title} subtitle={subtitle} showBackButton />

            {/* <Box display="flex" justifyContent="flex-end">
          <Link to={'/user/' + id + '/edit'}>
            <AppButton color="primary">Edit</AppButton>
          </Link>
        </Box> */}
          </Box>

          <Paper elevation={3} className={classes.paper}>
            <AppTabs
              active={activeTab}
              tabs={[
                'Downline Information',
                'Referral Commision',
                'Disbursement History',
              ]}
              onChange={(value: number) => setActiveTab(value)}
            />

            {activeTab === 0 && (
              <ReferralDownline
                customerId={id}
                customerName={customerState.customer.customerName}
                customerUplineName={
                  customerState.customer.upline
                    ? customerState.customer.upline.customerName
                    : undefined
                }
                onLoading={(value) => setIsLoading(value)}
              />
            )}
            {activeTab === 1 && (
              <ReferralCommision
                customerId={id}
                customerName={customerState.customer.customerName}
                customerUplineName={
                  customerState.customer.upline
                    ? customerState.customer.upline.customerName
                    : undefined
                }
                onLoading={(value) => setIsLoading(value)}
              />
            )}
            {activeTab === 2 && (
              <DisbursementHistory
                customerId={id}
                customerName={customerState.customer.customerName}
                onLoading={(value) => setIsLoading(value)}
              />
            )}
          </Paper>
        </>
      )}
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 0, 0, 0),
    },
  })
);

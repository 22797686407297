import {
  Grid,
  Modal,
  Paper,
  Typography,
  Box,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { CheckCircle, ChevronRight, Tag } from "react-feather";
import { useSelector } from "react-redux";
import AppButton from "../../components/Form/AppButton";
import FormInput from "../../components/Form/FormInput";
import HeaderPage from "../../components/Layout/HeaderPage";
import Layout from "../../components/Layout/Layout";
import useAuth from "../../hooks/useAuth";
import useWarranty from "../../hooks/useWarranty";
import { ApplicationState } from "../../redux/store";
import COLORS from "../../styles/colors";
import ExtraWarrantyHistory from "./component/ExtraWarrantyHistory";
import SimpleCard2 from "./component/SimpleCard2";

export default function ExtraWarranty() {
  const classes = useStyles();
  const masterData = useSelector(
    (state: ApplicationState) => state.masterDataReferral
  );
  const [isShowModalEditExtraWarranty, setIsShowModalEditExtraWarranty] =
    useState(false);
  const { fetchCurrentWarranty, updateExtraWarranty } = useWarranty();
  const [year, setYear] = useState<string>("");

  useEffect(() => {
    fetchCurrentWarranty();
  }, []);

  const handleCloseModalEditExtraWarranty = () => {
    setIsShowModalEditExtraWarranty(false);
  };

  const handleOpenModalEditWarranty = () => {
    setIsShowModalEditExtraWarranty(true);
  };
  const submit = () => {
    handleCloseModalEditExtraWarranty();
    updateExtraWarranty({
      year: year,
    });
  };

  return (
    <Layout title="Extra Warranty" loading={masterData.isLoadingExtraWarranty}>
      <HeaderPage
        title="Extra Warranty"
        subtitle="Extra Warranty Configuration"
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <SimpleCard2
            loading={masterData.isLoadingExtraWarranty}
            title="Extra Warranty"
            value={masterData.extraWarranty.year}
            topRightIconComponent={
              <CheckCircle style={{ width: 20, height: 20 }} />
            }
            bottomRightComponent={
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                style={{ cursor: "pointer" }}
                onClick={() => handleOpenModalEditWarranty()}
              >
                <Typography variant="subtitle1" color="primary">
                  Edit Extra Warranty
                </Typography>
                <ChevronRight color={COLORS.greyDark} size={15} />
              </Box>
            }
          />
        </Grid>
      </Grid>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: 10, paddingTop: 10, paddingBottom: 10 }}
      >
        <Typography>Extra Warranty History</Typography>
      </Box>

      <Paper elevation={3}>
        <ExtraWarrantyHistory />
      </Paper>

      {/** EDIT EXTRA WARRANTY */}
      <Modal
        open={isShowModalEditExtraWarranty}
        onClose={handleCloseModalEditExtraWarranty}
      >
        <div className={classes.containerModal}>
          <div className={classes.contentModal}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h5">Extra Warranty</Typography>

              <div onClick={() => handleCloseModalEditExtraWarranty()}>
                <Close />
              </div>
            </Box>

            <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
              <Grid item xs={1}>
                <FormInput
                  value={year}
                  onChange={(value: any) => setYear(value)}
                  type="number"
                  required
                  // label="Value Warranty"
                  placeholder="6"
                />
              </Grid>
              <Grid item xs={11}>
                <p style={{ marginLeft: 3, marginTop: -1 }}>Bulan</p>
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="flex-end">
              <Box>
                <AppButton
                  color="secondary"
                  onClick={handleCloseModalEditExtraWarranty}
                >
                  Cancel
                </AppButton>
              </Box>
              <Box>
                <AppButton onClick={() => submit()}>Save</AppButton>
              </Box>
            </Box>
          </div>
        </div>
      </Modal>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerModal: {
      position: "absolute",
      width: "100%",
      height: "100%",
      alihandleCloseModalApprovalgnContent: "center",
    },
    contentModal: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
      width: 400,
      margin: "0 auto",
      marginTop: "10%",
      borderRadius: 10,
    },
  })
);

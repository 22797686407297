import { useDispatch } from "react-redux";
import {
  apiAddImageRecipeCategory,
  apiCreateRecipeCategory,
  apiDeleteRecipeCategory,
  apiFindAllRecipeCategory,
  apiFindOneRecipeCategory,
  apiUpdateRecipeCategory,
} from "../api/recipe-category";
import {
  setLoadingRecipeCategory,
  setErrorRecipeCategory,
  findAllRecipeCategory,
  findOneRecipeCategory,
  createRecipeCategory,
  updateRecipeCategory,
  deleteRecipeCategory,
} from "../redux/action/recipe-category.action";
import { useHistory } from "react-router-dom";
import { setUpladImageStatus } from "../redux/action/config-app.action";
import { generateQueryString } from "../helpers/functions/generateQueryString";
import { setErrorBanner } from "../redux/action/content/banner.action";

export default function useRecipeCategory() {
  const dispatch = useDispatch();
  const history = useHistory();

  const fetchAllRecipeCategory = async (params: any) => {
    try {
      dispatch(setLoadingRecipeCategory(true));
      let query = generateQueryString(params);

      const result = await apiFindAllRecipeCategory(query);

      dispatch(findAllRecipeCategory(result.data));

      dispatch(setLoadingRecipeCategory(false));
    } catch (error: any) {
      dispatch(setLoadingRecipeCategory(false));
      dispatch(setErrorRecipeCategory(error.response));
    }
  };

  const fetchOneRecipeCategory = async (categoryId: string) => {
    try {
      dispatch(setLoadingRecipeCategory(true));

      const result = await apiFindOneRecipeCategory(categoryId);

      dispatch(findOneRecipeCategory(result.data.payload));

      dispatch(setLoadingRecipeCategory(false));
    } catch (error: any) {
      dispatch(setLoadingRecipeCategory(false));
      dispatch(setErrorRecipeCategory(error.response));
    }
  };

  const addRecipeCategory = async (data: any) => {
    try {
      dispatch(setLoadingRecipeCategory(true));
      const result = await apiCreateRecipeCategory(data);

      if (result.data.payload) {
        dispatch(createRecipeCategory(result.data.payload));
      }

      dispatch(setLoadingRecipeCategory(false));
      dispatch(setUpladImageStatus(true));
      return result
    } catch (error: any) {
      dispatch(setLoadingRecipeCategory(false));
      dispatch(setErrorRecipeCategory(error.response));
    }
  };

  const addImageRecipeCategory = async (data: any) => {
    try {
      dispatch(setLoadingRecipeCategory(true));
      const result = await apiAddImageRecipeCategory(data);

      if (result.data.payload) {
        dispatch(createRecipeCategory(result.data.payload));
      }

      dispatch(setLoadingRecipeCategory(false));
      dispatch(setUpladImageStatus(true));
    } catch (error: any) {
      dispatch(setLoadingRecipeCategory(false));
      dispatch(setErrorRecipeCategory(error.response));
    }
  };

  const updateOneRecipeCategory = async (data: any) => {
    try {
      dispatch(setLoadingRecipeCategory(true));
      console.log(data)

      const result = await apiUpdateRecipeCategory(data);

      if (result.data.payload) {
        dispatch(updateRecipeCategory(result.data.payload));
        
      }

      dispatch(setLoadingRecipeCategory(false));
      dispatch(setUpladImageStatus(true));
      return result
    } catch (error: any) {
      dispatch(setLoadingRecipeCategory(false));
      dispatch(setErrorRecipeCategory(error.response));
    }
  };

  const removeRecipeCategory = async (categoryId: string) => {
    try {
      dispatch(setLoadingRecipeCategory(true));

      await apiDeleteRecipeCategory(categoryId);

      dispatch(deleteRecipeCategory(categoryId));

      dispatch(setLoadingRecipeCategory(false));
    } catch (error: any) {
      dispatch(setLoadingRecipeCategory(false));
      dispatch(setErrorRecipeCategory(error.response));
    }
  };

  return {
    fetchAllRecipeCategory,
    fetchOneRecipeCategory,
    addRecipeCategory,
    updateOneRecipeCategory,
    removeRecipeCategory,
    addImageRecipeCategory
  };
}

import { AdminSingle } from './admin.type';
import { CustomerProperties } from './customer.type';

export enum EVoucherDiscountType {
  nominal = 'nominal',
  percentage = 'percentage',
} 

export enum EVoucherStatus {
  pending = 'pending',
  used = 'used',
  available = 'available',
  expired = 'expired',
} 

export enum EVoucherType {
  generate = 'generate',
  partner = 'partner',
}

export interface VoucherProperties {
  voucherCode: string;
  voucherEventId: string;
  discountType: EVoucherDiscountType;
  discountValue: string;
  maxDiscountValue: number;
  requirement: any;
  limitMaxUsePerPerson: number;
  limitMaxUseUser: number;
  validStartAt: Date;
  validEndAt: Date;
  refCommissionType: EVoucherDiscountType;
  refCommissionValue: number;
  voucherType: EVoucherType;
  partnerId: string;
  voucherStatus: EVoucherStatus;
  updatedAt: Date;
  createdAt: Date;
  event: VoucherEventProperties
  partner?: CustomerProperties
  totalUsed?: number
}

export const initialVoucherEvent: VoucherEventProperties = {
  voucherEventId: '',
  title: '',
  description: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  tags: []
}

export const initialVoucherData: VoucherProperties = {
  voucherCode: '',
  voucherEventId: '',
  discountType: EVoucherDiscountType.nominal,
  discountValue: '',
  maxDiscountValue: 0,
  requirement: {},
  limitMaxUsePerPerson: 0,
  limitMaxUseUser: 0,
  validStartAt: new Date(),
  validEndAt: new Date(),
  refCommissionType: EVoucherDiscountType.nominal,
  refCommissionValue: 0,
  voucherType: EVoucherType.generate,
  partnerId: '',
  voucherStatus: EVoucherStatus.pending,
  updatedAt: new Date(),
  createdAt: new Date(),
  event: initialVoucherEvent,
  totalUsed: 0
}

export interface VoucherEventProperties {
  voucherEventId: string
  title: string
  description: string
  createdAt: Date
  updatedAt: Date
  vouchers?: VoucherProperties[]
  tags: VoucherTagProperties[];
}

export interface VoucherTagProperties {
  voucherTagId: string | null
  name: string
  published?: boolean
  createdAt?: Date
  updatedAt?: Date
  events?: VoucherEventProperties[];
}

export interface IVoucherQuery {
  limit: number,
  offset?: number,
  page: number,
  validStartAt?: Date | string,
  validEndAt?: Date | string,
  search?: string,
  eventName?: string,
  voucherType: EVoucherType,
  discountType?: EVoucherDiscountType | null,
  partnerId?: string,
  voucherStatus?: EVoucherStatus | null
  tags?: string[]
}

export interface IVoucherTags {
  search?: string | null,
  published: boolean,
  exclude?: string[] | null
}

export interface VoucherAppState {
  chefName: string
}

export interface VoucherEventReport extends VoucherProperties {
  voucherGenerate?: VoucherGenerateProperties
  voucherValidator?: VoucherValidatorProperties
}

export interface VoucherGenerateProperties {
  voucherCode: string
  phoneNumber: string
  invoiceId: string
  totalTransaction: number
  createdByMetadata: AdminSingle
  createdAt: Date
  updatedAt: Date
}

export interface VoucherValidatorProperties {
  voucherCode: string
  usedByMetadata: AdminSingle
  usedAt: Date
  totalTransaction: number
  createdAt: Date
  updatedAt: Date
}

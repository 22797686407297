import { FormControl, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import { Link } from "react-router-dom";
import useGlobalStyles from "../../styles/globalStyles";
import AppButton from "../../components/Form/AppButton";
import HeaderPage from "../../components/Layout/HeaderPage";
import Search from "../../components/Form/Search";
import useCategory from '../../hooks/useCategory';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from '../../hooks/useDebounce';
import { ApplicationState } from '../../redux/store';
import { findOneCategory } from '../../redux/action/category.action';
import { generateQueryString } from '../../helpers/functions/generateQueryString';
import { useLocation } from 'react-router-dom';
import { setLatestParams, enableUpdateParams } from '../../redux/action/config-app.action';

const headers: ITableHeader[] = [
  {
    title: "categoryId",
    column: "categoryId",
    type: "id",
  },
  {
    title: "Category ID",
    column: "categoryId",
    width: '20%'
  },
  {
    title: "Image",
    column: "imageUrl",
    type: "image",
  },
  {
    title: "Name",
    sortable: true,
    column: "categoryName",
    width: "20%",
  },
  {
    title: "Status",
    column: "published",
    type: 'status-boolean'
  },
  {
    title: "Created At",
    column: "createdAt",
    type: 'date'
  },
  {
    title: "Updated By",
    column: JSON.stringify(['adminMetadata', 'adminName']),
    type: 'nested'
  },
];

export default function Category() {
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();

  const categoryState = useSelector((state: ApplicationState) => state.category);

  const [page, setPage] = useState(1);
  const [filterStatus, setFilterStatus] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState<string | null>(null);
  const [query, setQuery] = useState({ limit: 25, offset: 0, search: '', published: 1 });

  const { fetchAllCategory } = useCategory();
  const debouncedTitle = useDebounce(search, 300);

  const location = useLocation();

  const { updateParams, latestParams } = useSelector((state: ApplicationState) => state.configApp)

  useEffect(() => {
    searchBank(search);
  }, [debouncedTitle]);

  const searchBank = async (value: any) => {
    if (value !== null && value.length > 0) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          limit: 25,
          offset: 0,
          search: value,
          published: 1,
        };
      });
    } else if (value !== null) {
      setQuery({ limit: 25, offset: 0, search: '', published: 1 });
    }
  };

  useEffect(() => {
    if (updateParams) {
      console.info('update true')
      dispatch(setLatestParams({
        pathname: location.pathname,
        params: generateQueryString(query),
        query: {
          limit: query['limit'],
          offset: query['offset'],
          published: query['published'],
          page
        }
      }))
      fetchAllCategory(query)
    } else {
      console.info('update false')
      setPage(latestParams.query.page || page || 1)
      fetchAllCategory(latestParams.query)
      dispatch(enableUpdateParams(true))
    }
  }, [query])

  useEffect(() => {
    setTotalData(categoryState.data.payload.count);
    if (categoryState.data.payload.count > 0) {
      setTotalPage(Math.ceil(categoryState.data.payload.count / rowsPerPage));
    } else {
      setTotalPage(1)
    }
  }, [categoryState.data]);

  function changePagination(value: number) {
    setPage(value);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
        limit: rowsPerPage
      };
    });
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value);
    setPage(1)
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: 0,
        limit: value
      };
    });
  }

  function setFilter(value: number) {
    setFilterStatus(value);
    setQuery((val: any) => {
      return {
        ...val,
        published: value,
      };
    });
  }

  return (
    <Layout title="Category" loading={categoryState.isLoadingCategory}>
      <HeaderPage title="Category" subtitle="List of all category" />

      <Grid container spacing={3} justify="space-between">
        <Grid item xs={12} md={5} lg={7}>
          <Link
            to={{ pathname: "create-category" }}
            className={globalClasses.link}
          >
            <AppButton
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
            >
              Add Category
            </AppButton>
          </Link>
        </Grid>
        <Grid item xs={12} md={3} lg={2} alignItems="flex-end" alignContent="flex-end">
          <FormControl style={{ width: '100%' }}>
            <InputLabel id="select-status" required>
              Status
            </InputLabel>
            <Select
              label="Status"
              placeholder="Status"
              labelId="select-status"
              id="demo-customized-select"
              value={filterStatus}
              onChange={(event: any) => {
                setFilter(event.target.value);
              }}
            >
              <MenuItem value={1}>Published</MenuItem>
              <MenuItem value={0}>Unpublished</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Search
            placeholder="Search by category name"
            onChange={(value: string) => setSearch(value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <CustomizedTable
            data={categoryState.data.payload.results}
            headers={headers}
            totalData={totalData}
            showAction={["detail", "edit"]}
            page={page}
            totalPage={totalPage}
            rowsPerPage={rowsPerPage}
            onPressDetail={(data: any) => {
              dispatch(findOneCategory(data));
            }}
            onPressEdit={(data: any) => {
              dispatch(findOneCategory(data));
            }}
            onChange={(newPage) => changePagination(newPage)}
            onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
          />
        </Grid>
      </Grid>
    </Layout>
  );
}

import { LinearProgress, withStyles, Theme, createStyles } from '@material-ui/core';
import React from "react";

export const LoadingIndicator = (props: any) => {
  return (
    <BorderLinearProgress {...props} />
  )
}

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 6,
      top: 63,
    },
    colorPrimary: {
      backgroundColor: 'rgba(255,255,255,0.4)'
    },
    bar: {
      backgroundColor: '#FABB43',
    },
  }),
)(LinearProgress);
import axios from "axios";
import { getHeaders } from "../helpers/auth";
import { ESparepartTransactionStatus } from "../types/fulfillment-sparepart.types";

export async function apiFindAllTransaction(params: any) {
  const headers = await getHeaders();
  const query = params || null;
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/sparepart/transactions" + query,
    method: "GET",
    headers,
  });
}

export async function apiFindOneTransaction(transactionId: string) {
  const headers = await getHeaders();
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/sparepart/transactions" + transactionId + "/by-transaction",
    method: "GET",
    headers,
  });
}

export async function apiProcessStatusSparepartTransaction(
  transactionId: string,
  data: {
    newStatus: ESparepartTransactionStatus;
    remark?: string;
  }
) {
  const headers = await getHeaders();
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/sparepart/transactions/" + transactionId,
    method: "PATCH",
    headers,
    data,
  });
}

export async function apiFindHistorySparepartTransaction(
  queryString: string,
  specialParams: { transactionId: string }
) {
  console.info("apiFindHistoryTransaction::: specialParams", specialParams);
  const headers = await getHeaders();
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      "/sparepart/transactions/" +
      specialParams.transactionId +
      "/history_status" +
      queryString,
    method: "GET",
    headers,
  });
}

import axios from 'axios';
import { getHeaders } from '../helpers/auth';

export async function apiFindAllReportReferral(params: string) {
  const headers = await getHeaders();
  const query = params || null;
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      '/referral/customer/referral-report' +
      query,
    method: 'GET',
    headers,
  });
}

export async function apiFindAllReportReferralSimple(params: string) {
  const headers = await getHeaders();
  const query = params || null;
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      '/referral/customer/referral-report-simple' +
      query,
    method: 'GET',
    headers,
  });
}

export async function apiFindAllReportReferralDetail(query: string) {
  const headers = await getHeaders();
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      '/referral/customer/referral-report-downline' +
      (query || null),
    method: 'GET',
    headers,
  });
}

export async function apiExportDownline(customerId: string) {
  const headers = await getHeaders();
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      '/referral/customer/referral-report-downline-export/' +
      customerId,
    method: 'POST',
    headers,
    responseType: 'blob',
  });
}

import axios from 'axios';
import { getToken, removeToken} from '../auth';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL
axios.defaults.timeout = 60000

export const httpRequestExport = axios.create();

httpRequestExport.interceptors.request.use(
  async (config: any) => {
    config.headers['Content-Type'] = 'application/json'
    config.headers['Authorization'] = 'Bearer ' + await getToken()
    config.responseType = 'blob'
    return config;
  },
  (error) => {
    console.error('httpRequest: Error interceptor request:::', error.response)
    return Promise.reject(error);
  }
)

httpRequestExport.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error && error.response) {
      if (
        error.response.status === 403 ||
        error.response.data.code === 'err_unauthorized'
      ) {
        removeToken();
        window.location.href = '/';
      }
      console.error(
        'httpRequest: Error interceptor response:::',
        error.response
      );
      return Promise.reject(error.response);
    } else {
      console.error(error);
    }
  }
)
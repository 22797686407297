import React, { useEffect, useState } from 'react';
import {
  Typography,
  CardMedia,
  Paper,
  createStyles,
  makeStyles,
  Theme,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Select,
  Box,
  Tooltip,
  Chip,
  IconButton,
  Dialog,
  DialogContent,
  Grid,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import { Link, useLocation } from 'react-router-dom';
import AppButton from '../Form/AppButton';
import { convertNumToCurrency } from '../../helpers/functions/currency';
import { Edit2, Eye, Trash2, ChevronDown, ChevronUp } from 'react-feather';
import COLORS from '../../styles/colors';
import PlaceholderImageSquare from '../../assets/default_1000x1000.png';
import { convertDateTimeToText } from '../../helpers/functions/datetime';
import { CategoryResponse } from '../../types/category.type';
import { TagResponse } from '../../types/tag.type';
import { LabelStatusTransaction } from '../StatusTransaction';
import { CheckCircle, Close, DragIndicator, Remove } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { BannerChangeOrderRequest } from '../../types/banner.type';
import { resourceLimits } from 'worker_threads';
import { ChangeOrderRequest } from '../../types/storefront.type';

// TABLE
const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '10px 10px'
    },
    head: {
      // backgroundColor: theme.palette.common.black,
      // color: theme.palette.common.white,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      fontWeight: 'bold',
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const StyledPagination = withStyles((theme: Theme) =>
  createStyles({
    ul: {
      margin: 5,
    },
    // '&Item-root': {
    //   height: 28,
    //   minWidth: 28,
    // },
  })
)(Pagination);

export interface ITableHeaderDnd {
  title: string;
  align?: 'left' | 'center' | 'right';
  alignContent?: 'left' | 'center' | 'right';
  sortable?: boolean;
  column: string | any;
  type?:
  | 'image'
  | 'text'
  | 'currency'
  | 'percentage'
  | 'tags'
  | 'status'
  | 'image-product'
  | 'nested'
  | 'nested-price'
  | 'nested-number'
  | 'id'
  | 'params'
  | 'date'
  | 'dd mmm yyyy hh:mm'
  | 'status-boolean'
  | 'escape-string'
  | 'product-categories'
  | 'status-membership'
  | 'status-transaction'
  | 'nested-stock'
  | 'checkmark'
  | 'image-etalase'
  | 'order-handle';
  imageSize?: 'small' | 'medium' | 'small-landscape' | 'medium-portrait'; // required only if type = 'image'
  width?: string | number | null;
  format?: any;
  imageResolution?: '500x500' | '1200x600' | '1200x840' | '1200x1200';
}

export interface IPropsCustomizedDnDTable {
  headers: ITableHeaderDnd[];
  data: any[];
  totalData: number;
  showAction?: string[];
  showInfo?: 'right';
  page: number;
  totalPage: number;
  rowsPerPage: number;
  selection?: boolean;
  selected?: Array<string | number>;
  sort?: { column: string; sort: 'DESC' | 'ASC' }[];
  hidePagination?: boolean;
  dragColumn?: boolean;
  onSelected?: (id: string) => void;
  onSelectAll?: () => void;
  onUnselectAll?: () => void;
  onChange: (page: number) => void;
  onRowsPerPageChange: (value: any) => void;
  onPressDetail?: (item: any) => void;
  onPressEdit?: (item: any) => void;
  onPressEditDialog?: (item: any) => void;
  onDeleteItem?: (item: any) => void;
  onChangeSort?: (newSort: { column: string; sort: 'DESC' | 'ASC' }[]) => void;
  onChangeOrder?: (data: ChangeOrderRequest) => void;
}

export default function CustomizedDnDTable(props: IPropsCustomizedDnDTable) {
  const location = useLocation();
  const classes = useStyles();

  const [willBeDeleted, setWillBeDeleted] = useState(false);
  const [isShowDeleteConfirmation, setIsShowDeleteConfirmation] = useState(
    false
  );
  const [data, setData] = useState<any>([])

  useEffect(() => {
    setData(props.data)
  }, [props.data])

  const confirmDelete = (data: any) => {
    setWillBeDeleted(data);
    setIsShowDeleteConfirmation(true);
  };

  const handleClose = () => {
    //
    setIsShowDeleteConfirmation(false);
  };

  const deleteItem = () => {
    props.onDeleteItem ? props.onDeleteItem(willBeDeleted) : console.info('');
    handleClose();
  };

  const handleClickSort = (column: string) => {
    let list = props.sort;

    if (list && Array.isArray(list) && list.length > 0) {
      list = list.map((item) => {
        if (item.column === column) {
          if (item.sort === 'DESC') {
            item = {
              ...item,
              sort: 'ASC',
            };
          } else {
            item = {
              ...item,
              sort: 'DESC',
            };
          }
        }
        return item;
      });
      if (props.onChangeSort) {
        props.onChangeSort(list);
      }
    }
  };

  const renderTags = (tags: TagResponse[]) => {
    return tags.map((item: TagResponse, index: number) => {
      return (
        <Chip
          key={index}
          size="small"
          label={item.tagName}
          style={{ marginRight: 5 }}
        />
      );
    });
  };

  const renderStatus = (status: 'published' | 'unpublished') => {
    const statusRendered = status[0].toUpperCase() + status.substring(1);
    if (status === 'published') {
      return (
        <Chip
          size="small"
          color="primary"
          label={statusRendered}
          style={{ marginRight: 5 }}
        />
      );
    } else if (status === 'unpublished') {
      return (
        <Chip
          size="small"
          color="primary"
          variant="outlined"
          label={statusRendered}
        />
      );
    }
  };

  const renderStatusBoolean = (status: boolean) => {
    if (status) {
      return <Chip size="small" color="primary" label="Published" />;
    } else {
      return (
        <Chip
          size="small"
          color="primary"
          variant="outlined"
          label="Unpublished"
        />
      );
    }
  };

  const renderStatusMembership = (status: 'reguler' | 'premium') => {
    if (status === 'premium') {
      return <Chip size="small" color="primary" label="Premium" />;
    } else {
      return (
        <Chip size="small" color="primary" variant="outlined" label="Reguler" />
      );
    }
  };

  const renderProductImageList = (item: any, header: string) => {
    if (item[header]) {
      if (typeof item[header] !== 'string' && item[header].length > 0) {
        return item[header][0]['imageUrl'];
      } else if (typeof item[header] === 'string') {
        return item[header]
      }
    } else {
      return PlaceholderImageSquare;
    }
  };

  const renderNestedValue = (item: any, header: string, type: string | any) => {
    let result = JSON.parse(header).reduce((a: any, v: any) => a[v], item);
    if (result === 0) {
      if (type === 'nested-stock') {
        return (
          <Typography style={result <= 5 ? { color: COLORS.error, fontWeight: 'bold' } : {}}>
            {result}
          </Typography>
        )
      }
      return result;
    } else if (!result) {
      return <span className={classes.dataEmpty}>Not Set</span>
    } else if (type && type === 'nested-price') {
      return convertNumToCurrency(result);
    } else if (type && type === 'nested-number') {
      return result;
    } else if (type && type === 'nested-stock') {
      return (
        <Typography style={result <= 5 ? { color: COLORS.error, fontWeight: 'bold' } : {}}>
          {result}
        </Typography>
      )
    }
    return result;
  };

  const getDataId = () => {
    return props.headers.find((header: ITableHeaderDnd) => header.type === 'id');
  };

  const getDataParams = () => {
    return props.headers.find((header: ITableHeaderDnd) => header.type === 'params');
  };

  const escapeString = (data: string) => {
    const check = data.split('_')
    if (check.length > 1) {
      return data.split('_').join(' ').toUpperCase();
    } else {
      return data.split('-').join(' ').toUpperCase();
    }
  };

  const renderMultiChip = (data: CategoryResponse[]) => {
    if (Array.isArray(data) && data.length > 0) {
      return data.map((item: CategoryResponse) => {
        const featuredProduct = item.categoryName.includes('Featured')
        if (!featuredProduct) {
          return (
            <Chip
              key={item.categoryId}
              style={{ marginRight: 10, fontSize: 12 }}
              size="small"
              color="primary"
              label={item.categoryName}
            />
          );
        }
        return <Chip size="small" color="primary" label="Featured" />;
      });
    }
    return <Chip size="small" color="primary" label="Uncategorized" />;
  };

  const renderCheckMark = (data: string) => {
    if (data) {
      return <div style={{ marginTop: 8 }}><CheckCircle style={{ color: COLORS.primary, }} /></div>
    }
    return <div style={{ marginTop: 8 }}><Remove style={{ color: grey[500] }} /></div>
  }

  let totalColumn = props.headers.length;
  if (props.selection) {
    totalColumn++;
  }
  if (props.showAction) {
    totalColumn++;
  }
  if (props.showInfo) {
    totalColumn++;
  }

  let isAllSelected = true;
  if (props.selection) {
    if (props.data.length === 0) {
      isAllSelected = false;
    } else {
      props.data.forEach((item) => {
        if (!(props.selected && props.selected.includes(item.id))) {
          isAllSelected = false;
        }
      });
    }
  } else {
    isAllSelected = false;
  }

  const renderPagination = (val: { totalData: number, rowsPerPage: number, totalPage: number, page: number, data: number }) => {
    let paginationStatus = ''
    let currentIndex = val.totalData > 0 ? 1 : 0
    let currentIndexOf = val.totalData > val.rowsPerPage ? val.rowsPerPage : val.totalData

    if (val.page > 1) {
      currentIndex = val.rowsPerPage * (val.page - 1) + 1
      currentIndexOf = val.page === val.totalPage ? val.totalData : val.rowsPerPage * val.page
    }

    paginationStatus = currentIndex + '-' + currentIndexOf + ' of ' + val.totalData

    if (val.totalData === 0) {
      paginationStatus = '0 of 0'
    }

    return paginationStatus

  }

  // a little function to help us with reordering the result
  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  function onDragEnd(result: any) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    if (result.source.index !== result.destination.index) {
      const found = data.find((el: any) => el.id === result.draggableId)

      const items = reorder(
        data,
        result.source.index,
        result.destination.index
      )

      props.onChangeOrder && props.onChangeOrder({
        id: result.draggableId,
        currentIndex: result.destination.index,
        prevIndex: found.order || result.source.index
      })

      setData(items)
    }

  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
            {props.selection && (
              <StyledTableCell align="center">
                {isAllSelected ? (
                  <IconButton
                    aria-label="select-and-unselect"
                    onClick={() =>
                      props.onUnselectAll
                        ? props.onUnselectAll()
                        : console.info('do nothing onUnselectAll')
                    }
                  >
                    <CheckBoxIcon />
                  </IconButton>
                ) : (
                    <IconButton
                      aria-label="select-and-unselect"
                      onClick={() =>
                        props.onSelectAll
                          ? props.onSelectAll()
                          : console.info('do nothing onSelectAll')
                      }
                    >
                      <CheckBoxOutlineBlankIcon />
                    </IconButton>
                  )}
              </StyledTableCell>
            )}
            {props.headers.map(
              (header) =>
                header.type !== 'id' &&
                header.type !== 'params' && (
                  <StyledTableCell
                    key={header.title}
                    align={header.align ? header.align : 'left'}
                  >
                    { header.type === 'order-handle' ? <Box /> :
                      (
                        <Box
                          display={header.sortable ? 'flex' : 'block'}
                          flexDirection="row"
                          justifyContent="space-between"
                          alignItems="center"
                          onClick={() =>
                            header.sortable ? handleClickSort(header.column) : false
                          }
                          style={header.sortable ? { cursor: 'pointer' } : {}}
                        >
                          <Box>{header.title}</Box>

                          {header.sortable &&
                            props.sort &&
                            props.sort.find(
                              (item) => item.column === header.column
                            ) &&
                            (
                              props.sort.find(
                                (item) => item.column === header.column
                              ) as any
                            ).sort === 'DESC' ? (
                              <ChevronDown
                                onClick={() => handleClickSort(header.column)}
                                size={18}
                                style={{ cursor: 'pointer' }}
                                color={COLORS.primary}
                              />
                            ) : header.sortable &&
                              props.sort &&
                              props.sort.find(
                                (item) => item.column === header.column
                              ) &&
                              (
                                props.sort.find(
                                  (item) => item.column === header.column
                                ) as any
                              ).sort === 'ASC' ? (
                                <ChevronUp
                                  onClick={() => handleClickSort(header.column)}
                                  size={18}
                                  style={{ cursor: 'pointer' }}
                                  color={COLORS.primary}
                                />
                              ) : header.sortable && props.sort ? (
                                <ChevronUp
                                  // onClick={() => handleClickSort(header.column)}
                                  size={18}
                                  style={{ cursor: 'pointer' }}
                                  color={COLORS.grey500}
                                />
                              ) : (
                                  false
                                )}
                        </Box>
                      )
                    }
                  </StyledTableCell>
                )
            )}
            {props.showAction ? (
              <StyledTableCell align="center">Actions</StyledTableCell>
            ) : (
                false
              )}
            {props.showInfo === 'right' ? (
              <StyledTableCell align="left"></StyledTableCell>
            ) : (
                false
              )}
          </StyledTableRow>
        </TableHead>
        {/* props.dragColumn */}
        {/* <TableBody component={DroppableComponent(onDragEnd)}>
          {[1,2,3,4,5,6,7].map((item, index) => (
            <TableRow component={DraggableComponent(item.id, index)} key={item.id} >
              <TableCell scope="row">{index + 1}</TableCell>
              <TableCell>{item.primary}</TableCell>
              <TableCell align="right">{item.secondary}</TableCell>
            </TableRow>
          ))}
        </TableBody> */}
        <TableBody
          component={DroppableComponent(onDragEnd)}
        >
          {data.length === 0 && (
            <StyledTableRow>
              <StyledTableCell
                colSpan={totalColumn}
                component="th"
                scope="row"
                style={{
                  textAlign: 'center',
                }}
              >
                <Typography variant="subtitle1">Data not found</Typography>
              </StyledTableCell>
            </StyledTableRow>
          )}
          {data.map((row: any, key: number) => (
            <TableRow
              component={DraggableComponent(row[getDataId()?.column], key)}
              key={'row_' + key}
            >
              {props.selection && (
                <StyledTableCell align="center">
                  <IconButton
                    aria-label="select-and-unselect"
                    onClick={() =>
                      props.onSelected
                        ? props.onSelected(row.id)
                        : console.info('do nothing onSelected: ' + row.id)
                    }
                  >
                    {props.selected && props.selected.includes(row.id) ? (
                      <CheckBoxIcon />
                    ) : (
                        <CheckBoxOutlineBlankIcon />
                      )}
                  </IconButton>
                </StyledTableCell>
              )}
              {props.headers.map((header: any) =>
                header.type === 'id' || header.type === 'params' ? (
                  false
                ) : header.type === 'image-product' ? (
                  <StyledTableCell
                    key={header.title}
                    component="th"
                    scope="row"
                    width={header.width ? header.width : 60}
                  >
                    <CardMedia
                      style={{ borderRadius: 10 }}
                      className={
                        header.imageSize === 'medium'
                          ? classes.mediumMediaLandscape
                          : header.imageSize === 'medium-portrait'
                            ? classes.mediumMediaPortrait
                            : header.imageSize === 'small-landscape'
                              ? classes.smallMediaLandscape
                              : classes.smallMedia
                      }
                      image={renderProductImageList(row, header.column)}
                      title={row[header.column]}
                    />
                  </StyledTableCell>
                ) : header.type === 'image' ? (
                  <StyledTableCell
                    key={header.title}
                    component="th"
                    scope="row"
                    width={header.width ? header.width : 60}
                  >
                    <CardMedia
                      style={{ borderRadius: 10 }}
                      className={
                        header.imageSize === 'medium'
                          ? classes.mediumMediaLandscape
                          : header.imageSize === 'small-landscape'
                            ? classes.smallMediaLandscape
                            : classes.smallMedia
                      }
                      image={
                        row[header.column]
                          ? row[header.column]
                          : header.imageResolution
                            ? 'assets/default_' + header.imageResolution + '.png'
                            : 'assets/default_500x500.png'
                      }
                      title={row[header.column]}
                    />
                  </StyledTableCell>
                ) : (
                        <StyledTableCell
                          key={header.title}
                          component="td"
                          scope="row"
                          width={header.width ? header.width : null}
                          style={{
                            textAlign: header.alignContent || 'left',
                            ...(row.hasOwnProperty(header.column + 'Style')
                              ? (row[header.column + 'Style'] as any)
                              : ({} as any)),
                          }}
                        >
                          {header.type === 'currency' ? (
                            convertNumToCurrency(row[header.column])
                          ) : header.type === 'percentage' ? (
                            row[header.column] * 100 + '%'
                          ) : header.type === 'tags' ? (
                            renderTags(row[header.column])
                          ) : header.type === 'status' ? (
                            renderStatus(row[header.column])
                          ) : header.type === 'status-boolean' ? (
                            renderStatusBoolean(row[header.column])
                          ) : header.type === 'status-membership' ? (
                            renderStatusMembership(row[header.column])
                          ) : header.type === 'status-transaction' ? (
                            <LabelStatusTransaction status={row[header.column]} />
                          ) : header.type === 'date' ? (
                            convertDateTimeToText(row[header.column], header.format)
                          ) : header.type === 'dd mmm yyyy hh:mm' ? (
                            convertDateTimeToText(
                              row[header.column],
                              'dd mmm yyyy hh:mm'
                            )
                          ) : header.type === 'escape-string' ? (
                            escapeString(row[header.column])
                          ) : header.type && header.type.includes('nested') ? (
                            renderNestedValue(row, header.column, header.type)
                          ) : header.type === 'product-categories' ? (
                            renderMultiChip(row[header.column])
                          ) : header.type === 'checkmark' ? (
                            renderCheckMark(row[header.column])
                          ) : header.type === 'order-handle' ? (
                            <div>
                              <DragIndicator
                                style={{
                                  color: COLORS.primary,
                                  fontSize: 24,
                                  marginTop: 10
                                }}
                              />
                            </div>
                          ) : row[header.column] ? (
                            <span style={{ lineHeight: 1.4 }}>{row[header.column]}</span>
                          ) : (<span className={classes.dataEmpty}>Not Set</span>)}
                        </StyledTableCell>
                      )
              )}

              {props.showAction ? (
                <StyledTableCell align="center" width="5%">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {props.showAction.includes('detail-dialog') && (
                      <IconButton
                        size="small"
                        onClick={() =>
                          props.onPressDetail
                            ? props.onPressDetail(row)
                            : console.info('')
                        }
                      >
                        <Eye color={COLORS.primary} size={18} />
                      </IconButton>
                    )}
                    {props.showAction.includes('detail') &&
                      props.onPressDetail ? (
                        <Link
                          // to="#"
                          to={{
                            pathname: `${location.pathname}/${row[getDataId()?.column]
                              }`,
                            search: row[getDataParams()?.column] || '',
                          }}
                          className={classes.actionIcon}
                          onClick={() =>
                            props.onPressDetail
                              ? props.onPressDetail(row)
                              : console.info('')
                          }
                        >
                          <Eye color={COLORS.primary} size={18} />
                        </Link>
                      ) : props.showAction.includes('detail') ? (
                        <Link
                          className={classes.actionIcon}
                          to={{
                            pathname: `${location.pathname}/${row[getDataId()?.column]
                              }`,
                            search: row[getDataParams()?.column] || '',
                          }}
                        >
                          <Eye color={COLORS.primary} size={18} />
                        </Link>
                      ) : (
                          false
                        )}
                    {props.showAction.includes('edit') && props.onPressEdit ? (
                      <Link
                        className={classes.actionIcon}
                        to={{
                          pathname:
                            location.pathname +
                            '/' +
                            row[getDataId()?.column] +
                            '/edit',
                        }}
                        onClick={() =>
                          props.onPressEdit
                            ? props.onPressEdit(row)
                            : console.info('')
                        }
                      >
                        <Edit2 color={COLORS.primary} size={18} />
                      </Link>
                    ) : props.showAction.includes('edit') ? (
                      <Link
                        className={classes.actionIcon}
                        to={{
                          pathname:
                            location.pathname +
                            '/' +
                            row[getDataId()?.column] +
                            '/edit',
                        }}
                      >
                        <Edit2 color={COLORS.primary} size={18} />
                      </Link>
                    ) : (
                          false
                        )}
                    {props.showAction.includes('edit-dialog') && (
                      <IconButton
                        size="small"
                        onClick={() =>
                          props.onPressEditDialog
                            ? props.onPressEditDialog(row)
                            : console.info('')
                        }
                      >
                        <Edit2 color={COLORS.primary} size={18} />
                      </IconButton>
                    )}
                    {props.showAction.includes('delete') && (
                      <Link
                        to="#"
                        className={classes.actionIcon}
                        onClick={() => confirmDelete(row)}
                      >
                        <Trash2 color={COLORS.primary} size={18} />
                      </Link>
                    )}
                  </div>
                </StyledTableCell>
              ) : (
                  false
                )}

              {props.showInfo === 'right' && row['infoMessage'] ? (
                <StyledTableCell align="right" width="5%">
                  <Tooltip title={row['infoMessage']} placement="top-end">
                    <InfoRoundedIcon />
                  </Tooltip>
                </StyledTableCell>
              ) : (
                  false
                )}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      { !props.hidePagination &&
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box p={1}>
              <Typography variant="subtitle1">Rows per page: </Typography>
            </Box>

            <Box p={1}>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={props.rowsPerPage}
                onChange={(event) => {
                  props.onRowsPerPageChange(event.target.value);
                }}
                style={{ fontSize: 12 }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                {/* <MenuItem value={'ALL'}>All</MenuItem> */}
              </Select>
            </Box>

            <Box p={1}>
              <Typography variant="subtitle1">
                {renderPagination({
                  totalData: props.totalData,
                  rowsPerPage: props.rowsPerPage,
                  totalPage: props.totalPage,
                  page: props.page,
                  data: props.data.length
                })}
                {/* {props.totalData > 0 ? '1-' : '0-'}
              {props.totalData > props.rowsPerPage
                ? props.rowsPerPage
                : props.totalData}{' '}
              of {props.totalData} */}
              </Typography>
            </Box>
          </Box>
          <Box display="flex">
            <StyledPagination
              count={props.totalPage}
              variant="outlined"
              shape="rounded"
              page={props.page}
              onChange={(event, page) => props.onChange(page)}
            />
          </Box>
        </Box>
      }


      <Dialog
        open={isShowDeleteConfirmation}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogContent style={{ padding: 16, minWidth: 400, borderRadius: 10 }}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography variant="h5" style={{ lineHeight: 1.4 }}>
              Delete Confirmation
            </Typography>

            <button onClick={() => handleClose()}>
              <Close />
            </button>
          </Box>

          <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
            <Grid item xs={12}>
              <Typography color="primary" variant="body1">
                Are you sure want to delete this item?
              </Typography>
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="flex-end">
            <AppButton onClick={() => handleClose()} color="secondary">
              Cancel
            </AppButton>
            <AppButton onClick={() => deleteItem()}>Yes</AppButton>
          </Box>
        </DialogContent>
      </Dialog>
    </TableContainer>
  );
}

const getItemStyle = (isDragging: boolean, draggableStyle: any, index: number) => {
  const style = {
    // styles we need to apply on draggables
    ...draggableStyle,
    ...(isDragging ? {
      background: "green !important"
    } : index % 2 === 0 ? { background: 'rgba(0, 0, 0, 0.04)' } : { background: 'white' })
  }
  return style
}

const DraggableComponent: any = (id: any, index: any) => (props: any) => {

  return (
    <Draggable draggableId={id} index={index}>
      {(provided: any, snapshot: any) => (
        <TableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          // style={{ background: 'red' }}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, index)}
          {...props}
        >
          {props.children}
        </TableRow>
      )}
    </Draggable>
  )
}

const DroppableComponent: any = (
  onDragEnd: (result: any, provided: any) => void) => (props: any) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={'1'} direction="vertical">
          {(provided: any) => {
            return (
              <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
                {props.children}
                {provided.placeholder}
              </TableBody>
            )
          }}
        </Droppable>
      </DragDropContext>
    )
  }


const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 700,
  },
  containerModal: {
    position: 'absolute',
    width: '60%',
    height: '100%',
    padding: theme.spacing(35, 35, 10, 35),
    alignContent: 'center',
  },
  contentModal: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  mediumMedia: {
    width: 197,
    height: 197,
  },
  mediumMediaLandscape: {
    width: 197,
    height: 100,
  },
  mediumMediaPortrait: {
    width: 150,
    height: 180,
  },
  smallMedia: {
    width: 50,
    height: 50,
  },
  smallMediaLandscape: {
    width: 120,
    height: 80,
  },
  actionIcon: {
    // margin: theme.spacing(0, 1, 0, 0),
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
  },
  dataEmpty: {
    fontStyle: 'italic',
    opacity: 0.5,
  },
}));

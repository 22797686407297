/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Typography,
  Paper,
  createStyles,
  makeStyles,
  Theme,
  Box,
  Modal,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import AppButton from "../../components/Form/AppButton";
import { useLocation, useParams } from "react-router-dom";
import HeaderPage from "../../components/Layout/HeaderPage";
import { Link } from "react-router-dom";
import COLORS from "../../styles/colors";
import { Close } from "@material-ui/icons";
import FormInput from "../../components/Form/FormInput";
import { convertNumToCurrency } from "../../helpers/functions/currency";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import usePaginateTable, { IPaginateTable } from '../../hooks/usePaginateTable';
import { apiFetchListCommissionHistorical, apiGetTotalCommission, SingleCommission } from '../../api/commission';
import { generateQueryString } from '../../helpers/functions/generateQueryString';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../redux/store';
import useCustomer from '../../hooks/useCustomer';
import { parseURLSearch } from '../../helpers/functions/url';

let headers: ITableHeader[] = [
  {
    title: 'Name',
    align: 'left',
    column: 'customerName',
  },
  {
    title: 'Transaction Date',
    align: 'left',
    sortable: true,
    type: 'dd mmm yyyy hh:mm',
    column: 'createdAt',
  },
  {
    title: 'Transaction ID',
    align: 'left',
    column: 'invoiceId',
  },
  {
    title: 'Total Transaction',
    align: 'left',
    type: 'currency',
    column: 'totalTransaction',
  },
  {
    title: 'Commision',
    align: 'left',
    column: 'commisionPercentage',
  },
  {
    title: 'Amount Receive',
    align: 'left',
    type: 'currency',
    column: 'amount',
  },
];

type IDisbursementDetail = {
  id: string;
  id_trx: string;
  name: string;
  email: string;
  phone: string;
  roles: string[];
  image: string;
  gudang: string;
  status: string | number;
  created_at: string;
  paid_at: string;
  rekening_bank: string;
  total_refund: number;
  commision: number;
};

export default function DisbursementDetail() {
  const location = useLocation();
  let { customerId } = useParams<{ customerId: string }>();
  const { startAt, endAt, filterStatus } = parseURLSearch(location.search);

  const classes = useStyles();

  const customerState = useSelector(
    (state: ApplicationState) => state.customer
  );
  const { fetchOneCustomer } = useCustomer();
  useEffect(() => {
    fetchOneCustomer(customerId);
  }, [])

  const [isShowModalFormCancelOrder, setIsShowModalFormCancelOrder] =
    useState(false);
  const [isShowModalFormInputResi, setIsShowModalFormInputResi] =
    useState(false);
  const [isShowModalFormRefund, setIsShowModalFormRefund] = useState(false);

  const defaultParamsStartAt = startAt?new Date(startAt):null;
  const defaultParamsEndAt = endAt?new Date(endAt):null;
  const {
    onChangePage,
    onChangeRowsPerPage,
    fetchList,
    params,
    data,
    page,
    totalPage,
    totalData,
    rowsPerPage,
  }: IPaginateTable & { data: SingleCommission[] } = usePaginateTable(
    apiFetchListCommissionHistorical,
    {
      userId: customerId,
      limit: 25,
      offset: 0,
      filterStatus,
      startAt: defaultParamsStartAt
        ? defaultParamsStartAt.toISOString()
        : '',
      endAt: defaultParamsEndAt
        ? defaultParamsEndAt.toISOString()
        : '',
    },
    1,
    null,
    false
  );

  let listCommission: SingleCommission[] = [];
  if (data.length > 0) {
    listCommission = data.map((item: SingleCommission) => ({
      ...item,
      invoiceId: item.invoice ? item.invoice.invoiceId : null,
      customerName: item.commissionMetadata
        ? (item.commissionMetadata.sourceUserName || '-') + (item.commissionMetadata.source !== 'downline_transaction' ? ' (You)' : '')
        : null,
      totalTransaction: item.txMetadata.transactionPrice,
      commisionPercentage: item.commissionMetadata.commissionPercentage,
    }));
  }

  //
  // totalCommission
  const [totalCommission, setTotalCommission] = useState(0);
  const [paramsTotalCommission, setParamsTotalCommission] = useState({
    userId: customerId,
    startAt: defaultParamsStartAt
        ? defaultParamsStartAt.toISOString()
        : '',
    endAt: defaultParamsEndAt
        ? defaultParamsEndAt.toISOString()
        : '',
    // filterSource: 'downline_transaction',
    filterStatus,
  });

  useEffect(() => {
    getTotalCommission();
  }, []);

  const getTotalCommission = (incomingParams: any = {}) => {
    const newParams = {
      ...paramsTotalCommission,
      ...incomingParams,
    };
    setParamsTotalCommission(newParams);
    apiGetTotalCommission(generateQueryString(newParams))
      .then((res) => {
        setTotalCommission(res.totalCommission);
      })
      .catch((err) => {
        console.error('Error getTotalCommission', err);
      });
  };
  //

  const handleCloseModalFormCancelOrder = () => {
    //
    setIsShowModalFormCancelOrder(false);
  };

  const handleCloseModalFormInputResi = () => {
    //
    setIsShowModalFormInputResi(false);
  };

  const handleCloseModalFormRefund = () => {
    //
    setIsShowModalFormRefund(false);
  };

  let title = 'Detail Disbursement';
  let subtitle = 'Detailed information about Disbursement';

  return (
    <Layout title={title}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <HeaderPage title={title} subtitle={subtitle} showBackButton />
      </Box>

      <Paper elevation={3} className={classes.paper}>
        <Grid container spacing={1} style={{ marginBottom: 10 }}>
          <Grid item xs={2}>
            <Typography color="textSecondary">Customer</Typography>
            <Typography>{customerState.customer.customerName}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography color="textSecondary">Upline</Typography>
            {customerState.customer.upline ? (
              <Typography>
                {customerState.customer.upline.customerName}
              </Typography>
            ) : (
                <Typography
                  variant="body1"
                  style={{ fontStyle: 'italic', color: COLORS.grey }}
                >
                  Not Set
                </Typography>
              )}
          </Grid>
          <Grid item xs={2}>
            <Typography color="textSecondary">Komisi Referral</Typography>
            <Typography>{convertNumToCurrency(totalCommission)}</Typography>
          </Grid>
        </Grid>

        <Box>
          <CustomizedTable
            data={listCommission}
            headers={headers}
            totalData={totalData}
            page={page}
            totalPage={totalPage}
            rowsPerPage={rowsPerPage}
            onChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
        </Box>
      </Paper>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 0, 0, 0),
      padding: theme.spacing(2),
    },
    containerForm: {},
    input: {
      padding: theme.spacing(0, 2, 1, 2),
    },
    row: {
      padding: theme.spacing(1, 0, 1, 0),
    },
    processCircle: {
      width: 25,
      height: 25,
      backgroundColor: COLORS.accent,
      borderRadius: "50%",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: COLORS.grey,
    },
    cardMedia: {
      borderColor: COLORS.grey,
      borderWidth: 1,
      borderStyle: "solid",
      width: 69,
      height: 69,
      borderRadius: 10,
      backgroundColor: COLORS.accent,
    },
    containerModal: {
      position: "absolute",
      width: "100%",
      height: "100%",
      alihandleCloseModalFormCreategnContent: "center",
    },
    contentModal: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
      width: 600,
      margin: "0 auto",
      marginTop: "10%",
      borderRadius: 10,
    },
  })
);

import {
  createStyles,
  makeStyles,
  Paper,
  InputBase,
  Theme,
  IconButton,
  InputBaseProps,
} from "@material-ui/core";
import { useState } from "react";
import { Eye, EyeOff, Lock, Mail } from "react-feather";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      marginTop: 10,
      marginBottom: 10,
      borderRadius: 40,
      paddingTop: 10,
      paddingBottom: 10,
      paddingRight: 10,
      paddingLeft: 10,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: theme.spacing(1),
      margin: 0,
    },
    iconLeft: {
      marginLeft: theme.spacing(1)
    },
    divider: {
      height: 28,
      margin: 4,
    },
  })
);

// const CustomTextField = withStyles({
//   root: {
//     "& .MuiFilledInput-root": {
//       backgroundColor: COLORS.accent,
//       borderRadius: 40,
//       borderColor: COLORS.accent,
//     },
//     "& .MuiFormLabel-root": {
//       color: COLORS.primary,
//     },
//     "& .MuiInputBase-root": {
//       color: COLORS.primary,
//     },
//     "& .MuiOutlinedInput-root": {
//       "& fieldset": {
//         backgroundColor: COLORS.accent,
//         borderRadius: 40,
//         borderColor: COLORS.accent,
//       },
//       "&:hover fieldset": {
//         borderColor: COLORS.accent,
//       },
//       "&.Mui-focused fieldset": {
//         borderColor: COLORS.accent,
//       },
//     },
//   },
// })(TextField);

type Props = {
  type: "username" | "email" | "password";
  fullWidth?: boolean;
  id?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  autoFocus?: boolean;
  onChange?: (value: any) => void;
  value?: string | number;
};

export default function TextInput(props: Props | InputBaseProps) {
  const classes = useStyles();
  const [isShowPassword, setIsShowPassword] = useState(false);

  return (
    <Paper component="form" className={classes.root}>
      {props.type === "email" && <Mail size={20} color="#9E9E9E" className={classes.iconLeft} />}
      {props.type === "password" && <Lock size={20} color="#9E9E9E" className={classes.iconLeft} />}

      <InputBase
        {...props}
        type={isShowPassword ? "text" : props.type}
        className={classes.input}
        placeholder={props.placeholder}
        inputProps={{ "aria-label": props.placeholder }}
        onChange={(event: any) => {
          if (props.onChange) props.onChange(event.target.value);
        }}
        value={props.value}
      />

      {props.type === "password" && isShowPassword && (
        <IconButton
          className={classes.iconButton}
          aria-label="view-password"
          onClick={() => setIsShowPassword(false)}
        >
          <EyeOff size={20} color="#9E9E9E" />
        </IconButton>
      )}
      {props.type === "password" && !isShowPassword && (
        <IconButton
          className={classes.iconButton}
          aria-label="view-password"
          onClick={() => setIsShowPassword(true)}
        >
          <Eye size={20} color="#9E9E9E" />
        </IconButton>
      )}
      {/* <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        color="primary"
        className={classes.iconButton}
        aria-label="directions"
      > */}
      {/* </IconButton> */}
    </Paper>
  );

  // return (
  //   <CustomTextField
  //     {...props}
  //     // error
  //     // helperText="Incorrect entry."
  //     variant={props.variant || "outlined"}
  //     InputProps={{
  //       startAdornment: (
  //         <InputAdornment position="start">
  //           <Mail />
  //         </InputAdornment>
  //       ),
  //     }}
  //   />
  // );
}

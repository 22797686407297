import axios from 'axios';
import { getHeadersMultiPart, getHeaders } from '../helpers/auth';

export async function apiFindAllRecipeCategory(params: string) {
  const query = params || null
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/recipe-category' + query,
    method: 'GET',
    headers
  });
}

export async function apiFindOneRecipeCategory(recipeCategoryId: string) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/recipe-category/' + recipeCategoryId,
    method: 'GET',
    headers
  });
}

export async function apiCreateRecipeCategory(data: any) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/recipe-category',
    method: 'POST',
    headers: headers,
    data
  });
}

export async function apiUpdateRecipeCategory(data: any) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/recipe-category/' + data.recipeCategoryId,
    method: 'PATCH',
    headers: headers,
    data
  });
}

export async function apiDeleteRecipeCategory(recipeCategoryId: string) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/recipe-category/' + recipeCategoryId,
    method: 'DELETE',
    headers
  });
}

export async function apiAddImageRecipeCategory(data: FormData) {
  const headers = await getHeadersMultiPart();
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      "/recipe-category/upload-image?recipeCategoryId=" + data.get("recipeCategoryId"),
    method: "POST",
    headers,
    data,
  });
}
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { apiFindAllCollection, apiUpdateCollection } from "../api/collection";
import { generateQueryString } from "../helpers/functions/generateQueryString";
import {
  findAllCollection,
  setErrorCollection,
  setLoadingCollection,
  updateCollection,
} from "../redux/action/collection.action";

export default function useCollection() {
  const dispatch = useDispatch();
  const history = useHistory();

  const fetchAllCollection = async (params: any) => {
    try {
      dispatch(setLoadingCollection(true));
      let query = generateQueryString(params);

      const result = await apiFindAllCollection(query);
      console.log(result);

      dispatch(findAllCollection(result.data));

      dispatch(setLoadingCollection(false));
    } catch (error: any) {
      dispatch(setLoadingCollection(false));
      dispatch(setErrorCollection(error.response));
    }
  };

  const updateCollectionApproval = async (params: any) => {
    try {
      dispatch(setLoadingCollection(true));

      const result = await apiUpdateCollection(params);
      console.log(result);

      if (result.data.payload) {
        dispatch(updateCollection(result.data.payload));
      } else {
        console.log("Something wrong");
      }

      dispatch(setLoadingCollection(false));
    } catch (error: any) {
      dispatch(setLoadingCollection(false));
      dispatch(setErrorCollection(error.response));
    }
  };

  return { fetchAllCollection, updateCollectionApproval };
}

export function convertNumToCurrency(num: number) {
  if (!num) {
    return 'Rp0';
  }
  return 'Rp' + num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export function calculateDoubleDiscount(
  originalPrice: number,
  discountsData: string
) {
  let discounts = discountsData.split('%');

  if (discounts.length === 0) {
    console.error('Please provide at least two discounts.');
    return;
  }

  // Calculate the final discounted price
  let finalPrice = originalPrice;
  for (let i = 0; i < discounts.length; i++) {
    console.log(Number(discounts[i]), 1 - Number(discounts[i]) / 100);
    finalPrice *= 1 - Number(discounts[i]) / 100;
  }

  // Return the final discounted price
  return finalPrice;
}

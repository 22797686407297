import React, { useEffect, useState } from "react";
import {
  Button,
  Chip,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from "@material-ui/core";
import Layout from "../../components/Layout/Layout";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import AppButton from "../../components/Form/AppButton";
import HeaderPage from "../../components/Layout/HeaderPage";
import Search from "../../components/Form/Search";
import { ChevronDown, Download } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import useCustomer from "../../hooks/useCustomer";
import useDebounce from "../../hooks/useDebounce";
import { ApplicationState } from "../../redux/store";
import { apiExportCustomer, apiUpdateCustomer } from "../../api/customer";
import { format } from "date-fns";
import { id } from "date-fns/locale";
import { SaveAlt } from "@material-ui/icons";
import { CustomerResponse } from "../../types/customer.type";
import { initCustomer } from "../../redux/reducer/customer.reducer";
import COLORS from "../../styles/colors";
import { convertNumToCurrency } from "../../helpers/functions/currency";
import { generateQueryString } from "../../helpers/functions/generateQueryString";
import { useLocation } from "react-router-dom";
import {
  setLatestParams,
  enableUpdateParams,
} from "../../redux/action/config-app.action";
import { validateMaxText } from "../../helpers/functions/text";

const headers: ITableHeader[] = [
  {
    title: "customerId",
    column: "customerId",
    type: "id",
  },
  {
    title: "Name",
    align: "left",
    sortable: true,
    column: "customerName",
  },
  {
    title: "Phone",
    align: "left",
    column: "phoneNumber",
  },
  // {
  //   title: 'Email',
  //   align: 'left',
  //   column: 'correspondenceEmail',
  // },
  {
    title: "Status",
    align: "left",
    column: "status",
  },
  {
    title: "Type",
    align: "left",
    column: "customerType",
  },
  {
    title: "Status Membership",
    align: "left",
    column: "membershipStatus",
  },
  {
    title: "Total Transaction",
    align: "left",
    column: "totalTransaction",
  },
  {
    title: "Created At",
    align: "left",
    column: "createdAt",
    sortable: true,
    type: "date",
  },
  {
    title: "Last Login At",
    align: "left",
    column: "lastLoginAt",
    sortable: true,
    // type: "date",
  },
];

let data: any = [];

export default function UserCustomer() {
  const classes = useStyles();

  const customerState = useSelector(
    (state: ApplicationState) => state.customer
  );

  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingUpdateStatus, setLoadingUpdateStatus] = useState(false);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  // const [filterStatus, setFilterStatus] = useState();
  const [sort, setSort] = useState<any[]>([
    {
      column: "customerName",
      sort: "ASC",
    },
    {
      column: "createdAt",
      sort: "",
    },
  ]);
  const [search, setSearch] = useState<string | null>(null);
  const [query, setQuery] = useState({
    limit: 25,
    offset: 0,
    keyword: "",
    sort: ["customerName,ASC"],
  });

  const { fetchAllCustomer, updateDataCustomer } = useCustomer();

  const debouncedTitle = useDebounce(search, 300);
  const dispatch = useDispatch();
  const location = useLocation();

  const { updateParams, latestParams } = useSelector(
    (state: ApplicationState) => state.configApp
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElMembership, setAnchorElMembership] =
    React.useState<null | HTMLElement>(null);
  const [anchorElCustomerType, setAnchorElCustomerType] =
    React.useState<null | HTMLElement>(null);

  const [statusTmp, setStatusTmp] = useState<string>("");
  const [membershipStatusTmp, setMembershipStatusTmp] = useState<string>("");
  const [customerTypeTmp, setCustomerTypeTmp] = useState<string>("");

  const [dialogStatus, setDialogStatus] = useState(false);
  const [dialogMembership, setDialogMembership] = useState(false);
  const [dialogCustomerType, setDialogCustomerType] = useState(false);

  const [customerSelected, setCustomerSelected] = useState(initCustomer);

  useEffect(() => {
    searchBank(search);
  }, [debouncedTitle]);

  const searchBank = async (value: any) => {
    if (value !== null && value.length > 0) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          limit: 25,
          offset: 0,
          keyword: value,
        };
      });
    } else if (value !== null) {
      setQuery({
        limit: 25,
        offset: 0,
        keyword: "",
        sort: ["customerName,ASC"],
      });
    }
  };

  async function exportCustomer() {
    try {
      setLoadingExport(true);
      const excel = await apiExportCustomer();

      if (excel.data) {
        const url = window.URL.createObjectURL(new Blob([excel.data]));
        const link = document.createElement("a");
        link.href = url;
        const downloadDate = format(new Date(), "dd/MM/yyyy");
        link.setAttribute("download", `oxone-customer-${downloadDate}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
        setLoadingExport(false);
      }
    } catch (error) {
      setLoadingExport(false);
    }
  }

  useEffect(() => {
    if (updateParams) {
      dispatch(
        setLatestParams({
          pathname: location.pathname,
          params: generateQueryString(query),
          query: {
            limit: query["limit"],
            offset: query["offset"],
            page,
          },
        })
      );
      fetchAllCustomer(generateQueryString(query));
    } else {
      setPage(latestParams.query.page || page || 1);
      fetchAllCustomer(latestParams.params);
      dispatch(enableUpdateParams(true));
    }
  }, [query]);

  useEffect(() => {
    if (customerState.data.payload.count > 0) {
      setTotalData(customerState.data.payload.count);
      setTotalPage(Math.ceil(customerState.data.payload.count / rowsPerPage));
    }
  }, [customerState.data]);

  function changePagination(value: number) {
    setPage(value);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
        limit: rowsPerPage,
      };
    });
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value);
    setPage(1);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: 0,
        limit: value,
      };
    });
  }

  function handleSort(value: any) {
    let activeSort = "";

    let difference = value.filter((x: any) => !sort.includes(x));

    const newSort = value.map((data: any) => {
      if (data.column !== difference[0].column) {
        return {
          ...data,
          sort: "",
        };
      }
      return data;
    });

    activeSort = Object.keys(difference[0])
      .map((key) => difference[0][key])
      .join(",");

    setSort(newSort);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        sort: activeSort,
      };
    });
  }

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: CustomerResponse
  ) => {
    setAnchorEl(event.currentTarget);
    setCustomerSelected(item);
  };

  const handleClickMembership = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: CustomerResponse
  ) => {
    setAnchorElMembership(event.currentTarget);
    setCustomerSelected(item);
  };

  const handleClickCustomerType = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: CustomerResponse
  ) => {
    setAnchorElCustomerType(event.currentTarget);
    setCustomerSelected(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElMembership(null);
    setAnchorElCustomerType(null);

    setStatusTmp("");
    setMembershipStatusTmp("");
    setCustomerTypeTmp("");
  };

  const confirmUpdateStatus = (value: string) => {
    setAnchorEl(null);
    setDialogStatus(true);
    setStatusTmp(value.toLowerCase());
  };

  const confirmUpdateMembership = (value: string) => {
    setAnchorElMembership(null);
    setDialogMembership(true);
    setMembershipStatusTmp(value.toLowerCase());
  };

  const confirmUpdateCustomerType = (value: string) => {
    setAnchorElCustomerType(null);
    setDialogCustomerType(true);
    setCustomerTypeTmp(value.toLowerCase());
  };

  const updateCustomer = async (type: string) => {
    try {
      let data = customerSelected;

      delete data.transactionSummary;
      delete data.bankAccount;
      delete data.addresses;

      if (type === "status") {
        data = {
          ...data,
          customerType:
            data.customerType === "reguler" ? null : data.customerType,
          status: statusTmp,
        };
      } else if (type === "customer-type") {
        data = {
          ...data,
          customerType: customerTypeTmp === "reguler" ? null : "spg",
        };
      } else if (type === "status-membership") {
        data = {
          ...data,
          customerType:
            data.customerType === "reguler" ? null : data.customerType,
          membershipStatus: membershipStatusTmp,
        };
      }

      await apiUpdateCustomer(data);

      await fetchAllCustomer(generateQueryString(query));

      setStatusTmp("");
      setMembershipStatusTmp("");
      setCustomerTypeTmp("");

      setDialogMembership(false);
      setDialogStatus(false);
      setDialogCustomerType(false);
    } catch (error) {
      setStatusTmp("");
      setMembershipStatusTmp("");
      setCustomerTypeTmp("");

      setDialogMembership(false);
      setDialogStatus(false);
      setDialogCustomerType(false);
    }
  };

  data = customerState.data.payload.results.map((item: CustomerResponse) => {
    return {
      ...item,
      customerName: (
        <Typography>{validateMaxText(20, item.customerName)}</Typography>
      ),
      totalTransaction:
        item.transactionSummary &&
        item.transactionSummary.totalFinalTransactionPrice
          ? convertNumToCurrency(
              Number(item.transactionSummary.totalFinalTransactionPrice)
            )
          : convertNumToCurrency(0),
      status: (
        <>
          <Button
            variant="text"
            style={{ padding: 0 }}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
              handleClick(event, item)
            }
          >
            {item.status}
            <ChevronDown
              color={COLORS.greyDark}
              size={15}
              style={{ marginLeft: 8 }}
            />
          </Button>
          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            elevation={1}
          >
            {["ACTIVE", "INACTIVE"].map((option: string, index: number) => (
              <MenuItem
                key={index}
                selected={option === item.status}
                onClick={(event: any) => {
                  if (customerSelected.status !== option.toLowerCase()) {
                    confirmUpdateStatus(option);
                  } else {
                    setAnchorEl(null);
                  }
                }}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        </>
      ),
      customerType: (
        <>
          <Button
            disabled={item.membershipStatus === "premium"}
            variant="text"
            style={{ padding: 0 }}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
              handleClickCustomerType(event, item)
            }
          >
            {item.customerType}
            <ChevronDown
              color={COLORS.greyDark}
              size={15}
              style={{ marginLeft: 8 }}
            />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorElCustomerType}
            keepMounted
            open={Boolean(anchorElCustomerType)}
            onClose={handleClose}
            elevation={1}
          >
            {["REGULER", "SPG"].map((option: string, index: number) => (
              <MenuItem
                key={index}
                selected={option === item.customerType}
                onClick={(event: any) => {
                  if (customerSelected.customerType !== option.toLowerCase()) {
                    confirmUpdateCustomerType(option);
                  } else {
                    setAnchorElCustomerType(null);
                  }
                }}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        </>
      ),
      membershipStatus: (
        <>
          <Button
            title={
              item.customerType === "spg"
                ? 'Customer dengan tipe "SPG" tidak bisa diubah menjadi premium'
                : ""
            }
            disabled={item.customerType === "spg"}
            variant="text"
            style={{ padding: 0 }}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
              handleClickMembership(event, item)
            }
          >
            {item.membershipStatus === "premium" ? (
              <Chip
                disabled={item.customerType === "spg"}
                size="small"
                color="primary"
                label="Premium"
              />
            ) : (
              <Chip
                disabled={item.customerType === "spg"}
                size="small"
                color="primary"
                variant="outlined"
                label="Reguler"
              />
            )}
            <ChevronDown
              color={COLORS.greyDark}
              size={15}
              style={{ marginLeft: 8 }}
            />
          </Button>
          <Menu
            id="lock-menu-1"
            anchorEl={anchorElMembership}
            keepMounted
            open={Boolean(anchorElMembership)}
            onClose={handleClose}
            elevation={1}
          >
            {["REGULER", "PREMIUM"].map((option: string, index: number) => (
              <MenuItem
                key={index}
                selected={option === item.membershipStatus}
                onClick={(event: any) => {
                  if (
                    customerSelected.membershipStatus !== option.toLowerCase()
                  ) {
                    confirmUpdateMembership(option);
                  } else {
                    setAnchorElMembership(null);
                  }
                }}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        </>
      ),
      lastLoginAt: item.lastLoginAt ? (
        <Typography>
          {format(new Date(item.lastLoginAt), "dd MMM yyyy", { locale: id })}
        </Typography>
      ) : (
        <Typography style={{ color: "grey", fontStyle: "italic" }}>
          Not Set
        </Typography>
      ),
    };
  });

  return (
    <Layout title="Customer" loading={customerState.isLoadingCustomer}>
      <HeaderPage title="Customer" subtitle="List of all customer" />

      <Grid container spacing={3} justify="flex-end">
        <Grid item>
          <AppButton
            loading={loadingExport}
            color="primary"
            onClick={() => exportCustomer()}
          >
            <SaveAlt />
            <Typography component="span" style={{ marginLeft: 10 }}>
              Export All
            </Typography>
          </AppButton>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Search
            placeholder="Search by name, email, phone"
            onChange={(value: string) => setSearch(value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <CustomizedTable
            data={customerState.isLoadingCustomer ? [] : data}
            headers={headers}
            totalData={totalData}
            showAction={["detail"]}
            page={page}
            totalPage={totalPage}
            rowsPerPage={rowsPerPage}
            sort={sort}
            onChangeSort={(value) => handleSort(value)}
            onChange={(newPage) => changePagination(newPage)}
            onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
          />
        </Grid>
      </Grid>

      <Dialog
        open={dialogStatus}
        onClose={() => {
          setDialogStatus(false);
          setStatusTmp("");
          handleClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Konfirmasi ubah status
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="primary">
            Apakah anda yakin ingin mengubah status{" "}
            <strong>{customerSelected.customerName}</strong> menjadi{" "}
            <strong>"{statusTmp.toUpperCase()}"</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <AppButton
            color="secondary"
            onClick={() => {
              setDialogStatus(false);
              setStatusTmp("");
              handleClose();
            }}
          >
            Batal
          </AppButton>
          <AppButton
            loading={customerState.isLoadingCustomer}
            color="primary"
            onClick={() => updateCustomer("status")}
          >
            Ya
          </AppButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogMembership}
        onClose={() => {
          setMembershipStatusTmp("");
          setDialogMembership(false);
          handleClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Konfirmasi ubah status
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="primary">
            Apakah anda yakin ingin mengubah status membership{" "}
            <strong>{customerSelected.customerName}</strong> menjadi{" "}
            <strong>"{membershipStatusTmp.toUpperCase()}"</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <AppButton
            color="secondary"
            onClick={() => {
              setMembershipStatusTmp("");
              setDialogMembership(false);
              handleClose();
            }}
          >
            Batal
          </AppButton>
          <AppButton
            loading={customerState.isLoadingCustomer}
            color="primary"
            onClick={() => updateCustomer("status-membership")}
          >
            Ya
          </AppButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogCustomerType}
        onClose={() => {
          setCustomerTypeTmp("");
          setDialogCustomerType(false);
          handleClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Konfirmasi ubah tipe customer
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="primary">
            Apakah anda yakin ingin mengubah tipe customer{" "}
            <strong>{customerSelected.customerName}</strong> menjadi{" "}
            <strong>"{(customerTypeTmp as any).toUpperCase()}"</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <AppButton
            color="secondary"
            onClick={() => {
              setCustomerTypeTmp("");
              setDialogCustomerType(false);
              handleClose();
            }}
          >
            Batal
          </AppButton>
          <AppButton
            loading={customerState.isLoadingCustomer}
            color="primary"
            onClick={() => updateCustomer("customer-type")}
          >
            Ya
          </AppButton>
        </DialogActions>
      </Dialog>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formStatus: {
      width: "100%",
      margin: theme.spacing(1, 0, 2, 0),
      marginBottom: 10,
    },
  })
);

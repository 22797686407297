import { useDispatch } from "react-redux";
import {
  apiGetLoyalty,
  apiGetLoyaltyDetail,
  apiGetLoyaltyHistory,
  apiUpdateLoyalty,
} from "../api/master-data/loyalty";
import { generateQueryString } from "../helpers/functions/generateQueryString";
import {
  findLogLoyalty,
  findLoyalty,
  findOneLoyalty,
  findUpdateLoyalty,
  setLoadingLoyalty,
} from "../redux/action/master-data-referral.action";

export default function useLoyalty() {
  const dispatch = useDispatch();

  const fetchLoyalty = async () => {
    try {
      dispatch(setLoadingLoyalty(true));
      const result = await apiGetLoyalty();

      dispatch(findLoyalty(result.data));

      dispatch(setLoadingLoyalty(false));
    } catch (error) {
      dispatch(setLoadingLoyalty(false));
    }
  };

  const fetchOneLoyalty = async (id: any) => {
    try {
      dispatch(setLoadingLoyalty(true));
      const result = await apiGetLoyaltyDetail(id);

      dispatch(findOneLoyalty(result.data.payload));

      dispatch(setLoadingLoyalty(false));
    } catch (error) {
      dispatch(setLoadingLoyalty(false));
    }
  };

  const fetchLoyaltyHistory = async (id: string, params: any) => {
    try {
      dispatch(setLoadingLoyalty(true));
      let query = generateQueryString(params);
      const result = await apiGetLoyaltyHistory(id, query);

      dispatch(findLogLoyalty(result.data));

      dispatch(setLoadingLoyalty(false));
    } catch (error) {
      dispatch(setLoadingLoyalty(false));
    }
  };

  const updateLoyalty = async (params: any) => {
    try {
      dispatch(setLoadingLoyalty(true));

      const result = await apiUpdateLoyalty(params);
      console.log(result);

      if (result.data.payload) {
        dispatch(findUpdateLoyalty(result.data.payload));
      } else {
        console.log("Something wrong");
      }

      dispatch(setLoadingLoyalty(false));
    } catch (error) {
      dispatch(setLoadingLoyalty(false));
    }
  };

  return { fetchLoyalty, fetchLoyaltyHistory, fetchOneLoyalty, updateLoyalty };
}

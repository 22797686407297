import axios from "axios";
import { getHeaders } from "../helpers/auth";

export async function apiGetCoinHistory(params: string) {
  const query = params || null;
  const headers = await getHeaders();
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/coin-history" + query,
    method: "GET",
    headers,
  });
}

import {
  ERecipeStatus,
  ERecipeVideoType,
  RecipeResponse,
  RecipeState,
  RecipeTypes,
} from "../../types/recipe.type";
import { Reducer } from "redux";

export const initialRecipe = {
  categories: '',
    createdAt: new Date(),
    customer: '',
    customerId: '',
    isChefChoice: true,
    likeCount: 0,
    publishedAt: new Date(),
    recipeCategoryId: '',
    recipeCookingPortion: 0,
    recipeCookingDuration: {
        hour: 0,
        minute: 1
    },
    recipeDescription: '',
    recipeId: '',
    recipeImage: '',
    recipeIngredients: [{
        id: '',
        ingridients: [],
        title: ''
    }],
    recipeInstructions: [],
    recipeName: '',
    recipeRemark: '',
    recipeStatus: ERecipeStatus.draft,
    recipeTotalCookingDuration: 0,
    recipeVideoType: ERecipeVideoType.instagram_feed,
    recipeVideoUrl: '',
    saveCount: 0,
    tags: [],
    updatedAt: new Date()
};

const initialState: RecipeState = {
  isLoadingRecipe: false,
  data: {
    code: null,
    message: null,
    payload: {
      next: "",
      prev: "",
      count: 0,
      results: [],
    },
  },
  error: {},
  recipe: { ...initialRecipe },
  isSuccess: false,
};

export const recipe: Reducer<RecipeState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case RecipeTypes.FIND_ALL_RECIPE:
      const results = action.payload.payload.results.map(
        (item: RecipeResponse) => {
          return {
            ...item,

          };
        }
      );

      return {
        ...state,
        data: {
          ...action.payload,
          payload: {
            ...action.payload.payload,
            results,
          },
        },
      };
    case RecipeTypes.FIND_ONE_RECIPE:
      return {
        ...state,
        recipe: action.payload,
      };
    case RecipeTypes.CREATE_RECIPE:
      return {
        ...state,
        recipe: action.payload,
        isSuccess: true,
      };
    case RecipeTypes.UPDATE_RECIPE:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case RecipeTypes.REMOVE_RECIPE:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case RecipeTypes.SET_LOADING_RECIPE:
      return {
        ...state,
        isLoadingRecipe: action.payload,
      };
    case RecipeTypes.SET_ERROR_RECIPE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

import axios from 'axios';
import { getHeaders } from '../helpers/auth';
import { ReferralDiscountRequest, ReferralDiscountResponse } from '../types/referral-discount.type';


export async function apiFindAllReferralDiscount(params: string) {
  const query = params || null
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/retail-discount/log' + query,
    method: 'GET',
    headers
  });
}

export async function apiFindOneReferralDiscount(referralDiscountId: string) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/retail-discount/' + referralDiscountId,
    method: 'GET',
    headers
  });
}

export async function apiCreateReferralDiscount(data: ReferralDiscountRequest) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/retail-discount',
    method: 'POST',
    headers,
    data
  });
}

export async function apiCurrentReferralDiscount() {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/retail-discount/app/current-value',
    method: 'GET',
    headers,
  });
}
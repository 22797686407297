import {
  CustomerResponsePayload,
  CustomerTypes,
  CustomerResponse,
} from "../../types/customer.type";

export function findAllCustomer(payload: CustomerResponsePayload) {
  return {
    type: CustomerTypes.FIND_ALL_CUSTOMER,
    payload,
  };
}

export function findAllCustomerByServiceCenter(
  payload: CustomerResponsePayload
) {
  return {
    type: CustomerTypes.FIND_ALL_CUSTOMER_BY_SERVICE_CENTER,
    payload,
  };
}

export function createCustomerByServiceCenter(payload: any) {
  return {
    type: CustomerTypes.CREATE_CUSTOMER_BY_SERVICE_CENTER,
    payload,
  };
}

export function findOneCustomer(payload: CustomerResponse) {
  return {
    type: CustomerTypes.FIND_ONE_CUSTOMER,
    payload,
  };
}

export function setLoadingCustomer(payload: boolean) {
  return {
    type: CustomerTypes.SET_LOADING_CUSTOMER,
    payload,
  };
}

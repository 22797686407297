import { NewsDeleteResponse, NewsResponse, NewsResponsePayload, PromotionTypes } from '../../../types/news.type';

export function findAllPromotion(payload: NewsResponsePayload) {
  return {
    type: PromotionTypes.FIND_ALL_PROMOTION,
    payload
  }
}

export function findOnePromotion(payload: NewsResponse) {
  return {
    type: PromotionTypes.FIND_ONE_PROMOTION,
    payload
  }
}

export function createPromotion(payload: NewsResponse) {
  return {
    type: PromotionTypes.CREATE_PROMOTION,
    payload
  }
}

export function updatePromotion(payload: NewsResponse) {
  return {
    type: PromotionTypes.UPDATE_PROMOTION,
    payload
  }
}

export function deletePromotion(payload: NewsDeleteResponse) {
  return {
    type: PromotionTypes.DELETE_PROMOTION,
    payload
  }
}

export function setLoadingPromotion(payload: boolean) {
  return {
    type: PromotionTypes.SET_LOADING_PROMOTION,
    payload
  }
}

export function setErrorPromotion(payload: any) {
  return {
    type: PromotionTypes.SET_ERROR_PROMOTION,
    payload
  }
}

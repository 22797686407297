import { RecipeCategoryResponse, RecipeCategoryResponsePayload, RecipeCategoryTypes } from '../../types/recipe-category.type'


export function findAllRecipeCategory(payload: RecipeCategoryResponsePayload) {
  return {
    type: RecipeCategoryTypes.FIND_ALL_RECIPE_CATEGORY,
    payload
  }
}

export function findOneRecipeCategory(payload: RecipeCategoryResponse) {
  return {
    type: RecipeCategoryTypes.FIND_ONE_RECIPE_CATEGORY,
    payload
  }
}

export function createRecipeCategory(payload: RecipeCategoryResponse) {
  return {
    type: RecipeCategoryTypes.CREATE_RECIPE_CATEGORY,
    payload
  }
}

export function updateRecipeCategory(payload: RecipeCategoryResponse) {
  return {
    type: RecipeCategoryTypes.UPDATE_RECIPE_CATEGORY,
    payload
  }
}

export function deleteRecipeCategory(payload: any) {
  return {
    type: RecipeCategoryTypes.REMOVE_RECIPE_CATEGORY,
    payload
  }
}

export function setLoadingRecipeCategory(payload: boolean) {
  return {
    type: RecipeCategoryTypes.SET_LOADING_RECIPE_CATEGORY,
    payload
  }
}

export function setErrorRecipeCategory(payload: any) {
  return {
    type: RecipeCategoryTypes.SET_ERROR_RECIPE_CATEGORY,
    payload
  }
}
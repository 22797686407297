/* eslint-disable react-hooks/exhaustive-deps */
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  Box,
  Paper,
} from '@material-ui/core';
import React, { useState } from 'react';
import Layout from '../../components/Layout/Layout';
import CustomizedTable, {
  ITableHeader,
} from '../../components/Table/CustomizedTable';
import AppButton from '../../components/Form/AppButton';
import COLORS from '../../styles/colors';
import HeaderPage from '../../components/Layout/HeaderPage';
import Search from '../../components/Form/Search';
import FilterDate from '../../components/Form/FilterDate';
import { Circle, Download, Info, Upload, X } from 'react-feather';
import { CheckCircleOutlined } from '@material-ui/icons';
import { convertNumToCurrency } from '../../helpers/functions/currency';
import Disburse from '../../components/Icon/Disburse';
import AppTabs from '../../components/Tabs/AppTabs';
import {
  apiFetchListCommission,
  apiGetTotalCommission,
  SingleCommissionResult,
} from '../../api/commission';
import { convertDateTimeToText } from '../../helpers/functions/datetime';
import usePaginateTable from '../../hooks/usePaginateTable';
import { IPaginateTable } from '../../hooks/usePaginateTable';
import { useEffect } from 'react';
import { generateQueryString } from '../../helpers/functions/generateQueryString';
import { apiCheckBalance, apiDisburse, apiExportCommission, apiExportDetailCommission, IDisburseDto } from '../../api/disbursement';
import useDebounce from '../../hooks/useDebounce';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { setLatestParams, enableUpdateParams } from '../../redux/action/config-app.action';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../redux/store';
import { toast } from 'react-toastify';

const tabs = ['Disburse', 'Disburse Failed', 'Historical'];

export default function Disbursement() {
  const classes = useStyles();
  const [totalCommission, setTotalCommission] = useState(0);
  const [IRISBalance, setIRISBalance] = useState(0);
  const [isLoadingDisburse, setIsLoadingDisburse] = useState(false);

  const [search, setSearch] = useState('');
  const {
    onChangePage,
    onChangeRowsPerPage,
    fetchList,
    loading,
    params,
    data,
    page,
    totalPage,
    totalData,
    rowsPerPage,
  }: IPaginateTable & { data: SingleCommissionResult[] } = usePaginateTable(
    apiFetchListCommission,
    {
      limit: 25,
      offset: 0,
      filterStatus: 'pending',
      groupBy: 'userId-status',
      startAt: '',
      endAt: '',
    }
  );
  const [paramsTotalCommission, setParamsTotalCommission] = useState({
    filterStatus: 'pending,failed',
    startAt: '',
    endAt: '',
  });
  const debouncedTitle = useDebounce(search, 300);
  const dispatch = useDispatch();
  const location = useLocation();

  const { updateParams, latestParams } = useSelector((state: ApplicationState) => state.configApp)

  useEffect(() => {
    // console.info('updateParams', updateParams)
    // if (updateParams) {
    //   console.info('latestParams', latestParams.query, params)
    //   dispatch(setLatestParams({
    //     pathname: location.pathname,
    //     params: generateQueryString(params),
    //     query: {
    //       ...params,
    //       page
    //     }
    //   }))
    //   fetchList({ ...params, search });
    // } else {
    //   // onChangePage(newPage);
    //   // setPage(latestParams.query.page || page || 1)
    //   fetchList({ ...latestParams.query });
    //   // fetchList({ ...params, search });
    //   dispatch(enableUpdateParams(true))
    // }
  }, [debouncedTitle]);


  useEffect(() => {
    getTotalCommission();
    getIRISBalance();
    fetchList({ filterStatus: 'pending' });
  }, []);

  const getTotalCommission = (incomingParams: any = {}) => {
    const newParams = {
      ...paramsTotalCommission,
      ...incomingParams,
    };
    setParamsTotalCommission(newParams);
    apiGetTotalCommission(generateQueryString(newParams))
      .then((res) => {
        setTotalCommission(res.totalCommission);
      })
      .catch((err) => {
        console.error('Error getTotalCommission', err);
      });
  };

  const getIRISBalance = () => {
    apiCheckBalance()
      .then((res) => {
        setIRISBalance(Number(res.balance));
      })
      .catch((err) => {
        console.error('Error getIRISBalance', err);
      });
  };

  const [selected, setSelected] = useState<any[]>([]);

  useEffect(()=>{
    if(selected.length>0 && !params.startAt && !params.endAt){
      toast.error('Pilih periode disburse dahulu!', {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
    }
  }, [selected])

  const [activeTab, setActiveTab] = useState(0);

  const _renderBankNumber = (item: SingleCommissionResult) => {
    if (item && item.disburseMetadata) {
      if (!item.disburseMetadata.bankName || !item.disburseMetadata.bankAccountNumber || !item.disburseMetadata.bankOwnerName) {
        return (
          <Box display="flex" alignItems="center">
            {/* <Circle fill={COLORS.error} color={COLORS.error} size={12} /> */}
            <Typography style={{ color: COLORS.error, fontStyle: 'italic' }}>Not Set</Typography>
          </Box>)
      } else if (item.disburseMetadata.status === 'pending' || item.disburseMetadata.status === 'rejected') {
        return (
          <Box display="flex" alignItems="center">
            {/* <Circle fill={COLORS.error} color={COLORS.error} size={12} /> */}
            <Typography style={{ color: COLORS.error }}>
              {item.disburseMetadata.bankName.toUpperCase() +
                ' ' +
                item.disburseMetadata.bankAccountNumber +
                ' a.n ' +
                item.disburseMetadata.bankOwnerName +
                ' (' +
                item.disburseMetadata.status +
                ')'}
            </Typography>
          </Box>
        );
      } else {
        return (
          <Typography component="span">
            {item.disburseMetadata.bankName.toUpperCase() +
              ' ' +
              item.disburseMetadata.bankAccountNumber +
              ' a.n ' +
              item.disburseMetadata.bankOwnerName}{' '}
            <Typography component="span" style={{ color: COLORS.info, fontStyle: 'italic' }}>
              ({item.disburseMetadata.status})
              </Typography>
          </Typography>
        );
      }
    } else {
      return null;
    }
  };

  const listData = data.map((item: SingleCommissionResult) => {
    return {
      ...item,
      params: `?startAt=${params.startAt}&endAt=${params.endAt}&filterStatus=${params.filterStatus}`,
      // customId: item.userId + '/' + params.startAt + '/' + params.endAt,
      customId: item.userId,
      id:
        'commissions_' +
        (item.commissionIds ? item.commissionIds.join('_') : ''),
      name: item.user.name,
      phone: item.user.phoneNumber,
      bank_number: _renderBankNumber(item),
      amount_text: (
        <Typography
          style={
            item.totalAmount > 10000
              ? { color: COLORS.primary }
              : { color: COLORS.error }
          }
        >
          {convertNumToCurrency(item.totalAmount)}
        </Typography>
      ),
      disburse_date: item.commissions
        ? convertDateTimeToText(
          item.commissions && item.commissions[0].paidAt
            ? item.commissions[0].paidAt
            : '',
          'dd mmm yyyy hh:mm'
        )
        : null,
      period:
        convertDateTimeToText((item as any).periodStart, 'dd mmm yyyy') +
        ' - ' +
        convertDateTimeToText((item as any).periodEnd, 'dd mmm yyyy'),
      // transfer_receipt: transfer_receipt ? (
      //   <Box
      //     display="flex"
      //     alignItems="center"
      //     flexDirection="row"
      //     justifyContent="center"
      //   >
      //     <CheckCircleOutlined />
      //     <Typography variant="subtitle1">See Transfer Receipt</Typography>
      //   </Box>
      // ) : (
      //   <Box
      //     display="flex"
      //     alignItems="center"
      //     flexDirection="row"
      //     justifyContent="center"
      //   >
      //     <Upload />{' '}
      //     <Typography variant="subtitle1" component="span">
      //       Upload
      //     </Typography>
      //   </Box>
      // ),
      disbursement: (
        <Typography
          variant="subtitle1"
          component="span"
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 5,
            paddingBottom: 5,
            borderRadius: 10,
            borderColor: COLORS.greyDark,
            borderWidth: 1,
            borderStyle: 'solid',
            backgroundColor: COLORS.grey200,
          }}
        >
          PAID
        </Typography>
      ),
      isDisabled: (!item.disburseMetadata.bankName || !item.disburseMetadata.bankAccountNumber || !item.disburseMetadata.bankOwnerName || item.disburseMetadata.status === 'rejected' || item.disburseMetadata.status === 'pending')
    };
  });

  const onChangeFilterData = (startAt: string, endAt: string) => {
    fetchList({ startAt, endAt });
    getTotalCommission({ startAt, endAt });
  };

  const handleOnChangePage = (newPage: number) => {
    onChangePage(newPage);
    setSelected([]);
  };

  const onChangeTab = (value: number) => {
    setSelected([])
    setActiveTab(value);
    if (value === 0) {
      fetchList({ filterStatus: 'pending' });
    } else if (value === 1) {
      fetchList({ filterStatus: 'failed' });
    } else if (value === 2) {
      fetchList({ filterStatus: 'processed,done' });
    }
  };

  const onDisburse = () => {
    setIsLoadingDisburse(true);
    const usersData: IDisburseDto['users'] = [];

    selected.forEach((id) => {
      const findRowData = listData.find((item) => item.id === id);
      if (findRowData) {
        usersData.push({
          userId: findRowData.userId,
          commissionIds: findRowData.commissionIds || [],
        });
      }
    });
    const dataDisburse: IDisburseDto = {
      periodStart: params.startAt,
      periodEnd: params.endAt,
      users: usersData,
    };

    apiDisburse(dataDisburse)
      .then(() => {
        console.info('Success');
        setIsLoadingDisburse(false);
        fetchList();
        getTotalCommission();
        setSelected([]);
        onChangeTab(2);
      })
      .catch((err) => {
        console.error('error apiDisburse', err);
        setIsLoadingDisburse(false);
        onChangeTab(1);
      });
  };

  const exportDisbursement = () => {
    setIsLoadingDisburse(true);
    const usersData: IDisburseDto['users'] = [];

    selected.forEach((id) => {
      const findRowData = listData.find((item) => item.id === id);
      if (findRowData) {
        usersData.push({
          userId: findRowData.userId,
          commissionIds: findRowData.commissionIds || [],
        });
      }
    });
    const dataDisburse: IDisburseDto = {
      periodStart: params.startAt,
      periodEnd: params.endAt,
      users: usersData,
    };

    apiExportCommission(dataDisburse)
      .then((res) => {
        if (res) {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement('a');
          link.href = url;
          const downloadDate = format(new Date(), 'dd/MM/yyyy');
          link.setAttribute('download', `oxone-${params.filterStatus}-disbursement-${downloadDate}.xlsx`); //or any other extension
          document.body.appendChild(link);
          link.click();
        }

        setIsLoadingDisburse(false);
        setSelected([]);
      })
      .catch((err) => {
        console.error('error apiExportCommission', err);
        setIsLoadingDisburse(false);
      });
  };

  const exportDetailDisbursement = () => {
    setIsLoadingDisburse(true);
    const usersData: IDisburseDto['users'] = [];

    selected.forEach((id) => {
      const findRowData = listData.find((item) => item.id === id);
      if (findRowData) {
        usersData.push({
          userId: findRowData.userId,
          commissionIds: findRowData.commissionIds || [],
        });
      }
    });
    const dataDisburse: IDisburseDto = {
      periodStart: params.startAt,
      periodEnd: params.endAt,
      users: usersData,
    };

    apiExportDetailCommission(dataDisburse)
      .then((res) => {
        if (res) {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement('a');
          link.href = url;
          const downloadDate = format(new Date(), 'dd/MM/yyyy');
          link.setAttribute(
            'download',
            `oxone-${params.filterStatus}-detail-disbursement-${downloadDate}.xlsx`
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
        }

        setIsLoadingDisburse(false);
        setSelected([]);
      })
      .catch((err) => {
        console.error('error apiExportDetailCommission', err);
        setIsLoadingDisburse(false);
      });
  };

  let totalSelectedDisbursement = 0;
  selected.forEach((id) => {
    const findRowData = listData.find((item) => item.id === id);
    if (findRowData) {
      totalSelectedDisbursement += findRowData.totalAmount;
    }
  });

  return (
    <Layout title="Disbursement" loading={loading || isLoadingDisburse}>
      <Grid container alignItems="flex-end">
        <Grid item xs={9}>
          <HeaderPage
            title="Retail Disbursement"
            subtitle="List of all Disbursement product"
          />
        </Grid>
        <Grid item xs={3} style={{ marginBottom: 12 }}>
          <Search
            placeholder="Search name"
            onChange={(value: string) => setSearch(value)}
          />
        </Grid>
      </Grid>

      <Paper elevation={3} className={classes.paper}>
        <Grid container spacing={3} justify="space-between" alignItems="center">
          <Grid item xs={12} md={12} lg={2}>
            <Typography variant="subtitle1" color="textSecondary">
              Total Disbursement
            </Typography>
            <Typography
              style={{ fontWeight: 'bold', lineHeight: 1.4, fontSize: 22 }}
            >
              {selected.length > 0
                ? convertNumToCurrency(totalSelectedDisbursement)
                : convertNumToCurrency(totalCommission)}
            </Typography>
          </Grid>

          <Grid item xs={12} md={12} lg={2}>
            <Typography variant="subtitle1" color="textSecondary">
              IRIS Balance
            </Typography>
            <Typography
              style={{ fontWeight: 'bold', lineHeight: 1.4, fontSize: 22 }}
            >
              {convertNumToCurrency(IRISBalance)}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <FilterDate
              start_at={params.startAt}
              end_at={params.endAt}
              onChange={(start_at: string, end_at: string) =>
                onChangeFilterData(start_at, end_at)
              }
              notSetDefaultDate={true}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppButton
              onClick={() => {
                exportDisbursement();
              }}
              disabled={
                selected.length === 0 ||
                ! params.startAt ||
                ! params.endAt
              }
              style={{ marginBottom: 8 }}
              size="small"
              color="primary"
            >
              <Download size={18} />
              <Typography
                component="span"
                style={{ fontSize: 12, marginLeft: 5 }}
              >
                Export
              </Typography>
            </AppButton>
            <AppButton
              onClick={() => {
                exportDetailDisbursement();
              }}
              disabled={
                selected.length === 0 ||
                ! params.startAt ||
                ! params.endAt
              }
              style={{ marginBottom: 8 }}
              size="small"
              color="primary"
            >
              <Download size={18} />
              <Typography
                component="span"
                style={{ fontSize: 12, marginLeft: 5 }}
              >
                Export Detail
              </Typography>
            </AppButton>
            <AppButton
              style={{ marginBottom: 8 }}
              size="small"
              loading={isLoadingDisburse}
              disabled={
                isLoadingDisburse ||
                selected.length === 0
                || activeTab > 1||
                ! params.startAt ||
                ! params.endAt
              }
              color="primary"
              onClick={() => onDisburse()}
            >
              <Disburse
                color={
                  isLoadingDisburse || selected.length === 0
                    ? COLORS.greyDark
                    : COLORS.accent
                }
                size={18}
              />
              <Typography
                component="span"
                style={{ fontSize: 12, marginLeft: 5 }}
              >
                Disburse
              </Typography>
            </AppButton>
          </Grid>
        </Grid>
      </Paper>

      {(activeTab === 0 || activeTab === 1) && (
        <Paper elevation={3} className={classes.paperSlim}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            style={{ marginBottom: 10 }}
          >
            <Box display="flex" alignItems="center" flexDirection="row">
              <Info size={20} color={COLORS.warn} />
              <Typography style={{ marginLeft: 5 }}>
                Pilih periode dan customer yang akan di disburse
              </Typography>
            </Box>

            <Box display="flex" alignItems="center">
              <X size={20} />
            </Box>
          </Box>
        </Paper>
      )}
      {activeTab === 2 && (
        <Paper elevation={3} className={classes.paperSlim}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            style={{ marginBottom: 10 }}
          >
            <Box display="flex" alignItems="center" flexDirection="row">
              <Info size={20} color={COLORS.warn} />
              <Typography style={{ marginLeft: 5 }}>
                Daftar semua transaksi yang telah di disburse
              </Typography>
            </Box>

            <Box display="flex" alignItems="center">
              <X size={20} />
            </Box>
          </Box>
        </Paper>
      )}

      <Grid container spacing={3} className={classes.containerContent}>
        {/* Recent Orders */}
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: COLORS.grey200,
            padding: 0,
            borderRadius: 10,
          }}
        >
          <AppTabs
            active={activeTab}
            tabs={tabs}
            onChange={(value: number) => {
              onChangeTab(value);
            }}
          />
          {activeTab === 0 && (
            <CustomizedTable
              selected={selected}
              selection={true}
              onSelected={(id: string) => {
                let list: any[] = [...selected];
                if (list.includes(id)) {
                  list = list.filter((item) => item !== id);
                } else {
                  list.push(id);
                }
                setSelected(list);
              }}
              onSelectAll={() => {
                setSelected(listData.map((item) => item.id));
              }}
              onUnselectAll={() => {
                setSelected([]);
              }}
              data={listData}
              headers={headers}
              showAction={['detail']}
              totalData={totalData}
              page={page}
              totalPage={totalPage}
              rowsPerPage={rowsPerPage}
              onChange={handleOnChangePage}
              onRowsPerPageChange={(value: any) => onChangeRowsPerPage(value)}
            />
          )}

          {activeTab === 1 && (
            <CustomizedTable
              selected={selected}
              selection={true}
              onSelected={(id: string) => {
                let list: any[] = [...selected];
                if (list.includes(id)) {
                  list = list.filter((item) => item !== id);
                } else {
                  list.push(id);
                }
                setSelected(list);
              }}
              onSelectAll={() => {
                setSelected(listData.map((item) => item.id));
              }}
              onUnselectAll={() => {
                setSelected([]);
              }}
              data={listData}
              headers={headers}
              showAction={['detail']}
              totalData={totalData}
              page={page}
              totalPage={totalPage}
              rowsPerPage={rowsPerPage}
              onChange={handleOnChangePage}
              onRowsPerPageChange={(value: any) => onChangeRowsPerPage(value)}
            />
          )}

          {activeTab === 2 && (
            <CustomizedTable
              selected={selected}
              selection={true}
              onSelected={(id: string) => {
                console.info('on selected');
                let list: any[] = [...selected];
                if (list.includes(id)) {
                  list = list.filter((item) => item !== id);
                } else {
                  list.push(id);
                }
                setSelected(list);
              }}
              onSelectAll={() => {
                setSelected(listData.map((item) => item.id));
              }}
              onUnselectAll={() => {
                setSelected([]);
              }}
              data={listData}
              headers={headersHistorical}
              totalData={totalData}
              page={page}
              totalPage={totalPage}
              rowsPerPage={rowsPerPage}
              onChange={handleOnChangePage}
              onRowsPerPageChange={(value: any) => onChangeRowsPerPage(value)}
            />
          )}
        </Grid>
      </Grid>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(0, 0, 3, 0),
      padding: theme.spacing(2, 2, 2.5, 2),
    },
    paperSlim: {
      margin: theme.spacing(0, 0, 3, 0),
      padding: theme.spacing(0.5, 1, 0, 1),
    },
    formStatus: {
      width: '100%',
      margin: theme.spacing(1, 0, 2, 0),
    },
    cardMedia: {
      borderColor: COLORS.grey,
      borderWidth: 1,
      borderStyle: 'solid',
      width: 69,
      height: 69,
      borderRadius: 10,
      backgroundColor: COLORS.accent,
    },
    processCircle: {
      width: 25,
      height: 25,
      backgroundColor: COLORS.accent,
      borderRadius: '50%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: COLORS.grey,
    },
    containerContent: {
      padding: theme.spacing(1),
    },
  })
);

// header
let headers: ITableHeader[] = [
  {
    title: 'ID',
    type: 'id',
    column: 'customId',
  },
  {
    title: 'params',
    type: 'params',
    column: 'params',
  },
  {
    title: 'Name',
    align: 'left',
    sortable: true,
    column: 'name',
  },
  {
    title: 'Phone',
    align: 'left',
    column: 'phone',
  },
  {
    title: 'Bank Number',
    align: 'left',
    column: 'bank_number',
  },
  {
    title: 'Amount',
    align: 'left',
    alignContent: 'right',
    column: 'amount_text',
  },
];

let headersHistorical: ITableHeader[] = [
  {
    title: 'ID',
    type: 'id',
    column: 'customId',
  },
  {
    title: 'params',
    type: 'params',
    column: 'params',
  },
  {
    title: 'Name',
    align: 'left',
    sortable: true,
    column: 'name',
  },
  {
    title: 'Periode',
    align: 'left',
    column: 'period',
  },
  {
    title: 'Disburse Date',
    align: 'left',
    column: 'disburse_date',
  },
  {
    title: 'Bank Number',
    align: 'left',
    column: 'bank_number',
  },
  {
    title: 'Amount',
    align: 'left',
    alignContent: 'right',
    column: 'amount_text',
  },
  // {
  //   title: 'Transfer Receipt',
  //   align: 'center',
  //   alignContent: 'center',
  //   column: 'transfer_receipt',
  // },
  {
    title: 'Disbursement',
    align: 'left',
    column: 'disbursement',
  },
];

import React, { useRef, useState } from "react";
import {
  Box,
  CardMedia,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import AppButton from "./AppButton";
import { CloudUploadOutlined } from "@material-ui/icons";
import COLORS from "../../styles/colors";
import { toast } from 'react-toastify';

type Props = {
  title?: string;
  image?: string;
  size?: "small" | "large";
  imageResolution?: "500x500" | "1200x600" | "1200x840" | "1200x1200" | "1000x1000" | "850x1000";
  maxFileSize?: "1MB" | "5MB" | number;
  fileTypes?: Array<"jpg" | "jpeg" | "png">;
  onFileSelected?: (value: any) => void;
  editable?: boolean
};

export default function UploadImage(props: Props) {
  const classes = useStyles();

  const imageResolution:
    | "500x500"
    | "1200x600"
    | "1200x840"
    | "1200x1200"
    | "850x1000"
    | "1000x1000" = props.imageResolution ? props.imageResolution : "500x500";

  const cardMediaClassName:
    | "cardMedia_500x500"
    | "cardMedia_1200x600"
    | "cardMedia_1200x840"
    | "cardMedia_1200x1200"
    | "cardMedia_850x1000"
    | "cardMedia_1000x1000" = ("cardMedia_" + imageResolution) as any;

  const maxFileSize = props.maxFileSize ? props.maxFileSize : "1MB";
  const fileTypes = props.fileTypes ? props.fileTypes : ["jpg", "jpeg", "png"];

  const inputRef: any = useRef(null)
  const [imagePreview, setImagePreview] = useState<any>()
  const [fileName, setFileName] = useState<string>('')

  const parseImageResolution = (resolution: string) => {
    const raw = resolution.split('x')
    if (raw.length > 1) {
      return {
        width: Number(raw[0]),
        height: Number(raw[1]),
      }
    }
    return
  }

  const showWarning = (content: string) => {
    toast.warn(content, {
      position: toast.POSITION.TOP_CENTER,
      pauseOnHover: false,
      style: { width: 500, color: '#000' }
    });
  }

  const handleImageChange = (e: any) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    if (file.size < 1000000) {
      reader.onloadend = () => {

        const image: any = new Image()

        image.onload = () => {
          const imageResolution = parseImageResolution((props.imageResolution as string) || '500x500')
          if (imageResolution) {
            console.info(image.width, image.height)
            if (
              image.height > imageResolution.height + 2 ||
              image.height < imageResolution.height - 2 ||
              image.width > imageResolution.width + 2 ||
              image.width < imageResolution.width - 2
            ) {

              showWarning(`Resolusi gambar (${image.width}x${image.height}px), seharusnya ${imageResolution.width}x${imageResolution.height}px. Silahkan ganti ke ukuran yang sesuai.`);

              // } else if (image.width !== imageResolution.width) {

              //   showWarning(`Lebar gambar (${image.width}px), seharusnya ${imageResolution.width}px. Silahkan ganti ke ukuran yang sesuai.`);

              // } else if (image.height !== imageResolution.height) {

              //   showWarning(`Tinggi gambar (${image.height}px), seharusnya ${imageResolution.height}px. Silahkan ganti ke ukuran yang sesuai.`);

            } else {

              setImagePreview(reader.result);
              setFileName(file.name)
              if (props.onFileSelected) props.onFileSelected(file)

            }
          }
        }

        image.src = reader.result;
      }

      reader.readAsDataURL(file)
    } else {
      showWarning(`Ukuran file gambar maksimal 1 Mb`);
    }
  }

  return (
    <Box width="100%">
      {props.title ? <Typography variant="caption" display="block" style={{ color: COLORS.grey, marginBottom: 8, fontSize: 10 }}>{props.title}</Typography> : false}

      <CardMedia
        className={classes[cardMediaClassName]}
        image={
          imagePreview ? imagePreview : props.image
            ? props.image
            : props.imageResolution
              ?
              "/assets/default_" + imageResolution + ".png"
              : "/assets/default_500x500.png"
        }
        title="Oxone Image"
        component="img"
      />

      { props.editable &&
        (<Box>
          {props.size === "small" ? (
            <Box
              alignItems="center"
              style={{
                marginTop: 10,
                marginBottom: 10,
                borderRadius: 10,
              }}
            >
              <Box width="100%">
                <AppButton fullWidth size="small" color="primary">
                  <CloudUploadOutlined />
                  &nbsp;&nbsp;&nbsp;{fileName ? 'Change' : 'Browse'} Image
                </AppButton>
              </Box>

              <Box
                width="100%"
                style={{
                  padding: 3,
                  textAlign: "center",
                  backgroundColor: COLORS.greyLighten,
                }}
              >
                {fileName ? (
                  <Typography
                    variant="body1"
                    color="primary"
                  >
                    {fileName}
                  </Typography>
                ) : (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      style={{ fontStyle: "italic" }}
                    >
                      No file selected.
                    </Typography>

                  )
                }
              </Box>
            </Box>
          ) : (
              <Box
                display="flex"
                alignItems="center"
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  backgroundColor: COLORS.greyLighten,
                  borderRadius: 10,
                }}
              >
                <Box>
                  <AppButton size="small" color="primary" onClick={() => inputRef.current.click()}>
                    <CloudUploadOutlined />
              &nbsp;&nbsp;&nbsp;{fileName ? 'Change' : 'Browse'} Image
            </AppButton>
                </Box>

                {fileName ? (
                  <Typography
                    variant="body1"
                    color="primary"
                  >
                    {fileName}
                  </Typography>
                ) : (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      style={{ fontStyle: "italic" }}
                    >
                      No file selected.
                    </Typography>

                  )
                }
              </Box>
            )}

          <input
            style={{ display: 'none' }}
            ref={inputRef}
            type="file"
            accept="image/png, image/jpeg"
            onChange={(e: any) => handleImageChange(e)}
          />

          <Box>
            <Typography variant="subtitle2" style={{ lineHeight: 1.4 }}>
              Recommended image dimension:{" "}
              <b>{props.imageResolution ? props.imageResolution : "500x500"} px</b>,
          Max image size:
          <b>{maxFileSize}</b>, Supported file type are{" "}
              <b>{fileTypes.join(", ")}</b>.
        </Typography>
          </Box>
        </Box>)
      }
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {},
    cardRoot: {
      width: "100%",
      display: 'flex',
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      backgroundColor: COLORS.greyLighten,
      paddingTop: 16,
      paddingBottom: 16,
      marginTop: 10,
    },
    cardMedia_500x500: {
      width: '100%',
      height: '100%',
      maxHeight: 300,
      objectFit: 'contain',
      alignSelf: "center",
      backgroundColor: COLORS.accent,
      borderColor: COLORS.grey,
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 6,
    },
    cardMedia_1200x600: {
      width: '100%',
      height: '100%',
      maxHeight: 300,
      objectFit: 'contain',
      alignSelf: "center",
      backgroundColor: COLORS.accent,
      borderColor: COLORS.grey,
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 6,
    },
    cardMedia_1200x840: {
      width: '100%',
      height: '100%',
      maxHeight: 400,
      objectFit: 'contain',
      alignSelf: "center",
      backgroundColor: COLORS.accent,
      borderColor: COLORS.grey,
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 6,
    },
    cardMedia_850x1000: {
      width: '100%',
      height: '100%',
      maxHeight: 300,
      objectFit: 'contain',
      alignSelf: "center",
      backgroundColor: COLORS.accent,
      borderColor: COLORS.grey,
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 6,
    },
    cardMedia_1200x1200: {
      width: 200,
      height: 200,
      maxHeight: 300,
      alignSelf: "center",
      backgroundColor: COLORS.accent,
      borderColor: COLORS.accent,
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 10,
    },
    cardActionArea: {
      display: "flex",
      padding: 10,
    },
    cardMedia_1000x1000: {
      width: 300,
      height: 300,
      maxHeight: 300,
      alignSelf: "center",
      margin: "0 auto",
      backgroundColor: COLORS.accent,
      borderColor: COLORS.accent,
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 10,
    },
  })
);

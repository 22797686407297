import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import React from 'react';
import Layout from '../../components/Layout/Layout';
import CustomizedTable, {
  ITableHeader,
  StyledTableCell,
} from '../../components/Table/CustomizedTable';
import chefLogo from '../../assets/chef_choice.png';
import { Link, useLocation } from 'react-router-dom';
import useGlobalStyles from '../../styles/globalStyles';
import AppButton from '../../components/Form/AppButton';
import HeaderPage from '../../components/Layout/HeaderPage';
import Search from '../../components/Form/Search';
import AppTabs from '../../components/Tabs/AppTabs';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../redux/store';
import {
  setLatestParams,
  enableUpdateParams,
} from '../../redux/action/config-app.action';
import { generateQueryString } from '../../helpers/functions/generateQueryString';
import {
  ERecipeStatus,
  RecipeProperties,
  RecipeResponse,
} from '../../types/recipe.type';
import { httpRequest } from '../../helpers/api/api';
import { apiFindOneCustomer } from '../../api/customer';
import { apiFindOneRecipeCategory } from '../../api/recipe-category';
import { Close } from '@material-ui/icons';
import FormInput from '../../components/Form/FormInput';
import {
  RecipeCategoryResponse,
  RecipeCategoryTypes,
} from '../../types/recipe-category.type';
import useRecipe from '../../hooks/useRecipe';
import useDebounce from '../../hooks/useDebounce';
import { DefaultResponseList } from '../../hooks/useFetch';
import { updateRecipe } from '../../redux/action/recipe.action';

const tabs = ['Waiting Approval', 'Approved', 'Rejected', 'Semua'];

type DataProps = {
  id: string;
  image: string;
  recipeName: string;
  customer: string;
  phoneNumber: string;
  category: string;
  recipeType: string;
  approvedAt: Date;
  rejectedAt: Date;
  createdAt: Date;
  status: string;
};

const initialQuery = {
  limit: 25,
  page: 1,
  sortBy: 'createdAt:DESC',
  include: 'customer,category,tag',
  filterStatus: ERecipeStatus.waiting_approval,
};

const initialActiveTab = 0;

export default function Recipe() {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const waitingApprovalHeader: ITableHeader[] = [
    {
      title: 'recipeId',
      column: 'recipeId',
      type: 'id',
    },
    {
      title: 'Image',
      column: JSON.stringify(['recipeImage', '0', 'imageUrl']),
      type: 'nested-image',
    },
    {
      title: 'Recipe Name',
      column: 'recipeName',
      width: '20%',
    },
    {
      title: 'Customer',
      sortable: true,
      column: JSON.stringify(['customer', 'customerName']),
      type: 'nested',
      width: '20%',
    },
    {
      title: 'Phone Number',
      sortable: true,
      column: JSON.stringify(['customer', 'phoneNumber']),
      width: '20%',
      type: 'nested',
    },
    {
      title: 'Category',
      column: JSON.stringify(['categories', '0', 'recipeCategoryName']),
      type: 'nested',
    },
    {
      title: 'Created At',
      column: 'createdAt',
      type: 'date',
      width: '12%',
    },
    {
      title: 'Approve/Reject',
      column: 'actions',
    },
  ];

  const approvedHeader: ITableHeader[] = [
    {
      title: 'recipeId',
      column: 'recipeId',
      type: 'id',
    },
    {
      title: 'Image',
      column: JSON.stringify(['recipeImage', '0', 'imageUrl']),
      type: 'nested-image',
    },
    {
      title: 'Recipe Name',
      column: 'recipeName',
      width: '20%',
    },
    {
      title: 'Customer',
      sortable: true,
      column: JSON.stringify(['customer', 'customerName']),
      type: 'nested',
      width: '20%',
    },
    {
      title: 'Phone Number',
      sortable: true,
      column: JSON.stringify(['customer', 'phoneNumber']),
      type: 'nested',
    },
    {
      title: 'Category',
      column: JSON.stringify(['categories', '0', 'recipeCategoryName']),
      type: 'nested',
    },
    {
      title: 'Recipe Type',
      column: 'typeRecipe',
    },
    {
      title: 'Approved At',
      column: 'updatedAt',
      type: 'date',
    },
  ];

  const rejectedHeader: ITableHeader[] = [
    {
      title: 'recipeId',
      column: 'recipeId',
      type: 'id',
    },
    {
      title: 'Image',
      column: JSON.stringify(['recipeImage', '0', 'imageUrl']),
      type: 'nested-image',
    },
    {
      title: 'Recipe Name',
      column: 'recipeName',
      width: '20%',
    },
    {
      title: 'Customer',
      sortable: true,
      column: JSON.stringify(['customer', 'customerName']),
      type: 'nested',
      width: '20%',
    },
    {
      title: 'Phone Number',
      sortable: true,
      column: JSON.stringify(['customer', 'phoneNumber']),
      width: '20%',
      type: 'nested',
    },
    {
      title: 'Category',
      column: JSON.stringify(['categories', '0', 'recipeCategoryName']),
      type: 'nested',
    },
    {
      title: 'Rejected At',
      column: 'updatedAt',
      type: 'date',
    },
  ];

  const allStatusHeader: ITableHeader[] = [
    {
      title: 'recipeId',
      column: 'recipeId',
      type: 'id',
    },
    {
      title: 'Image',
      column: JSON.stringify(['recipeImage', '0', 'imageUrl']),
      type: 'nested-image',
    },
    {
      title: 'Recipe Name',
      column: 'recipeName',
      width: '20%',
    },
    {
      title: 'Customer',
      sortable: true,
      column: JSON.stringify(['customer', 'customerName']),
      type: 'nested',
      width: '20%',
    },
    {
      title: 'Phone Number',
      sortable: true,
      column: JSON.stringify(['customer', 'phoneNumber']),
      width: '20%',
      type: 'nested',
    },
    {
      title: 'Category',
      column: JSON.stringify(['categories', '0', 'recipeCategoryName']),
      type: 'nested',
    },
    {
      title: 'Status',
      column: 'recipeStatus',
      type: 'status-recipe',
    },
    {
      title: 'Created At',
      column: 'createdAt',
      type: 'date',
      width: '10%',
    },
    {
      title: 'Approve/Reject',
      column: 'approveReject',
    },
    {
      title: 'Approve/Reject',
      column: 'actions',
    },
  ];

  let title = 'Recipe';
  let subtitle = 'List of all Recipe';
  const recipeState = useSelector((state: ApplicationState) => state.recipe);
  const { updateParams, latestParams } = useSelector(
    (state: ApplicationState) => state.configApp
  );
  const { fetchAllRecipe, updateOneRecipe } = useRecipe();

  const [category, setCategory] = React.useState<string>('all');
  const [page, setPage] = React.useState(1);
  const [totalData, setTotalData] = React.useState(0);
  const [totalPage, setTotalPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [search, setSearch] = React.useState<string>('');
  const [activeTab, setActiveTab] = React.useState<number>(0);
  const [idRecipe, setIdRecipe] = React.useState<string>('');
  const [dataRecipe, setDataRecipe] = React.useState<any>([]);
  const [filterStatus, setFilterStatus] = React.useState('All');
  const [remark, setRemark] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isShowModalApproval, setIsShowModalApproval] = React.useState(false);
  const [isShowModalReject, setIsShowModalReject] = React.useState(false);
  const [chefChoice, setChefChoice] = React.useState<any>(false);
  const [query, setQuery] = React.useState<any>({
    limit: 25,
    offset: 0,
    search: '',
    filterStatus: ERecipeStatus.waiting_approval,
    sortBy: 'createdAt:DESC',
    include: 'customer,category,tag',
  });
  const [categories, setCategories] = React.useState<RecipeCategoryResponse[]>(
    []
  );

  const debouncedTitle = useDebounce(search, 300);
  const location = useLocation();

  React.useEffect(() => {
    try {
      fetchAllCategory();
    } catch (error) {}
  }, []);

  React.useEffect(() => {
    if (updateParams) {
      console.info('update true');
      dispatch(
        setLatestParams({
          pathname: location.pathname,
          params: generateQueryString(query),
          query: {
            limit: query['limit'],
            offset: query['offset'],
            published: query['published'],
            page,
          },
        })
      );
      fetchAllRecipe(query);
    } else {
      console.info('update false');
      setPage(latestParams.query.page || page || 1);
      fetchAllRecipe(latestParams.query);
      dispatch(enableUpdateParams(true));
    }
  }, [query]);

  React.useEffect(() => {
    searchRecipeName(search);
  }, [debouncedTitle]);

  function changePagination(value: number) {
    setPage(value);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
        limit: rowsPerPage,
      };
    });
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value);
    setPage(1);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: 0,
        limit: value,
      };
    });
  }

  const searchRecipeName = async (value: any) => {
    if (value !== null && value.length > 0) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          search: value,
        };
      });
    } else if (value !== null) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          search: '',
        };
      });
    }
  };

  React.useEffect(() => {
    const dataMapped = recipeState.data.payload.results.map((x) => {
      return {
        ...x,
        actions: (
          <div>
            {x.recipeStatus === ERecipeStatus.waiting_approval ? (
              <StyledTableCell align='center' width='5%'>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <AppButton
                    style={{ marginBottom: '10px' }}
                    onClick={() => {
                      dispatch(updateRecipe(x));
                      setIdRecipe(x.recipeId);
                      handleOpenModalApproval();
                    }}
                  >
                    Approve
                  </AppButton>
                  <AppButton
                    onClick={() => {
                      dispatch(updateRecipe(x));
                      setIdRecipe(x.recipeId);
                      handleOpenModalReject();
                    }}
                    color='secondary'
                  >
                    Reject
                  </AppButton>
                </div>
              </StyledTableCell>
            ) : (
              <></>
            )}
          </div>
        ),
        typeRecipe: (
          <>
            {x.isChefChoice ? (
              <Typography style={{ display: 'flex' }}>
                <img
                  src={chefLogo}
                  style={{
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    marginRight: 5,
                  }}
                  width={'20px'}
                  alt='Chef Choice'
                />
                Chef Choice
              </Typography>
            ) : (
              <Typography>Regular</Typography>
            )}
          </>
        ),
      };
    });
    setDataRecipe(dataMapped);
  }, [recipeState]);

  const fetchAllCategory = async () => {
    const category = await httpRequest.get<
      DefaultResponseList<RecipeCategoryResponse>
    >('recipe-category');
    setCategories([
      ...category.data.payload.results,
      {
        recipeCategoryId: 'All',
        recipeCategoryName: 'All',
      },
    ]);
  };

  const setFilter = (data: any) => {
    if (data === 'All') {
      setQuery((val: any) => {
        return {
          limit: 25,
          offset: 0,
          search: '',
          sortBy: 'createdAt:DESC',
          include: 'customer,category,tag',
        };
      });
    } else {
      const id = categories.find(
        (x) => x.recipeCategoryName === data
      )?.recipeCategoryId;
      setQuery((val: any) => {
        return {
          ...val,
          filterCategory: id,
        };
      });
    }
  };
  const handleCloseModalApproval = () => {
    setIsShowModalApproval(false);
  };
  const handleOpenModalApproval = () => {
    setIsShowModalApproval(true);
  };

  const handleCloseModalReject = () => {
    setIsShowModalReject(false);
  };
  const handleOpenModalReject = () => {
    setIsShowModalReject(true);
  };

  function submit(type: 'approved' | 'rejected') {
    setIsLoading(true);
    if (type === 'approved') {
      onApprove(idRecipe);

      handleCloseModalApproval();
      setIsLoading(false);
    }
    if (type === 'rejected') {
      onReject(idRecipe);

      handleCloseModalReject();
      setIsLoading(false);
    }
    setIdRecipe('');
  }
  const changeTab = (value: number) => {
    setActiveTab(value);

    let status: ERecipeStatus | null = null;

    if (value === 0) {
      status = ERecipeStatus.waiting_approval;
    } else if (value === 1) {
      status = ERecipeStatus.approved;
    } else if (value === 2) {
      status = ERecipeStatus.rejected;
    } else if (value === 3) {
      status = null;
    }

    if (value !== 3) {
      setQuery({
        offset: 0,
        filterStatus: status,
        limit: 25,
        page: 1,
        sortBy: 'createdAt:DESC',
        include: 'customer,category,tag',
      });
    } else {
      setQuery({
        offset: 0,
        limit: 25,
        page: 1,
        sortBy: 'createdAt:DESC',
        include: 'customer,category,tag',
      });
    }
  };

  const onApprove = async (id: string) => {
    try {
      const recipe = recipeState.data.payload.results.find(
        (x) => x.recipeId === id
      );
      updateOneRecipe({
        ...recipe,
        recipeStatus: ERecipeStatus.approved,
        isChefChoice: chefChoice,
      });
      fetchAllRecipe(query);
    } catch (error) {}
  };

  const onReject = async (id: string) => {
    const recipe = recipeState.data.payload.results.find(
      (x) => x.recipeId === id
    );
    updateOneRecipe({
      ...recipe,
      recipeStatus: ERecipeStatus.rejected,
      recipeRemark: remark,
    });
    fetchAllRecipe(query);
  };

  const renderAction = (item: RecipeProperties) => {
    return (
      <Box display='flex' flexDirection='row' justifyContent='center'>
        <AppButton
          onClick={() => {
            handleOpenModalApproval();
          }}
          size='small'
          color='primary'
        >
          Approve
        </AppButton>
      </Box>
    );
  };

  const onPressAccept = (data: any) => {
    setIdRecipe(data);
    setIsShowModalApproval(true);
  };

  const onPressReject = (data: any) => {
    setIdRecipe(data);
    setIsShowModalReject(true);
  };

  return (
    <Layout title={title} loading={recipeState.isLoadingRecipe}>
      <HeaderPage title={title} subtitle={subtitle} />

      <Grid
        container
        spacing={3}
        justify='space-between'
        alignItems='center'
        style={{ marginBottom: 10 }}
      >
        <Grid item xs={12} md={9} lg={9}>
          <Search
            placeholder='Search by recipe name, pembuat, atau phone number'
            onChange={(value: string) => setSearch(value)}
            value={search}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <FormControl style={{ width: '100%' }}>
            <InputLabel id='select-status' shrink>
              Sort
            </InputLabel>
            <Select
              label='Status'
              placeholder='Status'
              labelId='select-status'
              id='demo-customized-select'
              value={filterStatus}
              onChange={(event: any) => {
                setFilterStatus(event.target.value);
                setFilter(event.target.value);
              }}
            >
              {categories.map((x) => {
                return (
                  <MenuItem value={x.recipeCategoryName}>
                    {x.recipeCategoryName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <AppTabs
            active={activeTab}
            tabs={tabs}
            onChange={(value: number) => changeTab(value)}
          />

          <Grid container spacing={3}>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <CustomizedTable
                data={dataRecipe}
                headers={
                  activeTab === 0
                    ? waitingApprovalHeader
                    : activeTab === 1
                    ? approvedHeader
                    : activeTab === 2
                    ? rejectedHeader
                    : allStatusHeader
                }
                page={page}
                totalData={totalData}
                totalPage={totalPage}
                rowsPerPage={rowsPerPage}
                onChange={(newPage) => changePagination(newPage)}
                onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
                showAction={['detail']}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={isShowModalApproval}
        onClose={handleCloseModalApproval}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        <div className={classes.containerModal}>
          <div className={classes.contentModal}>
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
            >
              <Typography variant='h5'>Approve Recipe</Typography>

              <button onClick={() => handleCloseModalApproval()}>
                <Close />
              </button>
            </Box>

            <Grid container>
              <Grid item xs={12}>
                <Typography>
                  Approve this recipe and the recipe will be published
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={1}>
                <Checkbox
                  checked={chefChoice}
                  onChange={(e) => setChefChoice(e.target.checked)}
                  inputProps={{ 'aria-label': 'Mark as Chef Choice' }}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography style={{ marginTop: 8 }}>
                  Mark as Chef Choice
                </Typography>
              </Grid>
            </Grid>

            <Box display='flex' justifyContent='flex-end'>
              <Box>
                <AppButton color='secondary' onClick={handleCloseModalApproval}>
                  Cancel
                </AppButton>
              </Box>
              <Box>
                <AppButton
                  loading={isLoading}
                  onClick={() => submit('approved')}
                >
                  Approve & Publish
                </AppButton>
              </Box>
            </Box>
          </div>
        </div>
      </Modal>
      <Modal
        open={isShowModalReject}
        onClose={handleCloseModalReject}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        <div className={classes.containerModal}>
          <div className={classes.contentModal}>
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
            >
              <Typography variant='h5'>Reject Recipe</Typography>

              <button onClick={() => handleCloseModalReject()}>
                <Close />
              </button>
            </Box>

            <Grid container>
              <Grid item xs={12}>
                <Typography>
                  Are you sure want to reject this recipe?
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                <Typography>Remark</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormInput
                  onChange={(value: any) => setRemark(value)}
                  type='address'
                  fullWidth
                  required
                  placeholder='Alasan Pembatalan'
                />
              </Grid>
            </Grid>

            <Box display='flex' justifyContent='flex-end'>
              <Box>
                <AppButton color='secondary' onClick={handleCloseModalReject}>
                  Cancel
                </AppButton>
              </Box>
              <Box>
                <AppButton
                  loading={isLoading}
                  onClick={() => submit('rejected')}
                >
                  Save
                </AppButton>
              </Box>
            </Box>
          </div>
        </div>
      </Modal>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
    paperTitle: {
      // padding: theme.spacing(2),
    },
    containerButton: {
      flexDirection: 'row',
      margin: theme.spacing(2, 0, 1, 0),
    },
    containerTable: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    containerModal: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      alihandleCloseModalApprovalgnContent: 'center',
    },
    contentModal: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
      width: 600,
      margin: '0 auto',
      marginTop: '10%',
      borderRadius: 10,
    },
  })
);

import { createStore, Store } from 'redux';
import { AdminState } from '../types/admin.type';
import { BankApprovalState } from '../types/bank-approval.types';
import { BannerState } from '../types/banner.type';
import { CategoryState } from '../types/category.type';
import { ConfigAppState } from '../types/config.type';
import { CustomerState } from '../types/customer.type';
import { DashboardState } from '../types/dashboard.type';
import { FeaturedProductState } from '../types/featured-product.type';
import { FeedbackState } from '../types/feedback.types';
import { ReferralComissionState } from '../types/master-data-referral.type';
import { PromotionState, NewsState } from '../types/news.type';
import { ProductPriceHistoryState, ProductState } from '../types/product.type';
import { ReferralDiscountState } from '../types/referral-discount.type';
import { StockState } from '../types/stock-retail.type';
import { TagState } from '../types/tag.type';
import { TransactionState } from '../types/transaction.types';
import { VersionState } from '../types/version.type';
import { VoucherAppState } from '../types/voucher.type';
import rootReducer from './reducer/index';
import { StorefrontAppState } from './reducer/storefront.reducer';
// import createSagaMiddleware from 'redux-saga';
// import rootSaga from './redux/saga';
import { B2BState } from './reducer/b2b.reducer';
import { CollectionState } from '../types/collection.type';
import { ServiceCenterTransactionState } from '../types/service-center-transaction.types';
import { ServiceCenterState } from '../types/service-center.types';
import { CoinHistoryState } from '../types/coin.type';
import { PickupPointState } from '../types/pickup-point';
import { ReportReferralState } from '../types/report-referral.type';
import { RecipeCategoryState } from '../types/recipe-category.type';
import { RecipeTagState } from '../types/recipe-tag.type';
import { RecipeState } from '../types/recipe.type';


// const sagaMiddleware = createSagaMiddleware(); // <-- saga

export interface ApplicationState {
  promotion: PromotionState;
  news: NewsState;
  tag: TagState;
  banner: BannerState;
  product: ProductState;
  stockRetail: StockState;
  category: CategoryState;
  collection: CollectionState;
  featuredProduct: FeaturedProductState;
  transaction: TransactionState;
  customer: CustomerState;
  masterDataReferral: ReferralComissionState;
  feedback: FeedbackState;
  bankApproval: BankApprovalState;
  configApp: ConfigAppState;
  dashboard: DashboardState;
  admin: AdminState;
  version: VersionState;
  referralDiscount: ReferralDiscountState;
  voucher: VoucherAppState;
  storefront: StorefrontAppState;
  b2b: B2BState;
  serviceCenter: ServiceCenterState;
  serviceCenterTransaction: ServiceCenterTransactionState;
  coin: CoinHistoryState;
  pickupPoint: PickupPointState;
  reportReferral: ReportReferralState;
  recipeCategory: RecipeCategoryState;
  recipeTag: RecipeTagState;
  recipe: RecipeState
}

const store: Store<ApplicationState> = createStore(
  rootReducer
  // applyMiddleware(sagaMiddleware), // <-- apply middleware
);

// then run the saga
// sagaMiddleware.run(rootSaga);

export default store;


export enum VoucherTypes {
  SET_CHEF_NAME = 'SET_CHEF_NAME',
}

export function setChefName(payload: string) {
  return {
    type: VoucherTypes.SET_CHEF_NAME,
    payload
  }
}
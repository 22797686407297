import { CoinHistoryResponse, CoinTypes } from "../../types/coin.type";

export function findLogCoinHistory(payload: CoinHistoryResponse) {
  return {
    type: CoinTypes.FIND_LOG_COIN_HISTORY,
    payload,
  };
}

export function setLoadingCoin(payload: boolean) {
  return {
    type: CoinTypes.SET_LOADING_COIN_HISTORY,
    payload,
  };
}

import React, { useEffect, useState } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { APP_AUTH_TOKEN } from '../helpers/auth';
import useAuth from '../hooks/useAuth';

export interface IProps {
  exact?: boolean;
  path: string;
  roles?: any;
};

const PrivateRoute: React.FC<IProps> = ({ children, roles, ...rest }) => {
  let auth = useAuth();
  const [isLogin, setIsLogin] = useState(false)

  useEffect(() => {
    auth.checkUserSignIn()
  }, [])

  if (auth.loading) {
    return <div />
  } else {
    return (
      <Route
        {...rest}
        render={({ location }: any) => {
          if (!auth.userSignIn) {
            <Redirect
              to={{
                pathname: "/",
                state: { from: location }
              }}
            />
          }

          if (roles) {
            if (auth.userSignIn && !roles.includes(auth.user.role)) {
              return (
                <Redirect
                  to={{
                    pathname: "/unauthorized",
                    state: { from: location }
                  }}
                />
              )
            } else {
              return children
            }
          }

          return children
        }
        }
      />
    );
  }

};

export default PrivateRoute;
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setUpladImageStatus } from "../redux/action/config-app.action";
import { generateQueryString } from "../helpers/functions/generateQueryString";
import { createPickupPoint, deletePickupPoint, findAllPickupPoint, findAllPickupPointByAro, findOnePickupPoint, setErrorPickupPoint, setLoadingPickupPoint, updatePickupPoint } from "../redux/action/pickup-point.action";
import { apiCreatePickupPoint, apiDeletePickupPoint, apiFindAllPickupPoint, apiFindAllPickupPointByAro, apiFindOnePickupPoint, apiUpdatePickupPoint } from "../api/pickup-point";
import { PickupPointType } from "../types/pickup-point";
import { toast } from "react-toastify";

export default function usePickupPoint() {
  const dispatch = useDispatch();
  const history = useHistory();

  const fetchAllPickupPoint = async (params: any) => {
    try {
      dispatch(setLoadingPickupPoint(true));
      let query = generateQueryString(params);

      const result = await apiFindAllPickupPoint(query);

      dispatch(findAllPickupPoint(result.data));

      dispatch(setLoadingPickupPoint(false));
    } catch (error: any) {
      dispatch(setLoadingPickupPoint(false));
      dispatch(setErrorPickupPoint(error.response));
    }
  };

  const fetchAllPickupPointByAro = async (aroid: string, params: any) => {
    try {
      dispatch(setLoadingPickupPoint(true));
      let query = generateQueryString(params);

      const result = await apiFindAllPickupPointByAro(aroid, query);

      dispatch(findAllPickupPointByAro(result.data));

      dispatch(setLoadingPickupPoint(false));
    } catch (error: any) {
      dispatch(setLoadingPickupPoint(false));
      dispatch(setErrorPickupPoint(error.response));
    }
  };

  const fetchOnePickupPoint = async (PickupPointId: string) => {
    try {
      dispatch(setLoadingPickupPoint(true));

      const result = await apiFindOnePickupPoint(PickupPointId);

      dispatch(findOnePickupPoint(result.data.payload));

      dispatch(setLoadingPickupPoint(false));
    } catch (error: any) {
      dispatch(setLoadingPickupPoint(false));
      dispatch(setErrorPickupPoint(error.response));
    }
  };

  const addPickupPoint = async (data: PickupPointType) => {
    try {
      dispatch(setLoadingPickupPoint(true));
      const result = await apiCreatePickupPoint(data);

      if (result.data.payload) {
        dispatch(createPickupPoint(result.data.payload));
        history.go(-1);
      }

      dispatch(setLoadingPickupPoint(false));
      dispatch(setUpladImageStatus(true));
    } catch (error: any) {
      dispatch(setLoadingPickupPoint(false));
      dispatch(setErrorPickupPoint(error.response));
    }
  };

  const updateOnePickupPoint = async (pickupPointId: string, data: PickupPointType) => {
    try {
      dispatch(setLoadingPickupPoint(true));
      const result = await apiUpdatePickupPoint(pickupPointId, data);

      if (result.data.payload) {
        dispatch(updatePickupPoint(result.data.payload));
        history.go(-1);
      }

      dispatch(setLoadingPickupPoint(false));
      dispatch(setUpladImageStatus(true));
    } catch (error: any) {
      dispatch(setLoadingPickupPoint(false));
      dispatch(setErrorPickupPoint(error.response));
    }
  };

  const removePickupPoint = async (aroId: string | null = null, pickupPointId: string) => {
    try {
      dispatch(setLoadingPickupPoint(true));

      await apiDeletePickupPoint(pickupPointId);

      dispatch(deletePickupPoint(pickupPointId));

      if(aroId){
        await fetchAllPickupPointByAro(aroId, { limit: 25, offset: 0, search: '' })
      }else {
        await fetchAllPickupPoint({ limit: 25, offset: 0, search: '' })
      }

      dispatch(setLoadingPickupPoint(false));

      toast.success("Success remove pickup point", {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
    } catch (error: any) {
      dispatch(setLoadingPickupPoint(false));
      dispatch(setErrorPickupPoint(error.response));
    }
  };

  return {
    fetchAllPickupPoint,
    fetchAllPickupPointByAro,
    fetchOnePickupPoint,
    addPickupPoint,
    updateOnePickupPoint,
    removePickupPoint,
  };
}

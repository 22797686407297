import React from "react";
import {
  Typography,
  Box,
  CardMedia,
  createStyles,
  makeStyles,
  Theme,
  Chip,
} from "@material-ui/core";
import COLORS from "../../styles/colors";
import AppButton from "../Form/AppButton";
import { Eye } from "react-feather";
import { ProductCategory, ProductResponse } from '../../types/product.type';
import { convertNumToCurrency } from '../../helpers/functions/currency';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { findOneProduct } from '../../redux/action/product.action';
import PlaceholderImageSquare from '../../assets/default_1200x1200.png'

export type Props = {
  product: ProductResponse
}

export default function ProductPreviewLong(props: Props) {
  const classes = useStyles();

  const history = useHistory();

  const dispatch = useDispatch()

  const convertCurrency = (data: number) => {
    return convertNumToCurrency(data)
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      style={{
        padding: 10,
        borderRadius: 10,
        borderColor: COLORS.grey,
        borderWidth: 1,
        borderStyle: 'solid',
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        style={{
          borderRightColor: COLORS.greyLight,
          borderRightWidth: 1,
          borderRightStyle: 'solid',
          flex: 1,
        }}
      >
        <Box
          style={{
            borderRadius: 10,
            width: 120,
            height: 120,
          }}
        >
          <CardMedia
            className={classes.cardMedia}
            image={
              props.product.images && props.product.images.length > 0
                ? props.product.images[0].imageUrl
                : PlaceholderImageSquare
            }
          />
        </Box>
        <Box width="40%" style={{ padding: '0px 10px 10px 10px' }}>
          <Typography variant="body1" color="textSecondary">
            Product Name
          </Typography>
          <Typography>
            {props.product.productName}
          </Typography>

          <Typography variant="body1" color="textSecondary">
            SKU
          </Typography>
          <Typography>{props.product.sku}</Typography>
        </Box>

        <Box style={{ padding: '0px 10px 10px 10px' }}>
          {/* <Typography variant="body1" color="textSecondary">
            Categories
          </Typography>

          <Box display="flex" flexDirection="row">
            {props.product.categories && props.product.categories.length > 0 && props.product.categories.map((item: ProductCategory) => (
              <Chip label={item.categoryName} style={{ marginRight: 20, borderRadius: 10 }} />
            ))}
          </Box> */}

          <Typography variant="body1" color="textSecondary">
            Retail Price
          </Typography>
          <Typography>
            {convertCurrency(props.product.price.retailPrice)}
          </Typography>

          <Typography variant="body1" color="textSecondary">
            Status
          </Typography>
          <Typography>
            {props.product.published ? 'Published' : 'Unpublished'}
          </Typography>
        </Box>

        {/* <Box style={{ padding: 10 }}>
          <Typography variant="body1" color="textSecondary">
            Retail Price
          </Typography>
          <Typography>{convertCurrency(props.product.price.retailPrice)}</Typography>

          <Typography variant="body1" color="textSecondary">
            Agent Price
          </Typography>
          <Typography>{convertCurrency(props.product.price.distributorPrice)}</Typography>
        </Box> */}
      </Box>
      <Box style={{ paddingLeft: 50, paddingRight: 50 }}>
        <AppButton
          onClick={() => {
            dispatch(findOneProduct(props.product));
            history.replace('/product/' + props.product.productId);
          }}
          size="small"
        >
          <Eye />
          <Typography component="span" style={{ marginLeft: 5 }}>
            Detail Product
          </Typography>
        </AppButton>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardMedia: {
      width: 120,
      height: 120,
      borderRadius: 10,
      backgroundColor: COLORS.accent,
      borderColor: COLORS.grey,
      borderWidth: 1,
      borderStyle: "solid",

    },
    categoryItem: {
      padding: theme.spacing(0.5, 1, 0.5, 1),
      backgroundColor: COLORS.grey,
      borderRadius: 10,
      marginRight: 5,
    },
  })
);

import { IconProps } from "./IconProps";

export default function Refund(props: IconProps) {
  return (
    <svg
      width={props.size || 24}
      height={props.size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 11C0 11.5523 0.447715 12 1 12C1.55228 12 2 11.5523 2 11H0ZM14 19C13.4477 19 13 19.4477 13 20C13 20.5523 13.4477 21 14 21V19ZM1 8C0.447715 8 0 8.44772 0 9C0 9.55228 0.447715 10 1 10V8ZM23 10C23.5523 10 24 9.55228 24 9C24 8.44772 23.5523 8 23 8V10ZM6.57935 15.949C6.96987 15.5585 6.96987 14.9253 6.57935 14.5348C6.18883 14.1443 5.55566 14.1443 5.16514 14.5348L6.57935 15.949ZM3.91615 17.198L3.20904 16.4909C2.81852 16.8814 2.81852 17.5146 3.20904 17.9051L3.91615 17.198ZM5.16514 19.8612C5.55566 20.2517 6.18883 20.2517 6.57935 19.8612C6.96987 19.4707 6.96987 18.8375 6.57935 18.447L5.16514 19.8612ZM8.26909 18.198C8.82138 18.198 9.26909 17.7503 9.26909 17.198C9.26909 16.6457 8.82138 16.198 8.26909 16.198V18.198ZM21 3H3V5H21V3ZM3 3C1.34314 3 0 4.34315 0 6H2C2 5.44771 2.44771 5 3 5V3ZM21 21C22.6569 21 24 19.6569 24 18H22C22 18.5523 21.5523 19 21 19V21ZM24 18V6H22V18H24ZM24 6C24 4.34315 22.6569 3 21 3V5C21.5523 5 22 5.44772 22 6H24ZM0 6V11H2V6H0ZM14 21H21V19H14V21ZM1 10H23V8H1V10ZM12.3961 17.198C12.3961 13.776 9.62008 11 6.19804 11V13C8.51551 13 10.3961 14.8806 10.3961 17.198H12.3961ZM6.19804 11C2.77601 11 0 13.776 0 17.198H2C2 14.8806 3.88058 13 6.19804 13V11ZM0 17.198C0 20.6201 2.77601 23.3961 6.19804 23.3961V21.3961C3.88058 21.3961 2 19.5155 2 17.198H0ZM6.19804 23.3961C9.62008 23.3961 12.3961 20.6201 12.3961 17.198H10.3961C10.3961 19.5155 8.51551 21.3961 6.19804 21.3961V23.3961ZM5.16514 14.5348L3.20904 16.4909L4.62326 17.9051L6.57935 15.949L5.16514 14.5348ZM3.20904 17.9051L5.16514 19.8612L6.57935 18.447L4.62326 16.4909L3.20904 17.9051ZM8.26909 16.198H3.91615V18.198H8.26909V16.198Z"
        fill={props.color || "#201D1D"}
      />
    </svg>
  );
}

import { Reducer } from 'redux';
import { VoucherAppState } from '../../types/voucher.type';
import { VoucherTypes } from '../action/voucher.action';

const initialVoucher: VoucherAppState = {
  chefName: ''
}

export const voucher: Reducer<VoucherAppState> = (state = initialVoucher, action) => {
  switch (action.type) {
    case VoucherTypes.SET_CHEF_NAME:
      return {
        ...state,
        chefName: action.payload
      }
  
    default:
      return state;
  }
}

import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { apiCreateReferralDiscount, apiCurrentReferralDiscount, apiFindAllReferralDiscount, apiFindOneReferralDiscount } from '../api/referral-discount';
import { generateQueryString } from '../helpers/functions/generateQueryString';
import { setLoadingReferralDiscount, setErrorReferralDiscount, findOneReferralDiscount, createReferralDiscount, findLogReferralDiscount, currentReferralDiscount } from '../redux/action/referral-discount.action';
import { ReferralDiscountRequest } from '../types/referral-discount.type';

export default function useReferralDiscount() {
  const dispatch = useDispatch()

  const fetchAllReferralDiscount = async (params?: any) => {
    try {
      dispatch(setLoadingReferralDiscount(true))

      let query = generateQueryString(params)

      const result = await apiFindAllReferralDiscount(query)
      dispatch(findLogReferralDiscount(result.data))

      dispatch(setLoadingReferralDiscount(false))
    } catch (error) {
      console.info(error)
      dispatch(setLoadingReferralDiscount(false))
      dispatch(setErrorReferralDiscount(error.response))
    }

  }

  const fetchOneReferralDiscount = async (versionId: string) => {
    try {
      dispatch(setLoadingReferralDiscount(true))

      const result = await apiFindOneReferralDiscount(versionId)
      dispatch(findOneReferralDiscount(result.data.payload))

      dispatch(setLoadingReferralDiscount(false))
    } catch (error) {
      console.info(error)
      dispatch(setLoadingReferralDiscount(false))
      dispatch(setErrorReferralDiscount(error.response))
    }

  }

  const createNewReferralDiscount = async (data: ReferralDiscountRequest) => {
    try {
      dispatch(setLoadingReferralDiscount(true))

      await apiCreateReferralDiscount(data)

      dispatch(setLoadingReferralDiscount(false))

      toast.success("Success create data", {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
    } catch (error) {
      dispatch(setLoadingReferralDiscount(false))
      dispatch(setErrorReferralDiscount(error.response))
    }
  }

  const getCurrentReferralDiscount = async () => {
    try {

      const result = await apiCurrentReferralDiscount()

      dispatch(currentReferralDiscount(result.data.payload))
    } catch (error) {
      dispatch(setErrorReferralDiscount(error.response))
    }

  }

  return {
    fetchAllReferralDiscount,
    createNewReferralDiscount,
    fetchOneReferralDiscount,
    getCurrentReferralDiscount
  }
}
import { useDispatch } from 'react-redux';
import { apiAddProductStock, apiFindAllLimitedStock, apiHistoryStockRetail, apiRemoveProductStock } from '../api/stock-retail';
import { changeProductStock, findStockHistory, setErrorStock, setLoadingStock, setLimitedStock } from '../redux/action/stock-retail.action';
import { StockRequest } from '../types/stock-retail.type';
import { useEffect } from 'react';
import { generateQueryString } from '../helpers/functions/generateQueryString';

export default function useStock() {
  const dispatch = useDispatch()

  const fetchLimitedStock = async (params?: any) => {
    try {

      const result = await apiFindAllLimitedStock('?maxStock=5')

      dispatch(setLimitedStock(result.data.payload.count || 0))

    } catch (error) {
      console.info(error)
    }
  }

  const fetchStockHistory = async (productId: string, params?: any) => {
    try {
      dispatch(setLoadingStock(true))
      let query = generateQueryString(params)
      const result = await apiHistoryStockRetail(productId, query)

      dispatch(findStockHistory(result.data))

      dispatch(setLoadingStock(false))
    } catch (error) {
      dispatch(setLoadingStock(false))
      dispatch(setErrorStock(error.response))
    }
  }

  const addStock = async (data: StockRequest) => {
    try {
      dispatch(setLoadingStock(true))

      const result = await apiAddProductStock(data)

      dispatch(changeProductStock(result.data))

      dispatch(setLoadingStock(false))

      await fetchLimitedStock()
    } catch (error) {
      dispatch(setLoadingStock(false))
      dispatch(setErrorStock(error.response))
    }
  }

  const removeStock = async (data: StockRequest) => {
    try {
      dispatch(setLoadingStock(true))

      const result = await apiRemoveProductStock(data)

      dispatch(changeProductStock(result.data))

      dispatch(setLoadingStock(false))

      await fetchLimitedStock()
    } catch (error) {
      dispatch(setLoadingStock(false))
      dispatch(setErrorStock(error.response))
    }
  }

  return {
    fetchStockHistory,
    addStock,
    removeStock,
    fetchLimitedStock
  }
}
import { useDispatch } from 'react-redux'
import { apiFindAllBanner, apiCreateBanner, apiDeleteBanner, apiUpdateBanner, apiFindOneBanner, apiChangeOrderBanner } from '../api/content/banner'
import { setLoadingBanner, findAllBanner, setErrorBanner, createBanner, deleteBanner, updateBanner, findOneBanner } from '../redux/action/content/banner.action'
import { useHistory } from 'react-router-dom';
import { setUpladImageStatus } from '../redux/action/config-app.action';
import { toast } from 'react-toastify';
import { BannerChangeOrderRequest } from '../types/banner.type';
import { generateQueryString } from '../helpers/functions/generateQueryString';


export default function useBanner() {
  const dispatch = useDispatch()
  const history = useHistory()

  const fetchAllBanner = async (params?: any) => {
    try {
      dispatch(setLoadingBanner(true))

      let query = generateQueryString(params)

      const result = await apiFindAllBanner(query)
      dispatch(findAllBanner(result.data))

      dispatch(setLoadingBanner(false))
    } catch (error) {
      console.info(error)
      dispatch(setLoadingBanner(false))
      dispatch(setErrorBanner(error.response))
    }

  }

  const changeOrderBanner = async (params: BannerChangeOrderRequest, query?: any) => {
    try {
      dispatch(setLoadingBanner(true))

      const res = await apiChangeOrderBanner(params)

      if (res && res.data && res.data.payload && res.data.payload.result) {
        fetchAllBanner(query)
      }

      dispatch(setLoadingBanner(false))
    } catch (error) {
      console.info(error)
      dispatch(setLoadingBanner(false))
      dispatch(setErrorBanner(error.response))
    }

  }

  const fetchOneBanner = async (bannerId: string) => {
    try {
      dispatch(setLoadingBanner(true))

      const result = await apiFindOneBanner(bannerId)
      dispatch(findOneBanner(result.data.payload))

      dispatch(setLoadingBanner(false))
    } catch (error) {
      console.info(error)
      dispatch(setLoadingBanner(false))
      dispatch(setErrorBanner(error.response))
    }

  }

  const createNewBanner = async (data: any) => {
    try {
      dispatch(setLoadingBanner(true))

      const result = await apiCreateBanner(data)

      dispatch(createBanner(result.data.payload))

      dispatch(setLoadingBanner(false))
      dispatch(setUpladImageStatus(true))

      toast.success("Success create banner", {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
    } catch (error) {
      dispatch(setLoadingBanner(false))
      dispatch(setErrorBanner(error.response))
    }
  }

  const updateCurrentBanner = async (data: FormData) => {
    try {
      dispatch(setLoadingBanner(true))

      const result = await apiUpdateBanner(data)

      dispatch(updateBanner(result.data.payload))

      dispatch(setLoadingBanner(false))
      dispatch(setUpladImageStatus(true))

      toast.success("Success update banner", {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
    } catch (error) {
      dispatch(setLoadingBanner(false))
      dispatch(setErrorBanner(error.response))
    }
  }

  const removeBanner = async (bannerId: string) => {
    try {
      dispatch(setLoadingBanner(true))

      const result = await apiDeleteBanner(bannerId)

      dispatch(deleteBanner(result.data.payload))

      await fetchAllBanner({ limit: 25, offset: 0, search: '' })

      dispatch(setLoadingBanner(false))

      toast.success("Success remove banner", {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
    } catch (error) {
      dispatch(setLoadingBanner(false))
      dispatch(setErrorBanner(error.response))
    }

  }

  return {
    fetchAllBanner,
    createNewBanner,
    removeBanner,
    updateCurrentBanner,
    fetchOneBanner,
    changeOrderBanner
  }
}
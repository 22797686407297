import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Layout from '../../components/Layout/Layout';
import {
  Box,
  createStyles,
  makeStyles,
  Modal,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import COLORS from '../../styles/colors';
import HeaderPage from '../../components/Layout/HeaderPage';
import SimpleCard2 from './components/SimpleCard2';
import { Tag } from 'react-feather';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'react-feather';
import AppButton from '../../components/Form/AppButton';

import 'react-quill/dist/quill.snow.css';
import ShipmentDiscountHistory from './components/ShipmentDiscountHistory';
import { Close } from '@material-ui/icons';
import FormInput from '../../components/Form/FormInput';
import useShipmentDiscount from '../../hooks/useShipmentDiscount';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../redux/store';
import { convertNumToCurrency } from '../../helpers/functions/currency';
import { useAuth } from '../../hooks/useAuth';

export default function ShipmentDiscount() {
  const classes = useStyles();

  const { user } = useAuth()
  const masterData = useSelector((state: ApplicationState) => state.masterDataReferral)

  const { fetchCurrentShipmentDiscount, updateShipmentDiscount } = useShipmentDiscount()
  const [amount, setAmount] = useState(0)

  useEffect(() => {
    fetchCurrentShipmentDiscount()
  }, [])

  useEffect(() => {
    if (masterData.shipmentDiscount) {
      setAmount(masterData.shipmentDiscount.amount)
    }
  }, [masterData.shipmentDiscount])

  const [
    isShowModalEditShipmentDiscount,
    setIsShowModalEditShipmentDiscount,
  ] = useState(false);

  const handleCloseModalEditShipmentDiscount = () => {
    setAmount(masterData.shipmentDiscount.amount || 0)
    setIsShowModalEditShipmentDiscount(false);
  };
  const handleOpenModalEditShipmentDiscount = () => {
    console.info(masterData.shipmentDiscount)
    setAmount(masterData.shipmentDiscount.amount || 0)
    setIsShowModalEditShipmentDiscount(true);
  };

  const submit = () => {
    handleCloseModalEditShipmentDiscount()
    updateShipmentDiscount({
      amount: amount,
      adminMetadata: {
        adminName: user.name,
        role: user.role
      }
    })
  }

  return (
    <Layout title="Discount" loading={masterData.isLoadingShipmentDiscount}>
      <HeaderPage title="Discount" subtitle="Summary of discount" />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <SimpleCard2
            loading={masterData.isLoadingShipmentDiscount}
            title="Shipment Discount"
            value={convertNumToCurrency(masterData.shipmentDiscount && masterData.shipmentDiscount.amount ? masterData.shipmentDiscount.amount : 0)}
            topRightIconComponent={<Tag style={{ width: 20, height: 20 }} />}
            bottomRightComponent={
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpenModalEditShipmentDiscount()}
              >
                <Typography variant="subtitle1" color="primary">
                  Edit Shipment Discount
                </Typography>
                <ChevronRight color={COLORS.greyDark} size={15} />
              </Box>
            }
          />
        </Grid>
      </Grid>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: 10, paddingTop: 10, paddingBottom: 10 }}
      >
        <Typography>Shipment Discount History</Typography>
      </Box>

      <Paper elevation={3}>
        <ShipmentDiscountHistory />
      </Paper>

      {/** EDIT REFERRAL COMMISION */}
      <Modal
        open={isShowModalEditShipmentDiscount}
        onClose={handleCloseModalEditShipmentDiscount}
      >
        <div className={classes.containerModal}>
          <div className={classes.contentModal}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h5">Shipment Discount</Typography>

              <div onClick={() => handleCloseModalEditShipmentDiscount()}>
                <Close />
              </div>
            </Box>

            <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
              <Grid item xs={12}>
                <FormInput
                  value={amount}
                  onChange={(value: any) => setAmount(value)}
                  type="currency"
                  fullWidth
                  required
                  label="Shipment Discount"
                  placeholder="Shipment Discount"
                />
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="flex-end">
              <Box>
                <AppButton
                  color="secondary"
                  onClick={handleCloseModalEditShipmentDiscount}
                >
                  Cancel
                </AppButton>
              </Box>
              <Box>
                <AppButton
                  disabled={!amount}
                  onClick={() => submit()}
                >Save</AppButton>
              </Box>
            </Box>
          </div>
        </div>
      </Modal>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerModal: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      alihandleCloseModalApprovalgnContent: 'center',
    },
    contentModal: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
      width: 400,
      margin: '0 auto',
      marginTop: '10%',
      borderRadius: 10,
    },
  })
);

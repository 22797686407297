import { useDispatch } from 'react-redux';
import { apiCurrentReferralInformation, apiUpdateReferralInformation } from '../api/master-data/referral-information';
import { createReferralInformation, currentReferralInformation, setLoadingReferralInformation } from '../redux/action/master-data-referral.action';


export default function useReferralInformation() {
  const dispatch = useDispatch()

  const fetchCurrentReferralInformation = async () => {
    try {
      dispatch(setLoadingReferralInformation(true))
      const result = await apiCurrentReferralInformation()

      dispatch(currentReferralInformation(result.data.payload))

      dispatch(setLoadingReferralInformation(false))
    } catch (error) {
      dispatch(setLoadingReferralInformation(false))
    }
  }

  const updateReferralInformation = async (data: any) => {
    try {
      dispatch(setLoadingReferralInformation(true))
      const result = await apiUpdateReferralInformation(data)

      dispatch(createReferralInformation(result.data))

      await fetchCurrentReferralInformation()

      dispatch(setLoadingReferralInformation(false))
    } catch (error) {
      dispatch(setLoadingReferralInformation(false))
    }
  }

  return {
    fetchCurrentReferralInformation,
    updateReferralInformation
  }
}
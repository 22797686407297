import { Backdrop, CircularProgress } from '@material-ui/core'
import React from 'react'

export const LoadingScreen = () => {
  return (
    <Backdrop open={true}>
      <CircularProgress size={50} thickness={4} color="primary" />
    </Backdrop>
    // <div style={{
    //   height: '100vh',
    //   // width: '100%',
    //   // position: 'absolute',
    //   display: 'flex',
    //   alignItems: 'center',
    //   justifyContent: 'center'
    // }}>
    // </div>
  )
}
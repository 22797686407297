import { ImageProduct } from "./product.type";

export enum CollectionTypes {
  FIND_ALL_COLLECTION = "FIND_ALL_COLLECTION",
  FIND_ONE_COLLECTION = "FIND_ONE_COLLECTION",
  UPDATE_COLLECTION = "UPDATE_COLLECTION",
  SET_LOADING_COLLECTION = "SET_LOADING_COLLECTION",
  SET_ERROR_COLLECTION = "SET_ERROR_COLLECTION",
  SET_LAST_PATH_COLLECTION = "SET_LAST_PATH_COLLECTION",
}

export type CollectionResponse = {
  collectionId: string;
  invoiceId: string;
  productId: string;
  userId: string;
  sku: string;
  serialNumber: string;
  extraWarranty?: any;
  remark: string;
  status: string;
  isFromOPA?: boolean | null;
  updatedAt?: any;
  createdAt?: any;
  invoice: {
    invoiceId: string;
  };
  product: {
    productId: string;
    productName: string;
    sku: string;
  };
  customer: {
    customerId: string;
    customerName: string;
    phoneNumber: string;
  };
  images: ImageProduct[];
  invoiceImages: ImageProduct[];
};

export type CollectionRequest = {
  collectionId?: string;
};

export type CollectionResponsePayload = {
  code: string | null;
  message: string | null;
  payload: {
    next: string;
    prev: string;
    count: number;
    results: CollectionResponse[];
  };
};

export type CollectionState = {
  isLoadingCollection: boolean;
  data: CollectionResponsePayload;
  collection: CollectionResponse;
  error: any;
  isSuccess: boolean;
};

import { Reducer } from 'redux';
import {
  ReportReferralState,
  ReportReferralTypes,
} from '../../types/report-referral.type';

const initialState: ReportReferralState = {
  loading: false,
  datas: {
    code: '',
    message: '',
    payload: {
      next: '',
      prev: '',
      count: 0,
      results: [],
    },
  },
};

export const reportReferral: Reducer<ReportReferralState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ReportReferralTypes.FIND_ALL_REPORT_REFERRAL:
      return {
        ...state,
        datas: action.payload,
      };
    case ReportReferralTypes.LOADING_REPORT_REFERRAL:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

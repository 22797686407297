
export enum RecipeCategoryTypes {
  FIND_ALL_RECIPE_CATEGORY = 'FIND_ALL_RECIPE_CATEGORY',
  FIND_ONE_RECIPE_CATEGORY = 'FIND_ONE_RECIPE_CATEGORY',
  CREATE_RECIPE_CATEGORY = 'CREATE_RECIPE_CATEGORY',
  UPDATE_RECIPE_CATEGORY = 'UPDATE_RECIPE_CATEGORY',
  REMOVE_RECIPE_CATEGORY = 'REMOVE_RECIPE_CATEGORY',
  SET_LOADING_RECIPE_CATEGORY = 'SET_LOADING_RECIPE_CATEGORY',
  SET_ERROR_RECIPE_CATEGORY = 'SET_ERROR_RECIPE_CATEGORY',
  SET_LAST_PATH_RECIPE_CATEGORY = 'SET_LAST_PATH_RECIPE_CATEGORY',
}

export type RecipeCategoryResponse = {
  recipeCategoryName: string;
  recipeCategoryId: string;
  updatedAt?: Date | string;
  createdAt?: Date | string;
  imageUrl?: string
}

export type RecipeCategoryRequest = {
  recipeCategoryId?: string;
  recipeCategoryName: string;
  image: File;
}

export type RecipeCategoryResponsePayload = {
  code: string | null;
  message: string | null;
  payload: {
    next: string;
    prev: string;
    count: number;
    results: RecipeCategoryResponse[]
  }
}

export type RecipeCategoryState = {
  isLoadingRecipeCategory: boolean;
  data: RecipeCategoryResponsePayload;
  recipeCategory: RecipeCategoryResponse;
  error: any;
  isSuccess: boolean;
}
import { useDispatch } from 'react-redux'
import { apiCreateCustomerByServiceCenter, apiFindAllCustomer, apiFindAllCustomerByServiceCenter, apiFindOneCustomer, apiUpdateCustomer } from '../api/customer'
import { setLoadingCustomer, findAllCustomer, findOneCustomer, findAllCustomerByServiceCenter, createCustomerByServiceCenter } from '../redux/action/customer.action'
import { CustomerResponse } from '../types/customer.type'


export default function useCustomer() {
  const dispatch = useDispatch()

  const fetchAllCustomer = async (query: string) => {
    try {
      dispatch(setLoadingCustomer(true))

      const result = await apiFindAllCustomer(query)

      dispatch(findAllCustomer(result.data))

      dispatch(setLoadingCustomer(false))
    } catch (error) {
      console.info(error)
      dispatch(setLoadingCustomer(false))
      // dispatch(setErrorCustomer(error.response))
    }
  }

  const fetchOneCustomer = async (customerId: string) => {
    try {
      dispatch(setLoadingCustomer(true))

      const result = await apiFindOneCustomer(customerId)

      dispatch(findOneCustomer(result.data.payload))

      dispatch(setLoadingCustomer(false))
    } catch (error) {
      console.info(error)
      dispatch(setLoadingCustomer(false))
      // dispatch(setErrorCustomer(error.response))
    }
  }

  const updateDataCustomer = async (data: CustomerResponse) => {
    try {
      dispatch(setLoadingCustomer(true))
      delete data.bankAccount
      delete data.addresses

      await apiUpdateCustomer(data)

      await fetchOneCustomer((data.customerId as string))

      dispatch(setLoadingCustomer(false))
    } catch (error) {
      console.info(error)
      dispatch(setLoadingCustomer(false))
    }
  }

  const fetchAllCustomerByServiceCenter = async (serviceCenterId: string, query: string) => {
    try {
      dispatch(setLoadingCustomer(true))

      const result = await apiFindAllCustomerByServiceCenter(serviceCenterId, query)

      dispatch(findAllCustomerByServiceCenter(result.data))

      dispatch(setLoadingCustomer(false))
    } catch (error) {
      console.info(error)
      dispatch(setLoadingCustomer(false))
      // dispatch(setErrorCustomer(error.response))
    }
  }

  const createDataCustomerByServiceCenter = async (data: any) => {
    try {
      dispatch(setLoadingCustomer(true))

      const result = await apiCreateCustomerByServiceCenter(data)

      console.log(result)

      dispatch(createCustomerByServiceCenter(result.data.payload.dataValues))

      dispatch(setLoadingCustomer(false))
    } catch (error) {
      console.info(error)
      dispatch(setLoadingCustomer(false))
      // dispatch(setErrorCustomer(error.response))
    }
  }

  return {
    fetchAllCustomer,
    fetchOneCustomer,
    fetchAllCustomerByServiceCenter,
    createDataCustomerByServiceCenter,
    updateDataCustomer
  }
}
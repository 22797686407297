import { Reducer } from 'redux';
import { ENewsStatus, NewsResponse, NewsState, NewsTypes } from '../../../types/news.type'
import { TagResponse } from '../../../types/tag.type';

export const initNews: NewsResponse = {
  newsId: null,
  title: null,
  status: ENewsStatus.PUBLISHED,
  content: null,
  imageLink: null,
  youtubeLink: '',
  newsType: null,
  tags: []
}

const initialState: NewsState = {
  isLoadingNews: false,
  data: {
    code: null,
    message: null,
    payload: {
      next: '',
      prev: '',
      count: 0,
      results: []
    },
  },
  news: initNews,
  deleteResult: {
    results: {
      isSuccess: false,
      newsId: null
    }
  },
  error: {}
}

export const news: Reducer<NewsState> = (state = initialState, action) => {
  switch (action.type) {
    case NewsTypes.FIND_ALL_NEWS:
      return {
        ...state,
        data: action.payload
      }
    case NewsTypes.FIND_ONE_NEWS:
      return {
        ...state,
        news: action.payload
      }
    case NewsTypes.CREATE_NEWS:
      return {
        ...state,
        news: action.payload
      }
    case NewsTypes.UPDATE_NEWS:
      return {
        ...state,
        news: action.payload
      }
    case NewsTypes.DELETE_NEWS:
      return {
        ...state,
      }
    case NewsTypes.SET_LOADING_NEWS:
      return {
        ...state,
        isLoadingNews: action.payload
      }
    case NewsTypes.SET_ERROR_NEWS:
      return {
        ...state,
        error: action.payload
      }

    default:
      return state
  }
}

export default news;
import React from 'react'
import DetailGeneralInformation from '../DetailGeneralInformation'
import { SummaryProductImages } from '../../../types/b2b/order/delivery-order.type';
import { Typography } from '@material-ui/core';
import PODImagePreview from '../PODImagePreview';

interface Props {
  rplId: string
  rploId: string | null
  rplStatus: string
  distributor: string
  rplDate: string | null
  approveDate: string | null
  rplNote: string
  productImages: SummaryProductImages[]
}

const GeneralInformationRpl: React.FC<Props> = (props) => {

  const [showImages, setShowImages] = React.useState<boolean>(false)

  const handleOpenImage = () => {
    setShowImages(true)
  }

  const handleCloseDialog = () => {
    setShowImages(false)
  }

  return (
    <>
      <DetailGeneralInformation
        styleContainer={{ paddingBottom: 0 }}
        data={[
          {
            label: "Rpl. ID",
            value: props.rplId,
          },
          {
            label: "Rpl.O ID",
            value: props.rploId,
          },
          {
            label: "Replacement Status",
            value: props.rplStatus,
          },
          {},
          {
            label: "Distributor",
            value: props.distributor,
          },
          {
            label: "Rpl. Date",
            value: props.rplDate,
          },
          {
            label: 'Approve Date',
            value: props.approveDate,
          },
          {}
        ]}
      />

      <div>
        <Typography color="textSecondary">Replacement Note</Typography>
        <Typography>{props.rplNote}</Typography>
      </div>

      {props.productImages && (
        <>
          <Typography color="textSecondary" style={{ marginBottom: 10, marginTop: 15 }}>Foto Produk</Typography>
          <div style={{
            width: '100%',
            overflowX: 'auto',
            height: 100,
            display: 'flex',
            alignItems: 'center',
            marginBottom: 20
          }}>
            {props.productImages.map((item, idx) => (
              <div
                key={idx}
                style={{
                  cursor: 'pointer',
                  marginRight: 10,
                  width: 100,
                  height: '100%',
                }}
              >
                <img
                  onClick={() => setShowImages(true)}
                  src={item.imageLink}
                  key={idx}
                  style={{
                    width: 100,
                    height: 100,
                    objectFit: 'cover',
                    borderRadius: 10,
                  }}
                />
              </div>
            ))}
          </div>
        </>
      )}

      {props.productImages && (
        <PODImagePreview
          handleCloseDialog={() => handleCloseDialog()}
          title="Foto Produk"
          isShow={showImages}
          podImages={props.productImages}
        />
      )}
    </>
  )
}

export default GeneralInformationRpl
import { Grid, Typography, Paper, createStyles, makeStyles, Theme, Box, Modal, Snackbar } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import AppButton from "../../components/Form/AppButton";
import { useParams, useHistory } from "react-router-dom";
import HeaderPage from "../../components/Layout/HeaderPage";
import COLORS from "../../styles/colors";
import { Home } from "react-feather";
import { Close } from "@material-ui/icons";
import FormInput from "../../components/Form/FormInput";
import { convertNumToCurrency } from "../../helpers/functions/currency";
import CustomizedTable, { ITableHeader } from "../../components/Table/CustomizedTable";
import StatusTransaction from "../../components/StatusTransaction";
import { convertDateTimeToText } from "../../helpers/functions/datetime";
import { Alert } from "@material-ui/lab";
import { LoadingScreen } from "../../components/LoadingScreen";
import { useAuth } from "../../hooks/useAuth";
import useFetchOne from "../../hooks/useFetchOne";
import {
  ESparepartTransactionStatus,
  Sparepart,
  SparepartTransactionItem,
  initialSparepartTransactions,
} from "../../types/fulfillment-sparepart.types";
import {
  apiFindHistorySparepartTransaction,
  apiProcessStatusSparepartTransaction,
} from "../../api/fulfillment-sparepart";
import usePaginateTable, { IPaginateTable } from "../../hooks/usePaginateTable";

let headers: ITableHeader[] = [
  {
    title: "Status Transaksi",
    align: "left",
    sortable: true,
    type: "status-transaction",
    column: "statusAfter",
  },
  {
    title: "Tanggal",
    align: "left",
    column: "updatedAt",
    type: "dd mmm yyyy hh:mm",
  },
  {
    title: "Aktor",
    align: "left",
    column: "actor",
  },
  {
    title: "Role",
    align: "left",
    column: "role",
  },
  {
    title: "Remark",
    align: "left",
    column: "note",
  },
];

const SparepartTransactionDetail: React.FC = () => {
  const history = useHistory();
  let { id }: any = useParams();

  const auth = useAuth();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [isShowModalFormCancelOrder, setIsShowModalFormCancelOrder] = useState(false);
  const [actionType, setActionType] = useState("");
  const [remark, setRemark] = useState("");

  const {
    fetchOneData,
    data: dataFS,
    loading: loadingSO,
  } = useFetchOne<SparepartTransactionItem>({
    url: "sparepart/transactions",
    initialData: initialSparepartTransactions,
  });

  React.useEffect(() => {
    fetchOneData(id);
  }, [id]);

  const {
    onChangePage: historyStatus_onChangePage,
    onChangeRowsPerPage: historyStatus_onChangeRowsPerPage,
    fetchList: historyStatus_fetchList,
    loading: historyStatus_loading,
    params: historyStatus_params,
    data: historyStatus_data,
    page: historyStatus_page,
    totalPage: historyStatus_totalPage,
    totalData: historyStatus_totalData,
    rowsPerPage: historyStatus_rowsPerPage,
  }: IPaginateTable & { data: any[] } = usePaginateTable(
    apiFindHistorySparepartTransaction,
    {
      limit: 25,
      offset: 0,
    },
    25,
    { transactionId: id },
    false
  );

  const handleCloseModalFormCancelOrder = () => {
    setIsShowModalFormCancelOrder(false);
  };
  const handleOpenModalFormCancelOrder = () => {
    setIsShowModalFormCancelOrder(true);
  };

  const handleProcessTransaction = async (status: ESparepartTransactionStatus, remark?: string) => {
    setIsLoading(true);
    if (status === ESparepartTransactionStatus.PROCESS) {
      setActionType("process");
    }
    if (status === ESparepartTransactionStatus.CANCELED) {
      setActionType("reject");
    }

    try {
      await apiProcessStatusSparepartTransaction(dataFS.transactionId, {
        newStatus: status,
        remark,
      });
      setIsLoading(false);
      await fetchOneData(id);
      historyStatus_fetchList({
        limit: 25,
        offset: 0,
      });
      setActionType("");
    } catch (error) {
      setActionType("");
      setIsLoading(false);
      console.log("error", error);
    }
  };

  const listHistoryStatus =
    historyStatus_data?.map((item) => ({
      ...item,
      role: item.updatedBy
        ? "Admin"
        : item.updatedBy === "SYSTEM"
        ? item.updatedBy
        : item.statusAfter === "created"
        ? dataFS &&
          dataFS?.transactionMetadata &&
          dataFS?.transactionMetadata?.buyer &&
          dataFS?.transactionMetadata?.buyer?.name
          ? "Customer"
          : null
        : null,
      actor:
        item.updatedByAdmin && item.updatedByAdmin.name
          ? item.updatedByAdmin.name
          : item.updatedByCustomer && item.updatedByCustomer.customerName
          ? item.updatedByCustomer.customerName
          : item.updatedBy
          ? item.updatedBy
          : item.statusAfter === "created"
          ? dataFS &&
            dataFS?.transactionMetadata &&
            dataFS?.transactionMetadata?.buyer &&
            dataFS?.transactionMetadata?.buyer?.name
            ? dataFS?.transactionMetadata?.buyer?.name
            : null
          : null,
      note: item?.remark,
    })) || {};
  // end history

  let subtitle = "Detailed information about sparepart transaction";

  if (!loadingSO) {
    return (
      <Layout loading={isLoading} title={"Detail Transaction"}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <HeaderPage title={"Detail Transaction"} subtitle={subtitle} showBackButton />
        </Box>

        <Paper elevation={3} className={classes.paper}>
          <Typography variant="body1">
            No.Transaksi:{" "}
            <Typography component="span" variant="body1" style={{ fontWeight: "bold" }}>
              {dataFS?.invoiceId || dataFS?.transactionId}
            </Typography>
          </Typography>
        </Paper>

        <Paper elevation={3} className={classes.paper}>
          <Grid container spacing={3} className={classes.containerForm}>
            <Grid item xs={3} className={classes.input}>
              <Typography variant="subtitle1" color="textSecondary" style={{ marginBottom: 5 }}>
                Informasi Pembelian
              </Typography>
              <Typography variant="body1">
                {dataFS?.transactionMetadata && dataFS?.transactionMetadata?.buyer && dataFS?.transactionMetadata?.buyer
                  ? dataFS?.transactionMetadata?.buyer?.name
                  : "---"}
              </Typography>
              <Typography variant="body1">
                Tgl. Pembelian:{" "}
                <Typography component="span" variant="body1" style={{ fontWeight: "bold" }}>
                  {convertDateTimeToText(dataFS?.createdAt, "dd mmm yyyy")}
                </Typography>
              </Typography>
              <Typography variant="body1">
                SC:{" "}
                <Typography component="span" variant="body1" style={{ fontWeight: "bold" }}>
                  {dataFS.transactionMetadata.buyer?.serviceCenter?.name || dataFS.transactionMetadata.buyer.name}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={2} className={classes.input}>
              <Typography variant="subtitle1" color="textSecondary" style={{ marginBottom: 5 }}>
                Status Pesanan
              </Typography>

              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                // alignItems="center"
                // alignContent="center"
              >
                <StatusTransaction status={dataFS?.status} isFulfillmentSparepart />
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.input}>
              <Typography variant="subtitle1" color="textSecondary" style={{ marginBottom: 5 }}>
                Tanggal Dibayar
              </Typography>
              <Typography variant="body1">{convertDateTimeToText(dataFS?.updatedAt, "dd mmm yyyy")}</Typography>
            </Grid>
            <Grid item xs={5} className={classes.input}>
              {auth.user.role !== "packaging" && (
                <Typography variant="subtitle1" color="textSecondary" style={{ marginBottom: 5 }}>
                  Actions
                </Typography>
              )}
              {auth.user.role !== "packaging" && dataFS?.status === ESparepartTransactionStatus.CREATED && (
                <Box display="flex" flexDirection="row">
                  <AppButton
                    size="small"
                    color="primary"
                    onClick={() => handleProcessTransaction(ESparepartTransactionStatus.PROCESS)}
                  >
                    Proses Pesanan
                  </AppButton>
                  <AppButton size="small" color="secondary" onClick={() => handleOpenModalFormCancelOrder()}>
                    Batalkan Pesanan
                  </AppButton>
                </Box>
              )}
              {auth.user.role !== "packaging" && dataFS?.status === ESparepartTransactionStatus.PROCESS && (
                <Box display="flex" flexDirection="row">
                  <AppButton
                    size="small"
                    color="primary"
                    onClick={() => handleProcessTransaction(ESparepartTransactionStatus.SENT)}
                  >
                    Dikirim
                  </AppButton>
                </Box>
              )}
            </Grid>
          </Grid>
        </Paper>

        <Paper elevation={3} className={classes.paper}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box display="flex" width={"50%"}>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Daftar Belanja ({dataFS?.items?.length || 0})
              </Typography>
            </Box>

            <Box display="flex" width={"50%"} paddingLeft={2} flexDirection="row" justifyContent="space-between">
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Total Transaksi
              </Typography>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                {convertNumToCurrency(dataFS.transactionPrice)}
              </Typography>
            </Box>
          </Box>
        </Paper>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Paper elevation={3} className={classes.paper} style={{ height: "97%" }}>
              {dataFS?.items &&
                (dataFS.items?.length || 0) > 0 &&
                dataFS?.items?.map((item: Sparepart, index: number) => (
                  <Box display="flex" flexDirection="row" style={{ marginTop: 10 }} key={index}>
                    <Box style={{ width: "100%" }}>
                      <Typography variant="body1">
                        {item.sparepartMetadata.productCode} {item.sparepartMetadata.sparepartName}
                      </Typography>
                      <div>
                        {/* <Typography
                          component="span"
                          variant="subtitle1"
                          style={{
                            paddingLeft: 5,
                            paddingRight: 5,
                            paddingTop: 2,
                            paddingBottom: 2,
                            backgroundColor: COLORS.errorLight,
                            fontWeight: "bold",
                            color: COLORS.error,
                            borderRadius: 5,
                            marginRight: 5,
                          }}
                        >
                          {item.price}
                        </Typography>
                        <Typography
                          component="span"
                          color="textSecondary"
                          variant="subtitle1"
                          style={{
                            textDecoration: "line-through",
                            marginRight: 5,
                          }}
                        >
                          {convertNumToCurrency(item.sparepartMetadata.price)}
                        </Typography> */}
                        <Typography component="span" style={{ fontWeight: "bold" }}>
                          {convertNumToCurrency(item.price)}
                        </Typography>
                      </div>
                      <Typography variant="subtitle1" color="textSecondary">
                        {item.qty} Barang
                      </Typography>
                    </Box>
                  </Box>
                ))}
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Informasi Pengiriman
              </Typography>

              <Box style={{ marginTop: 10 }}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Home size={15} color={COLORS.grey500} />
                  <Typography variant="body1" color="textSecondary" style={{ marginLeft: 5 }}>
                    Lokasi Pengiriman
                  </Typography>
                </Box>

                <Typography style={{ fontWeight: "bold" }}>Alamat Pengiriman</Typography>
                <Typography>
                  {dataFS?.transactionMetadata?.buyer?.serviceCenter?.metaAddress?.address || "Tidak diketahui"},{" "}
                  {dataFS?.transactionMetadata?.buyer?.serviceCenter?.metaAddress.subdistrict || "---"},{" "}
                  {dataFS?.transactionMetadata?.buyer?.serviceCenter?.metaAddress?.city || "---"},{" "}
                  {dataFS?.transactionMetadata?.buyer?.serviceCenter?.metaAddress?.province || "---"}
                </Typography>
              </Box>
            </Paper>

            <Paper elevation={3} className={classes.paper} style={{ marginTop: 10 }}>
              <Typography style={{ fontWeight: "bold" }}>Ringkasan Belanja</Typography>

              <Box style={{ marginTop: 10 }}>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography>Total Harga ({dataFS?.items?.length || 0})</Typography>
                  <Typography>{convertNumToCurrency(dataFS?.transactionPrice)}</Typography>
                </Box>

                <hr />

                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography>Total Transaksi</Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {convertNumToCurrency(dataFS?.transactionPrice)}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Riwayat Status Pesanan
              </Typography>

              <Box>
                <CustomizedTable
                  data={listHistoryStatus}
                  headers={headers}
                  totalData={historyStatus_totalData}
                  page={historyStatus_page}
                  totalPage={historyStatus_totalPage}
                  rowsPerPage={historyStatus_rowsPerPage}
                  onChange={historyStatus_onChangePage}
                  onRowsPerPageChange={historyStatus_onChangeRowsPerPage}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <Modal
          open={isShowModalFormCancelOrder}
          onClose={handleCloseModalFormCancelOrder}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.containerModal}>
            <div className={classes.contentModal}>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Typography variant="h5">Pembatalan Pesanan</Typography>

                <div onClick={() => handleCloseModalFormCancelOrder()}>
                  <Close />
                </div>
              </Box>

              <Box display="flex" flexDirection="row" style={{ paddingTop: 5, paddingBottom: 5 }}>
                <Box width="30%">
                  <Typography>No.Transaksi</Typography>
                </Box>
                <Box width="70%">
                  <Typography style={{ fontWeight: "bold" }}>{dataFS?.invoiceId || "---"}</Typography>
                </Box>
              </Box>

              <hr color={COLORS.greyLight} />

              <Box display="flex" flexDirection="row" style={{ paddingTop: 5, paddingBottom: 5 }}>
                <Box width="30%">
                  <Typography>Pembeli</Typography>
                </Box>
                <Box width="70%">
                  <Typography>
                    {dataFS?.transactionMetadata && dataFS?.transactionMetadata?.buyer
                      ? dataFS?.transactionMetadata?.buyer?.name
                      : "---"}
                  </Typography>
                </Box>
              </Box>

              <hr color={COLORS.greyLight} />

              <Box display="flex" flexDirection="row" style={{ paddingTop: 5, paddingBottom: 5 }}>
                <Box width="30%">
                  <Typography>Alasan Pembatalan</Typography>
                </Box>
                <Box width="70%">
                  <FormInput
                    onChange={(value: any) => setRemark(value)}
                    type="address"
                    fullWidth
                    required
                    placeholder="Alasan Pembatalan"
                  />
                </Box>
              </Box>

              <Box display="flex" justifyContent="flex-end">
                <Box>
                  <AppButton color="secondary" onClick={handleCloseModalFormCancelOrder}>
                    Cancel
                  </AppButton>
                </Box>
                <Box>
                  <AppButton
                    onClick={async () => {
                      await handleProcessTransaction(ESparepartTransactionStatus.CANCELED, remark);
                      setRemark("");
                      handleCloseModalFormCancelOrder();
                    }}
                  >
                    Batalkan
                  </AppButton>
                </Box>
              </Box>
            </div>
          </div>
        </Modal>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={actionType !== ""}
          autoHideDuration={6000}
        >
          <Alert severity="success" variant="filled">
            Berhasil{" "}
            {actionType === "process"
              ? "memproses pesanan"
              : actionType === "reject"
              ? "membatalkan pesanan"
              : actionType === "waybill"
              ? "memasukkan nomor resi"
              : ""}
          </Alert>
        </Snackbar>
      </Layout>
    );
  }
  return <LoadingScreen />;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 0, 0, 0),
      padding: theme.spacing(2),
    },
    containerForm: {},
    input: {
      padding: theme.spacing(0, 2, 1, 2),
    },
    row: {
      padding: theme.spacing(1, 0, 1, 0),
    },
    processCircle: {
      width: 25,
      height: 25,
      backgroundColor: COLORS.accent,
      borderRadius: "50%",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: COLORS.grey,
    },
    cardMedia: {
      borderColor: COLORS.grey,
      borderWidth: 1,
      borderStyle: "solid",
      width: 69,
      height: 69,
      borderRadius: 10,
      backgroundColor: COLORS.accent,
    },
    containerModal: {
      position: "absolute",
      width: "100%",
      height: "100%",
      alihandleCloseModalFormCreategnContent: "center",
    },
    contentModal: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
      width: 600,
      margin: "0 auto",
      marginTop: "10%",
      borderRadius: 10,
    },
  })
);

export default SparepartTransactionDetail;

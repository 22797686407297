import React from 'react'
import { httpRequest } from '../helpers/api/api';
import { generateQueryString } from '../helpers/functions/generateQueryString';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../redux/store';
import { setLatestActiveTab, setLatestState } from '../redux/action/config-app.action';
import debounce from 'lodash.debounce';
import axios, { CancelTokenSource } from 'axios';

export interface DefaultResponse<T = any> {
  code: string
  message: string
  payload: T
}

export interface DefaultResponseResults<T = any> {
  code: string
  message: string
  payload: {
    results: T
  }
}

export interface DefaultResponseList<T = any> {
  code: string
  message: string
  payload: {
    count: number
    prev: string
    next: string
    results: Array<T>
  }
}

export interface IQueryTable<StatusType = any> {
  offset?: number;
  limit: number;
  page: number;
  search?: string;
  sort?: string;
  startAt?: string;
  endAt?: string;
  status?: StatusType;
}

interface IPaginationTable {
  page: number
  perPage: number
  countPage: number
  totalData: number
  next: string
  prev: string
}

const initialPagination: IPaginationTable = {
  page: 1,
  perPage: 25,
  totalData: 0,
  countPage: 0,
  next: '',
  prev: '',
}

interface Props<QueryType = any> {
  url: string
  query: QueryType
  staticUrl?: boolean
  activeTab?: number
}

export default function useFetch<T = any>(props: Props, useLocationState: boolean = true) {
  const history = useHistory()

  const isUpdateUrl = !props.staticUrl ? true : false;
  const { state: locationState, activeTab: currentActiveTab } = useSelector((state: ApplicationState) => state.configApp)
  const dispatch = useDispatch()

  const [query, setQuery] = React.useState<any>(locationState && useLocationState ? locationState : props.query);
  const [search, setSearch] = React.useState<string | undefined>('');
  const [data, setData] = React.useState<Array<T>>([]);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState<IPaginationTable>(initialPagination)
  const [activeTab, setActiveTab] = React.useState<number>(currentActiveTab ? currentActiveTab : Number(props.activeTab))
  const [errorMessage, setErrorMessage] = React.useState<string>()

  const searchData = async (value: any) => {
    if (typeof value === 'string' && value !== '' && value.length > 0) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          limit: 25,
          page: 1,
          offset: 0,
          search: value,
        };
      });
    } else if (value !== undefined && typeof value === 'string') {
      setQuery((query: any) => {
        return {
          ...query,
          limit: 25,
          offset: 0,
          search: '',
        }
      });
    }
  };

  const debouncedChangeHandler = React.useCallback(debounce(value => searchData(value), 800), []);

  const changeHandler = (value: string) => {
    setSearch(value)
    debouncedChangeHandler(value)
  }

  const handlePageChange = (page: number) => {
    setQuery((query: any) => {
      return {
        ...query,
        page,
        offset: (page - 1) * pagination.perPage
      }
    })
  }

  const handlePerPageChange = (limit: number) => {
    setQuery((query: any) => {
      return {
        ...query,
        limit
      }
    })
  }

  const fetchDataTable = React.useCallback(async () => {
    try {
      setLoading(true);
      const newQuery = generateQueryString(query)

      const res = await httpRequest.get<DefaultResponseList<T>>(props.url + newQuery)

      if (res.status === 200) {
        setData(res.data.payload.results);
        setPagination((pagination) => {
          return {
            ...pagination,
            page: props.query.page,
            next: res.data.payload.next,
            prev: res.data.payload.prev,
            totalData: res.data.payload.count,
            countPage: Math.ceil(res.data.payload.count / (props.query.limit || 25)),
          }
        })

        dispatch(setLatestState(null))
      }
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, [query])

  const fetchDataTableAlt = React.useCallback(async (source: CancelTokenSource) => {
    try {
      setLoading(true);
      const newQuery = generateQueryString(props.query)

      const res = await httpRequest.get<DefaultResponseList<T>>(props.url + newQuery, { cancelToken: source.token })

      if (res && res.status === 200) {
        setData(res.data.payload.results);
        setPagination((pagination) => {
          return {
            ...pagination,
            page: props.query.page,
            next: res.data.payload.next,
            prev: res.data.payload.prev,
            totalData: res.data.payload.count,
            countPage: Math.ceil(res.data.payload.count / (props.query.limit || 25)),
          }
        })
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }, [])

  React.useEffect(() => {
    const source = axios.CancelToken.source()

    const fetchList = async () => {
      try {
        setLoading(true);
        const newQuery = generateQueryString(query)
        // isUpdateUrl && history.replace({ pathname: history.location.pathname, search: newQuery });

        const res = await httpRequest.get<DefaultResponseList<T>>(props.url + newQuery, { cancelToken: source.token })

        if (res && res.status === 200) {
          setData(res.data.payload.results);
          setPagination((pagination) => {
            return {
              ...pagination,
              page: locationState ? locationState.page : query.page,
              next: res.data.payload.next,
              prev: res.data.payload.prev,
              totalData: res.data.payload.count,
              countPage: Math.ceil(res.data.payload.count / (query.limit || 25)),
            }
          })
          setLoading(false);
        }

        dispatch(setLatestState(null))
        dispatch(setLatestActiveTab(0))
      } catch (error) {
        setLoading(false);
        setErrorMessage(error.response)
        throw error;
      }
    }

    isUpdateUrl && fetchList()

    return () => {
      source.cancel()
    };
  }, [query, props.url]);

  React.useEffect(() => {
    dispatch(setLatestActiveTab(activeTab))
  }, [activeTab])

  return {
    loading,
    data,
    pagination,
    query,
    search,
    activeTab,
    errorMessage,
    fetchDataTable,
    handlePageChange,
    handlePerPageChange,
    setQuery,
    changeHandler,
    setActiveTab,
    fetchDataTableAlt
  };
};

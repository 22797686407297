import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Copyright from "../../components/Copyright";
import Logo from "../../components/Icon/Logo";
import TextInput from "../../components/Form/TextInput";
import COLORS from "../../styles/colors";
import { useAuth } from '../../hooks/useAuth';
import { Link, useParams, useHistory } from "react-router-dom";

export default function ResetPassword() {
  const classes = useStyles();
  const auth = useAuth();
  const history = useHistory();
  let { sessionId } = useParams<{ sessionId: string }>();

  const [newPassword, setNewPassword] = useState("");
  const [statusSent, setStatusSent] = useState<
    undefined | "SUCCESS" | "FAILED"
  >();

  const submit = () => {
    auth.confirmPasswordReset({ sessionId, newPassword }, () => {
      setStatusSent("SUCCESS");
    })
  };

  const redirectToLogin = () => {
    history.replace({ pathname: '/login' })
  }

  return (
    <div className={classes.body}>
      <Container className={classes.container} component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography> */}

          <Logo />
          <Typography
            variant="subtitle1"
            style={{ color: "white", lineHeight: 2 }}
          >
            Be a chef at your own home
          </Typography>

          {statusSent !== "SUCCESS" && (
            <div className={classes.title}>
              <Typography variant="h5" align="center">
                Reset Password
              </Typography>
            </div>
          )}

          {statusSent === "SUCCESS" ? (
            <div className={classes.emailSentMessage}>
              <Typography variant="h5" align="center">
                Reset Password Success
              </Typography>

              <Typography align="center">
                Your password has been changed, now you can login with your new password
              </Typography>

              <Button
                onClick={() => redirectToLogin()}
                fullWidth
                variant="outlined"
                className={classes.submit}
              >
                Kembali ke Login
              </Button>
            </div>
          ) : (
              <form className={classes.form} noValidate>
                <TextInput
                  type="password"
                  fullWidth
                  placeholder="New Password"
                  autoFocus={true}
                  onChange={(value: string) => setNewPassword(value)}
                  value={newPassword}
                />

                {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
                <Button
                  onClick={() => submit()}
                  fullWidth
                  variant="outlined"
                  className={classes.submit}
                >
                  Renew Password
              </Button>

                <Grid container>
                  <Grid item xs>
                    <Link to="/" className={classes.forgotPassword}>
                      <Typography align="center">Kembali ke halaman login</Typography>
                    </Link>
                  </Grid>
                </Grid>
              </form>
            )}
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  body: {
    height: "100vh",
    width: "100%",
    backgroundImage: `linear-gradient(
            rgba(0, 0, 0, 0.77),
            rgba(0, 0, 0, 0.77)
          ), url(${"/assets/background/bg-original.png"})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    display: "flex",
    alignItems: "center",
  },
  container: {},
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    borderRadius: 40,
    margin: theme.spacing(3, 0, 6),
    padding: theme.spacing(1.5, 0, 1.5),
    backgroundColor: "transparent",
    borderColor: COLORS.accent,
    borderWidth: 2,
    color: COLORS.accent,
  },
  forgotPassword: {
    marginTop: 100,
    color: COLORS.accent,
  },
  emailSentMessage: {
    color: COLORS.accent,
    marginTop: 40,
    marginBottom: 40,
  },
  title: {
    color: COLORS.accent,
    marginTop: theme.spacing(4),
  },
}));

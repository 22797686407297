export enum ReferralDiscountTypes {
  FIND_LOG_REFERRAL_DISCOUNT = 'FIND_LOG_REFERRAL_DISCOUNT',
  CURRENT_REFERRAL_DISCOUNT = 'CURRENT_REFERRAL_DISCOUNT',
  FIND_REFERRAL_DISCOUNT = 'FIND_REFERRAL_DISCOUNT',
  CREATE_REFERRAL_DISCOUNT = 'CREATE_REFERRAL_DISCOUNT',
  SET_LOADING_REFERRAL_DISCOUNT = 'SET_LOADING_REFERRAL_DISCOUNT',
  SET_ERROR_REFERRAL_DISCOUNT = 'SET_ERROR_REFERRAL_DISCOUNT',
}

export enum ERetailDiscount {
  REFERRAL = 'referral',
  NON_REFERRAL = 'non-referral'
}

export type ReferralDiscountResponse = {
  retailDiscountId: string;
  discountNote: string;
  discountFormula: string;
  discountType: ERetailDiscount;
  adminMetadata: any;
  createdAt?: Date | String;
  updatedAt?: Date | String;
}

export type ReferralDiscountResponsePayload = {
  code: string;
  message: string;
  payload: {
    next: string;
    prev: string;
    count: number;
    results: ReferralDiscountResponse[]
  }
}

export type ReferralDiscountRequest = {
  discountNote: string;
  discountType: ERetailDiscount;
}

export type ReferralDiscountState = {
  isLoadingReferralDiscount: boolean;
  logReferralDiscount: ReferralDiscountResponsePayload;
  current: {
    referral: Omit<ReferralDiscountResponse, 'discountFormula' | 'adminMetadata' | 'createdAt' | 'updatedAt'>,
    nonReferral: Omit<ReferralDiscountResponse, 'discountFormula' | 'adminMetadata' | 'createdAt' | 'updatedAt'>
  };
  data: ReferralDiscountResponse;
  error: any;
  isSuccess: boolean;
} 
import { DeliveryOrderProperties, ETripStatus } from './order/delivery-order.type';
import { ReplacementDeliveryProperties } from './replacement/replacement-delivery.type';
import { ReplacementPickupProperties } from './replacement/replacement-pickup.type';
import { PickupOrderProperties } from './return/pickup-order.type';

export enum ETripGroupStatus {
  created = 'created',
  loading = 'loading',
  loading_complete = 'loading_complete',
  completed = 'completed',
}

export interface TripGroupProperties {
  tripGroupId: string
  status: ETripGroupStatus
  driverId: string
  createdAt: Date
  updatedAt: Date
  tripItems?: TripProperties[]
}


export interface TripHistories {
  status: ETripStatus,
  date: string,
  note: string | null
}

export interface TripProperties {
  tripId: string
  tripGroupId: string
  status: ETripStatus
  warehouseMetadata: any
  note: string
  receiverName: string
  signature: any
  distributorMetadata: any
  branchMetadata: any
  driverId: string
  driverMetadata: any
  agentMetadata: any
  doIds: string[]
  poIds: string[]
  rplpIds: string[]
  rpldIds: string[]
  adminMetadata: any
  tripHistories: TripHistories[]
  createdAt?: Date
  updatedAt?: Date
  deliveryOrders?: DeliveryOrderProperties[]
  pickupOrders?: PickupOrderProperties[]
  replacementPickups?: ReplacementPickupProperties[]
  replacementDeliveries?: ReplacementDeliveryProperties[]
  tripGroup?: TripGroupProperties
}

export interface PickupProperties {
  pickupId: string
  status: ETripStatus
  distributorMetadata: any  
  branchMetadata: any  
  agentMetadata: any
  doIds: string[]
  poIds: string[]
  rplpIds: string[]
  rpldIds: string[]
  adminMetadata: any
  createdAt: Date
  updatedAt: Date
  deliveryOrders?: DeliveryOrderProperties[]
  pickupOrders?: PickupOrderProperties[]
  replacementPickups?: ReplacementPickupProperties[]
  replacementDeliveries?: ReplacementDeliveryProperties[]
}

export interface TripReturnAllItems {
  deliveryOrders: DeliveryOrderProperties[],
  pickupOrders: PickupOrderProperties[],
  replacementPickups: ReplacementPickupProperties[],
  replacementDeliveries: ReplacementDeliveryProperties[],
}

export interface DeliveryOrderPropertiesCheck extends DeliveryOrderProperties {
  id: string
  qtyProduct: number
  createdAtConvert: string
}

export interface PickupOrderPropertiesCheck extends PickupOrderProperties {
  id: string
  qtyProduct: number
  createdAtConvert: string
}

export interface ReplacementPickupPropertiesCheck extends ReplacementPickupProperties {
  id: string
  qtyProduct: number
  createdAtConvert: string
}

export interface ReplacementDeliveryPropertiesCheck extends ReplacementDeliveryProperties {
  id: string
  qtyProduct: number
  createdAtConvert: string
}

export interface TripAllItemsCheck {
  deliveryOrders: DeliveryOrderPropertiesCheck[],
  pickupOrders: PickupOrderPropertiesCheck[],
  replacementPickups: ReplacementPickupPropertiesCheck[],
  replacementDeliveries: ReplacementDeliveryPropertiesCheck[],
}

export interface TripCreateRequest {
  distributorMetadata: any
  warehouseMetadata: any
  branchMetadata: any
  agentMetadata: any
  doIds: string[]
  poIds: string[]
  rplpIds: string[]
  rpldIds: string[]
  driverId: string
}

export const initialTripGroup:  TripGroupProperties = {
  tripGroupId: '',
  status: ETripGroupStatus.created,
  driverId: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  tripItems: []
}

export const initialTrip: TripProperties = {
  tripId: '',
  tripGroupId: '',
  status: ETripStatus.created,
  warehouseMetadata: {},
  note: '',
  receiverName: '',
  signature: '',
  distributorMetadata: {},
  branchMetadata: {},
  driverId: '',
  driverMetadata: {},
  agentMetadata: {},
  doIds: [],
  poIds: [],
  rplpIds: [],
  rpldIds: [],
  adminMetadata: {},
  tripHistories: [],
  createdAt: new Date(),
  updatedAt: new Date(),
  deliveryOrders: [],
  pickupOrders: [],
  replacementPickups: [],
  replacementDeliveries: [],
  tripGroup: initialTripGroup
}

export interface StepItem {
  status: ETripStatus,
  statusText: string,
  date: string | null
  note: string | null
}

export const initialDefaultStep: StepItem[] = [
  {
    status: ETripStatus.created,
    statusText: 'Created',
    date: null,
    note: null,
  },
  {
    status: ETripStatus.loading,
    statusText: 'Loading',
    date: null,
    note: null,
  },
  {
    status: ETripStatus.loading_complete,
    statusText: 'Loading Selesai',
    date: null,
    note: null,
  },
  {
    status: ETripStatus.otw,
    statusText: 'OTW',
    date: null,
    note: null,
  },
  {
    status: ETripStatus.arrived,
    statusText: 'Tiba di Penerima',
    date: null,
    note: null,
  },
  {
    status: ETripStatus.confirmed,
    statusText: 'Confirmed',
    date: null,
    note: null,
  },
];

export interface PickupProperties {
  pickupId: string
  status: ETripStatus
  distributorMetadata: any  
  branchMetadata: any  
  agentMetadata: any
  doIds: string[]
  poIds: string[]
  rplpIds: string[]
  rpldIds: string[]
  adminMetadata: any
  createdAt: Date
  updatedAt: Date
  deliveryOrders?: DeliveryOrderProperties[]
  pickupOrders?: PickupOrderProperties[]
  replacementPickups?: ReplacementPickupProperties[]
  replacementDeliveries?: ReplacementDeliveryProperties[]
}
import { DistributorProperties, initialDistributor } from '../distributor/distributor.type';

export interface BranchProperties {
  branchId: string
  distributorId: string
  disabled: boolean
  name: string
  address: string
  phoneNumber: string
  coordinate: string
  createdAt?: string | Date
  updatedAt?: string | Date
  distributor?: DistributorProperties
}

export const initialBranch = {
  branchId: '',
  distributorId: '',
  disabled: false,
  name: '',
  address: '',
  phoneNumber: '',
  coordinate: '',
  createdAt: '',
  updatedAt: '',
  distributor: initialDistributor
}
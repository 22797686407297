/* eslint-disable react-hooks/exhaustive-deps */
import {
  Paper,
  createStyles,
  makeStyles,
  Theme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import FormInput from '../../components/Form/FormInput';
import AppButton from '../../components/Form/AppButton';
import COLORS from '../../styles/colors';
import { useLocation, useParams } from 'react-router-dom';
import UploadImage from '../../components/Form/UploadImage';
import HeaderPage from '../../components/Layout/HeaderPage';
import { CategoryRequest, CategoryResponse } from '../../types/category.type';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../redux/store';
import useCategory from '../../hooks/useCategory';
import { useHistory } from 'react-router-dom';
import { findOneCategory } from '../../redux/action/category.action';
import { initCategory } from '../../redux/reducer/category.reducer';
import { enableUpdateParams } from '../../redux/action/config-app.action';
import { useAuth } from '../../hooks/useAuth';
import useServiceCenterTransaction from '../../hooks/useServiceCenterTransaction';
import {
  ServiceCenterTransactionType,
  StatusTransaction,
} from '../../types/service-center.types';
import { convertDateTimeToText } from '../../helpers/functions/datetime';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import moment from 'moment';

export default function ServiceCenterTransactionDetail() {
  const location = useLocation();

  let { id } = useParams<{ id: string }>();

  const classes = useStyles();

  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);

  const serviceCenterTransaction = useSelector(
    (state: ApplicationState) => state.serviceCenterTransaction
  );

  const [data, setData] = useState<ServiceCenterTransactionType>();

  const { fetchOneServiceCenterTransaction } = useServiceCenterTransaction();

  const [listPreviewImg, setListPreviewImg] = useState<any[]>([]);
  const [previewImg, setPreviewImg] = useState<number>(0);

  const statusWarranty = (dateData: Date) => {
    const todayAt = new Date();
    const endAt = new Date(dateData);

    endAt.setDate(endAt.getDate() + 1);

    if (todayAt <= endAt) {
      return 'AKTIF';
    } else {
      return 'TIDAK AKTIF';
    }
  };

  const handlePreviewImage = (value: number) => {
    setPreviewImg(value);
    setIsShowDialog(true);
  };

  const handleCloseDialog = () => {
    setIsShowDialog(false);
  };

  useEffect(() => {
    console.log('here 1');
    fetchOneServiceCenterTransaction(id);

    const resultListPreviewImg =
      serviceCenterTransaction?.serviceCenterTransaction?.images?.map(
        (item: any) => {
          return item.imageUrl;
        }
      );

    if (resultListPreviewImg) {
      setListPreviewImg(resultListPreviewImg);
    } else {
      setListPreviewImg([]);
    }
  }, [id]);

  useEffect(() => {
    const result = { ...serviceCenterTransaction.serviceCenterTransaction };

    const initialWarrantyDate = result?.metaProduct?.product?.initialWarranty;
    const initialCreatedAt = result?.metaProduct?.createdAt;

    const initialCreatedAtMoment = moment(
      initialCreatedAt,
      'YYYY-MM-DDTHH:mm:ss.SSSZ'
    );

    const resultInitialWarrant = initialCreatedAtMoment
      .clone()
      .add(initialWarrantyDate, 'months');

    setData({
      ...result,
      metaProduct: {
        ...result.metaProduct,
        initialWarranty: initialWarrantyDate
          ? resultInitialWarrant.format('YYYY-MM-DD')
          : initialWarrantyDate,
      },
    });
  }, [serviceCenterTransaction]);

  let title = 'Detail Service Center Transaction';
  let subtitle = 'Detailed information about service center transaction';

  return (
    <Layout title={title}>
      <Grid container justify='space-between' spacing={3} alignItems='center'>
        <Grid item xs={12} md={6} lg={10}>
          <HeaderPage title={title} subtitle={subtitle} showBackButton />
        </Grid>
      </Grid>

      <Paper elevation={3} className={classes.paper}>
        <div className={classes.row}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Typography
                variant='body1'
                style={{ color: COLORS.grey, marginBottom: 5 }}
              >
                Service Center
              </Typography>
              <Typography variant='h5' style={{ fontWeight: 'normal' }}>
                {data?.serviceCenterName}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                wordWrap: 'break-word',
              }}
            >
              <Typography
                variant='body1'
                style={{ color: COLORS.grey, marginBottom: 5 }}
              >
                Nama Customer
              </Typography>
              <Typography variant='h5' style={{ fontWeight: 'normal' }}>
                {data?.metaUser?.customerName}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant='body1'
                style={{ color: COLORS.grey, marginBottom: 5 }}
              >
                Tanggal Service
              </Typography>
              <Typography variant='h5' style={{ fontWeight: 'normal' }}>
                {convertDateTimeToText(data?.serviceStartAt, 'dd mmm yyyy')} -{' '}
                {data?.serviceFinishAt !== null ? (
                  <>
                    {convertDateTimeToText(
                      data?.serviceFinishAt,
                      'dd mmm yyyy'
                    )}
                  </>
                ) : (
                  'Belum Selesai'
                )}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className={classes.row}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Typography
                variant='body1'
                style={{ color: COLORS.grey, marginBottom: 5 }}
              >
                Produk
              </Typography>
              <Typography variant='h5' style={{ fontWeight: 'normal' }}>
                {data?.metaProduct?.sku}{' '}
                {data?.metaProduct?.product?.productName}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant='body1'
                style={{ color: COLORS.grey, marginBottom: 5 }}
              >
                Status
              </Typography>
              <Typography variant='h5' style={{ fontWeight: 'normal' }}>
                {data?.status === StatusTransaction.FINISHED
                  ? 'Selesai'
                  : 'Terima'}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant='body1'
                style={{ color: COLORS.grey, marginBottom: 5 }}
              >
                Warranty
              </Typography>
              <Typography variant='h5' style={{ fontWeight: 'normal' }}>
                {data?.metaProduct?.extraWarranty ||
                data?.metaProduct?.product?.initialWarranty !== null
                  ? statusWarranty(
                      data?.metaProduct?.extraWarranty ||
                        data?.metaProduct?.product?.initialWarranty
                    )
                    ? 'AKTIF'
                    : 'TIDAK AKTIF'
                  : 'TIDAK AKTIF'}
                {' - '}
                {data?.metaProduct?.extraWarranty
                  ? `${convertDateTimeToText(
                      data?.metaProduct?.extraWarranty,
                      'dd mmm yyyy'
                    )}`
                  : data?.metaProduct?.product?.initialWarranty !== null
                  ? `${convertDateTimeToText(
                      data?.metaProduct?.product?.initialWarranty,
                      'dd mmm yyyy'
                    )}`
                  : 'Tidak Ada Garansi'}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className={classes.row}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Typography
                variant='body1'
                style={{ color: COLORS.grey, marginBottom: 5 }}
              >
                Problem
              </Typography>
              <Typography variant='h5' style={{ fontWeight: 'normal' }}>
                {data?.problem}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant='body1'
                style={{ color: COLORS.grey, marginBottom: 5 }}
              >
                Catatan
              </Typography>
              <Typography variant='h5' style={{ fontWeight: 'normal' }}>
                {data?.note}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant='body1'
                style={{ color: COLORS.grey, marginBottom: 5 }}
              >
                Solusi
              </Typography>
              <Typography variant='h5' style={{ fontWeight: 'normal' }}>
                {data?.solution}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className={classes.row}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                variant='body1'
                style={{ color: COLORS.grey, marginBottom: 5 }}
              >
                Foto Permasalahan Produk
              </Typography>
              <div className={classes.wrapperImg}>
                {data?.images && data?.images?.length > 0 ? (
                  data?.images.map((item: any, index: any) => (
                    <img
                      key={index}
                      src={item.imageUrl}
                      width={120}
                      height={120}
                      alt='Foto Permasalahan Produk'
                      title='Foto Permasalahan Produk'
                      style={{
                        borderRadius: 8,
                        marginLeft: 10,
                        cursor: 'pointer',
                      }}
                      onClick={() => handlePreviewImage(index)}
                    />
                  ))
                ) : (
                  <Box
                    style={{
                      backgroundColor: 'grey',
                      color: '#fff',
                      width: '15%',
                      textAlign: 'center',
                      paddingRight: 10,
                      paddingLeft: 10,
                    }}
                  >
                    <Typography variant='h5' style={{ fontWeight: 'normal' }}>
                      TIDAK ADA FOTO
                    </Typography>
                  </Box>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.row}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Typography
                variant='body1'
                style={{ color: COLORS.grey, marginBottom: 5 }}
              >
                Created At
              </Typography>
              <Typography variant='h5' style={{ fontWeight: 'normal' }}>
                {convertDateTimeToText(data?.createdAt, 'dd mmm yyyy')}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant='body1'
                style={{ color: COLORS.grey, marginBottom: 5 }}
              >
                Updated at
              </Typography>
              <Typography variant='h5' style={{ fontWeight: 'normal' }}>
                {convertDateTimeToText(data?.updatedAt, 'dd mmm yyyy')}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Paper>

      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={isShowDialog}
        onClose={handleCloseDialog}
        scroll={'body'}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DialogTitle id='scroll-dialog-title'>
          Preview Foto Permasalahan Produk
        </DialogTitle>
        <DialogContent>
          <IconButton
            size='small'
            aria-label='left'
            disabled={previewImg === 0 ? true : false}
            onClick={() => setPreviewImg(previewImg - 1)}
            style={{ position: 'absolute', top: '42%', left: 10 }}
          >
            <ChevronLeft className={classes.icon} />
          </IconButton>
          <Box display='flex' justifyContent='center' alignItems='center'>
            <img
              src={listPreviewImg[previewImg]}
              alt='Foto Permasalahan Produk'
              title='Foto Permasalahan Produk'
              style={{
                borderRadius: 8,
                textAlign: 'center',
                margin: '0 auto',
                cursor: 'pointer',
              }}
              height={400}
              width={400}
            />
          </Box>
          <IconButton
            size='small'
            aria-label='right'
            disabled={previewImg === listPreviewImg.length - 1 ? true : false}
            onClick={() => setPreviewImg(previewImg + 1)}
            style={{ position: 'absolute', top: '42%', right: 10 }}
          >
            <ChevronRight className={classes.icon} />
          </IconButton>
        </DialogContent>
        <DialogActions>
          <AppButton color='secondary' onClick={handleCloseDialog}>
            Close
          </AppButton>
        </DialogActions>
      </Dialog>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 0, 0, 0),
      padding: theme.spacing(2),
    },
    containerForm: {
      padding: 20,
    },
    icon: { height: 60, width: 60 },
    input: {
      padding: theme.spacing(0, 0, 1, 0),
    },
    paperTitle: {
      padding: theme.spacing(2),
    },
    containerButton: {
      flexDirection: 'row',
      padding: theme.spacing(2),
    },
    formStatus: {
      width: '100%',
      margin: theme.spacing(0, 0, 2, 0),
    },
    row: {
      marginBottom: 15,
    },
    cardRoot: {
      width: '100%',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      backgroundColor: COLORS.greyLighten,
      marginTop: 10,
    },
    cardMedia: {
      height: 200,
      width: 200,
      alignSelf: 'center',
      backgroundColor: COLORS.accent,
      borderColor: COLORS.accent,
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: 10,
    },
    cardActionArea: {
      display: 'flex',
      padding: 10,
    },
    wrapperImg: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  })
);

import { withStyles, Theme, createStyles, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import COLORS from '../../styles/colors';

const AntTabs = withStyles({
  root: {
    backgroundColor: COLORS.grey200,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  indicator: {
    height: 3,
    backgroundColor: COLORS.primary,
  },
})(Tabs);

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 50,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(1),
      '&:hover': {
        color: COLORS.primary,
        opacity: 1,
      },
      '&$selected': {
        color: COLORS.primary,
        backgroundColor: COLORS.accent,
      },
      '&:focus': {
        color: COLORS.primary,
      },
    },
    selected: {},
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

const AntTabFirst = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 50,
      borderTopLeftRadius: 10,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(1),
      '&:hover': {
        color: COLORS.primary,
        opacity: 1,
      },
      '&$selected': {
        color: COLORS.primary,
        backgroundColor: COLORS.accent,
      },
      '&:focus': {
        color: COLORS.primary,
      },
    },
    selected: {},
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

interface StyledTabProps {
  label: string;
}

type Props = {
  active: number;
  tabs: string[];
  onChange: (index: number) => void;
};

export default function AppTabs(props: Props) {
  const onChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    props.onChange(newValue);
  };

  return (
    <AntTabs value={props.active} onChange={onChange} aria-label="app-tabs">
      {props.tabs.map((item: string, key: number) =>
        key === 0 ? <AntTabFirst key={key} label={item} /> : <AntTab key={key} label={item} />
      )}
    </AntTabs>
  );
}

import axios from 'axios';
import { getHeaders } from '../helpers/auth';
import '../helpers/interceptor';

export async function apiCreateAro(data?: any) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/aros',
    method: 'POST',
    headers,
    data
  });
}

export async function apiGetAroFullfillment(query: string){
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/aros/fullfillment'+query,
    method: 'GET',
    headers
  });
}
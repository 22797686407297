import axios from 'axios';
import { getHeaders, getHeadersMultiPart } from '../../helpers/auth';
import { NewsRequest, NewsRequestImageOnly } from '../../types/news.type';

export async function apiFindAllPromotion(params: string) {
  const query = params || null
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/promotion' + query,
    method: 'GET',
    headers
  });
}

export async function apiFindOnePromotion(promotionId: string) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/promotion/' + promotionId,
    method: 'GET',
    headers
  });
}

export async function apiCreatePromotion(data: NewsRequest) {
  const headers = await getHeadersMultiPart()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/promotion',
    method: 'POST',
    headers,
    data
  });
}

export async function apiUpdatePromotion(data: any) {
  const headers = await getHeadersMultiPart()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/promotion/' + data.get('newsId'),
    method: 'PATCH',
    headers,
    data
  });
}

export async function apiUpdateImagePromotion(data: NewsRequestImageOnly) {
  const headers = await getHeadersMultiPart()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/promotion/upload-image/' + data.newsId,
    method: 'PUT',
    headers,
    data
  });
}

export async function apiDeletePromotion(promotionId: string) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/promotion?newsId=' + promotionId,
    method: 'DELETE',
    headers,
  });
}
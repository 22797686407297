import {
  RecipeTagResponse,
  RecipeTagState,
  RecipeTagTypes,
} from "../../types/recipe-tag.type";
import { Reducer } from "redux";

export const initialRecipeTag = {
  recipeTagId: "",
  recipeTagName: "",
  imageUrl: "",
};

const initialState: RecipeTagState = {
  isLoadingRecipeTag: false,
  data: {
    code: null,
    message: null,
    payload: {
      next: "",
      prev: "",
      count: 0,
      results: [],
    },
  },
  error: {},
  recipeTag: { ...initialRecipeTag },
  isSuccess: false,
};

export const recipeTag: Reducer<RecipeTagState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case RecipeTagTypes.FIND_ALL_RECIPE_TAG:
      const results = action.payload.payload.results.map(
        (item: RecipeTagResponse) => {
          return {
            ...item,

          };
        }
      );

      return {
        ...state,
        data: {
          ...action.payload,
          payload: {
            ...action.payload.payload,
            results,
          },
        },
      };
    case RecipeTagTypes.FIND_ONE_RECIPE_TAG:
      return {
        ...state,
        recipeTag: action.payload,
      };
    case RecipeTagTypes.CREATE_RECIPE_TAG:
      return {
        ...state,
        recipeTag: action.payload,
        isSuccess: true,
      };
    case RecipeTagTypes.UPDATE_RECIPE_TAG:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case RecipeTagTypes.REMOVE_RECIPE_TAG:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case RecipeTagTypes.SET_LOADING_RECIPE_TAG:
      return {
        ...state,
        isLoadingRecipeTag: action.payload,
      };
    case RecipeTagTypes.SET_ERROR_RECIPE_TAG:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

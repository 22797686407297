import { ReferralDiscountResponsePayload, ReferralDiscountTypes, ReferralDiscountResponse } from '../../types/referral-discount.type'


export function findLogReferralDiscount(payload: ReferralDiscountResponsePayload) {
  return {
    type: ReferralDiscountTypes.FIND_LOG_REFERRAL_DISCOUNT,
    payload
  }
}

export function findOneReferralDiscount(payload: ReferralDiscountResponse) {
  return {
    type: ReferralDiscountTypes.CURRENT_REFERRAL_DISCOUNT,
    payload
  }
}

export function createReferralDiscount(payload: ReferralDiscountResponse) {
  return {
    type: ReferralDiscountTypes.CREATE_REFERRAL_DISCOUNT,
    payload
  }
}

export function currentReferralDiscount(payload: {
  referral: ReferralDiscountResponse,
  nonReferral: ReferralDiscountResponse,
}) {
  return {
    type: ReferralDiscountTypes.CURRENT_REFERRAL_DISCOUNT,
    payload
  }
}

export function setLoadingReferralDiscount(payload: boolean) {
  return {
    type: ReferralDiscountTypes.SET_LOADING_REFERRAL_DISCOUNT,
    payload
  }
}

export function setErrorReferralDiscount(payload: any) {
  return {
    type: ReferralDiscountTypes.SET_ERROR_REFERRAL_DISCOUNT,
    payload
  }
}
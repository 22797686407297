import { CategoryResponse } from "./category.type";

export enum ProductTypes {
  FIND_ALL_PRODUCT = "FIND_ALL_PRODUCT",
  FIND_HISTORY_PRODUCT_PRICES = "FIND_HISTORY_PRODUCT_PRICES",
  FIND_ONE_PRODUCT = "FIND_ONE_PRODUCT",
  ADD_PRODUCT_IMAGE = "ADD_PRODUCT_IMAGE",
  REMOVE_PRODUCT_IMAGE = "REMOVE_PRODUCT_IMAGE",
  SET_LOADING_PRODUCT = "SET_LOADING_PRODUCT",
  SET_LOADING_PRODUCT_IMAGE_ADD = "SET_LOADING_PRODUCT_IMAGE_ADD",
  SET_LOADING_PRODUCT_IMAGE_REMOVE = "SET_LOADING_PRODUCT_IMAGE_REMOVE",
  SET_ERROR_PRODUCT = "SET_ERROR_PRODUCT",
  SET_LAST_PATH_PRODUCT = "SET_LAST_PATH_PRODUCT",
}

export type ProductRequestAddImage = {
  productId: string;
  image: File;
};

export type ProductRequestRemoveImage = {
  productId: string;
  fileId: string;
};

export type ProductCategory = {
  categoryId: string;
  categoryName: string;
};

export type ImageProduct = {
  fileId: string;
  imageUrl: string;
};

export type VideoMetaData = {
  title: string;
  type: string;
  url: string;
};

export type ProductPrice = {
  productId: string;
  catalogPrice: number;
  retailDiscountFormula: string;
  retailDiscountNote: string;
  retailDiscountAmount: number;
  retailPrice: number;
  goldPrice: number;
  goldDiscountFormula: string;
  goldDiscountNote: string;
  goldDiscountAmount: number;
  platinumPrice: number;
  platinumDiscountFormula: string;
  platinumDiscountNote: string;
  platinumDiscountAmount: number;
  distributorPrice: number;
  createdAt?: string | Date;
  updatedAt?: string | Date;
};

export type ProductResponse = {
  productId: string;
  createdByUserId: string;
  productName: string;
  sku: string;
  metadata: {
    specifications: Array<string>;
    details: Array<string>;
  };
  description: string;
  published: boolean;
  createdAt?: string | Date;
  updatedAt?: string | Date;
  price: ProductPrice;
  videoMetaData: VideoMetaData[];
  initialWarranty: string;
  categories: CategoryResponse[];
  stock: {
    currentStock: number;
  };
  images: ImageProduct[];
  b2c: boolean;
  b2b: boolean;
  type?: string;
};

export type ProductPriceHistoryResponse = {
  id: string;
  productId: string;
  updatedBy: string;
  note: string;
  discountNote: {
    gold: string;
    silver: string;
    platinum: string;
  };
  discountAmount: {
    gold: number;
    silver: number;
    platinum: number;
  };
  retailPrice: {
    gold: number;
    silver: number;
    platinum: number;
  };
  createdAt?: string | Date;
  updatedAt?: string | Date;
};

export type ProductResponsePayload = {
  code: string | null;
  message: string | null;
  payload: {
    next: string;
    prev: string;
    count: number;
    results: ProductResponse[];
  };
};

export type HistoryProductPriceResponsePayload = {
  code: string | null;
  message: string | null;
  payload: {
    next: string;
    prev: string;
    count: number;
    results: ProductPriceHistoryResponse[];
  };
};

export type ProductState = {
  isLoadingProduct: boolean;
  data: ProductResponsePayload;
  product: ProductResponse;
  dataHistory: HistoryProductPriceResponsePayload;
  productHistory: ProductPriceHistoryResponse;
  error: any;
  lastPathProduct: string;
  isLoadingImageAdd: boolean;
  isLoadingImageRemove: boolean;
};

export type ProductPriceHistoryState = {
  data: ProductPriceHistoryResponse;
  productHistory: ProductPriceHistoryResponse;
  error: any;
};

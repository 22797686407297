import { BannerResponsePayload, BannerTypes, BannerResponse, BannerDeleteResponse } from '../../../types/banner.type'

export function findAllBanner(payload: BannerResponsePayload) {
  return {
    type: BannerTypes.FIND_ALL_BANNER,
    payload
  }
}

export function findOneBanner(payload: BannerResponse) {
  return {
    type: BannerTypes.FIND_ONE_BANNER,
    payload
  }
}

export function createBanner(payload: BannerResponse) {
  return {
    type: BannerTypes.CREATE_BANNER,
    payload
  }
}

export function updateBanner(payload: BannerResponse) {
  return {
    type: BannerTypes.UPDATE_BANNER,
    payload
  }
}

export function deleteBanner(payload: BannerDeleteResponse) {
  return {
    type: BannerTypes.DELETE_BANNER,
    payload
  }
}

export function setLoadingBanner(payload: boolean) {
  return {
    type: BannerTypes.SET_LOADING_BANNER,
    payload
  }
}

export function setErrorBanner(payload: any) {
  return {
    type: BannerTypes.SET_ERROR_BANNER,
    payload
  }
}

import axios from 'axios';
import { getHeadersMultiPart, getHeaders } from '../helpers/auth';

export async function apiFindAllRecipeTag(params: string) {
  const query = params || null
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/recipe-tag' + query,
    method: 'GET',
    headers
  });
}

export async function apiFindOneRecipeTag(recipeTagId: string) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/recipe-tag/' + recipeTagId,
    method: 'GET',
    headers
  });
}

export async function apiCreateRecipeTag(data: any) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/recipe-tag',
    method: 'POST',
    headers: headers,
    data
  });
}

export async function apiUpdateRecipeTag(data: any) {
  const headers = await getHeaders()
  console.log( data.recipeTagId)
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/recipe-tag/' + data.recipeTagId,
    method: 'PATCH',
    headers: headers,
    data
  });
}

export async function apiDeleteRecipeTag(recipeTagId: string) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/recipe-tag/' + recipeTagId,
    method: 'DELETE',
    headers
  });
}
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  Box,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Snackbar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  Button,
  Menu,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import { Add as AddIcon } from "@material-ui/icons";
import { Link } from "react-router-dom";
import AppButton from "../../components/Form/AppButton";
import COLORS from "../../styles/colors";
import HeaderPage from "../../components/Layout/HeaderPage";
import Search from "../../components/Form/Search";
import FilterDate from "../../components/Form/FilterDate";
import AppTabs from "../../components/Tabs/AppTabs";
import { useSelector, useDispatch } from "react-redux";
import useDebounce from "../../hooks/useDebounce";
import useTransaction from "../../hooks/useTransaction";
import { ApplicationState } from "../../redux/store";
import {
  findAllTransaction,
  setOneTransaction,
} from "../../redux/action/transaction.action";
import { convertDateTimeToText } from "../../helpers/functions/datetime";
import FormInput from "../../components/Form/FormInput";
import {
  TransactionListResponse,
  TransactionLog,
} from "../../types/transaction.types";
import StatusTransaction from "../../components/StatusTransaction";
import { Alert } from "@material-ui/lab";
import { useAuth } from "../../hooks/useAuth";
import { generateQueryString } from "../../helpers/functions/generateQueryString";
import { useLocation } from "react-router-dom";
import {
  setLatestParams,
  enableUpdateParams,
} from "../../redux/action/config-app.action";
import { useHistory } from "react-router-dom";
import { ISingleAro } from "../../types/aro.type";
import { httpRequest } from "../../helpers/api/api";
import { parseURLSearch } from "../../helpers/functions/url";
import {
  apiFetchListServiceCenter,
  apiFetchListServiceCenterTransaction,
  ServiceCenterTransactionType,
  ServiceCenterType,
} from "../../api/service-center";
import useServiceCenterTransaction from "../../hooks/useServiceCenterTransaction";
import { findOneServiceCenterTransaction } from "../../redux/action/service-center-transaction.action";
import useGlobalStyles from "../../styles/globalStyles";
import useServiceCenter from "../../hooks/useServiceCenter";
import { ChevronDown } from "react-feather";
import { EStatus } from "../../types/service-center.types";

let headers: ITableHeader[] = [
  {
    title: "serviceCenterId",
    column: "serviceCenterId",
    type: "id",
  },
  {
    title: "Service Center Name",
    column: "name",
    sortable: true,
  },
  {
    title: "Phone",
    align: "left",
    sortable: true,
    column: "phoneNumber",
  },
  {
    title: "Status",
    align: "left",
    sortable: true,
    column: "status",
  },
  {
    title: "Created At",
    align: "left",
    column: "createdAt",
  },
];

export default function ServiceCenter() {
  const location = useLocation();
  const globalClasses = useGlobalStyles();
  const history = useHistory();
  const { aroId } = parseURLSearch(location.search);

  const classes = useStyles();
  const auth = useAuth();

  const [status, setStatus] = useState("");
  const [changeStatus, setChangeStatus] = useState<string>("");
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const serviceCenterState = useSelector(
    (state: ApplicationState) => state.serviceCenter
  );
  const { updateParams, latestParams } = useSelector(
    (state: ApplicationState) => state.configApp
  );
  const dispatch = useDispatch();
  const [serviceCenter, setServiceCenter] = useState<ServiceCenterType[]>([]);

  const [filterStatus, setFilterStatus] = useState("ASC");
  const [search, setSearch] = useState<string | null>(null);
  const [query, setQuery] = useState({
    offset: 0,
    limit: 25,
    search: "",
    status: "",
    startAt: "",
    endAt: "",
    orderBy: "ASC",
  });
  const [dateTemp, setDateTemp] = useState({
    startAt: "",
    endAt: "",
  });

  const [anchorElPickupDO, setAnchorElPickupDO] = React.useState<any>();

  const {
    fetchAllServiceCenter,
    updateDataServiceCenter,
    removeDataServiceCenter,
  } = useServiceCenter();

  const debouncedCustomerName = useDebounce(search, 300);

  const debouncedStatus = useDebounce(status, 300);

  useEffect(() => {
    setQuery((prev) => ({
      ...prev,
      status: debouncedStatus,
    }));
  }, [debouncedStatus]);

  useEffect(() => {
    searchCustomerName(search);
  }, [debouncedCustomerName]);

  const searchCustomerName = async (value: any) => {
    if (value !== null && value.length > 0) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          offset: 0,
          limit: 25,
          search: value,
        };
      });
      setPage(1);
    } else if (value !== null) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          search: "",
        };
      });
    }
  };

  const handleClose = () => {
    setAnchorElPickupDO(null);
  };

  useEffect(() => {
    if (updateParams) {
      console.info("update true");
      console.log(query);
      dispatch(
        setLatestParams({
          pathname: location.pathname,
          params: generateQueryString(query),
          query: {
            limit: query["limit"],
            offset: query["offset"],
            page,
          },
        })
      );
      fetchAllServiceCenter(query);
      setDateTemp({
        startAt: "",
        endAt: "",
      });
    } else {
      console.info("update false");
      setPage(latestParams.query.page || page || 1);
      fetchAllServiceCenter(latestParams.query);
      dispatch(enableUpdateParams(true));
      setDateTemp({
        startAt: latestParams.query.startAt,
        endAt: latestParams.query.endAt,
      });
    }
  }, [query]);

  useEffect(() => {
    setTotalData(serviceCenterState.data.payload.count);
    if (serviceCenterState.data.payload.count > 0) {
      setTotalPage(
        Math.ceil(serviceCenterState.data.payload.count / rowsPerPage)
      );
    } else {
      setTotalPage(1);
    }
  }, [serviceCenterState.data]);

  function changePagination(value: number) {
    setPage(value);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
      };
    });
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value);
    setPage(1);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        startAt: dateTemp.startAt || oldVal.startAt,
        endAt: dateTemp.endAt || oldVal.endAt,
        offset: 0,
        limit: value,
      };
    });
  }

  const data = serviceCenterState.data.payload.results.map((item: any) => {
    return {
      ...item,
      // aroLabel:item?.aroMetadata?.labelName,
      name: (
        <div>
          <Typography variant="body1">{item.name}</Typography>
        </div>
      ),
      phoneNumber: <Typography variant="body1">{item.phoneNumber}</Typography>,
      status: (
        // <Box>
        //   <Select
        //     label="Service Center"
        //     labelId="select-service-center"
        //     // multiple={true}
        //     value={item.status}
        //     onChange={(event: any) => {
        //       setChangeStatus(event.target.value);
        //       updateDataServiceCenter(item.serviceCenterId, {
        //         status: event.target.value
        //       }, true)
        //     }}
        //     displayEmpty={true}
        //   >
        //     <MenuItem value='active'>ACTIVE</MenuItem>
        //     <MenuItem value='inactive'>IN ACTIVE</MenuItem>
        //   </Select>
        // </Box>
        <Chip
          size="small"
          color="primary"
          label={item.status}
          style={{ marginRight: 5, textTransform: "uppercase" }}
        />
      ),
      createdAt: (
        <Typography variant="body1">
          {convertDateTimeToText(item.createdAt, "dd mmm yyyy")}
        </Typography>
      ),
    };
  });

  function setFilter(sort: "ASC" | "DESC") {
    setFilterStatus(sort);
    if (sort === "ASC") {
      setQuery((val: any) => {
        return {
          ...val,
          orderBy: "ASC",
        };
      });
    } else if (sort === "DESC") {
      setQuery((val: any) => {
        return {
          ...val,
          orderBy: "DESC",
        };
      });
    }
  }

  return (
    <Layout
      title="Service Center"
      loading={serviceCenterState.isLoadingServiceCenter}
    >
      <HeaderPage
        title="Service Center"
        subtitle="List of all Service Center"
      />

      <Grid
        container
        spacing={3}
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 10 }}
      >
        <Grid item xs={12} md={6} lg={6}>
          <Link
            to={{ pathname: "service-center-list-create" }}
            className={globalClasses.link}
          >
            <AppButton
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
            >
              Add Service Center
            </AppButton>
          </Link>
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <Search
            placeholder="Search by name"
            onChange={(value: string) => setSearch(value)}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={3}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="select-status" shrink>
              Status
            </InputLabel>
            <Select
              label="Service Center"
              labelId="select-service-center"
              // multiple={true}
              value={status}
              onChange={(event: any) => {
                setStatus(event.target.value);
              }}
              displayEmpty={true}
            >
              <MenuItem value="">ALL</MenuItem>
              <MenuItem value="active">ACTIVE</MenuItem>
              <MenuItem value="inactive">IN ACTIVE</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: COLORS.grey200,
            padding: 0,
            borderRadius: 10,
          }}
        >
          <CustomizedTable
            data={data}
            headers={headers}
            totalData={totalData}
            page={page}
            totalPage={totalPage}
            rowsPerPage={rowsPerPage}
            onChange={changePagination}
            onRowsPerPageChange={changeRowsPerPage}
            showAction={["detail", "edit", "delete"]}
            onDeleteItem={(data: any) => {
              removeDataServiceCenter(data.serviceCenterId);
            }}
          />
        </Grid>
      </Grid>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardMedia: {
      borderColor: COLORS.grey,
      borderWidth: 1,
      borderStyle: "solid",
      width: 69,
      height: 69,
      borderRadius: 10,
      backgroundColor: COLORS.accent,
    },
    aroChip: {
      borderColor: COLORS.warn,
      backgroundColor: COLORS.accent,
      borderRadius: 10,
      marginRight: 5,
      padding: 1,
    },
  })
);

import { Box, Paper, createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import FormInput from "../../components/Form/FormInput";
import AppButton from "../../components/Form/AppButton";
import COLORS from "../../styles/colors";
import UploadImage from "../../components/Form/UploadImage";
import HeaderPage from "../../components/Layout/HeaderPage";
import useAuth from '../../hooks/useAuth';
import { apiUpdateAdmin } from '../../api/admin';
import { useHistory } from 'react-router-dom'
import { setLoadingAdmin } from '../../redux/action/admin.action';
import { useDispatch } from 'react-redux';

export default function MyProfileEdit() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, fetchMyProfile } = useAuth();

  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [updatedImage, setUpdatedImage] = useState<any>();

  useEffect(() => {
    setName(user.name)
    setPhoneNumber(user.phoneNumber)
  }, [user])

  const classes = useStyles();

  const cancel = () => {
    history.go(-1)
  };

  const submit = async () => {
    dispatch(setLoadingAdmin(true));
    const newUser: any = {
      adminId: user.adminId,
      email: user.email,
      name,
      phoneNumber,
      role: user.role,
      status: user.status,
      updatedImage,
    };

    const formData = new FormData();
    Object.keys(newUser).forEach((key) => {
      if (key === 'updatedImage') {
        formData.append('image', newUser[key] as any);
      } else {
        formData.append(key, String(newUser[key]) as any);
      }
    });
    try {
      const res = await apiUpdateAdmin(newUser.adminId as string, formData)
      if (res.data && res.data.payload && res.data.code === 'success') {
        await fetchMyProfile()
        history.go(-1)
      }
    } catch (err) {
      console.info(err);
      dispatch(setLoadingAdmin(false));
    }
  };

  return (
    <Layout title="Edit Profile">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <HeaderPage
          title="Edit Profile"
          subtitle="Customize your profile data"
          showBackButton
          onBack={() => history.go(-2)}
        />

        <Box display="flex" justifyContent="flex-end">
          <AppButton color="secondary" onClick={cancel}>
            Cancel
          </AppButton>
          <AppButton color="primary" onClick={submit}>
            Save
          </AppButton>
        </Box>
      </Box>

      <Paper elevation={3} className={classes.paper}>
        <Box display="flex" flexWrap="wrap" className={classes.containerForm}>
          <Box width="30%">
            <UploadImage
              image={user.imageUrl}
              editable={true}
              onFileSelected={(value: any) => {
                setUpdatedImage(value);
              }}
            />
          </Box>
          <Box width="35%" className={classes.input}>
            <FormInput
              onChange={(value: any) => setName(value)}
              fullWidth
              label="Name"
              value={name}
            />
            <FormInput
              onChange={(value: any) => setPhoneNumber(value)}
              fullWidth
              label="Phone"
              value={phoneNumber}
            />
            <FormInput
              onChange={() => false}
              disabled
              fullWidth
              label="Email"
              value={user.email}
            />
          </Box>
          <Box width="35%" className={classes.input}>
            <FormInput
              onChange={() => false}
              disabled
              fullWidth
              label="Status"
              value={user.status.toUpperCase()}
            />
            <FormInput
              onChange={() => false}
              disabled
              fullWidth
              label="Role"
              value={user.role.toUpperCase()}
            />
          </Box>
        </Box>
      </Paper>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 0, 0, 0),
      padding: theme.spacing(2),
    },
    containerForm: {
      paddingTop: 20
    },
    input: {
      padding: theme.spacing(0, 2, 1, 2),
    },
    paperTitle: {
      padding: theme.spacing(2),
    },
    containerButton: {
      flexDirection: "row",
      padding: theme.spacing(2),
    },
    formStatus: {
      width: "100%",
      margin: theme.spacing(1, 0, 2, 0),
    },
    cardRoot: {
      width: "100%",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      backgroundColor: COLORS.greyLighten,
      marginTop: 10,
    },
    cardMedia: {
      height: 200,
      width: 200,
      alignSelf: "center",
      backgroundColor: COLORS.accent,
      borderColor: COLORS.accent,
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 10,
    },
    cardActionArea: {
      display: "flex",
      padding: 10,
    },
    formBranch: {
      width: "100%",
      margin: theme.spacing(1, 0, 2, 0),
    },
  })
);

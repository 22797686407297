export enum PickupPointTypes {
  FIND_ALL_PICKUP_POINT = "FIND_ALL_PICKUP_POINT",
  FIND_ALL_PICKUP_POINT_BY_ARO = "FIND_ALL_PICKUP_POINT_BY_ARO",
  FIND_ONE_PICKUP_POINT = "FIND_ONE_PICKUP_POINT",
  CREATE_PICKUP_POINT = "CREATE_PICKUP_POINT",
  UPDATE_PICKUP_POINT = "UPDATE_PICKUP_POINT",
  REMOVE_PICKUP_POINT = "REMOVE_PICKUP_POINT",
  SET_LOADING_PICKUP_POINT = "SET_LOADING_PICKUP_POINT",
  SET_ERROR_PICKUP_POINT = "SET_ERROR_PICKUP_POINT",
  SET_LAST_PATH_PICKUP_POINT = "SET_LAST_PATH_PICKUP_POINT",
}

export enum EStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export type metaAddressType = {
  province: string;
  city: string;
  subdistrict: string;
  address: string;
  location: {
    lat: any;
    lng: any;
  };
};

export type PickupPointType = {
  pickupPointId?: string;
  aroId?: string | null;
  namePickupPoint: string;
  phoneNumber: string;
  metaAddress: metaAddressType;
  status: EStatus;
  createdAt?: Date;
  updatedAt?: Date;
};

export type PickupPointResponsePayload = {
  code: string | null;
  message: string | null;
  payload: {
    next: string;
    prev: string;
    count: number;
    results: PickupPointType[];
  };
};

export type PickupPointState = {
  isLoadingPickupPoint: boolean;
  data: PickupPointResponsePayload;
  pickupPoint: PickupPointType;
  error: any;
  isSuccess: boolean;
};


export enum ConfigAppTypes {
  SET_UPLOAD_IMAGE_PAYLOAD = 'SET_UPLOAD_IMAGE_PAYLOAD',
  SET_LATEST_PARAMS = 'SET_LATEST_PARAMS',
  UPDATE_PARAM = 'UPDATE_PARAM',
  SET_LATEST_STATE = 'SET_LATEST_STATE',
  SET_LATEST_ACTIVE_TAB = 'SET_LATEST_ACTIVE_TAB',
}

export type ConfigAppState = {
  isSuccessUploadImage: boolean,
  latestParams: {
    pathname: string,
    params: string,
    query: any,
    activeTab?: number
  },
  updateParams: boolean
  state: any,
  activeTab: number | null,
}
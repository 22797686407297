import { ConfigAppState, ConfigAppTypes } from '../../types/config.type';
import { Reducer } from 'redux';

export const initLatestParams: ConfigAppState['latestParams'] = {
  pathname: '',
  params: '',
  query: {}
}

const initialState: ConfigAppState = {
  isSuccessUploadImage: false,
  latestParams: { ...initLatestParams },
  updateParams: true,
  state: null,
  activeTab: 0
}

export const configApp: Reducer<ConfigAppState> = (state = initialState, action) => {
  switch (action.type) {
    case ConfigAppTypes.SET_UPLOAD_IMAGE_PAYLOAD:
      return {
        ...state,
        isSuccessUploadImage: action.payload
      }
    case ConfigAppTypes.SET_LATEST_PARAMS:
      return {
        ...state,
        latestParams: action.payload
      }
    case ConfigAppTypes.UPDATE_PARAM:
      return {
        ...state,
        updateParams: action.payload
      }
    case ConfigAppTypes.SET_LATEST_STATE:
      return {
        ...state,
        state: action.payload
      }
    case ConfigAppTypes.SET_LATEST_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload
      }

    default:
      return state
  }
} 